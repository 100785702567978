import React, {
  useCallback,
  useState,
  useRef,
  useEffect,
  Fragment,
  Component,
  useImperativeHandle,
  forwardRef,
  useMemo,
} from 'react'
import clsx from 'clsx'
import { alpha, makeStyles } from '@material-ui/core/styles'
import { AutoSizer, MultiGrid } from 'react-virtualized'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Loading from '../Loading'
import {
  copyToClipboard,
  customFormatDecimals, generateUUID,
  getClickAction,
  isClippyActivated,
  openInNewTab,
  tellMeMore,
} from './Utils'
import { BootstrapHtmlTooltip, TransitionLeft, TransitionTop } from './Style'
import Badge from '@material-ui/core/Badge'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import WarningIcon from '@material-ui/icons/ReportProblemOutlined'
import ErrorIcon from '@material-ui/icons/ErrorOutline'
import SettingsIcon from '@material-ui/icons/Settings'
import { PUBLIC_URL } from '../../utils/config'
import IconButton from '@material-ui/core/IconButton'
import ClearIcon from '@material-ui/icons/Clear'
import { Tooltip } from '@material-ui/core'
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import { arrayMove, SortableContainer, SortableElement } from 'react-sortable-hoc'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Box from '@material-ui/core/Box'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import CardMedia from '@material-ui/core/CardMedia'
import TextField from '@material-ui/core/TextField'
import DeleteIcon from '@material-ui/icons/Delete'
import { useDispatch, useSelector } from 'react-redux'
import RotateLeftIcon from '@material-ui/icons/RotateLeft'
import { fetchDisplayJson } from '../../redux/json/actions'
import { displayPopupMessage, generateErrorPart } from './Message'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputAdornment from '@material-ui/core/InputAdornment'
import ErrorBoundaryGuard from '../ErrorBoundaryGuard'
import { t } from 'i18next'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import * as I18n from 'i18next'
import { Trans } from 'react-i18next'
import FilterListIcon from '@material-ui/icons/FilterList'
import Stack from '@mui/material/Stack'
import Menu from '@material-ui/core/Menu'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import ListItemText from '@material-ui/core/ListItemText'
import NewMenuItem from '@mui/material/MenuItem'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import SmartToyIcon from '@mui/icons-material/SmartToy'

const useStyles = makeStyles((theme) => ({
  disabled: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
  evenRow: {
    backgroundColor: '#ffffff',
  },
  oddRow: {
    backgroundColor: '#fafafa',
  },
  cell: {
    border: 'none',
  },
  actionContainer: {
    '& button': {
      padding: 0,
      marginRight: 5,
    },
    '& > button:hover': {
      color: theme.palette.secondary.main,
      backgroundColor: 'transparent',
    },
  },
  selected: {
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  cellConfig: {
    padding: 10,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  multiGrid: {
    padding: 10,
    '& .ReactVirtualized__Grid:focus': {
      outline: 'none',
    },
  },
  selectedRow: {
    cursor: 'pointer',
    background: 'rgba(0, 0, 0, 0.1)',
  },
  blink: {
    // animation: 'blink-fade 1s ease-in-out 0s',
    background: alpha(theme.palette.secondary.light, 0.19),
  },
  selectedRowBlink: {
    background: alpha(theme.palette.secondary.light, 0.37),
  },
  noBlink: {
    cursor: 'pointer',
  },
  flagInfo: {
    color: theme.palette.common.infoBlue,
    verticalAlign: 'bottom',
    fontSize: 20,
  },
  flagWarning: {
    color: theme.palette.common.warning,
    verticalAlign: 'bottom',
    fontSize: 20,
  },
  flagError: {
    color: theme.palette.common.error,
    verticalAlign: 'bottom',
    fontSize: 20,
  },
  chipTooltip: {
    fontSize: 14,
  },
  selectionBadge: {
    '& .MuiBadge-badge': {
      top: 10,
      right: 8,
    },
  },
  tooltip: {
    fontSize: 13,
    padding: 5,
  },
  helperTooltip: {
    fontSize: 13,
    padding: 5,
    fontWeight: 100,
    lineHeight: '1.3em',
  },
  settingsButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    '& button': {
      // opacity: 0,
      transition: 'all 0.1s ease',
      padding: 0,
      transform: 'scale(0)',
    },
    '& button:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.secondary.main,
    },
  },
  multiGridContainer: {
    '&:hover button': {
      // opacity: 1,
      transition: 'all 0.1s ease',
      transform: 'scale(1)',
    },
  },
  sortableTableHeader: {
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    borderRadius: 3,
    margin: '0px 10px',
    padding: '5px 10px',
    background: alpha(theme.palette.primary.main, 0.02),
    minWidth: 100,
    cursor: 'grab',
    verticalAlign: 'top',
  },
  sortableTableHeadertitle: {
    fontWeight: 600,
    whiteSpace: 'nowrap',
    borderBottom: '1px solid',
    borderBottomColor: alpha(theme.palette.primary.main, 0.35),
  },
  sorting: {
    zIndex: theme.zIndex.modal + 100,
  },
  header: {
    boxShadow: 'inset 0px 0px 82px 10px rgba(0,0,0,1)',
  },
  cardImg: {
    opacity: 0.8,
  },
  title: {
    position: 'absolute',
    top: 55,
    left: 34,
    fontSize: 40,
    color: theme.palette.secondary.main,
    textShadow: '2px 1px 10px white',
  },
  columnSizetextfield: {
    width: '100%',
    marginTop: 10,
    '& input': {
      padding: 8,
      background: 'white',
    },
  },
  deletePrefsButton: {
    color: 'white',
    background: theme.palette.common.error,
    '&:hover': {
      backgroundColor: '#a10404',
    },
  },
  selectFormat: {
    '& > div': {
      padding: '8px 32px 8px 10px',
      background: 'white',
    },
  },
  columnDecimals: {
    '& .MuiOutlinedInput-adornedStart': {
      paddingLeft: 8,
    },
  },
  activeFilterColumn: {
    color: theme.palette.secondary.main + ' !important',
  },
  firstColumnForGlobal: {
    fontWeight: 'bold',
  },
}))

const STYLE_BOTTOM_RIGHT_GRID = {
  '&:focus': {
    outline: 'none',
  },
}
const STYLE_TOP_LEFT_GRID = {
  fontWeight: 'bold',
}
const STYLE_TOP_RIGHT_GRID = {
  fontWeight: 'bold',
}

const TableMultiGrid = function New_Table_MultiGrid({
  global,
  convertGlobalItem,
  data,
  isFetchingData,
  orderBy: propOrderBy,
  idTable,
  selection = false,
  onSelect: propOnSelect,
  onOpenMultiSelectionDialog,
  onMoleculeClick,
  onMoleculeDoubleClick,
  sortAction,
  columns: propsColumns,
  fixedColumnCount,
  fixedRowCount,
  rowHeight,
  getColumnRender,
  scrollTo = 0,
  tableItemSelectedForColor,
  onMoleculeOverExtra,
  settingsOption = true,
  forwardedRef,
  collection,
  parentMultiGridRef,
  chromatoGridRef,
  aiAssistantActivated,
  matrixes,
  contextMenu = null,
  setContextMenu = function () {},
}) {
  useImperativeHandle(forwardedRef, () => ({
    clearSelection() {
      setSelectedRows([])
      // Update the chromato grid selection
      if (chromatoGridRef) chromatoGridRef.current.customSelection([])
      if (propOnSelect) propOnSelect([])
    },
    customSelection(selection, fromchromatoGrid) {
      setSelectedRows(selection)
      // Update the chromato grid selection
      if (chromatoGridRef && !fromchromatoGrid) chromatoGridRef.current.customSelection(selection)
      if (propOnSelect) propOnSelect(selection)
    },
    reloadGrid() {
      forceUpdateGrid()
    },
  }))

  const classes = useStyles()
  const dispatch = useDispatch()

  const orderBySplit = propOrderBy.split('$')
  const settingsVisibilitySaved = localStorage.getItem('TABLE_' + idTable + '_columns_visible')
    ? localStorage
        .getItem('TABLE_' + idTable + '_columns_visible')
        .split('[|,|]')
        .reduce(function (map, obj) {
          map[obj.split('[|:|]')[0]] = obj.split('[|:|]')[1] === 'true'
          return map
        }, {})
    : {}
  let settingsOrderSaved = localStorage.getItem('TABLE_' + idTable + '_columns_order')
    ? localStorage.getItem('TABLE_' + idTable + '_columns_order').split('[|,|]')
    : []
  settingsOrderSaved.push(
    ...propsColumns
      .map((column) => column.key)
      .filter((key) =>
        localStorage.getItem('TABLE_' + idTable + '_columns_order')
          ? localStorage
              .getItem('TABLE_' + idTable + '_columns_order')
              .split('[|,|]')
              .indexOf(key) == -1
          : key,
      ),
  )
  const settingsSizeSaved = localStorage.getItem('TABLE_' + idTable + '_columns_size')
    ? localStorage
        .getItem('TABLE_' + idTable + '_columns_size')
        .split('[|,|]')
        .reduce(function (map, obj) {
          map[obj.split('[|:|]')[0]] = parseInt(obj.split('[|:|]')[1])
          return map
        }, {})
    : {}
  const settingsDecimalsSaved = localStorage.getItem('TABLE_' + idTable + '_columns_decimals')
    ? localStorage
        .getItem('TABLE_' + idTable + '_columns_decimals')
        .split('[|,|]')
        .reduce(function (map, obj) {
          map[obj.split('[|:|]')[0]] = parseInt(obj.split('[|:|]')[1])
          return map
        }, {})
    : {}

  const settingsFormatSaved = localStorage.getItem('TABLE_' + idTable + '_columns_format')
    ? localStorage
        .getItem('TABLE_' + idTable + '_columns_format')
        .split('[|,|]')
        .reduce(function (map, obj) {
          map[obj.split('[|:|]')[0]] = obj.split('[|:|]')[1]
          return map
        }, {})
    : {}

  const [moleculeOver, setMoleculeOver] = useState(null)
  const [moleculeColumnOver, setMoleculeColumnOver] = useState(null)
  const [order, setOrder] = useState(orderBySplit[0])
  const [orderBy, setOrderBy] = useState(orderBySplit[1])
  const [selectedRows, setSelectedRows] = useState([])
  const [scrollToRow, setScrollToRow] = useState(0)
  const [selected, setSelected] = useState(null)
  const [openSettingsPanel, setOpenSettingsPanel] = useState(false)
  const [settingsOrder, setSettingsOrder] = useState(
    localStorage.getItem('TABLE_' + idTable + '_columns_order')
      ? // ? localStorage.getItem('TABLE_' + idTable + '_columns_order').split('[|,|]')
        settingsOrderSaved
      : propsColumns.map((column) => column.key),
  )
  const [settingsVisible, setSettingsVisible] = useState(
    localStorage.getItem('TABLE_' + idTable + '_columns_visible')
      ? // ? localStorage
        //     .getItem('TABLE_' + idTable + '_columns_visible')
        //     .split('[|,|]')
        //     .reduce(function (map, obj) {
        //       map[obj.split('[|:|]')[0]] = obj.split('[|:|]')[1] === 'true'
        //       return map
        //     }, {})
        propsColumns.reduce(function (map, obj) {
          map[obj.key] =
            typeof settingsVisibilitySaved[obj.key] === 'boolean'
              ? settingsVisibilitySaved[obj.key]
              : true
          return map
        }, {})
      : propsColumns.reduce(function (map, obj) {
          map[obj.key] = typeof obj.visible === 'boolean' ? obj.visible : true
          return map
        }, {}),
  )
  const [settingsSize, setSettingsSize] = useState(
    localStorage.getItem('TABLE_' + idTable + '_columns_size')
      ? // ? localStorage
        //     .getItem('TABLE_' + idTable + '_columns_size')
        //     .split('[|,|]')
        //     .reduce(function (map, obj) {
        //       map[obj.split('[|:|]')[0]] = parseInt(obj.split('[|:|]')[1])
        //       return map
        //     }, {})
        propsColumns.reduce(function (map, obj) {
          map[obj.key] =
            typeof settingsSizeSaved[obj.key] === 'number' ? settingsSizeSaved[obj.key] : obj.size
          return map
        }, {})
      : propsColumns.reduce(function (map, obj) {
          map[obj.key] = obj.size
          return map
        }, {}),
  )
  const [settingsDecimals, setSettingsDecimals] = useState(
    localStorage.getItem('TABLE_' + idTable + '_columns_decimals')
      ? propsColumns.reduce(function (map, obj) {
          map[obj.key] = settingsDecimalsSaved[obj.key]
          return map
        }, {})
      : propsColumns.reduce(function (map, obj) {
          map[obj.key] = 3
          return map
        }, {}),
  )
  const [settingsFormat, setSettingsFormat] = useState(
    localStorage.getItem('TABLE_' + idTable + '_columns_format')
      ? propsColumns.reduce(function (map, obj) {
          map[obj.key] = settingsFormatSaved[obj.key]
          return map
        }, {})
      : propsColumns.reduce(function (map, obj) {
          map[obj.key] = 'auto'
          return map
        }, {}),
  )

  const [open, setOpen] = React.useState(
    settingsOrder.reduce((json, value) => {
      json[value] = false
      return json
    }, {}),
  )

  const wikiCODHelper = useSelector((state) => state.settings.wikiCODHelper)
  const isFetchingFilters = useSelector((state) => state.filters.isFetchingFilters)
  const filters = useSelector((state) => state.filters.filters)
  const activeFilter = useMemo(() => {
    const activeFilterId = JSON.parse(localStorage.getItem('GLOBAL_filter_selected'))
    if (activeFilterId && filters.length > 0) {
      return filters.filter((f) => f._id === activeFilterId)[0]
    } else {
      return null
    }
  }, [JSON.stringify(filters), JSON.parse(localStorage.getItem('GLOBAL_filter_selected'))])
  const columnsFiltered = useMemo(() => {
    if (activeFilter && activeFilter.content.columnFilters.length > 0) {
      return activeFilter.content.columnFilters.map((cf) => cf.column)
    } else {
      return []
    }
  }, [JSON.stringify(activeFilter)])

  const [selectedColumn, setSelectedColumn] = useState(null)

  const handleCloseContextMenu = () => {
    setSelectedColumn(null)
    setContextMenu(null)
  }

  const handleClose = (key) => {
    setOpen((oldOpen) => ({ ...oldOpen, [key]: false }))
  }

  const handleOpen = (key) => {
    setOpen((oldOpen) => ({ ...oldOpen, [key]: true }))
  }

  let multiGridRef = useRef(null)
  if (parentMultiGridRef) {
    multiGridRef = parentMultiGridRef
  }
  const refSortContainer = useRef(null)

  useEffect(() => {
    const index = data.map((uaf) => uaf._id).indexOf(scrollTo)
    // const table = document.getElementById(idTable).firstElementChild.firstElementChild
    //   .children[1].children[1]
    // table.scrollTo({
    //   top: index * 40,
    //   // behavior: 'smooth',
    // })
    setSelected(scrollTo)
    setScrollToRow(index + 1)
  }, [scrollTo, data])

  const reloadMultiGrid = useCallback(
    function () {
      if (multiGridRef.current && multiGridRef.current.recomputeGridSize) {
        multiGridRef.current.recomputeGridSize()
      }
    },
    [multiGridRef],
  )
  const forceUpdateGrid = useCallback(
    function () {
      if (multiGridRef.current && multiGridRef.current.forceUpdateGrids) {
        multiGridRef.current.forceUpdateGrids()
      }
    },
    [multiGridRef],
  )

  if (isFetchingData) return <Loading mode="miniCircle" />

  const setItemSelectedColor = (id) => {
    setSelected(id)
  }

  const onSelect = (event, row) => {
    const checked = event.target.checked
    const shiftKey = event.nativeEvent.shiftKey
    let rows = []
    if (checked) {
      if (shiftKey) {
        let originMultiSelection = 0
        selectedRows
          .sort(function (a, b) {
            return b - a
          })
          .forEach(function (rowIndex) {
            if (rowIndex < row - 1 && originMultiSelection === 0) {
              originMultiSelection = rowIndex
            }
          })
        for (let i = originMultiSelection + 1; i < row - 1; i++) {
          rows.push(i)
        }
      }
      rows = [...rows, ...selectedRows, row - 1]
      setSelectedRows(rows)
      // Update the chromato grid selection
      if (chromatoGridRef) chromatoGridRef.current.customSelection(rows)
    } else {
      const index = selectedRows.indexOf(row - 1)
      selectedRows.splice(index, 1)
      rows = selectedRows
      setSelectedRows(rows)
      // Update the chromato grid selection
      if (chromatoGridRef) chromatoGridRef.current.customSelection(rows)
    }
    if (propOnSelect) propOnSelect(rows)
  }

  const onSelectAll = (event) => {
    const checked = event.target.checked
    // const dataFiltered = JSON.parse(JSON.stringify(data)).filter(
    //   (d) => d.tags.indexOf('off_visibility') === -1,
    // )
    const dataFiltered = JSON.parse(JSON.stringify(data))
      .map((objet, index) => (objet.tags && objet.tags.includes('off_visibility') ? null : index))
      .filter((index) => index !== null)
    let rows = null
    if (checked) {
      if (selectedRows.length > 0) {
        rows = []
        setSelectedRows(rows)
      } else {
        // rows = Array.from(Array(dataFiltered.length), (x, index) => index)
        rows = dataFiltered
        setSelectedRows(rows)
        if (onOpenMultiSelectionDialog) onOpenMultiSelectionDialog(rows)
      }
    } else {
      if (selectedRows.length === dataFiltered.length) {
        rows = []
        setSelectedRows(rows)
      }
    }
    // Update the chromato grid selection
    if (chromatoGridRef) chromatoGridRef.current.customSelection(rows)

    if (propOnSelect) propOnSelect(rows)
  }

  const customColumns = function (columns) {
    let customColumns = columns
    if (localStorage.getItem('TABLE_' + idTable + '_columns_order')) {
      // const columnsSettings = localStorage
      //   .getItem('TABLE_' + idTable + '_columns_order')
      //   .split('[|,|]')
      const columnsSettings = JSON.parse(JSON.stringify(settingsOrder))
      if (selection) columnsSettings.splice(0, 0, 'select')
      columns = columns.map((column) => ({
        ...column,
        order: columnsSettings.indexOf(column.key),
      }))
      customColumns = columns.sort(function (a, b) {
        return a.order - b.order
      })
    }
    if (localStorage.getItem('TABLE_' + idTable + '_columns_visible')) {
      // const columnsSettingsVisible = localStorage
      //   .getItem('TABLE_' + idTable + '_columns_visible')
      //   .split('[|,|]')
      //   .reduce(function (map, obj) {
      //     map[obj.split('[|:|]')[0]] = obj.split('[|:|]')[1] === 'true'
      //     return map
      //   }, {})
      const columnsSettingsVisible = settingsVisible
      let visibleColumns = []
      customColumns.forEach(function (column) {
        if (columnsSettingsVisible[column.key] || column.key === 'select') {
          visibleColumns.push(column)
        }
      })
      customColumns = visibleColumns
    }
    if (localStorage.getItem('TABLE_' + idTable + '_columns_size')) {
      // const columnsSettingsSize = localStorage
      //   .getItem('TABLE_' + idTable + '_columns_size')
      //   .split('[|,|]')
      //   .reduce(function (map, obj) {
      //     map[obj.split('[|:|]')[0]] = parseInt(obj.split('[|:|]')[1])
      //     return map
      //   }, {})
      const columnsSettingsSize = settingsSize
      let visibleColumns = customColumns.map(function (column) {
        if (column.key !== 'select') {
          column.size = columnsSettingsSize[column.key]
        }
        return column
      })
      customColumns = visibleColumns
    }
    if (localStorage.getItem('TABLE_' + idTable + '_columns_format')) {
      const columnsSettingsFormat = settingsFormat
      let formatColumns = customColumns.map(function (column) {
        if (column.type === 'number') {
          column.format = columnsSettingsFormat[column.key]
        }
        return column
      })
      customColumns = formatColumns
    }
    if (localStorage.getItem('TABLE_' + idTable + '_columns_decimals')) {
      const columnsSettingsDecimals = settingsDecimals
      let decimalsColumns = customColumns.map(function (column) {
        if (column.type === 'number') {
          column.decimals = columnsSettingsDecimals[column.key]
        }
        return column
      })
      customColumns = decimalsColumns
    }
    return customColumns
  }

  const columns = customColumns(
    selection
      ? [
          { key: 'select', label: t('view.common.table_multigrid.select'), size: 50 },
          ...propsColumns,
        ]
      : propsColumns,
  )

  const getClassName = function ({ columnIndex, rowIndex }) {
    // const rowClass = global
    //   ? rowIndex % 2 === 0
    //     ? columnIndex % 2 === 0
    //       ? classes.evenRow
    //       : classes.oddRow
    //     : columnIndex % 2 === 0
    //     ? classes.oddRow
    //     : classes.evenRow
    //   : rowIndex % 2 === 0
    //   ? classes.evenRow
    //   : classes.oddRow
    const rowClass = rowIndex % 2 === 0 ? classes.evenRow : classes.oddRow

    return clsx(rowClass, classes.cell, classes.cellConfig, {
      [classes.centeredCell]: columnIndex > 2,
    })
  }

  const onMoleculeOver = (row, column, active, itemId) => {
    setMoleculeOver(active ? row : null)
    if (global) setMoleculeColumnOver(active ? column : null)
    if (onMoleculeOverExtra) onMoleculeOverExtra(active && itemId ? itemId : null)
  }

  const columnHeaderClick = (columnIndex) => {
    const column = columns[columnIndex]
    const isAsc = orderBy === column.key && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(column.key)
    sortAction(column.key, isAsc ? 'desc' : 'asc')
  }

  const onDecimalsEnd = (event, label, forceSave, checkbox) => {
    const value = checkbox ? (event.target.checked ? 0 : -1) : parseFloat(event.currentTarget.value)
    clearTimeout(settingsTimer)
    if (forceSave) {
      setSettingsDecimals({ ...settingsDecimals, [label]: value })
    } else {
      settingsTimer = setTimeout(function () {
        setSettingsDecimals({ ...settingsDecimals, [label]: value })
      }, 1500)
    }
  }
  const onFormatEnd = (event, label) => {
    const value = event.target.value
    setSettingsFormat({ ...settingsFormat, [label]: value })
  }

  const customCellWithFlag = (flags, columnKey) => {
    var flags_dict = new Map()
    flags_dict.set('', -1)
    flags_dict.set('info', 0)
    flags_dict.set('warning', 1)
    flags_dict.set('error', 2)
    var level = ''
    var flagFiltered = []

    function getUpperFlag(flag, tag, columnKey, level) {
      if (flag.title === columnKey) {
        let icon = null
        switch (flag.level) {
          case 'info':
            icon = <InfoIcon className={classes.flagInfo} />
            break
          case 'warning':
            icon = <WarningIcon className={classes.flagWarning} />
            break
          case 'error':
            icon = <ErrorIcon className={classes.flagError} />
            break
          default:
            icon = null
            break
        }
        let msg =
          // flagFiltered.push(icon + ' - ' + tag + ': ' + flag.reason)
          // flagFiltered.push(`${icon} + ' - ' + ${tag} + ': ' + ${flag.reason}`)
          flagFiltered.push(
            <Fragment>
              {' '}
              {icon} {tag}: {flag.reason}
            </Fragment>,
          )
      }
      if (flag.title === columnKey && flags_dict.get(flag.level) > flags_dict.get(level)) {
        return (level = flag.level)
      }
      return level
    }

    const flags_globals = flags.globals ? flags.globals : []
    flags_globals.forEach((flag) => {
      level = getUpperFlag(flag, '[Globals]', columnKey, level)
    })
    const flags_channels = flags.channels ? flags.channels : {}
    const flags_channels_entries = Object.entries(flags_channels)
    if (flags_channels_entries.length > 0 && flags_dict.get(level) !== 2) {
      flags_channels_entries.forEach((channel, index1) => {
        channel[1].forEach((flag) => {
          let index = index1 + 1
          level = getUpperFlag(flag, 'Ch.' + index, columnKey, level)
        })
      })
    }
    return [level, flagFiltered]
  }

  let flagsDict = new Map()
  flagsDict.set('area', 'ISTD area')
  flagsDict.set('area', 'Blank area')
  // flagsDict.set('mass_conc', 'Concentration')
  flagsDict.set('amt_conc', 'Concentration')
  flagsDict.set('Bio matrix', 'Concentration')
  flagsDict.set('Dev std end', 'Concentration')
  flagsDict.set('s_n', 'S/N')
  flagsDict.set('devAccuracy', 'Accuracy')
  flagsDict.set('devRrt', 'Dev RRT Std end')
  flagsDict.set('devRrt', 'Dev RRT Calibration')
  flagsDict.set('areaRatio', 'Validity domain')
  for (
    let step = 1;
    step <=
    Number(localStorage.getItem('__config.json__NUMBER_CHANNEL_VISIBLE_FOR_VOLUMIC_CONCENTRATION'));
    step++
  ) {
    flagsDict.set('amt_conc_' + step, 'Concentration')
  }

  function getTooltipContent(key) {
    const startMarker = 'tooltip_start_' + key + '"></a>'
    const endMarker = '<a name="' + 'tooltip_end_' + key
    const startIndex = wikiCODHelper.indexOf(startMarker)
    const endIndex = wikiCODHelper.indexOf(endMarker)

    if (startIndex !== -1 && endIndex !== -1) {
      return wikiCODHelper.slice(startIndex + startMarker.length, endIndex)
    } else {
      return ''
    }
  }

  const _cellRenderer = ({ columnIndex, key, rowIndex, style, isScrolling }) => {
    // const item = data[rowIndex]
    const rowClassNames = getClassName({ columnIndex, rowIndex })
    const columnKey = columns[columnIndex].key
    const hideWikiTooltip = columns[columnIndex].hideWikiTooltip
    const moleculeOverState = moleculeOver
    if (rowIndex === 0) {
      if (columnIndex === 0 && columnKey === 'select')
        return (
          <Badge
            key={generateUUID()}
            badgeContent={selectedRows.length}
            max={999}
            color="secondary"
            className={classes.selectionBadge}
          >
            <Checkbox
              key={generateUUID()}
              checked={selectedRows.length === data.length && data.length !== 0}
              color="primary"
              indeterminate={selectedRows.length > 0 && selectedRows.length !== data.length}
              inputProps={{ 'aria-label': 'checkbox with default color' }}
              onChange={(e) => onSelectAll(e)}
            />
          </Badge>
        )
      const columnFilterActive = columnsFiltered.indexOf(columnKey) !== -1
      return (
        <Tooltip
          key={generateUUID()}
          disableHoverListener={
            (getTooltipContent(columns[columnIndex].key) === '' && !columnFilterActive) ||
            hideWikiTooltip
          }
          placement="top"
          arrow
          title={
            <div className={classes.helperTooltip}>
              <div
                className={classes.iframe}
                dangerouslySetInnerHTML={{
                  __html: getTooltipContent(columns[columnIndex].key)
                    ? getTooltipContent(columns[columnIndex].key)
                    : '',
                }}
              />
              {columnFilterActive && (
                <div>
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={0}
                  >
                    <FilterListIcon fontSize="small" style={{ marginRight: 5 }} />
                    {t('view.common.table_multigrid.column_filtered')}
                  </Stack>
                  <ul style={{ marginBlockStart: 0 }}>
                    {activeFilter.content.columnFilters
                      .filter((cf) => cf.column === columnKey)
                      .map((cf) => (
                        <li>
                          {cf.column} {cf.operator} {cf.value}
                        </li>
                      ))}
                  </ul>
                </div>
              )}
            </div>
          }
        >
          <TableSortLabel
            key={key}
            style={style}
            className={clsx(
              classes.cellConfig,
              columnFilterActive ? classes.activeFilterColumn : null,
            )}
            active={orderBy === columnKey}
            direction={orderBy === columnKey ? order : 'asc'}
            onClick={() => columnHeaderClick(columnIndex)}
            onContextMenu={(event) => {
              event.stopPropagation()
              event.preventDefault()
              const columnType = columns[columnIndex].type
              if (columnType === 'custom') {
                displayPopupMessage(
                  dispatch,
                  'info',
                  t('view.common.table_multigrid.copy_clipboard'),
                  t('view.common.table_multigrid.copy_clipboard_unavailable'),
                )
              } else {
                const columnKey = columns[columnIndex].key
                setSelectedColumn(columnKey)
                setContextMenu(
                  contextMenu === null
                    ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4, title: true }
                    : null,
                )
              }
            }}
          >
            {columns[columnIndex].customTitle
              ? columns[columnIndex].customTitle()
              : columns[columnIndex].label}
          </TableSortLabel>
        </Tooltip>
      )
    }

    // if (isScrolling)
    //   return (
    //     <div
    //       style={style}
    //       key={key}
    //       className={clsx(colorCell, rowIndex % 2 === 0 ? classes.evenRow : classes.oddRow)}
    //     >
    //       ...
    //     </div>
    //   )

    const item = data[rowIndex - 1]

    if (global && columnIndex === 0) {
      const columnFilterActive = columnsFiltered.indexOf(columnKey) !== -1
      return (
        // <TableSortLabel
        <div
          key={generateUUID()}
          style={style}
          className={clsx(
            rowIndex % 2 === 0 ? classes.evenRow : classes.oddRow,
            classes.cellConfig,
            columnFilterActive ? classes.activeFilterColumn : null,
            classes.firstColumnForGlobal,
          )}
          // active={orderBy === columnKey}
          // direction={orderBy === columnKey ? order : 'asc'}
          // onClick={() => columnHeaderClick(columnIndex)}
        >
          <Tooltip
            arrow
            placement="top"
            title={<div style={{ fontSize: 13, padding: 5 }}>{item.name}</div>}
          >
            <span>{item.name}</span>
          </Tooltip>
        </div>
        // </TableSortLabel>
      )
    }

    if (columnKey === 'select')
      return (
        <div
          style={style}
          key={generateUUID()}
          className={clsx(
            item?.tags && item?.tags?.indexOf('off_visibility') !== -1 ? classes.disabled : null,
            rowIndex % 2 === 0 ? classes.evenRow : classes.oddRow,
            moleculeOverState === rowIndex && classes.selectedRow,
            tableItemSelectedForColor === item._id ? classes.blink : classes.noBlink,
            moleculeOverState === rowIndex &&
              tableItemSelectedForColor === item._id &&
              classes.selectedRowBlink,
          )}
          onMouseEnter={() => onMoleculeOver(rowIndex, columnIndex, true, item._id)}
          onMouseLeave={() => onMoleculeOver(rowIndex, columnIndex, false)}
        >
          <Checkbox
            checked={selectedRows.includes(rowIndex - 1)}
            color="default"
            inputProps={{ 'aria-label': 'checkbox with default color' }}
            onChange={(e) => onSelect(e, rowIndex)}
          />
        </div>
      )

    const cellRender = getColumnRender(
      columnIndex,
      key,
      rowIndex,
      style,
      item,
      rowClassNames,
      columnKey,
      moleculeOverState,
      onMoleculeOver,
      tableItemSelectedForColor,
      onMoleculeClick,
      onMoleculeDoubleClick,
      columns,
    )
    if (cellRender) {
      return cellRender
    }
    let colorCell = null
    let tooltipTitle = null
    let flagLevel = null
    const flagDic = flagsDict.get(columnKey)
    if (item.flags && flagDic && item[columnKey] !== null && item[columnKey] !== '') {
      let [flagType, flagMsg] = customCellWithFlag(item.flags, flagDic)
      flagLevel = flagType
      switch (flagType) {
        case 'info':
          colorCell = classes.flagInfo
          break
        case 'warning':
          colorCell = classes.flagWarning
          break
        case 'error':
          colorCell = classes.flagError
          break
        default:
          colorCell = ''
      }

      tooltipTitle = flagMsg.map((msg, index) => {
        return (
          <React.Fragment key={item._id + columnKey + index + key}>
            {msg}
            <br />
          </React.Fragment>
        )
      })
    }

    // { console.log('tooltipTitle="' + tooltipTitle + '", level=' + flagLevel)}
    return tooltipTitle && tooltipTitle.length > 0 ? (
      <BootstrapHtmlTooltip
        title={tooltipTitle}
        className={classes.chipTooltip}
        level={flagLevel}
        key={generateUUID()}
      >
        <div
          style={{
            ...style,
            textAlign: columns[columnIndex].textAlign ? columns[columnIndex].textAlign : 'inherit',
          }}
          key={generateUUID()}
          className={clsx(
            item.disabled || (item?.tags && item?.tags?.indexOf('off_visibility') !== -1)
              ? classes.disabled
              : null,
            colorCell,
            rowClassNames,
            moleculeOverState === rowIndex && classes.selectedRow,
            tableItemSelectedForColor === item._id ? classes.blink : classes.noBlink,
            moleculeOverState === rowIndex &&
              tableItemSelectedForColor === item._id &&
              classes.selectedRowBlink,
          )}
          onMouseEnter={() => onMoleculeOver(rowIndex, columnIndex, true, item._id)}
          onMouseLeave={() => onMoleculeOver(rowIndex, columnIndex, false)}
          onClick={function (event) {
            const openFct = () => {
              onMoleculeClick(item)
            }
            getClickAction(dispatch, event, item, collection, openFct)
          }}
          onDoubleClick={() => onMoleculeDoubleClick(item)}
          onContextMenu={(event) => {
            event.stopPropagation()
            event.preventDefault()
            const columnType = columns[columnIndex].type
            const columnKey = columns[columnIndex].key
            // setSelectedColumn(columnKey)
            setContextMenu(
              contextMenu === null
                ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4, item: item }
                : null,
            )
          }}
        >
          {columns[columnIndex].customDisplay
            ? columns[columnIndex].customDisplay(item[columnKey], item, columns[columnIndex])
            : columns[columnIndex].type === 'number'
            ? customFormatDecimals(item[columnKey], item[columnKey], columns[columnIndex])
            : item[columnKey]}
        </div>
      </BootstrapHtmlTooltip>
    ) : (
      <div
        style={{
          ...style,
          textAlign: columns[columnIndex].textAlign ? columns[columnIndex].textAlign : 'inherit',
        }}
        key={generateUUID()}
        className={clsx(
          item.disabled || (item?.tags && item?.tags?.indexOf('off_visibility') !== -1)
            ? classes.disabled
            : null,
          rowClassNames,
          // global
          //   ? rowIndex % 2 === 0
          //     ? columnIndex % 2 === 0
          //       ? classes.evenRow
          //       : classes.oddRow
          //     : columnIndex % 2 === 0
          //       ? classes.oddRow
          //       : classes.evenRow
          //   : rowIndex % 2 === 0
          //     ? classes.evenRow
          //     : classes.oddRow,
          global
            ? moleculeColumnOver === columnIndex &&
                moleculeOverState === rowIndex &&
                classes.selectedRow
            : moleculeOverState === rowIndex && classes.selectedRow,
          global
            ? tableItemSelectedForColor.row === item.name &&
              tableItemSelectedForColor.column === columns[columnIndex].key
              ? classes.blink
              : classes.noBlink
            : tableItemSelectedForColor === item._id
            ? classes.blink
            : classes.noBlink,
          global
            ? moleculeColumnOver === columnIndex &&
                moleculeOverState === rowIndex &&
                tableItemSelectedForColor.row === item.name &&
                tableItemSelectedForColor.column === columns[columnIndex].key &&
                classes.selectedRowBlink
            : moleculeOverState === rowIndex &&
                tableItemSelectedForColor === item._id &&
                classes.selectedRowBlink,
        )}
        onMouseEnter={() => onMoleculeOver(rowIndex, columnIndex, true, item._id)}
        onMouseLeave={() => onMoleculeOver(rowIndex, columnIndex, false)}
        onClick={function (event) {
          let finalItem = item
          // Update the item in case of Global view
          if (global) {
            finalItem = convertGlobalItem(item, columns[columnIndex])
          }
          const openFct = () => {
            onMoleculeClick(finalItem)
          }
          getClickAction(dispatch, event, finalItem, collection, openFct)
        }}
        onDoubleClick={function () {
          let finalItem = item
          // Update the item in case of Global view
          if (global) {
            finalItem = convertGlobalItem(item, columns[columnIndex])
          }
          onMoleculeDoubleClick(finalItem)
        }}
        onContextMenu={(event) => {
          event.stopPropagation()
          event.preventDefault()
          let finalItem = item
          if (global) {
            finalItem = convertGlobalItem(item, columns[columnIndex])
          }
          setContextMenu(
            contextMenu === null
              ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4, item: finalItem }
              : null,
          )
        }}
      >
        {columns[columnIndex].customDisplay
          ? columns[columnIndex].customDisplay(item[columnKey], item, columns[columnIndex])
          : columns[columnIndex].type === 'number'
          ? customFormatDecimals(item[columnKey], item[columnKey], columns[columnIndex])
          : item[columnKey]}
      </div>
    )
  }

  const getColumnWidth = (width) =>
    function ({ index }) {
      const columnsSize = columns.reduce((acc, col) => acc + col.size, 0)
      const isLastColumn = index === columns.length - 1
      const delta = width - columnsSize - 10
      if (isLastColumn) {
        if (delta > 0) {
          return delta + columns[index].size
        }
      }
      return columns[index].size
    }

  const openSettings = function () {
    setOpenSettingsPanel(true)
  }

  const handleCloseSettingsPanel = function (save) {
    setOpenSettingsPanel(false)
    if (save) {
      localStorage.setItem('TABLE_' + idTable + '_columns_order', settingsOrder.join('[|,|]'))
      localStorage.setItem(
        'TABLE_' + idTable + '_columns_visible',
        Object.entries(settingsVisible)
          .map((visible) => visible[0] + '[|:|]' + visible[1])
          .join('[|,|]'),
      )
      localStorage.setItem(
        'TABLE_' + idTable + '_columns_size',
        Object.entries(settingsSize)
          .map((visible) => visible[0] + '[|:|]' + visible[1])
          .join('[|,|]'),
      )
      localStorage.setItem(
        'TABLE_' + idTable + '_columns_format',
        Object.entries(settingsFormat)
          .map((format) => format[0] + '[|:|]' + format[1])
          .join('[|,|]'),
      )
      localStorage.setItem(
        'TABLE_' + idTable + '_columns_decimals',
        Object.entries(settingsDecimals)
          .map((decimals) => decimals[0] + '[|:|]' + decimals[1])
          .join('[|,|]'),
      )
      // Force to refresh the table after modification
      setTimeout(() => {
        reloadMultiGrid()
      }, 500)
      displayPopupMessage(dispatch, 'info', 'Table preferences', 'The table preferences are saved.')
    } else {
      if (localStorage.getItem('TABLE_' + idTable + '_columns_order')) {
        setSettingsOrder(localStorage.getItem('TABLE_' + idTable + '_columns_order').split('[|,|]'))
        setSettingsVisible(
          localStorage
            .getItem('TABLE_' + idTable + '_columns_visible')
            .split('[|,|]')
            .reduce(function (map, obj) {
              map[obj.split('[|:|]')[0]] = obj.split('[|:|]')[1] === 'true'
              return map
            }, {}),
        )
        setSettingsSize(
          localStorage
            .getItem('TABLE_' + idTable + '_columns_size')
            .split('[|,|]')
            .reduce(function (map, obj) {
              map[obj.split('[|:|]')[0]] = parseInt(obj.split('[|:|]')[1])
              return map
            }, {}),
        )
      }
    }
  }

  const SortableItem = SortableElement(
    forwardRef(
      ({ columnKey, label, visible, size, decimals, format, type }, ref) => (
        <td ref={ref} tabIndex={0} className={classes.sortableTableHeader}>
          <div className={classes.sortableTableHeadertitle}>{label}</div>
          <Checkbox
            style={{ marginLeft: 'calc(50% - 12px)', padding: 0, marginTop: 6, marginBottom: 2 }}
            icon={<VisibilityOff />}
            checkedIcon={<Visibility />}
            name={label}
            checked={visible}
            onChange={(event) => onVisibleEnd(event, columnKey)}
          />
          <TextField
            className={classes.columnSizetextfield}
            label={t('view.common.table_multigrid.size')}
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            defaultValue={size}
            onChange={(event) => onSizeEnd(event, columnKey)}
            onBlur={(e) => onSizeEnd(e, columnKey, true)}
          />
          {type === 'number' && (
            <Fragment>
              <FormControl fullWidth size="small" style={{ marginTop: 13 }} variant="outlined">
                <InputLabel>Format</InputLabel>
                <Select
                  variant="outlined"
                  className={classes.selectFormat}
                  value={format}
                  label={t('view.common.table_multigrid.format')}
                  open={open[columnKey]}
                  onClose={() => handleClose(columnKey)}
                  onOpen={() => handleOpen(columnKey)}
                  onChange={(event) => onFormatEnd(event, columnKey)}
                >
                  <MenuItem key={'auto'} value={'auto'}>
                    {t('view.common.table_multigrid.auto')}
                  </MenuItem>
                  <MenuItem key={'std'} value={'std'}>
                    {t('view.common.table_multigrid.std')}
                  </MenuItem>
                  <MenuItem key={'sci'} value={'sci'}>
                    {t('view.common.table_multigrid.scientific')}
                  </MenuItem>
                </Select>
              </FormControl>
              <TextField
                className={clsx(classes.columnSizetextfield, classes.columnDecimals)}
                style={{ marginTop: 14, marginBottom: 8, background: 'white' }}
                label={t('view.common.table_multigrid.decimals')}
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: { min: 0 },
                  startAdornment: (
                    <InputAdornment position="start" style={{ width: 10 }}>
                      <Checkbox
                        checked={decimals !== -1 && format !== 'auto'}
                        disabled={format === 'auto'}
                        style={{ padding: 0 }}
                        size="small"
                        onChange={(event) => onDecimalsEnd(event, columnKey, true, true)}
                      />
                    </InputAdornment>
                  ),
                }}
                disabled={decimals === -1 || format === 'auto'}
                variant="outlined"
                defaultValue={decimals === -1 || format === 'auto' ? null : decimals}
                onChange={(event) => onDecimalsEnd(event, columnKey)}
                onBlur={(e) => onDecimalsEnd(e, columnKey, true)}
              />
            </Fragment>
          )}
        </td>
      ),
      {
        withRef: true,
      },
    ),
  )

  const SortableList = SortableContainer(({ orders, visibles, sizes, decimals, format }) => {
    return (
      <table ref={refSortContainer} style={{ borderSpacing: 15 }}>
        <tbody>
          <tr>
            {orders.map((value, index) => (
              <SortableItem
                key={`item-${value}`}
                columnKey={value}
                index={index}
                label={
                  propsColumns.filter((col) => col.key === value)[0]
                    ? propsColumns.filter((col) => col.key === value)[0].label
                    : value
                }
                type={
                  propsColumns.filter((col) => col.key === value)[0]
                    ? propsColumns.filter((col) => col.key === value)[0].type
                    : 'unknown'
                }
                visible={visibles[value]}
                size={sizes[value]}
                decimals={decimals[value]}
                format={format[value]}
              />
            ))}
          </tr>
        </tbody>
      </table>
    )
  })

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setSettingsOrder(arrayMove(settingsOrder, oldIndex, newIndex))
  }

  const onVisibleEnd = (event, label) => {
    setSettingsVisible({ ...settingsVisible, [label]: event.target.checked })
  }

  let settingsTimer = null
  const onSizeEnd = (event, label, forceSave) => {
    const value = parseFloat(event.currentTarget.value)
    clearTimeout(settingsTimer)
    if (forceSave) {
      setSettingsSize({ ...settingsSize, [label]: value })
    } else {
      settingsTimer = setTimeout(function () {
        setSettingsSize({ ...settingsSize, [label]: value })
      }, 1500)
    }
  }

  const cleanUserInfos = async () => {
    localStorage.removeItem('TABLE_' + idTable + '_columns_order')
    localStorage.removeItem('TABLE_' + idTable + '_columns_visible')
    localStorage.removeItem('TABLE_' + idTable + '_columns_size')

    setSettingsOrder(propsColumns.map((column) => column.key))
    setSettingsVisible(
      propsColumns.reduce(function (map, obj) {
        map[obj.key] = obj.visible ? obj.visible : true
        return map
      }, {}),
    )
    setSettingsSize(
      propsColumns.reduce(function (map, obj) {
        map[obj.key] = obj.size
        return map
      }, {}),
    )

    displayPopupMessage(
      dispatch,
      'info',
      t('view.common.table_multigrid.title'),
      t('view.common.table_multigrid.msg'),
    )
    setOpenSettingsPanel(false)

    // Force to refresh the table after modification
    setTimeout(() => {
      reloadMultiGrid()
    }, 500)
  }

  function copyToClipboardMenu(event) {
    const values = data.map((d) => d[selectedColumn])
    const valuesFormated = values.join('\n')
    copyToClipboard(event, valuesFormated)
    handleCloseContextMenu()
  }
  function handlerTellMeMore(event, item) {
    const matrix = matrixes?.[item.analyseId]
    if (item) {
      tellMeMore(dispatch, item, matrix)
    }
    handleCloseContextMenu()
  }

  return (
    <Fragment>
      <Dialog
        open={openSettingsPanel}
        onClose={() => handleCloseSettingsPanel(false)}
        TransitionComponent={TransitionLeft}
        aria-labelledby="settings-dialog-title"
      >
        <DialogTitle style={{ padding: 0 }}>
          <Box className={classes.header}>
            <CardMedia
              className={classes.cardImg}
              component="img"
              alt="header image"
              height="130"
              image={PUBLIC_URL + '/images/ua_settings.jpg'}
              title={t('view.common.table_multigrid.header_title')}
            />
            <span className={classes.title}>{t('view.common.table_multigrid.table_settings')}</span>
          </Box>
        </DialogTitle>
        <DialogContent style={{ padding: 0, margin: '0px 14px' }}>
          <div style={{ margin: '20px 0px 20px 10px', fontSize: '1.1rem' }}>
            {t('view.common.table_multigrid.can_reorder')}
          </div>
        </DialogContent>
        <DialogContent style={{ padding: 0, margin: '0px 14px' }}>
          {/*<div style={{ margin: '0px 0px 20px 10px' }}>*/}
          {/*  You can reorder, show and hide all the table column bellow:*/}
          {/*</div>*/}
          <SortableList
            lockToContainerEdges={true}
            lockAxis={'x'}
            axis={'x'}
            orders={settingsOrder}
            visibles={settingsVisible}
            sizes={settingsSize}
            onSortEnd={onSortEnd}
            helperClass={classes.sorting}
            decimals={settingsDecimals}
            format={settingsFormat}
          />
          {/*<SortableComponent />*/}
        </DialogContent>
        <DialogActions style={{ marginTop: 20 }}>
          <Button
            variant="contained"
            className={classes.deletePrefsButton}
            startIcon={<RotateLeftIcon />}
            onClick={cleanUserInfos}
          >
            {t('view.common.table_multigrid.reset')}
          </Button>
          <div style={{ width: 'calc(100% - 258px)' }}></div>
          <Button autoFocus onClick={() => handleCloseSettingsPanel(true)} color="primary">
            {t('view.common.table_multigrid.save')}
          </Button>
          <Button onClick={() => handleCloseSettingsPanel(false)} color="primary">
            {t('view.common.table_multigrid.cancel')}
          </Button>
        </DialogActions>
      </Dialog>
      <Menu
        open={contextMenu !== null}
        onClose={handleCloseContextMenu}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null ? { top: contextMenu?.mouseY, left: contextMenu?.mouseX } : undefined
        }
      >
        {contextMenu?.title && (
          <MenuItem onClick={copyToClipboardMenu}>
            <ListItemIcon style={{ minWidth: 36 }}>
              <FileCopyIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t('view.common.table_multigrid.copy_clipboard')}</ListItemText>
          </MenuItem>
        )}
        {aiAssistantActivated && !contextMenu?.title && (
          <NewMenuItem onClick={(evt) => handlerTellMeMore(evt, contextMenu.item)}>
            <ListItemIcon>
              {isClippyActivated() ? (
                <AttachFileIcon fontSize="small" />
              ) : (
                <SmartToyIcon fontSize="small" />
              )}
            </ListItemIcon>
            <ListItemText>{t('view.analysis.analysis.tell_me_more')}</ListItemText>
          </NewMenuItem>
        )}
      </Menu>
      <AutoSizer onResize={reloadMultiGrid}>
        {({ width, height }) => (
          <span className={classes.multiGridContainer}>
            <MultiGrid
              scrollToRow={scrollToRow}
              ref={multiGridRef}
              cellRenderer={_cellRenderer}
              columnWidth={getColumnWidth(width)}
              columnCount={columns.length}
              enableFixedColumnScroll
              enableFixedRowScroll
              fixedColumnCount={fixedColumnCount}
              fixedRowCount={fixedRowCount}
              height={height}
              rowHeight={rowHeight}
              rowCount={data.length + 1}
              styleBottomRightGrid={STYLE_BOTTOM_RIGHT_GRID}
              styleTopLeftGrid={STYLE_TOP_LEFT_GRID}
              styleTopRightGrid={STYLE_TOP_RIGHT_GRID}
              width={width}
              hideTopRightGridScrollbar
              hideBottomLeftGridScrollbar
            />
            {settingsOption && (
              <div className={classes.settingsButton}>
                <Tooltip
                  arrow
                  title={
                    <div className={classes.tooltip}>{t('view.common.table_multigrid.edit')}</div>
                  }
                >
                  <IconButton aria-label="settings" onClick={() => openSettings()}>
                    <SettingsIcon />
                  </IconButton>
                </Tooltip>
              </div>
            )}
          </span>
        )}
      </AutoSizer>
    </Fragment>
  )
}
export default React.forwardRef((props, forwardedRef) => {
  return (
    <ErrorBoundaryGuard>
      <TableMultiGrid {...props} forwardedRef={forwardedRef} />
    </ErrorBoundaryGuard>
  )
})
