/* eslint-disable max-len */
import React, { Fragment, useMemo } from 'react'
import {
  fetchSaveUASettings,
  fetchUpdateAnalysePeak,
  fetchUpdateCompoundPeak,
  fetchUpdateMainChannel,
  fetchUpdateUAValidation,
  fetchUpdateCompoundISTD,
  fetchUnitaryAnalysesDistinct,
  fetchUnitaryCompoundDistinct,
  fetchUpdateGlobalPeak,
  fetchResetAnalysePeak,
  fetchResetCompoundPeak,
} from '../../redux/unitaryAnalyses/actions'
import {
  fetchUpdateUCValidation,
  fetchUpdateISTD,
  fetchUnitaryCalibrationsDistinct,
} from '../../redux/unitaryCalibrations/actions'
import {
  fetchChangeAnalysesType,
  fetchCreateSubBatch,
  fetchCreateTrainingBatch,
  fetchMoveSampleToSubBatch,
  receiveCurrentBatchLease,
} from '../../redux/batches/actions'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Tooltip from '@material-ui/core/Tooltip'
import {
  API_BO_URL,
  API_URL,
  DATABASE,
  FLASK_BASE_URL,
  FLASK_URL,
  GET_SECONDARY_COLOR,
  SERVICE,
} from '../../utils/config'
import {
  fetchAnalyses,
  fetchComputeAIAnalyse,
  fetchUpdateAnalyse,
} from '../../redux/analyses/actions'
import { fetchBatch, fetchBatches } from '../../redux/batches/actions'
import Slide from '@material-ui/core/Slide'
import Chip from '@material-ui/core/Chip'
import { withStyles } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'
import DialogTitle from '@material-ui/core/DialogTitle'
import TableRow from '@material-ui/core/TableRow'
import { fetchAddTask, fetchFinishTask } from '../../redux/tasks/actions'
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import Button from '@material-ui/core/Button'
import { fetchDisplayJson } from '../../redux/json/actions'
import { displayPopupMessage, generateErrorPart } from './Message'
import { fetchChangeSettings } from '../../redux/settings/actions'
import { t } from 'i18next'
import { Trans } from 'react-i18next'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import IconButton from '@mui/material/IconButton'
import { useSelector } from 'react-redux'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import WarningIcon from '@material-ui/icons/ReportProblemOutlined'
import ErrorIcon from '@material-ui/icons/ErrorOutline'
import { fetchAddUserMessage } from '../../redux/assistant/actions'
import { styled } from '@mui/material/styles'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { alpha } from '@material-ui/core'

/**************************** GET SERVER TIME DELTA ***************************/

export const saveDateTimeDelta = (dispatch) => {
  const clientDateBefore = new Date().getTime() / 1000
  let url = `${FLASK_URL()}/datetime`
  return fetch(url, {
    method: 'GET',
    credentials: 'include',
  })
    .then(async (response) => {
      // check for error response
      if (!response.ok) {
        const statusText = response.statusText
        const status = response.status
        const url = response.url
        return response.text().then((errorMessage) => {
          const error = new Error(`${statusText} : ${errorMessage}`)
          if (response.headers.get('content-type') === 'application/json') {
            error.stack = JSON.stringify(
              JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
              null,
              2,
            )
          } else {
            error.stack = new Error().stack
          }
          error.statusText = statusText
          error.status = status
          error.url = url
          throw error
        })
      }
      const dataTimeResp = await response.json()
      if (typeof dataTimeResp.datetime === 'number') {
        const clientDateAfter = new Date().getTime() / 1000
        const serverDate = dataTimeResp.datetime
        const clientDateMean = clientDateBefore + (clientDateAfter - clientDateBefore) / 2
        const delta = serverDate - clientDateMean
        dispatch(fetchChangeSettings('dateTimeDelta', delta))
        return delta
      } else {
        throw response
      }
    })
    .catch((error) => {
      if (error.status === 403) {
        dispatch(fetchChangeSettings('loginPopup', true))
      }
      displayPopupMessage(
        dispatch,
        'error',
        t('view.common.utils.save_date_delta.title'),
        <Fragment>
          <div>{t('view.common.utils.save_date_delta.msg1')}</div>
          <div>{generateErrorPart(error)}</div>
          <div>{t('view.common.utils.save_date_delta.msg2')}</div>
        </Fragment>,
      )
    })
}

export const isLeasePassed = (serverLeaseDate, delta) => {
  const currentDate = new Date().getTime() / 1000
  return currentDate + delta > serverLeaseDate
}
/**************************** URL NAVIGATION **********************************/
export const goTo = (url, history) => {
  history.replace(`/reload`)
  setTimeout(() => {
    history.replace(url)
  })
}

/**************************** FLASK DATABASE **********************************/
export const getFlaskDataBase = (dispatch, servicePythonSelected) => {
  let url = `${FLASK_URL()}/db_prefix`
  let service = `${SERVICE()}`
  if (servicePythonSelected) {
    url = `${FLASK_BASE_URL()}/${servicePythonSelected}/db_prefix`
    service = servicePythonSelected
  }
  return fetch(url, {
    method: 'GET',
    credentials: 'include',
  })
    .then((response) => {
      if (!response.ok) {
        const statusText = response.statusText
        const status = response.status
        const url = response.url
        return response.text().then((errorMessage) => {
          const error = new Error(`${statusText} : ${errorMessage}`)
          if (response.headers.get('content-type') === 'application/json') {
            error.stack = JSON.stringify(
              JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
              null,
              2,
            )
          } else {
            error.stack = new Error().stack
          }
          error.statusText = statusText
          error.status = status
          error.url = url
          throw error
        })
      }
      return response.text()
    })
    .then((dataBase) => {
      if (dataBase.indexOf('<!DOCTYPE html>') === -1) {
        return dataBase
      } else {
        throw dataBase
      }
    })
    .catch((error) => {
      if (error.status === 403) {
        dispatch(fetchChangeSettings('loginPopup', true))
      }
      displayPopupMessage(
        dispatch,
        'error',
        t('view.common.utils.flask_database.title'),
        <Fragment>
          <div>
            <Trans i18nKey="view.common.utils.flask_database.msg1">
              Cannot get the server database for <i>{{ val: service }}</i>.
            </Trans>
          </div>
          <div>
            <Trans i18nKey="view.common.utils.flask_database.msg2">
              The service <i>{{ val: service }}</i> seems doesn't running.
            </Trans>
          </div>
          {generateErrorPart(error)}
        </Fragment>,
      )
      // Display popup configuration to set a good service
      dispatch(fetchChangeSettings('configurationService', true))
      return error
    })
}

/**************************** Retrieve current batch **********************************/
export const isAlreadyRetrieve = async () => {
  let filter = [
    {
      property: 'tags',
      value: localStorage.getItem('__config.json__ALPHACOD_TAG'),
      filterType: 'string',
      operator: 'like',
    },
    {
      property: 'status',
      value: 'FINISHED',
      filterType: 'string',
      operator: 'ne',
    },
    {
      property: 'status',
      value: 'FAILED',
      filterType: 'string',
      operator: 'ne',
    },
  ]
  let v = encodeURIComponent(JSON.stringify(filter))
  const responseVal = await fetch(`${API_URL()}/tasks/page/?filter=${v}`, {
    method: 'GET',
    credentials: 'include',
  })
  // const authenticationError = response.status === 403
  // if (authenticationError) {
  //   return dispatch(fetchChangeSettings('loginPopup', true))
  // }
  const resp = await responseVal.json()
  return resp
}

export const retrieveBatches = (
  dispatch,
  batch,
  option,
  screening_id,
  configurationProfile_name,
) => {
  // If batch, then pcd_analyze_scandir application else pcd_analyze_scandate application
  const taskId = new Date().getTime() + '_retrieve_current_batch'
  const task = {
    id: taskId,
    batchId: batch ? batch._id : null,
    title: batch
      ? t('view.common.utils.retrieve_batches.title_update')
      : t('view.common.utils.retrieve_batches.title_retrieve'),
    operator: localStorage.getItem('SESSION_user_name'),
    date: new Date().getTime(),
    action: batch ? 'update_batch' : 'retrieve_batches',
    percentage: 50,
    state: { value: 'running', message: '' },
    operation: batch
      ? t('view.common.utils.retrieve_batches.operation_update')
      : t('view.common.utils.retrieve_batches.operation_retrieve'),
    items: [],
  }
  // dispatch(fetchAddTask(task))
  displayPopupMessage(
    dispatch,
    'info',
    batch
      ? t('view.common.utils.retrieve_batches.title_update')
      : t('view.common.utils.retrieve_batches.title_retrieve'),
    <Fragment>
      {!batch && <div>{t('view.common.utils.retrieve_batches.info_retrieve')}</div>}
      {batch && (
        <div>
          <Trans i18nKey="view.common.utils.retrieve_batches.info_update">
            You will update the batch <i>{{ val: batch.name }}</i>.
          </Trans>
        </div>
      )}
      <div style={{ marginTop: 10 }}>
        <i>{t('view.common.utils.retrieve_batches.take_time')}</i>
      </div>
    </Fragment>,
  )

  let url = `${API_URL()}/applications/page/`
  return fetch(url, {
    method: 'GET',
    credentials: 'include',
  })
    .then(async (response) => {
      if (!response.ok) {
        displayPopupMessage(
          dispatch,
          'error',
          batch
            ? t('view.common.utils.retrieve_batches.title_update')
            : t('view.common.utils.retrieve_batches.title_retrieve'),
          <Fragment>
            <div>{t('view.common.utils.retrieve_batches.error_msg1')}</div>
            <div>{t('view.common.utils.retrieve_batches.error_msg2')}</div>
          </Fragment>,
        )
        const authenticationError = response.status === 403
        if (authenticationError) {
          dispatch(fetchChangeSettings('loginPopup', true))
          throw new Error(t('view.common.utils.retrieve_batches.not_logged'))
        }
        let json = response.text()
        return json.then(Promise.reject.bind(Promise))
      }
      const resp = await response.json()
      const applicationName = batch
        ? localStorage.getItem('__config.json__PCD_DIR_APPLICATION_NAME')
        : localStorage.getItem('__config.json__PCD_APPLICATION_NAME')
      const application = resp.result.filter((app) => app.name === applicationName)[0]
      if (application) {
        let url = `${API_URL()}/tasks/new?&application=${application._id}&create=true`
        return fetch(url, {
          method: 'GET',
          credentials: 'include',
        }).then(async (response) => {
          if (!response.ok) {
            displayPopupMessage(
              dispatch,
              'error',
              batch
                ? t('view.common.utils.retrieve_batches.title_update')
                : t('view.common.utils.retrieve_batches.title_retrieve'),
              <Fragment>
                <div>
                  <Trans i18nKey="view.common.utils.retrieve_batches.error_msg3">
                    Cannot create a new task from the application <i>{{ val: application.name }}</i>
                    .
                  </Trans>
                </div>
                <div>{t('view.common.utils.retrieve_batches.error_msg2')}</div>
              </Fragment>,
            )
            const authenticationError = response.status === 403
            if (authenticationError) {
              dispatch(fetchChangeSettings('loginPopup', true))
              throw new Error("You're not logged.")
            }
            // dispatch(
            //   fetchFinishTask(
            //     taskId,
            //     'error',
            //     `Cannot create a new task from the application ${application.name}`,
            //   ),
            // )
            let json = response.text()
            return json.then(Promise.reject.bind(Promise))
          }
          const task = await response.json()
          if (task) {
            let taskUpdated = task
            if (batch) {
              let mode = taskUpdated.inputs.filter((input) => input.name === 'mode')[0]
              if (mode) mode['value'] = option
              let screeningId = taskUpdated.inputs.filter(
                (input) => input.name === 'screening_id',
              )[0]
              if (screeningId) screeningId['value'] = screening_id
              let batchName = taskUpdated.inputs.filter((input) => input.name === 'batch_name')[0]
              if (batchName) batchName['value'] = batch.name
              let batchId = taskUpdated.inputs.filter((input) => input.name === 'batch_id')[0]
              if (batchId) batchId['value'] = batch._id
              let scanDir = taskUpdated.inputs.filter((input) => input.name === 'scan_dir')[0]
              if (scanDir) scanDir['value'] = batch.org_path ? batch.org_path : batch.path
              let batchDevice = taskUpdated.inputs.filter(
                (input) => input.name === 'batch_device',
              )[0]
              if (batchDevice) batchDevice['value'] = batch.device
              let batchDate = taskUpdated.inputs.filter((input) => input.name === 'batch_date')[0]
              if (batchDate) batchDate['value'] = formatDate(parseFloat(batch.batchDate) * 1000)
              let batchType = taskUpdated.inputs.filter((input) => input.name === 'batch_type')[0]
              if (batchType) batchType['value'] = batch.chromatoType
              let batchConfiguration = taskUpdated.inputs.filter(
                (input) => input.name === 'configuration',
              )[0]
              if (batchConfiguration) batchConfiguration['value'] = configurationProfile_name
            } else {
              let scanDate = taskUpdated.inputs.filter((input) => input.name === 'scan_date')[0]
              if (scanDate) scanDate['value'] = formatDate(new Date().getTime())
            }
            let url = `${API_URL()}/tasks/validate`
            const data = {
              save: true,
              previousTask: task,
              task: taskUpdated,
            }
            return fetch(url, {
              method: 'POST',
              credentials: 'include',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(data),
            }).then(async (response) => {
              if (!response.ok) {
                displayPopupMessage(
                  dispatch,
                  'error',
                  batch
                    ? t('view.common.utils.retrieve_batches.title_update')
                    : t('view.common.utils.retrieve_batches.title_retrieve'),
                  <Fragment>
                    <div>
                      <Trans i18nKey="view.common.utils.retrieve_batches.error_msg4">
                        Cannot validate the task <i>{{ val1: task.name }}</i> (num:
                        {{ val2: task.num }}).
                      </Trans>
                    </div>
                    <div>{t('view.common.utils.retrieve_batches.error_msg2')}</div>
                  </Fragment>,
                )
                const authenticationError = response.status === 403
                if (authenticationError) {
                  dispatch(fetchChangeSettings('loginPopup', true))
                  throw new Error(t('view.common.utils.retrieve_batches.not_logged'))
                }
                // dispatch(
                //   fetchFinishTask(
                //     taskId,
                //     'error',
                //     `Cannot validate the task ${task.name} (num: ${task.num})`,
                //   ),
                // )
                let json = response.text()
                return json.then(Promise.reject.bind(Promise))
              }
              const validatedTask = await response.json()
              if (validatedTask) {
                let url = `${API_URL()}/tasks/submit?taskId=${validatedTask._id}`
                return fetch(url, {
                  method: 'POST',
                  credentials: 'include',
                  headers: { 'Content-Type': 'application/json' },
                }).then(async (response) => {
                  if (!response.ok) {
                    displayPopupMessage(
                      dispatch,
                      'error',
                      batch
                        ? t('view.common.utils.retrieve_batches.title_update')
                        : t('view.common.utils.retrieve_batches.title_retrieve'),
                      <Fragment>
                        <div>
                          <Trans i18nKey="view.common.utils.retrieve_batches.error_msg5">
                            Cannot submit the task <i>{{ val1: task.name }}</i> (num:
                            {{ val2: task.num }}).
                          </Trans>
                        </div>
                        <div>{t('view.common.utils.retrieve_batches.error_msg2')}</div>
                      </Fragment>,
                    )
                    const authenticationError = response.status === 403
                    if (authenticationError) {
                      dispatch(fetchChangeSettings('loginPopup', true))
                      throw new Error(t('view.common.utils.retrieve_batches.not_logged'))
                    }
                    // dispatch(
                    //   fetchFinishTask(
                    //     taskId,
                    //     'error',
                    //     `Cannot submit the task ${task.name} (num: ${task.num})`,
                    //   ),
                    // )
                    let json = response.text()
                    return json.then(Promise.reject.bind(Promise))
                  }
                  const submittedTask = (await response.json())[0]
                  if (submittedTask) {
                    displayPopupMessage(
                      dispatch,
                      'success',
                      batch
                        ? t('view.common.utils.retrieve_batches.title_update')
                        : t('view.common.utils.retrieve_batches.title_retrieve'),
                      <Fragment>
                        <div>{t('view.common.utils.retrieve_batches.success_msg1')}</div>
                        <div>
                          <Trans i18nKey="view.common.utils.retrieve_batches.success_msg2">
                            The task is named <i>{{ val1: submittedTask.name }}</i> with num
                            <i>{{ val2: submittedTask.num }}</i>.
                          </Trans>
                        </div>
                        <div style={{ marginTop: 10 }}>
                          <i>{t('view.common.utils.retrieve_batches.success_msg3')}</i>
                        </div>
                      </Fragment>,
                    )
                    // dispatch(fetchFinishTask(taskId, 'success', 'Everything is fine'))
                    return submittedTask
                  } else {
                    // dispatch(
                    //   fetchFinishTask(
                    //     taskId,
                    //     'error',
                    //     `Cannot submit the validated task ${task.name} (num: ${task.num})`,
                    //   ),
                    // )
                    return Promise.reject.bind(Promise)(
                      <Trans i18nKey="view.common.utils.retrieve_batches.error_msg6">
                        Cannot submit the validated task {{ val1: task.name }} (num:
                        {{ val2: task.num }}).
                      </Trans>,
                    )
                  }
                })
              } else {
                // dispatch(
                //   fetchFinishTask(
                //     taskId,
                //     'error',
                //     `Cannot validate the task ${task.name} (num: ${task.num})`,
                //   ),
                // )
                return Promise.reject.bind(Promise)(
                  <Trans i18nKey="view.common.utils.retrieve_batches.error_msg7">
                    Cannot validate the task {{ val1: task.name }} (num: {{ val2: task.num }}).
                  </Trans>,
                )
              }
            })
          } else {
            // dispatch(
            //   fetchFinishTask(taskId, 'error', `Cannot create a task from ${applicationName}.`),
            // )
            return Promise.reject.bind(Promise)(
              <Trans i18nKey="view.common.utils.retrieve_batches.error_msg8">
                Cannot create a task from {{ val: applicationName }}.
              </Trans>,
            )
          }
        })
      } else {
        // dispatch(
        //   fetchFinishTask(taskId, 'error', `Cannot get the application named ${applicationName}.`),
        // )
        return Promise.reject.bind(Promise)(
          <Trans i18nKey="view.common.utils.retrieve_batches.error_msg9">
            Cannot get the application named {{ val: applicationName }}.
          </Trans>,
        )
      }
    })
    .catch(function (error) {
      // dispatch(fetchFinishTask(taskId, 'error', error.toString()))
      displayPopupMessage(
        dispatch,
        'error',
        batch
          ? t('view.common.utils.retrieve_batches.title_update')
          : t('view.common.utils.retrieve_batches.title_retrieve'),
        <Fragment>
          {batch && (
            <div>
              <Trans i18nKey="view.common.utils.retrieve_batches.error_msg10">
                Cannot update the batch <i>{{ val: batch.name }}</i>
              </Trans>
            </div>
          )}
          {!batch && <div>{t('view.common.utils.retrieve_batches.error_msg11')}</div>}
          <div>{error.toString()}</div>
          <div>{t('view.common.utils.retrieve_batches.error_msg2')}</div>
        </Fragment>,
      )
    })
}

/**************************** TRAINING BATCH FUNCTION **********************************/
export const buildATrainingBatch = (dispatch, batch, trainingBatchName) => {
  const taskId = new Date().getTime() + '_build_training_batch_' + batch._id
  const task = {
    id: taskId,
    title: t('view.common.utils.training_batch.title'),
    operator: localStorage.getItem('SESSION_user_name'),
    date: new Date().getTime(),
    action: 'build_batch',
    percentage: 50,
    state: { value: 'running', message: '' },
    operation: (
      <Trans i18nKey="view.common.utils.training_batch.operation">
        build a new batch {{ val: trainingBatchName }}
      </Trans>
    ),
    items: [
      {
        id: batch._id,
        name: batch.name,
      },
    ],
  }
  dispatch(fetchAddTask(task))
  displayPopupMessage(
    dispatch,
    'info',
    t('view.common.utils.training_batch.title'),
    <Fragment>
      <div>{t('view.common.utils.training_batch.info_msg1')}</div>
      <Chip variant="outlined" size="small" style={{ margin: 3 }} label={batch.name} />
      <div>
        <Trans i18nKey="view.common.utils.training_batch.named">
          named: {{ val: trainingBatchName }}
        </Trans>
      </div>
      <div style={{ marginTop: 10 }}>
        <i>{t('view.common.utils.training_batch.time')}</i>
      </div>
    </Fragment>,
  )
  dispatch(fetchCreateTrainingBatch(batch._id, trainingBatchName))
    .then(function (resp) {
      dispatch(fetchFinishTask(taskId, 'success', 'Everything is fine'))
      displayPopupMessage(
        dispatch,
        'success',
        t('view.common.utils.training_batch.title'),
        <Fragment>
          <div>
            <Trans i18nKey="view.common.utils.training_batch.created">
              The training batch from <i>{{ val: batch.name }}</i> is correctly created :
            </Trans>
          </div>
          <div>
            <Tooltip
              key={'go-training'}
              arrow
              title={
                <div style={{ fontSize: 13, padding: 5 }}>
                  {t('view.common.utils.training_batch.tooltip')}
                </div>
              }
            >
              <Button
                variant="outlined"
                style={{
                  borderRadius: 16,
                  padding: '0px 8px',
                  fontWeight: 400,
                  textTransform: 'none',
                  fontSize: '0.8125rem',
                }}
                onClick={() => {
                  openInNewTab(
                    `${window.location.origin}${window.location.pathname}?batch/${resp.trainingBatch._id}`,
                  )
                }}
              >
                {resp.trainingBatch.name}
              </Button>
            </Tooltip>
          </div>
          <div style={{ marginTop: 10 }}>
            <InfoOutlinedIcon />
            <i style={{ position: 'absolute', marginLeft: 4, marginTop: 4 }}>
              {t('view.common.utils.training_batch.click')}
            </i>
          </div>
        </Fragment>,
      )
    })
    .catch(function (error) {
      dispatch(fetchFinishTask(taskId, 'error', error))
      displayPopupMessage(
        dispatch,
        'error',
        t('view.common.utils.training_batch.title'),
        <Fragment>
          <div>
            <Trans i18nKey="view.common.utils.training_batch.error">
              Can not build a training batch from <i>{{ val: batch.name }}</i>
            </Trans>
          </div>
          {generateErrorPart(error)}
        </Fragment>,
      )
    })
}

/**************************** UA PARAMETERS FUNCTION **********************************/
export const saveUaParameters = (dispatch, settings, settingsInfo, batchId, analysisId) => {
  dispatch(fetchSaveUASettings(settingsInfo.id, settings, 'unitary_analysis', batchId, analysisId))
    .then(function (resp) {
      displayPopupMessage(
        dispatch,
        'success',
        t('view.common.utils.ua_param.title'),
        <Trans i18nKey="view.common.utils.ua_param.success">
          The settings is now updated for '{{ val: settingsInfo.name }}'
        </Trans>,
      )
    })
    .catch(function (error) {
      displayPopupMessage(
        dispatch,
        'error',
        t('view.common.utils.ua_param.title'),
        <Fragment>
          <div>
            <Trans i18nKey="view.common.utils.ua_param.error2">
              Can not update settings for {{ val: settingsInfo.name }}:
            </Trans>
          </div>
          <div>{generateErrorPart(error)}</div>
        </Fragment>,
      )
    })
}

/**************************** RESET UNITARY ANALYSES **********************************/
export const resetUnitaryAnalyses = (event, uas, dispatch) => {
  const taskId = new Date().getTime() + '_reset_uas_' + uas[0]._id
  const multi = uas.length > 1
  const task = {
    id: taskId,
    title: (
      <Trans i18nKey="view.common.utils.reset_ua.title">
        Reset unitary
        {{
          val: multi
            ? t('view.common.utils.reset_ua.analyses')
            : t('view.common.utils.reset_ua.analysis'),
        }}
      </Trans>
    ),
    operator: localStorage.getItem('SESSION_user_name'),
    date: new Date().getTime(),
    action: 'reset_ua',
    percentage: 50,
    state: { value: 'running', message: '' },
    operation: t('view.common.utils.reset_ua.operation'),
    items: uas.map((unitaryAnalysis) => ({
      id: unitaryAnalysis._id,
      type: unitaryAnalysis.type,
      name: unitaryAnalysis.name,
      analysis: {
        id: unitaryAnalysis.analyseId,
        type: unitaryAnalysis.analyseType,
        name: unitaryAnalysis.analyse,
      },
      batch: {
        id: unitaryAnalysis.batchId,
        name: unitaryAnalysis.batch_name,
      },
    })),
  }
  dispatch(fetchAddTask(task))
  displayPopupMessage(
    dispatch,
    'info',
    <Trans i18nKey="view.common.utils.reset_ua.title">
      Reset unitary
      {{
        val: multi
          ? t('view.common.utils.reset_ua.analyses')
          : t('view.common.utils.reset_ua.analysis'),
      }}
    </Trans>,
    <Fragment>
      <Trans i18nKey="view.common.utils.reset_ua.info">
        You will reset the unitary
        {{
          val: multi
            ? t('view.common.utils.reset_ua.analyses')
            : t('view.common.utils.reset_ua.analysis'),
        }}
        :
      </Trans>
      <div style={{ maxWidth: 600, maxHeight: 400, overflow: 'scroll' }}>
        {uas.map((item) => (
          <Chip variant="outlined" size="small" style={{ margin: 3 }} label={item.name} />
        ))}
      </div>
      <div style={{ marginTop: 10 }}>
        <i>{t('view.common.utils.reset_ua.time')}</i>
      </div>
    </Fragment>,
  )
  //TODO call REST url
  dispatch(fetchFinishTask(taskId, 'error', t('view.common.utils.reset_ua.not_exist')))
  displayPopupMessage(
    dispatch,
    'warning',
    <Trans i18nKey="view.common.utils.reset_ua.title">
      Reset unitary
      {{
        val: multi
          ? t('view.common.utils.reset_ua.analyses')
          : t('view.common.utils.reset_ua.analysis'),
      }}
    </Trans>,
    <Fragment>
      <div>
        <Trans i18nKey="view.common.utils.reset_ua.reset">
          The reset for unitary
          {{
            val: multi
              ? t('view.common.utils.reset_ua.analyses')
              : t('view.common.utils.reset_ua.analysis'),
          }}
          :
        </Trans>
        <div style={{ maxWidth: 600, maxHeight: 400, overflow: 'scroll' }}>
          {uas.map((item) => (
            <Chip variant="outlined" size="small" style={{ margin: 3 }} label={item.name} />
          ))}
        </div>
        {t('view.common.utils.reset_ua.cannot')}
      </div>
      <div style={{ marginTop: 10 }}>
        <i>{t('view.common.utils.reset_ua.rest')}</i>
      </div>
    </Fragment>,
  )
}

/**************************** RESET UNITARY CALIBRATION **********************************/
export const resetUnitaryCalibration = (event, ucs, dispatch) => {
  const taskId = new Date().getTime() + '_reset_uas_' + ucs[0]._id
  const multi = ucs.length > 1
  const task = {
    id: taskId,
    title: (
      <Trans i18nKey="view.common.utils.reset_cal.title">
        Reset unitary
        {{
          val: multi
            ? t('view.common.utils.reset_cal.calibrations')
            : t('view.common.utils.reset_cal.calibration'),
        }}
      </Trans>
    ),
    operator: localStorage.getItem('SESSION_user_name'),
    date: new Date().getTime(),
    action: 'reset_uc',
    percentage: 50,
    state: { value: 'running', message: '' },
    operation: t('view.common.utils.reset_cal.operation'),
    items: ucs.map(function (unitaryCalibration) {
      return {
        id: unitaryCalibration._id,
        type: unitaryCalibration.analyseType,
        name: unitaryCalibration.name,
        batch: {
          id: unitaryCalibration.batchId,
          name: unitaryCalibration.batchName,
        },
      }
    }),
  }
  dispatch(fetchAddTask(task))
  displayPopupMessage(
    dispatch,
    'info',
    <Trans i18nKey="view.common.utils.reset_cal.title">
      Reset unitary
      {{
        val: multi
          ? t('view.common.utils.reset_cal.calibrations')
          : t('view.common.utils.reset_cal.calibration'),
      }}
    </Trans>,
    <Fragment>
      <Trans i18nKey="view.common.utils.reset_ua.info">
        You will reset the unitary
        {{
          val: multi
            ? t('view.common.utils.reset_cal.calibrations')
            : t('view.common.utils.reset_cal.calibration'),
        }}
        :
      </Trans>
      <div style={{ maxWidth: 600, maxHeight: 400, overflow: 'scroll' }}>
        {ucs.map((item) => (
          <Chip variant="outlined" size="small" style={{ margin: 3 }} label={item.name} />
        ))}
      </div>
      <div style={{ marginTop: 10 }}>
        <i>{t('view.common.utils.reset_cal.time')}</i>
      </div>
    </Fragment>,
  )
  //TODO call REST url
  dispatch(fetchFinishTask(taskId, 'error', t('view.common.utils.reset_cal.not_exist')))
  displayPopupMessage(
    dispatch,
    'warning',
    <Trans i18nKey="view.common.utils.reset_cal.title">
      Reset unitary
      {{
        val: multi
          ? t('view.common.utils.reset_cal.calibrations')
          : t('view.common.utils.reset_cal.calibration'),
      }}
    </Trans>,
    <Fragment>
      <div>
        <Trans i18nKey="view.common.utils.reset_cal.reset">
          The reset for unitary
          {{
            val: multi
              ? t('view.common.utils.reset_cal.calibrations')
              : t('view.common.utils.reset_cal.calibration'),
          }}
          :
        </Trans>
        <div style={{ maxWidth: 600, maxHeight: 400, overflow: 'scroll' }}>
          {ucs.map((item) => (
            <Chip variant="outlined" size="small" style={{ margin: 3 }} label={item.name} />
          ))}
        </div>
        {t('view.common.utils.reset_cal.cannot')}
      </div>
      <div style={{ marginTop: 10 }}>
        <i>{t('view.common.utils.reset_cal.rest')}</i>
      </div>
    </Fragment>,
  )
}

/**************************** VALIDATION FUNCTION **********************************/

export const handleValidation = (event, unitaryAnalysis, validation, collection, dispatch) => {
  event.stopPropagation()
  const oldValidation = unitaryAnalysis.validation
  let newValidation = null
  let validationName = ''
  switch (oldValidation) {
    case '1':
      newValidation = validation === '1' ? '0' : validation
      break
    case '2':
      newValidation = validation === '2' ? '0' : validation
      break
    case '3':
      newValidation = validation === '3' ? '0' : validation
      break
    case '0':
    default:
      newValidation = validation
      break
  }
  switch (newValidation) {
    case '1':
      validationName = unitaryAnalysis.analyseType === 'sample' ? 'Detected' : 'OK'
      break
    case '2':
      validationName = unitaryAnalysis.analyseType === 'sample' ? 'Not detected' : 'KO'
      break
    case '3':
      validationName = 'Relance'
      break
    case '0':
    default:
      validationName = 'None'
      break
  }

  let data = {}
  data['value'] = newValidation
  callValidationDispatch(
    collection,
    dispatch,
    data,
    validationName,
    unitaryAnalysis,
    unitaryAnalysis.batchId,
  )
}

export const callValidationDispatch = (
  collection,
  dispatch,
  data,
  validationName,
  unitaryAnalysis,
  batchId,
  selectionType,
) => {
  const multiAnalyse = !unitaryAnalysis
  let originalData = data
  if (multiAnalyse) {
    const result = {}
    data.forEach((ua) => (result[ua._id] = validationName))
    data = result

    switch (validationName) {
      case 1:
        validationName =
          selectionType && selectionType.length === 2
            ? 'Detected / OK'
            : originalData[0].analyseType === 'sample'
            ? 'Detected'
            : 'OK'
        break
      case 2:
        validationName =
          selectionType && selectionType.length === 2
            ? 'Not detected / KO'
            : originalData[0].analyseType === 'sample'
            ? 'Not detected'
            : 'KO'
        break
      case 3:
        validationName = 'Relance'
        break
      case 0:
      default:
        validationName = 'No validation'
        break
    }
  } else {
    originalData = [unitaryAnalysis]
  }

  const taskId = new Date().getTime() + '_validation_' + originalData[0]._id
  switch (collection) {
    case 'unitary_compound':
    case 'unitary_analysis':
      const task = {
        id: taskId,
        title: (
          <Trans i18nKey="view.common.utils.validation.title">
            {{
              val:
                collection === 'unitary_compound'
                  ? t('view.common.utils.validation.compound')
                  : t('view.common.utils.validation.analysis'),
            }}
            validation
          </Trans>
        ),
        operator: localStorage.getItem('SESSION_user_name'),
        date: new Date().getTime(),
        action: 'validation',
        percentage: 50,
        state: { value: 'running', message: '' },
        operation: validationName,
        items: originalData.map((orData) => ({
          id: orData._id,
          type: collection === 'unitary_compound' ? 'compound' : 'analysis',
          name: orData.name,
          analysis: {
            id: orData.analyseId,
            type: orData.analysetype,
            name: orData.analyse,
          },
          batch: {
            id: orData.batchId,
            name: orData.batch_name,
          },
        })),
      }
      dispatch(fetchAddTask(task))

      dispatch(fetchUpdateUAValidation(data, collection, unitaryAnalysis, multiAnalyse, batchId))
        .then(function (resp) {
          // Update progress component by reload analyses
          dispatch(fetchAnalyses(batchId))
          if (collection === 'unitary_analysis') {
            dispatch(
              fetchUnitaryAnalysesDistinct(
                multiAnalyse
                  ? resp.objectsUpdated.uas[0].content.analysis.id
                  : unitaryAnalysis.analyseId,
                JSON.parse(localStorage.getItem('ANALYSIS_filter_requested')) === true,
              ),
            )
          } else {
            dispatch(
              fetchUnitaryCompoundDistinct(
                batchId,
                multiAnalyse ? resp.objectsUpdated.uas[0].content.event : unitaryAnalysis.event,
              ),
            )
          }
          // Don't want to see the confirm message...
          // sendValidationResultMessage(
          //   dispatch,
          //   resp ? resp.type : null,
          //   data,
          //   unitaryAnalysis,
          //   multiAnalyse,
          //   collection,
          // )
          dispatch(fetchFinishTask(taskId, 'success', t('view.common.utils.validation.fine')))
        })
        .catch(function (error) {
          if (error && error.status !== undefined && error.status === 401) {
            error.statusText = t('view.common.utils.lease_locked.code')
            displayPopupMessage(
              dispatch,
              'warning',
              t('view.common.utils.validation.update_title'),
              <Fragment>
                <div>{t('view.common.utils.validation.update_msg')}</div>
                {generateErrorPart(error)}
              </Fragment>,
            )
          } else {
            sendValidationResultMessage(dispatch, null, error, unitaryAnalysis)
          }
          dispatch(fetchFinishTask(taskId, 'error', error))
        })
      break
    case 'unitary_calibration':
      const taskCalib = {
        id: taskId,
        title: t('view.common.utils.validation.calib_valid'),
        operator: localStorage.getItem('SESSION_user_name'),
        date: new Date().getTime(),
        percentage: 50,
        state: { value: 'running', message: '' },
        operation: validationName,
        items: originalData.map((orData) => ({
          id: orData._id,
          type: 'calibration',
          name: orData.name,
          analysis: {
            id: orData.analyseId,
            type: orData.analysetype,
            name: orData.analyse,
          },
          batch: {
            id: orData.batchId,
            name: orData.batch_name,
          },
        })),
      }
      dispatch(fetchAddTask(taskCalib))
      // dispatch(fetchUpdateUCValidation(data, unitaryAnalysis, multiAnalyse, batchId))
      //   .then(function (resp) {
      //     // Update progress component by reload batches
      //
      //     // Update: do not download all batches, only the current one
      //     // dispatch(fetchBatches())
      //     dispatch(fetchBatch(batchId))
      //
      //     dispatch(fetchUnitaryCalibrationsDistinct(batchId))
      //     // Don't want to see the confirm message...
      //     // sendValidationResultMessage(
      //     //   dispatch,
      //     //   resp ? resp.type : null,
      //     //   data,
      //     //   unitaryAnalysis,
      //     //   multiAnalyse,
      //     //   collection,
      //     // )
      //     dispatch(fetchFinishTask(taskId, 'success', t('view.common.utils.validation.fine')))
      //   })
      //   .catch(function (error) {
      //     dispatch(fetchFinishTask(taskId, 'error', error.toString()))
      //
      //     if (error && error.code !== undefined && error.code === 554) {
      //       displayPopupMessage(
      //         dispatch,
      //         'warning',
      //         t('view.common.utils.validation.update_title'),
      //         <Fragment>
      //           <div>{t('view.common.utils.validation.update_msg')}</div>
      //           <div style={{ whiteSpace: 'pre-line' }}>{error.message}</div>
      //         </Fragment>,
      //       )
      //     } else {
      //       sendValidationResultMessage(dispatch, null, error, unitaryAnalysis)
      //     }
      //   })
      dispatch(fetchUpdateUCValidation(data, unitaryAnalysis, multiAnalyse, batchId))
        .then(function (resp) {
          // Update progress component by reload batches

          // Update: do not download all batches, only the current one
          // dispatch(fetchBatches())
          dispatch(fetchBatch(batchId))

          dispatch(fetchUnitaryCalibrationsDistinct(batchId))
          // Don't want to see the confirm message...
          // sendValidationResultMessage(
          //   dispatch,
          //   resp ? resp.type : null,
          //   data,
          //   unitaryAnalysis,
          //   multiAnalyse,
          //   collection,
          // )
          dispatch(fetchFinishTask(taskId, 'success', t('view.common.utils.validation.fine')))
        })
        .catch(function (error) {
          dispatch(fetchFinishTask(taskId, 'error', error))

          if (error && error.status !== undefined && error.status === 401) {
            error.statusText = t('view.common.utils.lease_locked.code')
            displayPopupMessage(
              dispatch,
              'warning',
              t('view.common.utils.validation.update_title'),
              <Fragment>
                <div>{t('view.common.utils.validation.update_msg')}</div>
                {generateErrorPart(error)}
              </Fragment>,
            )
          } else {
            sendValidationResultMessage(dispatch, null, error, unitaryAnalysis)
          }
        })
      break
    default:
      break
  }
  const sendValidationResultMessage = (
    dispatch,
    type,
    data,
    unitaryAnalysis,
    multiAnalyse,
    collection,
  ) => {
    switch (type) {
      case 'RECEIVE_UPDATE_UA_VALIDATION':
      case 'RECEIVE_UPDATE_UC_VALIDATION':
        if (multiAnalyse) {
          displayPopupMessage(
            dispatch,
            'success',
            t('view.common.utils.validation.update_title'),
            <Trans i18nKey="view.common.utils.validation.confirm_update">
              The current selection is now updated to '{{ val: validationName }}'
            </Trans>,
          )
        } else {
          let itemName = ''
          if (collection === 'unitary_analysis') {
            itemName = unitaryAnalysis.peakName.name
          }
          if (collection === 'unitary_compound') {
            itemName = unitaryAnalysis.analyse
          }
          if (collection === 'unitary_calibration') {
            itemName = unitaryAnalysis.name
          }
          displayPopupMessage(
            dispatch,
            'success',
            t('view.common.utils.validation.update_title'),
            <Trans i18nKey="view.common.utils.validation.valid_update">
              The validation of '{{ val1: itemName }}' is updated to '{{ val2: validationName }}'
            </Trans>,
          )
        }
        break
      default:
        // TODO get the good error message
        // const error = 'error'
        displayPopupMessage(
          dispatch,
          'error',
          t('view.common.utils.validation.update_title'),
          <Fragment>
            <div>{t('view.common.utils.validation.update_msg')}</div>
            {/*<div>{JSON.parse(data.toString().replace('Error: {','{'))}</div>*/}
            {generateErrorPart(data)}
          </Fragment>,
        )
    }
  }
  // dispatch(fetchUpdateValidation(data, unitaryAnalysis._id, objectCollection, multiAnalyse))

  // const requestOptions = {
  //   method: 'PUT',
  //   headers: { 'Content-Type': 'application/json' },
  //   body: JSON.stringify(data),
  // }
  // const multipleTreatment = !unitaryAnalysis
  // const objectCollection = collection === 'unitary_compound' ? 'unitary_analysis' : collection
  // let url
  // if (multipleTreatment) {
  //   url = `${FLASK_URL}/${objectCollection}/validate`
  // } else {
  //   url = `${FLASK_URL}/${objectCollection}/${unitaryAnalysis._id}/validate`
  // }
  // return fetch(url, requestOptions)
  //   .then(async (response) => {
  //     // check for error response
  //     if (!response.ok) {
  //       let json = response.text()
  //       return json.then(Promise.reject.bind(Promise))
  //     }
  //     if (multipleTreatment) {
  //       // TODO update all UA from multiple treatment
  //       const validationMap = {
  //         '1': 'Detected',
  //         '2': 'Excluded',
  //         '3': 'Relance',
  //       }
  //       displayPopupMessage(
  // dispatch,
  //           'success',
  //           'Validation update',
  //           `The current selection is now updated to '${validationMap[validationName]}'`,
  //         )
  //     } else {
  //       let itemName = ''
  //       if (collection === 'unitary_analysis') {
  //         itemName = unitaryAnalysis.peakName
  //         dispatch(fetchUpdateAnalyseValidation(unitaryAnalysis._id, data.value))
  //       }
  //       if (collection === 'unitary_compound') {
  //         itemName = unitaryAnalysis.analyse
  //         dispatch(fetchUpdateCompoundValidation(unitaryAnalysis._id, data.value))
  //       }
  //       if (collection === 'unitary_calibration') {
  //         itemName = unitaryAnalysis.name
  //         dispatch(fetchUpdateCalibrationValidation(unitaryAnalysis._id, data.value))
  //       }
  //
  //       displayPopupMessage(
  // dispatch,
  //           'success',
  //           'Validation update',
  //           `The validation of '${itemName}' is updated to '${validationName}'`,
  //         )
  //     }
  //   })
  //   .catch(function (error) {
  //     // this.setState({ message: error })
  //     // this.setState({ open: true })
  //     // this._handleOpen()
  //     console.log('error', `There was an error! ${error}`)
  //     displayPopupMessage(
  // dispatch,
  //         'error',
  //         'Validation update',
  //         <Fragment>
  //           <div>Can not update validation.</div>
  //           <div>{error.toString()}</div>
  //         </Fragment>,
  //       )
  //   })
}

/**************************** UPDATE  MAIN CHANNEL **********************************/

export const updateMainChannel = (dispatch, newMainChannel, item, collection, multiAnalyse) => {
  // displayPopupMessage(
  //           dispatch,'info', 'Main channel update', `Not implemented yet...`)
  const taskId = new Date().getTime() + '_main_channel_' + item._id
  const msg = multiAnalyse
    ? ' ' + t('view.common.utils.main_channel.all')
    : ' ' + t('view.common.utils.main_channel.current')
  const task = {
    id: taskId,
    title: 'Update main channel',
    operator: localStorage.getItem('SESSION_user_name'),
    date: new Date().getTime(),
    action: 'main_channel',
    percentage: 50,
    state: { value: 'running', message: '' },
    operation: t('view.common.utils.main_channel.channel') + ' ' + newMainChannel + msg,
    items: [
      {
        id: collection === 'analyse' ? item._id : item.analyseId,
        type: collection,
        name: collection === 'analyse' ? item.name : item.analyse,
        compound: {
          id: item._id,
          name: item.name,
        },
        analysis: {
          id: item.analyseId,
          name: item.analyse,
        },
        batch: {
          id: item.batchId,
          name: item.batch_name,
        },
      },
    ],
  }
  dispatch(fetchAddTask(task))

  dispatch(
    fetchUpdateMainChannel(
      newMainChannel,
      item._id,
      collection,
      multiAnalyse,
      item.batchId,
      item.analyseId,
    ),
  )
    .then(function (resp) {
      displayPopupMessage(
        dispatch,
        'success',
        t('view.common.utils.main_channel.main_title'),
        multiAnalyse ? (
          <Fragment>{t('view.common.utils.main_channel.main_msg1')}</Fragment>
        ) : (
          <Fragment>
            <Trans i18nKey="view.common.utils.main_channel.main_msg2">
              The main channel is now updated for <i>{{ val: item.analyse }}</i>.
            </Trans>
          </Fragment>
        ),
      )
      dispatch(fetchFinishTask(taskId, 'success', t('view.common.utils.main_channel.fine')))
    })
    .catch(function (error) {
      displayPopupMessage(
        dispatch,
        'error',
        t('view.common.utils.main_channel.main_title'),
        multiAnalyse ? (
          <Fragment>
            <div>{t('view.common.utils.main_channel.not_update_2')}</div>
            <div>{error.toString()}</div>
          </Fragment>
        ) : (
          <Fragment>
            <div>
              <Trans i18nKey="view.common.utils.main_channel.not_update_3">
                Can not update the main channel for <i>{{ val: item.analyse }}</i>
              </Trans>
            </div>
            {generateErrorPart(error)}
          </Fragment>
        ),
      )
      dispatch(fetchFinishTask(taskId, 'error', error))
    })

  // let data = {}
  // data['value'] = newMainChannel
  //
  // const requestOptions = {
  //   method: 'PUT',
  //   headers: { 'Content-Type': 'application/json' },
  //   body: JSON.stringify(data),
  // }
  // let url = ''
  // if (multiAnalyse) {
  //   url = `${FLASK_URL}/molecule/${item._id}/update_main_channel`
  // } else {
  //   url = `${FLASK_URL}/unitary_analysis/${item._id}/update_main_channel`
  // }
  //
  // return fetch(url, requestOptions)
  //   .then(async (response) => {
  //     // check for error response
  //     if (!response.ok) {
  //       let json = response.text()
  //       return json.then(Promise.reject.bind(Promise))
  //     }
  //     // TODO Update data after success
  //
  //     displayPopupMessage(
  // dispatch,
  //         'success',
  //         'Main channel update',
  //         multiAnalyse ? (
  //           <Fragment>The main channel is now updated for all analyse.</Fragment>
  //         ) : (
  //           <Fragment>
  //             The main channel is now updated for <i>{item.analyse}</i>.
  //           </Fragment>
  //         ),
  //       )
  //   })
  //   .catch(function (error) {
  //     console.log('error', `There was an error! ${error}`)
  //     displayPopupMessage(
  // dispatch,
  //         'error',
  //         'Main channel update',
  //         multiAnalyse ? (
  //           <Fragment>
  //             <div>Can not update the main channel for all analyse.</div>
  //             <div>{error.toString()}</div>
  //           </Fragment>
  //         ) : (
  //           <Fragment>
  //             <div>
  //               Can not update the main channel for <i>{item.analyse}</i>.
  //             </div>
  //             <div>{error.toString()}</div>
  //           </Fragment>
  //         ),
  //       )
  //   })
}

/**************************** UPDATE PEAK **********************************/

export const updateGlobalPeak = (
  dispatch,
  unitaryAnalysis,
  reintegrationType,
  reintegrationMethod,
) => {
  let data = {}
  const reintegrationString = Object.entries(reintegrationType)
    .filter((entry) => entry[1])
    .map((entry) => t('view.dialogs.chromato_apply_all.' + entry[0]))
    .join(', ')
  if (
    unitaryAnalysis.main_peak_base &&
    unitaryAnalysis.main_peak_base.left &&
    unitaryAnalysis.main_peak_base.right
  ) {
    data[`x1`] = unitaryAnalysis.main_peak_base.left.x
    data[`y1`] = unitaryAnalysis.main_peak_base.left.y
    data[`x2`] = unitaryAnalysis.main_peak_base.right.x
    data[`y2`] = unitaryAnalysis.main_peak_base.right.y
    // if (reintegrationType === 'standards') {
    //   data[`analysis_type`] = 'calibration'
    // } else if (reintegrationType === 'samples') {
    //   data[`analysis_type`] = 'sample'
    // } else {
    //   reintegrationType = 'whole'
    // }
    data[`analysis_type`] = reintegrationType
    if (reintegrationMethod === 'line') {
      data[`peak_integration`] = 'straight_line'
    } else if (reintegrationMethod === 'square') {
      data[`peak_integration`] = 'square_line'
    } else if (reintegrationMethod === 'zero') {
      data[`peak_integration`] = 'floor_line'
    } else if (reintegrationMethod === 'base') {
      data[`peak_integration`] = 'base_line'
    }
    if (unitaryAnalysis.selectedChannel) {
      data[`channel`] = unitaryAnalysis.selectedChannel
    }
  } else {
    displayPopupMessage(
      dispatch,
      'error',
      <Trans i18nKey="view.common.utils.global_peak.reintegration">
        {{ val: reintegrationString }} reintegration
      </Trans>,
      <Fragment>
        <div>
          <Trans i18nKey="view.common.utils.global_peak.cant_find">
            Cannot find peaks to apply to the reintegration from
            <i>{{ val: unitaryAnalysis.name }}</i>
          </Trans>
        </div>
      </Fragment>,
    )
    return
  }

  const taskId = new Date().getTime() + '_update_peak_' + unitaryAnalysis._id
  const task = {
    id: taskId,
    title: (
      <Trans i18nKey="view.common.utils.global_peak.reintegration">
        {{ val: reintegrationString }} reintegration
      </Trans>
    ),
    operator: localStorage.getItem('SESSION_user_name'),
    date: new Date().getTime(),
    action: 'update_peak',
    percentage: 50,
    state: { value: 'running', message: '' },
    operation: (
      <Trans i18nKey="view.common.utils.global_peak.reintegration">
        {{ val: reintegrationString }} reintegration
      </Trans>
    ),
    items: [
      {
        id: unitaryAnalysis._id,
        type: unitaryAnalysis.type,
        name: unitaryAnalysis.name,
        analysis: {
          id: unitaryAnalysis.analyseId,
          type: unitaryAnalysis.analyseType,
          name: unitaryAnalysis.analyse,
        },
        batch: {
          id: unitaryAnalysis.batchId,
          name: unitaryAnalysis.batch_name,
        },
        compound: {
          id: unitaryAnalysis.event,
          name: unitaryAnalysis.peakName.name,
        },
      },
    ],
  }
  dispatch(fetchAddTask(task))
  displayPopupMessage(
    dispatch,
    'info',
    <Trans i18nKey="view.common.utils.global_peak.reintegration">
      {{ val: reintegrationString }} reintegration
    </Trans>,
    <Fragment>
      <div>
        <Trans i18nKey="view.common.utils.global_peak.info_msg1">
          The reintegration from <i>{{ val1: unitaryAnalysis.name }}</i> will be applied to the
          {{ val2: reintegrationString }} analyzes.
        </Trans>
      </div>
      <div style={{ marginTop: 10 }}>
        <i>{t('view.common.utils.global_peak.time')}</i>
      </div>
    </Fragment>,
  )
  // dispatch(fetchFinishTask(taskId, 'error', "The REST request doesn't exist yet."))
  dispatch(
    fetchUpdateGlobalPeak(
      unitaryAnalysis._id,
      data,
      unitaryAnalysis.selectedChannel,
      unitaryAnalysis.batchId,
      unitaryAnalysis.analyseId,
    ),
  )
    .then(function (resp) {
      delete data.channel
      delete data.analysis_type
      delete data.peak_integration
      displayPopupMessage(
        dispatch,
        'success',
        <Trans i18nKey="view.common.utils.global_peak.reintegration">
          {{ val: reintegrationString }} reintegration
        </Trans>,
        <Fragment>
          <div>
            <Trans i18nKey="view.common.utils.global_peak.success">
              The peaks reintegration of <i>{{ val1: unitaryAnalysis.name }}</i> are updated to the
              {{ val2: reintegrationString }} analyzes:
            </Trans>
          </div>
          {Object.keys(data).map(function (key) {
            const firstPart = key.charAt(0)
            const secondPart = key.charAt(1)
            const point = secondPart === '1' ? 'left' : 'right'
            return (
              <div key={key}>
                {point.charAt(0).toUpperCase() + point.substr(1)} point {firstPart} is now{' '}
                {data[key] !== '' && data[key] !== null ? data[key].toFixed(2) : data[key]}
              </div>
            )
          })}
        </Fragment>,
      )
      dispatch(fetchFinishTask(taskId, 'success', t('view.common.utils.global_peak.fine')))
    })
    .catch(function (error) {
      if (error && error.status !== undefined && error.status === 401) {
        error.statusText = t('view.common.utils.lease_locked.code')
        displayPopupMessage(
          dispatch,
          'warning',
          <Trans i18nKey="view.common.utils.global_peak.reintegration">
            {{ val: reintegrationString }} reintegration
          </Trans>,
          <Fragment>
            <div>{t('view.common.utils.global_peak.cant_apply')}</div>
            {generateErrorPart(error)}
          </Fragment>,
        )
      } else {
        displayPopupMessage(
          dispatch,
          'error',
          <Trans i18nKey="view.common.utils.global_peak.reintegration">
            {{ val: reintegrationString }} reintegration
          </Trans>,
          <Fragment>
            <div>
              <Trans i18nKey="view.common.utils.global_peak.error">
                The peaks reintegration of <i>{{ val1: unitaryAnalysis.name }}</i> can not be
                applied to the {{ val2: reintegrationString }}
                analyzes.
              </Trans>
            </div>
            {generateErrorPart(error)}
          </Fragment>,
        )
      }
      dispatch(fetchFinishTask(taskId, 'error', error))
    })
}

export const updatePeak = (
  item,
  index,
  value,
  dispatch,
  collection,
  channel,
  updateCalibrationAfterPeakUpdate,
) => {
  const unitaryAnalyseId = item._id
  let data = {}
  if (index !== null) {
    const ind = index + 1
    data[`x${ind}`] = value.x
    data[`y${ind}`] = value.y
  } else {
    data[`x1`] = value[0].x
    data[`y1`] = value[0].y
    data[`x2`] = value[1].x
    data[`y2`] = value[1].y
  }
  data['channel'] = channel

  const taskId = new Date().getTime() + '_update_peak_' + item._id
  const task = {
    id: taskId,
    title: t('view.common.utils.update_peak.title'),
    operator: localStorage.getItem('SESSION_user_name'),
    date: new Date().getTime(),
    action: 'update_peak',
    percentage: 50,
    state: { value: 'running', message: '' },
    // operation: JSON.stringify(value),
    operation: item.name,
    items: [
      {
        id: item.analyseId,
        type: item.analysetype,
        name: item.analyse,
        analysis: {
          id: item.analyseId,
          type: item.analysetype,
          name: item.analyse,
        },
        batch: {
          id: item.batchId,
          name: item.batch_name,
        },
      },
    ],
  }
  dispatch(fetchAddTask(task))

  if (collection === 'analyse') {
    dispatch(fetchUpdateAnalysePeak(unitaryAnalyseId, data, item.batchId, item.analyseId))
      .then(function (resp) {
        sendResultMessage(dispatch, resp ? resp.type : null, data, item)
        dispatch(fetchFinishTask(taskId, 'success', t('view.common.utils.update_peak.fine')))
      })
      .catch(function (error) {
        if (error && error.status !== undefined && error.status === 401) {
          error.statusText = t('view.common.utils.lease_locked.code')
          displayPopupMessage(
            dispatch,
            'warning',
            t('view.common.utils.update_peak.title'),
            <Fragment>
              <div>{t('view.common.utils.update_peak.cant_update')}</div>
              {generateErrorPart(error)}
            </Fragment>,
          )
        } else {
          sendResultMessage(dispatch, null, error, item)
        }
        dispatch(fetchFinishTask(taskId, 'error', error))
      })
  }
  if (collection === 'compound') {
    dispatch(fetchUpdateCompoundPeak(unitaryAnalyseId, data, item.batchId, item.analyseId))
      .then(function (resp) {
        sendResultMessage(dispatch, resp.type, data, item)
        dispatch(fetchFinishTask(taskId, 'success', t('view.common.utils.update_peak.fine')))
        //Reload Calibration view to display recompute calibration button
        if (updateCalibrationAfterPeakUpdate) {
          updateCalibrationAfterPeakUpdate()
        }
      })
      .catch(function (error) {
        if (error && error.status !== undefined && error.status === 401) {
          error.statusText = t('view.common.utils.lease_locked.code')
          displayPopupMessage(
            dispatch,
            'warning',
            t('view.common.utils.update_peak.title'),
            <Fragment>
              <div>{t('view.common.utils.update_peak.cant_update')}</div>
              {generateErrorPart(error)}
            </Fragment>,
          )
        } else {
          sendResultMessage(dispatch, null, error, item)
        }
        dispatch(fetchFinishTask(taskId, 'error', error))
      })
  }
}

const sendResultMessage = (dispatch, type, data, item) => {
  switch (type) {
    case 'RECEIVE_UPDATE_PEAK_ANALYSE':
    case 'RECEIVE_UPDATE_PEAK_COMPOUND':
      delete data.channel
      // displayPopupMessage(
      // dispatch,
      //     'success',
      //     'Peak update',
      //     <Fragment>
      //       <div>
      //         The peaks of <i>{item.name}</i> are updated to:.
      //       </div>
      //       {Object.keys(data).map(function (key) {
      //         const firstPart = key.charAt(0)
      //         const secondPart = key.charAt(1)
      //         const point = secondPart === '1' ? 'left' : 'right'
      //         return (
      //           <div key={key}>
      //             {point.charAt(0).toUpperCase() + point.substr(1)} point {firstPart} is now{' '}
      //             {data[key] !== '' && data[key] !== null ? data[key].toFixed(2) : data[key]}
      //           </div>
      //         )
      //       })}
      //     </Fragment>,
      //   )
      break
    default:
      // TODO get the good error message
      const error = 'error'
      displayPopupMessage(
        dispatch,
        'error',
        t('view.common.utils.update_peak.title'),
        <Fragment>
          <div>{t('view.common.utils.update_peak.cant_update')}</div>
          {generateErrorPart(data)}
        </Fragment>,
      )
  }
}

export const resetPeak = (
  item,
  index,
  dispatch,
  collection,
  channel,
  updateCalibrationAfterPeakUpdate,
) => {
  const unitaryAnalyseId = item._id
  let data = {}
  data['channel'] = channel

  const taskId = new Date().getTime() + '_reset_peak_' + item._id
  const task = {
    id: taskId,
    title: t('view.common.utils.update_peak.title'),
    operator: localStorage.getItem('SESSION_user_name'),
    date: new Date().getTime(),
    action: 'reset_peak',
    percentage: 50,
    state: { value: 'running', message: '' },
    // operation: JSON.stringify(value),
    operation: item.name,
    items: [
      {
        id: item.analyseId,
        type: item.analysetype,
        name: item.analyse,
        analysis: {
          id: item.analyseId,
          type: item.analysetype,
          name: item.analyse,
        },
        batch: {
          id: item.batchId,
          name: item.batch_name,
        },
      },
    ],
  }
  dispatch(fetchAddTask(task))

  if (collection === 'analyse') {
    return dispatch(fetchResetAnalysePeak(unitaryAnalyseId, data, item.batchId, item.analyseId))
      .then(function (resp) {
        sendResultMessage(dispatch, resp ? resp.type : null, data, item)
        dispatch(fetchFinishTask(taskId, 'success', t('view.common.utils.update_peak.fine')))
        return resp
      })
      .catch(function (error) {
        if (error && error.status !== undefined && error.status === 401) {
          error.statusText = t('view.common.utils.lease_locked.code')
          displayPopupMessage(
            dispatch,
            'warning',
            t('view.common.utils.update_peak.title'),
            <Fragment>
              <div>{t('view.common.utils.update_peak.cant_update')}</div>
              {generateErrorPart(error)}
            </Fragment>,
          )
        } else {
          sendResultMessage(dispatch, null, error, item)
        }
        dispatch(fetchFinishTask(taskId, 'error', error))
        return null
      })
  }
  if (collection === 'compound') {
    return dispatch(fetchResetCompoundPeak(unitaryAnalyseId, data, item.batchId, item.analyseId))
      .then(function (resp) {
        sendResultMessage(dispatch, resp.type, data, item)
        dispatch(fetchFinishTask(taskId, 'success', t('view.common.utils.update_peak.fine')))
        //Reload Calibration view to display recompute calibration button
        if (updateCalibrationAfterPeakUpdate) {
          updateCalibrationAfterPeakUpdate()
        }
        return resp
      })
      .catch(function (error) {
        if (error && error.status !== undefined && error.status === 401) {
          error.statusText = t('view.common.utils.lease_locked.code')
          displayPopupMessage(
            dispatch,
            'warning',
            t('view.common.utils.update_peak.title'),
            <Fragment>
              <div>{t('view.common.utils.update_peak.cant_update')}</div>
              {generateErrorPart(error)}
            </Fragment>,
          )
        } else {
          sendResultMessage(dispatch, null, error, item)
        }
        dispatch(fetchFinishTask(taskId, 'error', error))
        return null
      })
  }
}

/**************************** UPDATE  ISTD **********************************/

export const updateISTD = (dispatch, istd_newGroup, istd_event, batch, item, collection) => {
  // displayPopupMessage(
  //           dispatch,'info', 'ISTD update', `Not implemented yet...`)
  displayPopupMessage(
    dispatch,
    'info',
    t('view.common.utils.update_istd.title'),
    <Fragment>
      <div>
        <Trans i18nKey="view.common.utils.update_istd.info_1">
          Requested for ISTD update on <i>{{ val: item.name }}</i>.
        </Trans>
      </div>
      <div style={{ marginTop: 10 }}>
        <i>{t('view.common.utils.update_istd.info_2')}</i>
      </div>
    </Fragment>,
  )

  const taskId = new Date().getTime() + '_istd_' + item._id

  switch (collection) {
    case 'batch':
      const task_istd_batch = {
        id: taskId,
        title: t('view.common.utils.update_istd.title'),
        operator: localStorage.getItem('SESSION_user_name'),
        date: new Date().getTime(),
        action: 'istd',
        percentage: 50,
        state: { value: 'running', message: '' },
        operation: batch.content.internal_standards[istd_newGroup].name,
        items: [
          {
            id: item._id,
            type: item.analyseType,
            name: item.name,
            compound: {
              name: item.name,
              istd: item.istd_name,
            },
            batch: {
              id: batch._id,
              name: batch.name,
            },
          },
        ],
      }
      dispatch(fetchAddTask(task_istd_batch))
      dispatch(fetchUpdateISTD(istd_newGroup, istd_event, batch._id))
        .then(function (resp) {
          displayPopupMessage(
            dispatch,
            'success',
            t('view.common.utils.update_istd.title'),
            <Fragment>
              <Trans i18nKey="view.common.utils.update_istd.success">
                The ISTD is now updated for <i>{{ val: item.name }}</i>.
              </Trans>
            </Fragment>,
          )
          dispatch(fetchFinishTask(taskId, 'success', t('view.common.utils.update_istd.fine')))
        })
        .catch(function (error) {
          if (error && error.status !== undefined && error.status === 401) {
            error.statusText = t('view.common.utils.lease_locked.code')
            displayPopupMessage(
              dispatch,
              'warning',
              t('view.common.utils.update_istd.title'),
              <Fragment>
                <div>{t('view.common.utils.update_istd.cant_1')}</div>
                {generateErrorPart(error)}
              </Fragment>,
            )
          } else {
            displayPopupMessage(
              dispatch,
              'error',
              t('view.common.utils.update_istd.title'),
              <Fragment>
                <div>
                  <Trans i18nKey="view.common.utils.update_istd.cant_2">
                    Can not update the ISTD for <i>{item.name}</i>.
                  </Trans>
                </div>
                {generateErrorPart(error)}
              </Fragment>,
            )
          }
          dispatch(fetchFinishTask(taskId, 'error', error))
        })
      break
    case 'compound':
      const task_istd_compound = {
        id: taskId,
        title: t('view.common.utils.update_istd.title'),
        operator: localStorage.getItem('SESSION_user_name'),
        date: new Date().getTime(),
        action: 'istd',
        percentage: 50,
        state: { value: 'running', message: '' },
        operation: batch.content.internal_standards[istd_newGroup].name,
        items: [
          {
            id: item._id,
            type: item.analyseType,
            name: item.name,
            compound: {
              name: item.name,
              istd_name: item.istd_name,
            },
            batch: {
              id: batch._id,
              name: batch.name,
            },
          },
        ],
      }
      dispatch(fetchAddTask(task_istd_compound))
      dispatch(fetchUpdateCompoundISTD(istd_newGroup, istd_event, batch._id))
        .then(function (resp) {
          displayPopupMessage(
            dispatch,
            'success',
            t('view.common.utils.update_istd.title'),
            <Fragment>
              <Trans i18nKey="view.common.utils.update_istd.success">
                The ISTD is now updated for <i>{{ val: item.name }}</i>.
              </Trans>
            </Fragment>,
          )
          dispatch(fetchFinishTask(taskId, 'success', t('view.common.utils.update_istd.fine')))
        })
        .catch(function (error) {
          if (error && error.status !== undefined && error.status === 401) {
            error.statusText = t('view.common.utils.lease_locked.code')
            displayPopupMessage(
              dispatch,
              'warning',
              t('view.common.utils.update_istd.title'),
              <Fragment>
                <div>{t('view.common.utils.update_istd.cant_1')}</div>
                {generateErrorPart(error)}
              </Fragment>,
            )
          } else {
            displayPopupMessage(
              dispatch,
              'error',
              t('view.common.utils.update_istd.title'),
              <Fragment>
                <div>
                  <Trans i18nKey="view.common.utils.update_istd.cant_2">
                    Can not update the ISTD for <i>{item.name}</i>.
                  </Trans>
                </div>
                {generateErrorPart(error)}
              </Fragment>,
            )
          }
          dispatch(fetchFinishTask(taskId, 'error', error))
        })
      break
    default:
      // Never in: back hole
      displayPopupMessage(
        dispatch,
        'error',
        t('view.common.utils.update_istd.title'),
        <Trans i18nKey="view.common.utils.update_istd.cant_2">
          Can not update the ISTD for <i>{item.name}</i>.
        </Trans>,
      )
  }
}

/**************************** CREATE/MOVE SUB-BATCH **********************************/

export const createSubBatch = (dispatch, batchId, batchName, selection, option) => {
  displayPopupMessage(
    dispatch,
    'info',
    <Trans i18nKey="view.common.utils.create_sub.title">
      {{
        val:
          option === 'copy'
            ? t('view.common.utils.create_sub.copy')
            : t('view.common.utils.create_sub.move'),
      }}
      to a new sub-batch
    </Trans>,
    <Fragment>
      <div>
        <Trans i18nKey="view.common.utils.create_sub.msg1">
          A new sub-batch named
          <i>
            {{
              val: batchName,
            }}
          </i>
          will be created with the selected samples:
        </Trans>
      </div>
      <div style={{ maxWidth: 600, maxHeight: 400, overflow: 'scroll' }}>
        {selection.map((item, index) => (
          <Chip
            key={index}
            variant="outlined"
            size="small"
            style={{ margin: 3 }}
            label={item.name}
          />
        ))}
      </div>
      <div style={{ marginTop: 10 }}>
        <i>{t('view.common.utils.create_sub.msg1')}</i>
      </div>
    </Fragment>,
  )
  const taskId =
    new Date().getTime() +
    '_moveToNewSubBatch_' +
    batchId +
    '_' +
    batchName +
    '_' +
    selection[0]._id
  const taskSubBatch = {
    id: taskId,
    title: (
      <Trans i18nKey="view.common.utils.create_sub.sample_title">
        Sample
        {{
          val:
            option === 'copy'
              ? t('view.common.utils.create_sub.copy_lower')
              : t('view.common.utils.create_sub.move_lower'),
        }}
      </Trans>
    ),
    operator: localStorage.getItem('SESSION_user_name'),
    date: new Date().getTime(),
    percentage: 50,
    action:
      option === 'copy'
        ? t('view.common.utils.create_sub.copy_lower')
        : t('view.common.utils.create_sub.move_lower'),
    state: { value: 'running', message: '' },
    operation: (
      <Trans i18nKey="view.common.utils.create_sub.operation">
        new sub-batch
        {{
          val: batchName,
        }}
      </Trans>
    ),
    items: selection.map((orData) => ({
      id: orData._id,
      type: 'analysis',
      name: orData.name,
      batch: {
        id: orData.content.batch.id,
        name: orData.content.batch.name,
      },
    })),
  }
  dispatch(fetchAddTask(taskSubBatch))
  dispatch(fetchCreateSubBatch(batchId, batchName, selection, option))
    .then(function (resp) {
      dispatch(fetchFinishTask(taskId, 'success', t('view.common.utils.create_sub.fine')))
      displayPopupMessage(
        dispatch,
        'success',
        <Trans i18nKey="view.common.utils.create_sub.title">
          {{
            val:
              option === 'copy'
                ? t('view.common.utils.create_sub.copy')
                : t('view.common.utils.create_sub.move'),
          }}
          to a new sub-batch
        </Trans>,
        <Fragment>
          <div>
            <Trans i18nKey="view.common.utils.create_sub.sub_named">
              A new sub-batch named <i>{{ val: batchName }}</i> is created with the selected
              samples:
            </Trans>
          </div>
          <div style={{ maxWidth: 600, maxHeight: 400, overflow: 'scroll' }}>
            {selection.map((item) => (
              <Chip variant="outlined" size="small" style={{ margin: 3 }} label={item.name} />
            ))}
          </div>
        </Fragment>,
      )
    })
    .catch(function (error) {
      if (error && error.status !== undefined && error.status === 401) {
        error.statusText = t('view.common.utils.lease_locked.code')
        displayPopupMessage(
          dispatch,
          'warning',
          <Trans i18nKey="view.common.utils.create_sub.title">
            {{
              val:
                option === 'copy'
                  ? t('view.common.utils.create_sub.copy')
                  : t('view.common.utils.create_sub.move'),
            }}
            to a new sub-batch
          </Trans>,
          <Fragment>
            <div>
              <Trans i18nKey="view.common.utils.create_sub.not_create">
                Can not create a sub-batch <i>{{ val: batchName }}</i>.
              </Trans>
            </div>
            {generateErrorPart(error)}
          </Fragment>,
        )
      } else {
        displayPopupMessage(
          dispatch,
          'error',
          <Trans i18nKey="view.common.utils.create_sub.title">
            {{
              val:
                option === 'copy'
                  ? t('view.common.utils.create_sub.copy')
                  : t('view.common.utils.create_sub.move'),
            }}
            to a new sub-batch
          </Trans>,
          <Fragment>
            <div>
              <Trans i18nKey="view.common.utils.create_sub.not_create">
                Can not create a sub-batch <i>{{ val: batchName }}</i>.
              </Trans>
            </div>
            {generateErrorPart(error)}
          </Fragment>,
        )
      }
      dispatch(fetchFinishTask(taskId, 'error', error))
    })
}

export const moveSampleToSubBatch = (dispatch, dest_batch_id, batchName, selection, option) => {
  displayPopupMessage(
    dispatch,
    'info',
    <Trans i18nKey="view.common.utils.move_to_subbatch.title">
      {{
        val:
          option === 'copy'
            ? t('view.common.utils.move_to_subbatch.copy')
            : t('view.common.utils.move_to_subbatch.move'),
      }}
      to a sub-batch
    </Trans>,
    <Fragment>
      <div>
        <Trans i18nKey="view.common.utils.move_to_subbatch.selected_sample">
          The selected sample bellow will be
          {{
            val:
              option === 'copy'
                ? t('view.common.utils.move_to_subbatch.copied')
                : t('view.common.utils.move_to_subbatch.moved'),
          }}
          to
          <i>{{ val2: batchName }}</i>:
        </Trans>
      </div>
      <div style={{ maxWidth: 600, maxHeight: 400, overflow: 'scroll' }}>
        {selection.map((item) => (
          <Chip variant="outlined" size="small" style={{ margin: 3 }} label={item.name} />
        ))}
      </div>
      <div style={{ marginTop: 10 }}>
        <i>{t('view.common.utils.move_to_subbatch.time')}</i>
      </div>
    </Fragment>,
  )
  const taskId =
    new Date().getTime() +
    '_moveToSubBatch_' +
    dest_batch_id +
    '_' +
    batchName +
    '_' +
    selection[0]._id
  const taskSubBatch = {
    id: taskId,
    title: (
      <Trans i18nKey="view.common.utils.move_to_subbatch.title2">
        Sample
        {{
          val:
            option === 'copy'
              ? t('view.common.utils.move_to_subbatch.copy_lower')
              : t('view.common.utils.move_to_subbatch.move_lower'),
        }}
      </Trans>
    ),
    operator: localStorage.getItem('SESSION_user_name'),
    date: new Date().getTime(),
    percentage: 50,
    action:
      option === 'copy'
        ? t('view.common.utils.move_to_subbatch.copy_lower')
        : t('view.common.utils.move_to_subbatch.move_lower'),
    state: { value: 'running', message: '' },
    operation: 'sub-batch ' + batchName,
    items: selection.map((orData) => ({
      id: orData._id,
      type: 'analysis',
      name: orData.name,
      batch: {
        id: orData.content.batch.id,
        name: orData.content.batch.name,
      },
    })),
  }
  dispatch(fetchAddTask(taskSubBatch))
  dispatch(
    fetchMoveSampleToSubBatch(dest_batch_id, selection, option, selection[0].content.batch.id),
  )
    .then(function (resp) {
      displayPopupMessage(
        dispatch,
        'success',
        <Trans i18nKey="view.common.utils.move_to_subbatch.title">
          {{
            val:
              option === 'copy'
                ? t('view.common.utils.move_to_subbatch.copy')
                : t('view.common.utils.move_to_subbatch.move'),
          }}
          to a sub-batch
        </Trans>,
        <Fragment>
          <div>
            <Trans i18nKey="view.common.utils.move_to_subbatch.selected_moved">
              The selected sample bellow are moved to <i>{{ val: batchName }}</i>:
            </Trans>
          </div>
          <div style={{ maxWidth: 600, maxHeight: 400, overflow: 'scroll' }}>
            {selection.map((item) => (
              <Chip variant="outlined" size="small" style={{ margin: 3 }} label={item.name} />
            ))}
          </div>
        </Fragment>,
      )
      dispatch(fetchFinishTask(taskId, 'success', t('view.common.utils.move_to_subbatch.fine')))
    })
    .catch(function (error) {
      if (error && error.status !== undefined && error.status === 401) {
        error.statusText = t('view.common.utils.lease_locked.code')
        displayPopupMessage(
          dispatch,
          'warning',
          <Trans i18nKey="view.common.utils.move_to_subbatch.title">
            {{
              val:
                option === 'copy'
                  ? t('view.common.utils.move_to_subbatch.copy')
                  : t('view.common.utils.move_to_subbatch.move'),
            }}
            to a sub-batch
          </Trans>,
          <Fragment>
            <div>
              <Trans i18nKey="view.common.utils.move_to_subbatch.canot">
                Can not
                {{
                  val1:
                    option === 'copy'
                      ? t('view.common.utils.move_to_subbatch.copy')
                      : t('view.common.utils.move_to_subbatch.move'),
                }}
                to a sub-batch <i>{{ val2: batchName }}</i>
              </Trans>
              .
            </div>
            {generateErrorPart(error)}
          </Fragment>,
        )
      } else {
        displayPopupMessage(
          dispatch,
          'error',
          <Trans i18nKey="view.common.utils.move_to_subbatch.title">
            {{
              val:
                option === 'copy'
                  ? t('view.common.utils.move_to_subbatch.copy')
                  : t('view.common.utils.move_to_subbatch.move'),
            }}
            to a sub-batch
          </Trans>,
          <Fragment>
            <div>
              <Trans i18nKey="view.common.utils.move_to_subbatch.canot">
                Can not
                {{
                  val1:
                    option === 'copy'
                      ? t('view.common.utils.move_to_subbatch.copy')
                      : t('view.common.utils.move_to_subbatch.move'),
                }}
                to a sub-batch <i>{{ val2: batchName }}</i>
              </Trans>
              :
            </div>
            {generateErrorPart(error)}
          </Fragment>,
        )
      }
      dispatch(fetchFinishTask(taskId, 'error', error))
    })
}

export const changeAnalysesType = (dispatch, batchId, type, selection, recomputeCalibration) => {
  const arr = selection.map(function (analysis) {
    let obj = {
      type: analysis.content.type,
      value:
        analysis.content.type === 'sample'
          ? analysis.content.value.toString()
          : parseFloat(analysis.content.value),
    }
    if (analysis.content.type === 'calibration' && analysis.content.suffix) {
      obj['suffix'] = analysis.content.suffix
    }
    if (recomputeCalibration) {
      obj['recomputeCalibration'] = recomputeCalibration
    }
    return { [analysis._id]: obj }
  })

  let dictionary = Object.assign({}, ...arr)
  const result = { analyses_types: dictionary }

  displayPopupMessage(
    dispatch,
    'info',
    t('view.common.utils.analysesType.title'),
    <Fragment>
      <div>
        <Trans i18nKey="view.common.utils.analysesType.will_moved">
          The analyses bellow will be moved out:
          <i>{{ val: type }}</i>:
        </Trans>
      </div>
      <div style={{ maxWidth: 600, maxHeight: 400, overflow: 'scroll' }}>
        {selection.map((item) => (
          <Chip
            key={item.name}
            variant="outlined"
            size="small"
            style={{ margin: 3 }}
            label={item.name}
          />
        ))}
      </div>
      <div style={{ marginTop: 10 }}>
        <i>{t('view.common.utils.analysesType.time')}</i>
      </div>
    </Fragment>,
  )
  const taskId = new Date().getTime() + '_changeAnalyseType_' + type + '_' + selection[0]._id
  const taskCalib = {
    id: taskId,
    title:
      type === 'Quality Control'
        ? t('view.common.utils.analysesType.quali_control')
        : t('view.common.utils.analysesType.others_move'),
    operator: localStorage.getItem('SESSION_user_name'),
    date: new Date().getTime(),
    percentage: 50,
    action: 'move',
    state: { value: 'running', message: '' },
    operation:
      type === 'Quality Control'
        ? t('view.common.utils.analysesType.others')
        : t('view.common.utils.analysesType.qc_samples'),
    items: selection.map((orData) => ({
      id: orData._id,
      type: 'analysis',
      name: orData.name,
      batch: {
        id: orData.content.batch.id,
        name: orData.content.batch.name,
      },
    })),
  }
  dispatch(fetchAddTask(taskCalib))
  dispatch(fetchChangeAnalysesType(batchId, type, result, selection))
    .then(function (resp) {
      // Reload the calibration filters count
      dispatch(fetchUnitaryCalibrationsDistinct(batchId))
      displayPopupMessage(
        dispatch,
        'success',
        t('view.common.utils.analysesType.title'),
        <Fragment>
          <div>{t('view.common.utils.analysesType.change_are_done')}</div>
          <div style={{ maxWidth: 600, maxHeight: 400, overflow: 'scroll' }}>
            {selection.map((item) => (
              <Chip variant="outlined" size="small" style={{ margin: 3 }} label={item.name} />
            ))}
          </div>
        </Fragment>,
      )
      dispatch(fetchFinishTask(taskId, 'success', t('view.common.utils.analysesType.fine')))
    })
    .catch(function (error) {
      if (error && error.status !== undefined && error.status === 401) {
        error.statusText = t('view.common.utils.lease_locked.code')
        displayPopupMessage(
          dispatch,
          'warning',
          t('view.common.utils.analysesType.title'),
          <Fragment>
            <div>{t('view.common.utils.analysesType.canot')}.</div>
            {generateErrorPart(error)}
          </Fragment>,
        )
      } else {
        displayPopupMessage(
          dispatch,
          'error',
          t('view.common.utils.analysesType.title'),
          <Fragment>
            <div>
              <i>{t('view.common.utils.analysesType.canot')}:</i>
            </div>
            {generateErrorPart(error)}
          </Fragment>,
        )
      }
      dispatch(fetchFinishTask(taskId, 'error', error))
    })
}

/**************************** Select next channel for ref ions display **********************************/
const getNextChannel = (main_channel_number, channels) => {
  const channels_number = Object.keys(channels).length
  if (channels_number > 0) {
    return channels_number > 1 && main_channel_number + 1 <= channels_number
      ? parseFloat(Object.keys(channels)[main_channel_number])
      : parseFloat(Object.keys(channels)[0])
  } else {
    return null
  }
}

/**************************** TEST CHROME WEBBROWSER **********************************/

export const isOnChrome = (dispatch) => {
  var isChromium = window.chrome
  var winNav = window.navigator
  var vendorName = winNav.vendor
  var isOpera = typeof window.opr !== 'undefined'
  var isIEedge = winNav.userAgent.indexOf('Edge') > -1
  var isIOSChrome = winNav.userAgent.match('CriOS')

  if (isIOSChrome) {
    // is Google Chrome on IOS
    return false
  } else if (
    isChromium !== null &&
    typeof isChromium !== 'undefined' &&
    vendorName === 'Google Inc.' &&
    isOpera === false &&
    isIEedge === false
  ) {
    // is Google Chrome
    console.log('USER:' + localStorage.getItem('SESSION_user_name'))
    displayPopupMessage(
      dispatch,
      'info',
      localStorage.getItem('__config.json__APPLICATION_TITLE'),
      <Fragment>
        <div>
          <Trans i18nKey="view.common.utils.isOnChrome.welcome">
            Welcome
            <i>
              {{
                val1: localStorage.getItem('SESSION_user_name')
                  ? localStorage.getItem('SESSION_user_name')
                  : '',
              }}
            </i>
            on {{ val2: localStorage.getItem('__config.json__APPLICATION_TITLE') }} platform.
          </Trans>
        </div>
        <div style={{ marginTop: 5 }}>
          <Trans i18nKey="view.common.utils.isOnChrome.python">
            The python service loaded is <i>{{ val: SERVICE() }}</i>.
          </Trans>
        </div>
        <div>
          <Trans i18nKey="view.common.utils.isOnChrome.database">
            The database working with is <i>{{ val: DATABASE() }}</i>.
          </Trans>
        </div>
      </Fragment>,
    )
  } else {
    displayPopupMessage(
      dispatch,
      'warning',
      t('view.common.utils.isOnChrome.title'),
      t('view.common.utils.isOnChrome.msg'),
    )
  }
}

/**************************** SORT **********************************/

function getWorstFlag(item) {
  const flags = item.flags
  let flags_dict = new Map()
  flags_dict.set('info', 0)
  flags_dict.set('warning', 1)
  flags_dict.set('error', 2)
  let flagValue = -1

  const flags_globals = flags && flags.globals ? flags.globals : []
  flags_globals.forEach((flag) => {
    if (flags_dict.get(flag.level) > flagValue) {
      flagValue = flags_dict.get(flag.level)
    }
  })
  const flags_channels = flags && flags.channels ? flags.channels : {}
  const flags_channels_entries = Object.entries(flags_channels)
  if (flags_channels_entries.length > 0 && flagValue !== 2) {
    flags_channels_entries.forEach((channel) => {
      channel[1].forEach((flag) => {
        if (flags_dict.get(flag.level) > flagValue) {
          if (channel[0] !== item.mainChannel.toString() && flag.level === 'error') {
            flagValue = 1
          } else {
            flagValue = flags_dict.get(flag.level)
          }
        }
      })
    })
  }
  return flagValue
}

export function descendingComparator(a, b, orderBy) {
  // Hack to sort on notes column
  if (orderBy === 'notes') {
    const aN = a.notes ? a.notes.length : a.content && a.content.notes ? a.content.notes.length : 0
    const bN = b.notes ? b.notes.length : b.content && b.content.notes ? b.content.notes.length : 0
    if (bN < aN) {
      return -1
    }
    if (bN > aN) {
      return 1
    }
    return 0
  }
  // Hack to sort on Progression column
  if (orderBy === 'progression') {
    const aP = a.content.progression_requested
    const bP = b.content.progression_requested
    if (bP < aP) {
      return -1
    }
    if (bP > aP) {
      return 1
    }
    return 0
  }
  // Hack to sort on flags column
  if (orderBy === 'flags') {
    let aFlag = 0
    let bFlag = 0

    // Case for analysis in batch view
    if ((a.content && a.content.flags) || (b.content && b.content.flags)) {
      aFlag = getWorstFlag({ ...a, flags: a.content.flags })
      bFlag = getWorstFlag({ ...b, flags: b.content.flags })
    } else {
      // All other cases
      aFlag = getWorstFlag(a)
      bFlag = getWorstFlag(b)
    }
    if (bFlag < aFlag) {
      return -1
    }
    if (bFlag > aFlag) {
      return 1
    }
    return 0
  }
  // Hack to sort on Operator column
  if (orderBy === 'operator') {
    const aOp = a.operator ? a.operator[a.operator.length - 1].name : ''
    const bOp = b.operator ? b.operator[b.operator.length - 1].name : ''
    if (bOp < aOp) {
      return -1
    }
    if (bOp > aOp) {
      return 1
    }
    return 0
  }
  // Case if string, lowercase to sort
  else if (
    (typeof b[orderBy] === 'string' && typeof a[orderBy] === 'string') ||
    orderBy === 'peakName'
  ) {
    let objA = a[orderBy]
    let objB = b[orderBy]
    if (orderBy === 'peakName') {
      objA = a[orderBy].name
      objB = b[orderBy].name
    }
    const lowerA = objA.toLowerCase()
    const lowerB = objB.toLowerCase()
    if (lowerB < lowerA) {
      return -1
    }
    if (lowerB > lowerA) {
      return 1
    }
    return 0
  } else if (typeof b[orderBy] === 'boolean' && typeof a[orderBy] === 'boolean') {
    const ab = a[orderBy].toString()
    const bb = b[orderBy].toString()
    if (bb < ab) {
      return -1
    }
    if (bb > ab) {
      return 1
    }
    return 0
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }
}

export function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

export function isValidDate(dateString) {
  const regEx = /^\d{2}-\d{2}-\d{2}$/
  return dateString.match(regEx) != null
}

export function getTimeStartDay() {
  const t = new Date()
  return new Date(t.getFullYear(), t.getMonth(), t.getDate(), 0, 0, 0).getTime()
}
export function getTimeEndDay() {
  const t = new Date()
  return new Date(t.getFullYear(), t.getMonth(), t.getDate(), 23, 59, 59).getTime()
}

export function formatDate(date, sep) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return [day, month, year].join(sep ? sep : '-')
}

export function setOrderValueFromAnalysisOrder(unitaryAnalysesCompound) {
  if (Math.max(...unitaryAnalysesCompound.map((u) => u['order'])) >= 1000) {
    // let order = 0
    // unitaryAnalysesCompound
    //   .sort(function (a, b) {
    //     if (a['order'] < b['order']) return -1
    //     if (a['order'] > b['order']) return 1
    //     return 0
    //   })
    //   .forEach((a) => {
    //     a['#'] = order
    //     order = order + 1
    //   })
    setOrderValueFromAnalysisOrder_new(unitaryAnalysesCompound)
  } else {
    setOrderValueFromAnalysisOrder_old(unitaryAnalysesCompound)
  }
  return unitaryAnalysesCompound
}

export function setOrderValueFromAnalysisOrder_new(unitaryAnalysesCompound) {
  let order = 0
  const compute_order_analysis_new = (analysis_list, order) => {
    analysis_list
      .sort(function (a, b) {
        if (a['order'] < b['order']) return -1
        if (a['order'] > b['order']) return 1
        return 0
      })
      .forEach((a) => {
        a['#'] = order
        order = order + 1
      })
    return order
  }

  order = compute_order_analysis_new(
    unitaryAnalysesCompound.filter((c) => c.analyseType === 'blank'),
    order,
  )
  order = compute_order_analysis_new(
    unitaryAnalysesCompound.filter((c) => c.analyseType === 'calibration'),
    order,
  )
  order = compute_order_analysis_new(
    unitaryAnalysesCompound.filter((c) => c.analyseType === 'matrix_bio'),
    order,
  )
  order = compute_order_analysis_new(
    unitaryAnalysesCompound.filter((c) => c.analyseType === 'matrix_bio_doped'),
    order,
  )
  order = compute_order_analysis_new(
    unitaryAnalysesCompound.filter((c) => c.analyseType === 'sample'),
    order,
  )
  order = compute_order_analysis_new(
    unitaryAnalysesCompound.filter((c) => c.analyseType === 'std_end'),
    order,
  )
  order = compute_order_analysis_new(
    unitaryAnalysesCompound.filter((c) => c.analyseType === 'other'),
    order,
  )

  return unitaryAnalysesCompound
}

export function setOrderValueFromAnalysisOrder_old(unitaryAnalysesCompound) {
  let order = 0
  const compute_order_analysis = (analysis_list, order, calibration) => {
    analysis_list
      .sort(function (a, b) {
        if (a['#'] < b['#']) return -1
        if (a['#'] > b['#']) return 1
        return 0
      })
      .sort(function (a, b) {
        if (a.analyse < b.analyse) return -1
        if (a.analyse > b.analyse) return 1
        return 0
      })
      .sort(function (a, b) {
        if (calibration) {
          if (a.level < b.level) return -1
          if (a.level > b.level) return 1
          return 0
        } else {
          return 0
        }
      })
      .sort(function (a, b) {
        if (calibration) {
          if (a.suffix < b.suffix) return -1
          if (a.suffix > b.suffix) return 1
          return 0
        } else {
          return 0
        }
      })
      .forEach((a) => {
        a['#'] = order
        order = order + 1
      })
    return order
  }

  order = compute_order_analysis(
    unitaryAnalysesCompound.filter((c) => c.analyseType === 'blank'),
    order,
  )
  order = compute_order_analysis(
    unitaryAnalysesCompound.filter((c) => c.analyseType === 'calibration'),
    order,
    true,
  )
  order = compute_order_analysis(
    unitaryAnalysesCompound.filter((c) => c.analyseType === 'matrix_bio'),
    order,
  )
  order = compute_order_analysis(
    unitaryAnalysesCompound.filter((c) => c.analyseType === 'matrix_bio_doped'),
    order,
  )
  order = compute_order_analysis(
    unitaryAnalysesCompound.filter((c) => c.analyseType === 'sample'),
    order,
  )
  order = compute_order_analysis(
    unitaryAnalysesCompound.filter((c) => c.analyseType === 'std_end'),
    order,
  )
  order = compute_order_analysis(
    unitaryAnalysesCompound.filter((c) => c.analyseType === 'other'),
    order,
  )

  return unitaryAnalysesCompound
}

/**************************** LIMS **********************************/

export function fetchReportToLims(analyses, batchId, nonDetectedUAs, mocFilters) {
  return async (dispatch) => {
    return new Promise((resolve, reject) => {
      const uaLimsFiltered = analyses.map((ua) => ({
        ...ua,
        limsUA:
          mocFilters.length > 0
            ? ua.limsUA.filter((lua) => mocFilters.indexOf(lua.moc) > -1)
            : ua.limsUA,
      }))
      let data = {}
      // Set is used to keep only 1 occurence
      data['ua_ids'] = [
        ...new Set(
          uaLimsFiltered
            .map((analysis) =>
              analysis.limsUA.filter((ua) => ua.validation === '1').map((ua) => ua._id),
            )
            .flat(),
        ),
      ]
      // Set is used to keep only 1 occurence
      data['nd_ua_ids'] = [
        ...new Set(
          nonDetectedUAs
            .concat(
              uaLimsFiltered.map((analysis) =>
                analysis.limsUA.filter((ua) => ua.validation === '2'),
              ),
            )
            .flat()
            .filter((lua) =>
              mocFilters.length > 0 ? mocFilters.indexOf(lua.content.moc) > -1 : lua,
            )
            .map((ua) => ua._id),
        ),
      ]

      const requestOptions = {
        method: 'PUT',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ batch_id: batchId, analysis_id: null, body: data }),
      }
      let url = `${FLASK_URL()}/analysis/report`
      fetch(url, requestOptions)
        .then((response) => {
          if (!response.ok) {
            const statusText = response.statusText
            const status = response.status
            const url = response.url
            return response.text().then((errorMessage) => {
              const error = new Error(`${statusText} : ${errorMessage}`)
              if (response.headers.get('content-type') === 'application/json') {
                error.stack = JSON.stringify(
                  JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                  null,
                  2,
                )
              } else {
                error.stack = new Error().stack
              }
              error.statusText = statusText
              error.status = status
              error.url = url
              throw error
            })
          }
          return response.json()
        })
        .then((data) => {
          resolve(data)
        })
        .catch((error) => {
          const authenticationError = error.status === 403
          if (authenticationError) {
            dispatch(fetchChangeSettings('loginPopup', true))
          }
          reject(error)
        })
    })
  }
}

export function fetchLmrReportToLims(analyses, batchId, mocFilters) {
  return async (dispatch) => {
    return new Promise((resolve, reject) => {
      const uaLimsFiltered = analyses.map((ua) => ({
        ...ua,
        limsUA:
          mocFilters.length > 0
            ? ua.limsUA.filter((lua) => mocFilters.indexOf(lua.moc) > -1)
            : ua.limsUA,
      }))
      let data = {}
      data['ua_ids'] = uaLimsFiltered
        .map((analysis) =>
          analysis.limsUA.filter((ua) => ua.validation === '1').map((ua) => ua._id),
        )
        .flat()

      const requestOptions = {
        method: 'PUT',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ batch_id: batchId, analysis_id: null, body: data }),
      }
      let url = `${FLASK_URL()}/analysis/report_lmr`
      fetch(url, requestOptions)
        .then((response) => {
          if (!response.ok) {
            const statusText = response.statusText
            const status = response.status
            const url = response.url
            return response.text().then((errorMessage) => {
              const error = new Error(`${statusText} : ${errorMessage}`)
              if (response.headers.get('content-type') === 'application/json') {
                error.stack = JSON.stringify(
                  JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                  null,
                  2,
                )
              } else {
                error.stack = new Error().stack
              }
              error.statusText = statusText
              error.status = status
              error.url = url
              throw error
            })
          }
          return response.json()
        })
        .then((data) => {
          resolve(data)
        })
        .catch((error) => {
          const authenticationError = error.status === 403
          if (authenticationError) {
            dispatch(fetchChangeSettings('loginPopup', true))
          }
          reject(error)
        })
    })
  }
}

/**************************** AUDIT TRAIL **********************************/

export function exportAuditTrail(dispatch) {
  displayPopupMessage(
    dispatch,
    'info',
    t('view.common.utils.audit_trail.title'),
    t('view.common.utils.audit_trail.msg'),
  )
}

export const handleActionReport = (event, analyse, value, collection, dispatch) => {
  event.stopPropagation()
  switch (value) {
    case 0:
      // Pas possible
      // const requestOptions = {
      //   method: 'GET',
      //   credentials: 'same-origin',
      //   // responseType: 'blob', // important
      //   // credentials: 'include',
      // }
      // let url = `${FLASK_URL}/analysis/${selection[0]._id}/audit_trail`
      // return fetch(url, requestOptions)
      //   .then(async (response) => {
      //     // check for error response
      //     if (!response.ok) {
      //       displayPopupMessage(
      // dispatch,
      //           'error',
      //           'Export audit trail',
      //           <Fragment>
      //             <div>Samples selected: {selection[0].name}</div>
      //             Cannot export audit trail.
      //           </Fragment>,
      //         )
      //       let json = response.text()
      //       return json.then(Promise.reject.bind(Promise))
      //     }
      //
      //     displayPopupMessage(
      // dispatch,
      //         'success',
      //         'Export audit trail',
      //         <Fragment>
      //           The audit trail for the analysis {selection[0].name} has been exported.
      //         </Fragment>,
      //       )
      //   })
      //
      displayPopupMessage(
        dispatch,
        'info',
        t('view.common.utils.audit_trail.export_title'),
        <Fragment>
          <div>
            <Trans i18nKey="view.common.utils.audit_trail.export_msg">
              Sample analysis selected: {{ val: analyse.name }}
            </Trans>
          </div>
        </Fragment>,
      )

      // OK
      // Create link to download
      // const blob = response.blob()
      // const url = window.URL.createObjectURL(new Blob([blob]));
      let url = `${FLASK_URL()}/analysis/${analyse._id}/audit_trail`
      let link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `audit_trail_${analyse.name}.txt`)
      document.body.appendChild(link)
      // Force download
      link.click()
      // Clean up and remove the link
      link.parentNode.removeChild(link)

      break
    case 1:
      displayPopupMessage(
        dispatch,
        'warning',
        'Report to LIMS',
        <Fragment>
          <div>
            <Trans i18nKey="view.common.utils.audit_trail.report_msg1">
              Sample analysis selected: {analyse.name}
            </Trans>
          </div>
          {t('view.common.utils.audit_trail.report_msg2')}
        </Fragment>,
      )
      break
    default:
      break
  }
}

/**************************** BUILD EXTRA FLAGS INFO **********************************/
export function getSeverity(flag) {
  switch (flag) {
    case 'Probable detection':
      return 'high'
    case 'Rare detection':
      return 'middle'
    case 'Interfering detection':
      return 'low'
    default:
      return null
  }
}
function extractExtraFlagInfo(flags) {
  const severityOrder = ['low', 'middle', 'high']
  const hasBlockingTrue = flags.some((item) => item.blocking === true && item.ignore === false)
  const hasBlockingExist = flags.some((item) => item.blocking === true)
  const allBlockingIgnored = flags.every((item) => !item.blocking || item.ignore)
  let highestSeverityIndex = -1
  flags.forEach((item) => {
    if (item.title.includes('Probable detection')) {
      highestSeverityIndex = Math.max(highestSeverityIndex, severityOrder.indexOf('high'))
    } else if (item.title.includes('Rare detection')) {
      highestSeverityIndex = Math.max(highestSeverityIndex, severityOrder.indexOf('middle'))
    } else if (item.title.includes('Interfering detection')) {
      highestSeverityIndex = Math.max(highestSeverityIndex, severityOrder.indexOf('low'))
    }
  })
  return highestSeverityIndex !== -1 && hasBlockingExist
    ? {
        severity: severityOrder[highestSeverityIndex],
        ignored: allBlockingIgnored,
      }
    : null
}

/**************************** REBUILD ANALYSES FOR GUI **********************************/

export function rebuildAnalyses(analyses) {
  return {
    ...analyses,
    content: {
      ...analyses.content,
      flags: {
        globals: analyses.content.flags.sort(function (a, b) {
          if (a.title < b.title) return -1
          if (a.title > b.title) return 1
          return 0
        }),
      },
    },
  }
}

/**************************** REBUILD UA FOR GUI **********************************/
export function computeUnitaryAnalysesForGlobalTable(unitAnalyse, criteria) {
  // Function to get the value from a complexe object
  function computeSpecialValue(currentObject) {
    const channel = localStorage.getItem('GLOBAL_table_channel')
      ? localStorage.getItem('GLOBAL_table_channel')
      : 'main'
    switch (criteria) {
      // ion_ratio
      case 'content.ion_ratio[&]content.main_ion_ratio_key':
        return currentObject.content.ion_ratio && currentObject.content.main_ion_ratio_key
          ? currentObject.content.ion_ratio[currentObject.content.main_ion_ratio_key]
          : null
      // modified
      case '_id[&]creationDate[&]modificationDate':
        return currentObject.creationDate !== currentObject.modificationDate
      // validation
      case 'content.validation[&]content.classification':
        return currentObject.content.validation
      // rt
      case 'content.main_channel[&]content.channels.[CHANNEL].rt_calib':
        let valueRt = null
        if (
          channel === 'main' &&
          currentObject.content &&
          currentObject.content.main_channel &&
          currentObject.content.channels &&
          currentObject.content.channels[currentObject.content.main_channel]
        ) {
          valueRt = currentObject.content.channels[currentObject.content.main_channel].rt_calib
        } else if (
          currentObject.content &&
          currentObject.content.channels &&
          currentObject.content.channels[channel]
        ) {
          valueRt = currentObject.content.channels[channel].rt_calib
        }
        return valueRt
      // mass_conc
      case 'content.main_channel[&]content.channels.[CHANNEL].concentration_mass':
        let valueMC = null
        if (
          channel === 'main' &&
          currentObject.content &&
          currentObject.content.main_channel &&
          currentObject.content.channels &&
          currentObject.content.channels[currentObject.content.main_channel]
        ) {
          valueMC =
            currentObject.content.channels[currentObject.content.main_channel].concentration_mass
        } else if (
          currentObject.content &&
          currentObject.content.channels &&
          currentObject.content.channels[channel]
        ) {
          valueMC = currentObject.content.channels[channel].concentration_mass
        }
        return valueMC
      // amt_conc
      case 'content.main_channel[&]content.channels.[CHANNEL].concentration[&]content.channels.[CHANNEL].q1':
        let valueC = null
        if (
          channel === 'main' &&
          currentObject.content &&
          currentObject.content.main_channel &&
          currentObject.content.channels &&
          currentObject.content.channels[currentObject.content.main_channel]
        ) {
          valueC = currentObject.content.channels[currentObject.content.main_channel].concentration
        } else if (
          currentObject.content &&
          currentObject.content.channels &&
          currentObject.content.channels[channel]
        ) {
          valueC = currentObject.content.channels[channel].concentration
        }
        return valueC
      // loq
      case 'content.parameters.loq[&]content.main_channel[&]content.channels.[CHANNEL].q1':
        if (currentObject.content && currentObject.content.parameters) {
          return currentObject.content.parameters.loq
        }
        return null
      // mass_conc_rdt
      case 'content.main_channel[&]content.channels.[CHANNEL].concentration_mass_rdt':
        let valueCMR = null
        if (
          channel === 'main' &&
          currentObject.content &&
          currentObject.content.main_channel &&
          currentObject.content.channels &&
          currentObject.content.channels[currentObject.content.main_channel]
        ) {
          valueCMR =
            currentObject.content.channels[currentObject.content.main_channel]
              .concentration_mass_rdt
        } else if (
          currentObject.content &&
          currentObject.content.channels &&
          currentObject.content.channels[channel]
        ) {
          valueCMR = currentObject.content.channels[channel].concentration_mass_rdt
        }
        return valueCMR
      // area
      case 'content.main_channel[&]content.channels.[CHANNEL].peak.area':
      case 'content.main_channel[&]content.channels.[CHANNEL].selected_policy[&]content.channels.[CHANNEL].candidates.[CANDIDATES].peak.area':
        if (
          channel === 'main' &&
          currentObject.content?.channels?.[currentObject.content?.main_channel]?.candidates?.[
            currentObject.content?.channels[currentObject.content?.main_channel]?.selected_policy
          ]?.peak?.hasOwnProperty('area')
        ) {
          return currentObject.content.channels?.[currentObject.content.main_channel].candidates[
            currentObject.content.channels[currentObject.content.main_channel].selected_policy
          ].peak.area
        } else if (
          currentObject.content?.channels?.[channel]?.candidates?.[
            currentObject?.content?.channels?.[channel]?.selected_policy
          ]?.peak?.hasOwnProperty('area')
        ) {
          return currentObject.content.channels[channel].candidates[
            currentObject.content.channels[channel].selected_policy
          ].peak.area
        } else {
          return null
        }
      // isArea
      case 'content.main_channel[&]content.channels.[CHANNEL].istd_area':
        let valueA = null
        if (
          channel === 'main' &&
          currentObject.content &&
          currentObject.content.main_channel &&
          currentObject.content.channels &&
          currentObject.content.channels[currentObject.content.main_channel]
        ) {
          valueA = currentObject.content.channels[currentObject.content.main_channel].istd_area
        } else if (
          currentObject.content &&
          currentObject.content.channels &&
          currentObject.content.channels[channel]
        ) {
          valueA = currentObject.content.channels[channel].istd_area
        }
        return valueA
      // areaRatio
      case 'content.main_channel[&]content.channels.[CHANNEL].area_ratio':
        let valueR = null
        if (
          channel === 'main' &&
          currentObject.content &&
          currentObject.content.main_channel &&
          currentObject.content.channels &&
          currentObject.content.channels[currentObject.content.main_channel]
        ) {
          valueR = currentObject.content.channels[currentObject.content.main_channel].area_ratio
        } else if (
          currentObject.content &&
          currentObject.content.channels &&
          currentObject.content.channels[channel]
        ) {
          valueR = currentObject.content.channels[channel].area_ratio
        }
        return valueR
      // s_n
      case 'content.main_channel[&]content.channels.[CHANNEL].selected_policy[&]content.channels.[CHANNEL].candidates.[CANDIDATES].peak.snrs':
        let peak = null
        if (
          channel === 'main' &&
          currentObject.content?.channels?.[currentObject.content?.main_channel]?.candidates?.[
            currentObject.content?.channels[currentObject.content?.main_channel]?.selected_policy
          ]?.hasOwnProperty('peak')
        ) {
          peak =
            currentObject.content.channels?.[currentObject.content.main_channel].candidates[
              currentObject.content.channels[currentObject.content.main_channel].selected_policy
            ].peak
        } else if (
          currentObject.content?.channels?.[channel]?.candidates?.[
            currentObject?.content?.channels?.[channel]?.selected_policy
          ]?.hasOwnProperty('peak')
        ) {
          peak =
            currentObject.content.channels[channel].candidates[
              currentObject.content.channels[channel].selected_policy
            ].peak
        }
        if (peak !== null) {
          return peak?.snrs?.methods?.[peak.snrs?.default]
            ? parseFloat(peak?.snrs?.methods?.[peak.snrs?.default].value)
            : null
        } else {
          return null
        }
      // conc_target
      case 'content.gt_concentration[&]content.main_channel[&]content.channels.[CHANNEL].q1':
        if (currentObject.content) {
          return currentObject.content.gt_concentration
        }
        return null
      // height
      case 'content.main_channel[&]content.channels.[CHANNEL].selected_policy[&]content.channels.[CHANNEL].candidates.[CANDIDATES].peak.ampl':
        if (
          channel === 'main' &&
          currentObject.content?.channels?.[currentObject.content?.main_channel]?.candidates?.[
            currentObject.content?.channels[currentObject.content?.main_channel]?.selected_policy
          ]?.peak?.hasOwnProperty('ampl')
        ) {
          return currentObject.content.channels?.[currentObject.content.main_channel].candidates[
            currentObject.content.channels[currentObject.content.main_channel].selected_policy
          ].peak.ampl
        } else if (
          currentObject.content?.channels?.[channel]?.candidates?.[
            currentObject?.content?.channels?.[channel]?.selected_policy
          ]?.peak?.hasOwnProperty('ampl')
        ) {
          return currentObject.content.channels[channel].candidates[
            currentObject.content.channels[channel].selected_policy
          ].peak.ampl
        } else {
          return null
        }
      // refIons
      case 'content.main_channel[&]content.channels.[CHANNEL].q1[&]content.channels.[CHANNEL].q3[&]content.molecule.channels.[CHANNEL].q1[&]content.molecule.channels.[CHANNEL].q3':
        let valueRI = null
        if (
          channel === 'main' &&
          currentObject.content &&
          currentObject.content.main_channel &&
          currentObject.content.channels
        ) {
          valueRI =
            getNextChannel(currentObject.content.main_channel, currentObject.content.channels) &&
            currentObject.content.molecule.channels[
              getNextChannel(currentObject.content.main_channel, currentObject.content.channels)
            ]
              ? currentObject.content.molecule.channels[
                  getNextChannel(currentObject.content.main_channel, currentObject.content.channels)
                ].q1 +
                ' > ' +
                currentObject.content.molecule.channels[
                  getNextChannel(currentObject.content.main_channel, currentObject.content.channels)
                ].q3
              : ''
        } else if (
          currentObject.content &&
          currentObject.content.channels &&
          currentObject.content.channels[channel]
        ) {
          valueRI =
            getNextChannel(
              currentObject.content.channels[channel],
              currentObject.content.channels,
            ) &&
            currentObject.content.molecule.channels[
              getNextChannel(
                currentObject.content.channels[channel],
                currentObject.content.channels,
              )
            ]
              ? currentObject.content.molecule.channels[
                  getNextChannel(
                    currentObject.content.channels[channel],
                    currentObject.content.channels,
                  )
                ].q1 +
                ' > ' +
                currentObject.content.molecule.channels[
                  getNextChannel(
                    currentObject.content.channels[channel],
                    currentObject.content.channels,
                  )
                ].q3
              : ''
        }
        return valueRI
      // rt_peak
      case 'content.main_channel[&]content.channels.[CHANNEL].selected_policy[&]content.channels.[CHANNEL].candidates.[CANDIDATES].peak.time':
        if (
          channel === 'main' &&
          currentObject.content?.channels?.[currentObject.content?.main_channel]?.candidates?.[
            currentObject.content?.channels[currentObject.content?.main_channel]?.selected_policy
          ]?.peak?.hasOwnProperty('time')
        ) {
          return currentObject.content.channels?.[currentObject.content.main_channel].candidates[
            currentObject.content.channels[currentObject.content.main_channel].selected_policy
          ].peak.time
        } else if (
          currentObject.content?.channels?.[channel]?.candidates?.[
            currentObject?.content?.channels?.[channel]?.selected_policy
          ]?.peak?.hasOwnProperty('time')
        ) {
          return currentObject.content.channels[channel].candidates[
            currentObject.content.channels[channel].selected_policy
          ].peak.time
        } else {
          return null
        }
      // devRt
      case 'content.main_channel[&]content.channels.[CHANNEL].dev_rt':
        let valueRT = null
        if (
          channel === 'main' &&
          currentObject?.content?.channels?.[currentObject?.content?.main_channel]
        ) {
          valueRT = currentObject.content.channels[currentObject.content.main_channel].dev_rt
        } else if (currentObject?.content?.channels?.[channel]) {
          valueRT = currentObject.content.channels[channel].dev_rt
        }
        return valueRT
      // devRrt
      case 'content.main_channel[&]content.channels.[CHANNEL].dev_rrt_calibration':
        let valueRRT = null
        if (
          channel === 'main' &&
          currentObject.content &&
          currentObject.content.main_channel &&
          currentObject.content.channels &&
          currentObject.content.channels[currentObject.content.main_channel]
        ) {
          valueRRT =
            currentObject.content.channels[currentObject.content.main_channel].dev_rrt_calibration
        } else if (
          currentObject.content &&
          currentObject.content.channels &&
          currentObject.content.channels[channel]
        ) {
          valueRRT = currentObject.content.channels[channel].dev_rrt_calibration
        }
        return valueRRT
      // devAcc
      case 'content.main_channel[&]content.channels.[CHANNEL].dev_accuracy':
        let valueACC = null
        if (
          channel === 'main' &&
          currentObject.content &&
          currentObject.content.main_channel &&
          currentObject.content.channels &&
          currentObject.content.channels[currentObject.content.main_channel]
        ) {
          valueACC = currentObject.content.channels[currentObject.content.main_channel].dev_accuracy
        } else if (
          currentObject.content &&
          currentObject.content.channels &&
          currentObject.content.channels[channel]
        ) {
          valueACC = currentObject.content.channels[channel].dev_accuracy
        }
        return valueACC
    }
  }

  let objetCourant = unitAnalyse
  // Case for multiple include in request
  if (criteria.indexOf('[&]') !== -1) {
    let concValues = {}
    criteria.split('[&]').forEach(function (c) {
      // Add all non content elements
      if (c.indexOf('content') !== 0) {
        let objetRec = unitAnalyse
        const segments = c.split('.')
        for (let i = 0; i < segments.length; i++) {
          let segment = segments[i]
          objetRec = objetRec[segment]
          if (objetRec === undefined) {
            console.log('Le chemin est invalide.')
            break
          } else if (i === segments.length - 1) {
            concValues[segment] = objetRec
          }
        }
      }
    })
    // Add the content elements
    concValues['content'] = unitAnalyse.content
    objetCourant = computeSpecialValue(concValues)
  } else {
    // Case for single include in request
    const segments = criteria.split('.')
    for (let i = 0; i < segments.length; i++) {
      let segment = segments[i]
      objetCourant = objetCourant[segment]
      // Assurez-vous que l'objet existe à chaque niveau
      if (objetCourant === undefined) {
        // console.log('Le chemin est invalide.')
        break
      }
    }
  }
  return {
    name: unitAnalyse.name,
    _id: unitAnalyse._id,
    analysisName: unitAnalyse.content.analysis.name,
    batchId: unitAnalyse.content.batch.id,
    event: unitAnalyse.content.molecule.event,
    analyseId: unitAnalyse.content.analysis.id,
    value: objetCourant,
    raw: unitAnalyse,
  }
}

export function rebuildUnitaryAnalyses(unitAnalyse) {
  let channelsFlags = {}
  Object.entries(unitAnalyse.content.channels).forEach(function (entry) {
    if (entry[1].flags.length !== 0)
      channelsFlags[entry[0]] = entry[1].flags.sort(function (a, b) {
        if (a.title < b.title) return -1
        if (a.title > b.title) return 1
        return 0
      })
  })
  let channelsClassification = {}
  Object.entries(unitAnalyse.content.channels).forEach(function (entry) {
    channelsClassification[entry[0]] = entry[1].classification
  })

  const amt_conc_channels = {}
  for (
    let step = 1;
    step <=
    Number(localStorage.getItem('__config.json__NUMBER_CHANNEL_VISIBLE_FOR_VOLUMIC_CONCENTRATION'));
    step++
  ) {
    if (
      unitAnalyse.content.channels &&
      unitAnalyse.content.channels[step] &&
      unitAnalyse.content.channels[step].concentration &&
      unitAnalyse.content.channels[step].concentration !== '' &&
      unitAnalyse.content.channels[step].concentration !== null
    ) {
      amt_conc_channels['amt_conc_' + step] = unitAnalyse.content.channels[step].concentration
    }
  }

  const mass_conc_channels = {}
  for (
    let step = 1;
    step <=
    Number(localStorage.getItem('__config.json__NUMBER_CHANNEL_VISIBLE_FOR_VOLUMIC_CONCENTRATION'));
    step++
  ) {
    const isValidConcentration = unitAnalyse.content?.channels?.[step]?.concentration_mass
    if (
      isValidConcentration !== '' &&
      isValidConcentration !== null &&
      isValidConcentration !== undefined
    ) {
      mass_conc_channels['mass_conc_' + step] = parseFloat(isValidConcentration)
    }
  }

  const peak =
    unitAnalyse.content?.channels[unitAnalyse.content?.main_channel]?.candidates?.[
      unitAnalyse.content?.selected_policy
    ]?.peak

  return {
    selected_policy: unitAnalyse.content?.selected_policy,
    recovery_factor: unitAnalyse.content.recovery_factor,
    extraFlags: extractExtraFlagInfo(unitAnalyse.content.flags),
    ...amt_conc_channels,
    recovery: unitAnalyse.content.channels?.[unitAnalyse.content?.main_channel]?.recovery,
    parameters: unitAnalyse.content.parameters,
    dev_ion_ratio: unitAnalyse.content.main_dev_ion_ratio,
    ion_ratio:
      unitAnalyse.content.ion_ratio && unitAnalyse.content.main_ion_ratio_key
        ? unitAnalyse.content.ion_ratio[unitAnalyse.content.main_ion_ratio_key]
        : null,
    use_loq_default:
      unitAnalyse.content.parameters && unitAnalyse.content.parameters['use_loq_default']
        ? unitAnalyse.content.parameters['use_loq_default']
        : '',
    event: unitAnalyse.content.molecule.event,
    analyse: unitAnalyse.content.analysis.name,
    analyseId: unitAnalyse.content.analysis.id,
    rt: unitAnalyse.content.channels[unitAnalyse.content.main_channel].rt_calib
      ? parseFloat(unitAnalyse.content.channels[unitAnalyse.content.main_channel].rt_calib)
      : '',
    rtCorrected: unitAnalyse.content.channels[unitAnalyse.content.main_channel].rt_corrected
      ? parseFloat(unitAnalyse.content.channels[unitAnalyse.content.main_channel].rt_corrected)
      : '',
    peakName: unitAnalyse.content.molecule,
    mass_conc: unitAnalyse.content.channels[unitAnalyse.content.main_channel].concentration_mass
      ? parseFloat(
          unitAnalyse.content.channels[unitAnalyse.content.main_channel].concentration_mass,
        )
      : '',
    ...mass_conc_channels,
    mass_conc_rdt: unitAnalyse.content.channels[unitAnalyse.content.main_channel]
      .concentration_mass_rdt
      ? parseFloat(
          unitAnalyse.content.channels[unitAnalyse.content.main_channel].concentration_mass_rdt,
        )
      : '',
    rdt: unitAnalyse.content.channels[unitAnalyse.content.main_channel].rdt
      ? parseFloat(unitAnalyse.content.channels[unitAnalyse.content.main_channel].rdt)
      : '',
    amt_conc: unitAnalyse.content.channels[unitAnalyse.content.main_channel].concentration
      ? parseFloat(unitAnalyse.content.channels[unitAnalyse.content.main_channel].concentration)
      : '',
    area: peak?.hasOwnProperty('area') ? parseFloat(peak.area) : '',
    // r2: '',
    isArea:
      unitAnalyse.content.channels[unitAnalyse.content.main_channel] &&
      unitAnalyse.content.channels[unitAnalyse.content.main_channel].istd_area
        ? parseFloat(unitAnalyse.content.channels[unitAnalyse.content.main_channel].istd_area)
        : '',
    areaRatio:
      unitAnalyse.content.channels[unitAnalyse.content.main_channel] &&
      unitAnalyse.content.channels[unitAnalyse.content.main_channel].area_ratio
        ? parseFloat(unitAnalyse.content.channels[unitAnalyse.content.main_channel].area_ratio)
        : '',
    isPeak_istdGroup: unitAnalyse.content.molecule.istd_name,
    istd_state: unitAnalyse.content.channels?.[unitAnalyse?.content?.main_channel]?.istd_state
      ? unitAnalyse.content.channels[unitAnalyse.content.main_channel].istd_state
      : '',
    istd_dev_area: unitAnalyse.content.channels?.[unitAnalyse?.content?.main_channel]?.istd_dev_area
      ? parseFloat(unitAnalyse.content.channels[unitAnalyse.content.main_channel].istd_dev_area)
      : '',
    s_n:
      peak && peak?.snrs?.methods?.[peak.snrs?.default]
        ? parseFloat(peak?.snrs?.methods?.[peak.snrs?.default].value)
        : '',
    tailing_factor: peak?.hasOwnProperty('tailing_factor') ? parseFloat(peak.tailing_factor) : '',
    asymetry_factor: peak?.hasOwnProperty('asymetry_factor')
      ? parseFloat(peak.asymetry_factor)
      : '',
    sampleType: unitAnalyse.content.sample_type,
    conc_target: unitAnalyse.content.gt_concentration
      ? parseFloat(unitAnalyse.content.gt_concentration)
      : null,
    level: unitAnalyse.content.level ? unitAnalyse.content.level : null,
    height: peak?.hasOwnProperty('ampl') ? parseFloat(peak.ampl) : '',
    type: unitAnalyse.content.istd && unitAnalyse.content.istd === 'True' ? 'ISTD' : 'Target',
    m_z: unitAnalyse.content.main_channel,
    refIons: getNextChannel(unitAnalyse.content.main_channel, unitAnalyse.content.channels)
      ? unitAnalyse.content.molecule.channels[
          getNextChannel(unitAnalyse.content.main_channel, unitAnalyse.content.channels)
        ].q1 +
        ' > ' +
        unitAnalyse.content.molecule.channels[
          getNextChannel(unitAnalyse.content.main_channel, unitAnalyse.content.channels)
        ].q3
      : '',
    devRt: unitAnalyse?.content?.channels?.[unitAnalyse?.content?.main_channel]?.dev_rt
      ? parseFloat(unitAnalyse.content.channels[unitAnalyse.content.main_channel].dev_rt)
      : '',
    devRrt:
      unitAnalyse.content.main_channel &&
      unitAnalyse.content.channels[unitAnalyse.content.main_channel].dev_rrt_calibration
        ? parseFloat(
            unitAnalyse.content.channels[unitAnalyse.content.main_channel].dev_rrt_calibration,
          )
        : '',
    devAccuracy: unitAnalyse.content.channels[unitAnalyse.content.main_channel.toString()]
      .dev_accuracy
      ? parseFloat(
          unitAnalyse.content.channels[unitAnalyse.content.main_channel.toString()].dev_accuracy,
        )
      : '',
    status: unitAnalyse.content.classification,
    validation: unitAnalyse.content.validation,
    main_channel_data: {
      channelNumber: unitAnalyse.content.main_channel,
      data: unitAnalyse.content.time.map((v, i) => ({
        x: v,
        y: unitAnalyse.content.channels[unitAnalyse.content.main_channel].ampl[i],
      })),
      q1: unitAnalyse.content.molecule.channels[unitAnalyse.content.main_channel]
        ? unitAnalyse.content.molecule.channels[unitAnalyse.content.main_channel].q1
        : null,
      q3: unitAnalyse.content.molecule.channels[unitAnalyse.content.main_channel]
        ? unitAnalyse.content.molecule.channels[unitAnalyse.content.main_channel].q3
        : null,
      ampl_max: unitAnalyse.content.channels[unitAnalyse.content.main_channel].ampl_max,
    },
    name: unitAnalyse.name,
    main_peak_base: peak ? peak.base : [],
    main_peak_base_left_init: unitAnalyse.content?.channels[unitAnalyse.content?.main_channel]
      ?.candidates?.[unitAnalyse.content?.initial_selected_policy]?.peak?.base?.left
      ? unitAnalyse.content?.channels[unitAnalyse.content?.main_channel]?.candidates?.[
          unitAnalyse.content?.initial_selected_policy
        ]?.peak?.base?.left
      : [],
    main_peak_base_right_init: unitAnalyse.content?.channels[unitAnalyse.content?.main_channel]
      ?.candidates?.[unitAnalyse.content?.initial_selected_policy]?.peak?.base?.right
      ? unitAnalyse.content?.channels[unitAnalyse.content?.main_channel]?.candidates?.[
          unitAnalyse.content?.initial_selected_policy
        ]?.peak?.base?.right
      : [],
    isPeakModified: Object.values(unitAnalyse.content?.channels || {}).some(
      (channel) => channel?.selected_policy !== channel?.initial_selected_policy,
    ),
    channels_data: Object.keys(unitAnalyse.content.channels)
      .filter((key) => parseInt(key) !== unitAnalyse.content.main_channel)
      .map(function (key) {
        let channel = unitAnalyse.content.channels[key]
        return {
          channelNumber: parseInt(key),
          data: unitAnalyse.content.time.map((v, i) => ({
            x: v,
            y: channel.ampl[i],
          })),
          q1: unitAnalyse.content.molecule.channels[key]
            ? unitAnalyse.content.molecule.channels[key].q1
            : null,
          q3: unitAnalyse.content.molecule.channels[key]
            ? unitAnalyse.content.molecule.channels[key].q3
            : null,
          peak: channel?.candidates?.[unitAnalyse.content?.selected_policy]?.peak,
        }
      }),
    rt_peak: peak?.hasOwnProperty('time') ? parseFloat(peak.time) : '',
    _id: unitAnalyse._id,
    batch_name: unitAnalyse.content.batch.name,
    batchId: unitAnalyse.content.batch.id,
    global_classification: unitAnalyse.content.classification,
    requested: unitAnalyse.content.requested ? 'True' : 'False',
    modification_date: new Date(unitAnalyse.modificationDate * 1000).toLocaleString(),
    flags: {
      globals: unitAnalyse.content.flags.sort(function (a, b) {
        if (a.title < b.title) return -1
        if (a.title > b.title) return 1
        return 0
      }),
      channels: channelsFlags,
    },
    classification: {
      global: unitAnalyse.content.classification,
      channels: channelsClassification,
    },
    analyseType: unitAnalyse.content.analysis_type,
    mainChannel: unitAnalyse.content.main_channel,
    selectedChannel: unitAnalyse.content.main_channel,
    channelDate: new Date().toString(),
    channels: unitAnalyse.content.channels,
    global_score: unitAnalyse.content.global_score,
    // modified: getModifications(unitAnalyse),
    modified: unitAnalyse.creationDate !== unitAnalyse.modificationDate,
    notes: unitAnalyse.content.notes ? unitAnalyse.content.notes : [],
    loq:
      unitAnalyse.content.parameters && unitAnalyse.content.parameters['loq']
        ? parseFloat(unitAnalyse.content.parameters['loq'])
        : '',
    unlocked:
      peak?.base?.hasOwnProperty('left') &&
      peak?.base?.hasOwnProperty('right') &&
      (peak.base.left.y < 0 || peak.base.right.y < 0),
    lmr: unitAnalyse.content.molecule && unitAnalyse.content.molecule.eu_lmr,
    score_details: unitAnalyse.content.score_details,
    ret_time: unitAnalyse.content.molecule.ret_time,
    istdEvent: unitAnalyse.content.molecule.istd,
    istdName: unitAnalyse.content.molecule.istd_name,
    peak: peak?.priority,
  }
}

export function rebuildUnitaryCompounds(unitAnalyse) {
  let channelsFlags = {}
  Object.entries(unitAnalyse.content.channels).forEach(function (entry) {
    if (entry[1].flags.length !== 0)
      channelsFlags[entry[0]] = entry[1].flags.sort(function (a, b) {
        if (a.title < b.title) return -1
        if (a.title > b.title) return 1
        return 0
      })
  })
  let channelsClassification = {}
  Object.entries(unitAnalyse.content.channels).forEach(function (entry) {
    channelsClassification[entry[0]] = entry[1].classification
  })
  const amt_conc_channels = {}
  for (
    let step = 1;
    step <=
    Number(localStorage.getItem('__config.json__NUMBER_CHANNEL_VISIBLE_FOR_VOLUMIC_CONCENTRATION'));
    step++
  ) {
    if (
      unitAnalyse.content.channels &&
      unitAnalyse.content.channels[step] &&
      unitAnalyse.content.channels[step].concentration &&
      unitAnalyse.content.channels[step].concentration !== '' &&
      unitAnalyse.content.channels[step].concentration !== null
    ) {
      amt_conc_channels['amt_conc_' + step] = unitAnalyse.content.channels[step].concentration
    }
  }
  const mass_conc_channels = {}
  for (
    let step = 1;
    step <=
    Number(localStorage.getItem('__config.json__NUMBER_CHANNEL_VISIBLE_FOR_VOLUMIC_CONCENTRATION'));
    step++
  ) {
    const isValidConcentration = unitAnalyse.content?.channels?.[step]?.concentration_mass
    if (
      isValidConcentration !== '' &&
      isValidConcentration !== null &&
      isValidConcentration !== undefined
    ) {
      mass_conc_channels['mass_conc_' + step] = parseFloat(isValidConcentration)
    }
  }
  const peak =
    unitAnalyse.content?.channels[unitAnalyse.content?.main_channel]?.candidates?.[
      unitAnalyse.content?.selected_policy
    ]?.peak
  return {
    selected_policy: unitAnalyse.content?.selected_policy,
    recovery_factor: unitAnalyse.content.recovery_factor,
    extraFlags: extractExtraFlagInfo(unitAnalyse.content.flags),
    recovery: unitAnalyse.content.channels?.[unitAnalyse.content?.main_channel]?.recovery,
    ...amt_conc_channels,
    '#': unitAnalyse.content.order,
    dev_ion_ratio: unitAnalyse.content.main_dev_ion_ratio,
    ion_ratio:
      unitAnalyse.content.ion_ratio && unitAnalyse.content.main_ion_ratio_key
        ? unitAnalyse.content.ion_ratio[unitAnalyse.content.main_ion_ratio_key]
        : null,
    order: unitAnalyse.content.order,
    event: unitAnalyse.content.molecule.event,
    analyse: unitAnalyse.content.analysis.name,
    analyseId: unitAnalyse.content.analysis.id,
    rt: unitAnalyse.content.channels[unitAnalyse.content.main_channel].rt_calib
      ? parseFloat(unitAnalyse.content.channels[unitAnalyse.content.main_channel].rt_calib)
      : '',
    rtCorrected: unitAnalyse.content.channels[unitAnalyse.content.main_channel].rt_corrected
      ? parseFloat(unitAnalyse.content.channels[unitAnalyse.content.main_channel].rt_corrected)
      : '',
    peakName: unitAnalyse.content.molecule.name,
    mass_conc: unitAnalyse.content.channels[unitAnalyse.content.main_channel].concentration_mass
      ? parseFloat(
          unitAnalyse.content.channels[unitAnalyse.content.main_channel].concentration_mass,
        )
      : '',
    ...mass_conc_channels,
    mass_conc_rdt: unitAnalyse.content.channels[unitAnalyse.content.main_channel]
      .concentration_mass_rdt
      ? parseFloat(
          unitAnalyse.content.channels[unitAnalyse.content.main_channel].concentration_mass_rdt,
        )
      : '',
    rdt: unitAnalyse.content.channels[unitAnalyse.content.main_channel].rdt
      ? parseFloat(unitAnalyse.content.channels[unitAnalyse.content.main_channel].rdt)
      : '',
    amt_conc: unitAnalyse.content.channels[unitAnalyse.content.main_channel].concentration
      ? parseFloat(unitAnalyse.content.channels[unitAnalyse.content.main_channel].concentration)
      : '',
    area: peak?.hasOwnProperty('area') ? parseFloat(peak.area) : '',
    // r2: '',
    isArea:
      unitAnalyse.content.channels[unitAnalyse.content.main_channel] &&
      unitAnalyse.content.channels[unitAnalyse.content.main_channel].istd_area
        ? parseFloat(unitAnalyse.content.channels[unitAnalyse.content.main_channel].istd_area)
        : '',
    areaRatio:
      unitAnalyse.content.channels[unitAnalyse.content.main_channel] &&
      unitAnalyse.content.channels[unitAnalyse.content.main_channel].area_ratio
        ? parseFloat(unitAnalyse.content.channels[unitAnalyse.content.main_channel].area_ratio)
        : '',
    isPeak_istdGroup: unitAnalyse.content.molecule.istd_name,
    istd_state: unitAnalyse.content.channels?.[unitAnalyse?.content?.main_channel]?.istd_state
      ? unitAnalyse.content.channels[unitAnalyse.content.main_channel].istd_state
      : '',
    istd_dev_area: unitAnalyse.content.channels?.[unitAnalyse?.content?.main_channel]?.istd_dev_area
      ? parseFloat(unitAnalyse.content.channels[unitAnalyse.content.main_channel].istd_dev_area)
      : '',
    s_n:
      peak && peak?.snrs?.methods?.[peak.snrs?.default]
        ? parseFloat(peak?.snrs?.methods?.[peak.snrs?.default].value)
        : '',
    tailing_factor: peak?.hasOwnProperty('tailing_factor') ? parseFloat(peak.tailing_factor) : '',
    asymetry_factor: peak?.hasOwnProperty('asymetry_factor')
      ? parseFloat(peak.asymetry_factor)
      : '',
    sampleType: unitAnalyse.content.sample_type,
    conc_target: unitAnalyse.content.gt_concentration
      ? parseFloat(unitAnalyse.content.gt_concentration)
      : null,
    level: unitAnalyse.content.level ? unitAnalyse.content.level : null,
    height: peak?.hasOwnProperty('ampl') ? parseFloat(peak.ampl) : '',
    // type: unitAnalyse.content.molecule.istd_name,
    type: unitAnalyse.content.istd && unitAnalyse.content.istd === 'True' ? 'ISTD' : 'Target',
    m_z: unitAnalyse.content.main_channel,
    refIons: getNextChannel(unitAnalyse.content.main_channel, unitAnalyse.content.channels)
      ? unitAnalyse.content.molecule.channels[
          getNextChannel(unitAnalyse.content.main_channel, unitAnalyse.content.channels)
        ].q1 +
        ' > ' +
        unitAnalyse.content.molecule.channels[
          getNextChannel(unitAnalyse.content.main_channel, unitAnalyse.content.channels)
        ].q3
      : '',
    devRt: unitAnalyse?.content?.channels?.[unitAnalyse?.content?.main_channel]?.dev_rt
      ? parseFloat(unitAnalyse.content.channels[unitAnalyse.content.main_channel].dev_rt)
      : '',
    devRrt:
      unitAnalyse.content.main_channel &&
      unitAnalyse.content.channels[unitAnalyse.content.main_channel].dev_rrt_calibration
        ? parseFloat(
            unitAnalyse.content.channels[unitAnalyse.content.main_channel].dev_rrt_calibration,
          )
        : '',
    devAccuracy: unitAnalyse.content.channels[unitAnalyse.content.main_channel.toString()]
      .dev_accuracy
      ? parseFloat(
          unitAnalyse.content.channels[unitAnalyse.content.main_channel.toString()].dev_accuracy,
        )
      : '',
    status: unitAnalyse.content.classification,
    validation: unitAnalyse.content.validation,
    // unitaryAnalyse: unitAnalyse,
    main_channel_data: {
      channelNumber: unitAnalyse.content.main_channel,
      area: peak?.hasOwnProperty('area') ? parseFloat(peak.area) : '',
      concentration: unitAnalyse.content.channels[unitAnalyse.content.main_channel].concentration
        ? parseFloat(unitAnalyse.content.channels[unitAnalyse.content.main_channel].concentration)
        : '',
      data: unitAnalyse.content.time.map((v, i) => ({
        x: v,
        y: unitAnalyse.content.channels[unitAnalyse.content.main_channel].ampl[i],
      })),
      q1: unitAnalyse.content.molecule.channels[unitAnalyse.content.main_channel]
        ? unitAnalyse.content.molecule.channels[unitAnalyse.content.main_channel].q1
        : null,
      q3: unitAnalyse.content.molecule.channels[unitAnalyse.content.main_channel]
        ? unitAnalyse.content.molecule.channels[unitAnalyse.content.main_channel].q3
        : null,
      ampl_max: unitAnalyse.content.channels[unitAnalyse.content.main_channel].ampl_max,
    },
    name: unitAnalyse.name,
    main_peak_base: peak ? peak.base : [],
    main_peak_base_left_init: unitAnalyse.content.channels[unitAnalyse.content.main_channel]
      .base_left_init
      ? unitAnalyse.content.channels[unitAnalyse.content.main_channel].base_left_init
      : [],
    main_peak_base_right_init: unitAnalyse.content.channels[unitAnalyse.content.main_channel]
      .base_right_init
      ? unitAnalyse.content.channels[unitAnalyse.content.main_channel].base_right_init
      : [],
    channels_data: Object.keys(unitAnalyse.content.channels)
      .filter((key) => parseInt(key) !== unitAnalyse.content.main_channel)
      .map(function (key) {
        let channel = unitAnalyse.content.channels[key]
        return {
          channelNumber: parseInt(key),
          concentration: channel.concentration,
          area: channel?.candidates?.[unitAnalyse.content?.selected_policy]?.peak?.hasOwnProperty(
            'area',
          )
            ? channel.candidates[unitAnalyse.content.selected_policy].peak.area
            : '',
          data: unitAnalyse.content.time.map((v, i) => ({
            x: v,
            y: channel.ampl[i],
          })),
          q1: unitAnalyse.content.molecule.channels[key]
            ? unitAnalyse.content.molecule.channels[key].q1
            : null,
          q3: unitAnalyse.content.molecule.channels[key]
            ? unitAnalyse.content.molecule.channels[key].q3
            : null,
          peak: channel?.candidates?.[unitAnalyse.content?.selected_policy]?.peak,
        }
      }),
    rt_peak: peak?.hasOwnProperty('time') ? parseFloat(peak.time) : '',
    _id: unitAnalyse._id,
    batch_name: unitAnalyse.content.batch.name,
    batchId: unitAnalyse.content.batch.id,
    global_classification: unitAnalyse.content.classification,
    requested: unitAnalyse.content.requested ? 'True' : 'False',
    modification_date: new Date(unitAnalyse.modificationDate * 1000).toLocaleString(),
    flags: {
      globals: unitAnalyse.content.flags.sort(function (a, b) {
        if (a.title < b.title) return -1
        if (a.title > b.title) return 1
        return 0
      }),
      channels: channelsFlags,
    },
    classification: {
      global: unitAnalyse.content.classification,
      channels: channelsClassification,
    },
    analyseType: unitAnalyse.content.analysis_type,
    mainChannel: unitAnalyse.content.main_channel,
    selectedChannel: unitAnalyse.content.main_channel,
    channels: unitAnalyse.content.channels,
    global_score: unitAnalyse.content.global_score,
    // modified: getModifications(unitAnalyse),
    modified: unitAnalyse.creationDate !== unitAnalyse.modificationDate,
    notes: unitAnalyse.content.notes ? unitAnalyse.content.notes : [],
    suffix: unitAnalyse.content.suffix,
    loq:
      unitAnalyse.content.parameters && unitAnalyse.content.parameters['loq']
        ? parseFloat(unitAnalyse.content.parameters['loq'])
        : '',
    unlocked:
      peak?.base?.hasOwnProperty('left') &&
      peak?.base?.hasOwnProperty('right') &&
      (peak.base.left.y < 0 || peak.base.right.y < 0),
    lmr: unitAnalyse.content.molecule && unitAnalyse.content.molecule.eu_lmr,
    parameters: unitAnalyse.content.parameters,
    score_details: unitAnalyse.content.score_details,
    ret_time: unitAnalyse.content.molecule.ret_time,
    istdEvent: unitAnalyse.content.molecule.istd,
    istdName: unitAnalyse.content.molecule.istd_name,
    peak: peak?.priority,
  }
}

export function rebuildUnitaryCalibrations(unitCalibration) {
  let channelsFlags = {}
  Object.entries(unitCalibration.content.channels).forEach(function (entry) {
    if (entry[1].flags.length !== 0)
      channelsFlags[entry[0]] = entry[1].flags.sort(function (a, b) {
        if (a.title < b.title) return -1
        if (a.title > b.title) return 1
        return 0
      })
  })
  let channelsClassification = {}
  Object.entries(unitCalibration.content.channels).forEach(function (entry) {
    channelsClassification[entry[0]] = entry[1].classification
  })
  let _included =
    unitCalibration.content.channels[unitCalibration.content.main_channel] &&
    unitCalibration.content.channels[unitCalibration.content.main_channel].included
      ? unitCalibration.content.channels[unitCalibration.content.main_channel].included
      : {}
  return {
    extraFlags: extractExtraFlagInfo(unitCalibration.content.flags),
    _id: unitCalibration._id,
    name: unitCalibration.name,
    event: unitCalibration.content.molecule.event,
    r2:
      unitCalibration.content.channels[unitCalibration.content.main_channel] &&
      unitCalibration.content.channels[unitCalibration.content.main_channel]
        .coefficient_of_determination
        ? parseFloat(
            unitCalibration.content.channels[
              unitCalibration.content.main_channel
            ].coefficient_of_determination.toFixed(6),
          )
        : '',
    rt:
      unitCalibration.content.channels[unitCalibration.content.main_channel] &&
      unitCalibration.content.channels[unitCalibration.content.main_channel].rt
        ? parseFloat(
            unitCalibration.content.channels[unitCalibration.content.main_channel].rt.toFixed(2),
          )
        : '',
    istd: unitCalibration.content.molecule.istd_name,
    type:
      unitCalibration.content.istd && unitCalibration.content.istd === 'True' ? 'ISTD' : 'Target',
    state: unitCalibration.content.classification,
    validation: unitCalibration.content.validation,
    flags: {
      globals: unitCalibration.content.flags.sort(function (a, b) {
        if (a.title < b.title) return -1
        if (a.title > b.title) return 1
        return 0
      }),
      channels: channelsFlags,
    },
    classification: {
      global: unitCalibration.content.classification,
      channels: channelsClassification,
    },
    analyseType: 'calibration',
    mainChannel: unitCalibration.content.main_channel,
    batchId: unitCalibration.content.batch.id,
    batchName: unitCalibration.content.batch.name,
    istd_group: unitCalibration.content.molecule.istd,
    initial_included:
      unitCalibration.content.channels[unitCalibration.content.main_channel] &&
      unitCalibration.content.channels[unitCalibration.content.main_channel].initial_included
        ? unitCalibration.content.channels[unitCalibration.content.main_channel].initial_included
        : _included,
    initial_interpolation_type:
      unitCalibration.content.parameters && unitCalibration.content.parameters.interpolation_type
        ? unitCalibration.content.parameters.interpolation_type
        : '',
    initial_interpolation_weights_policy:
      unitCalibration.content.parameters &&
      unitCalibration.content.parameters.interpolation_weights_policy
        ? unitCalibration.content.parameters.interpolation_weights_policy
        : '',
    initial_interpolation_zero_policy:
      unitCalibration.content.parameters &&
      unitCalibration.content.parameters.interpolation_zero_policy
        ? unitCalibration.content.parameters.interpolation_zero_policy
        : '',
    modified: unitCalibration.creationDate !== unitCalibration.modificationDate,
    notes: unitCalibration.content.notes ? unitCalibration.content.notes : [],
    ret_time: unitCalibration.content.molecule.ret_time
      ? unitCalibration.content.molecule.ret_time
      : '',
    q1: unitCalibration.content.molecule.channels[unitCalibration.content.main_channel].q1
      ? unitCalibration.content.molecule.channels[unitCalibration.content.main_channel].q1
      : '',
    q3: unitCalibration.content.molecule.channels[unitCalibration.content.main_channel].q3
      ? unitCalibration.content.molecule.channels[unitCalibration.content.main_channel].q3
      : '',
    // curve_fit: unitCalibration.content?.parameters?.interpolation_type,
    // curve_weighting: unitCalibration.content?.parameters?.interpolation_weights_policy,
    // curve_zero: unitCalibration.content?.parameters?.interpolation_zero_policy,
    curve_fit:
      unitCalibration.content.channels[unitCalibration.content.main_channel]?.interpolation?.type,
    curve_weighting:
      unitCalibration.content.channels[unitCalibration.content.main_channel]?.interpolation
        ?.weights_policy,
    curve_zero:
      unitCalibration.content.channels[unitCalibration.content.main_channel]?.interpolation
        ?.zero_policy,
    std_dev:
      unitCalibration.content.channels[unitCalibration.content.main_channel] &&
      unitCalibration.content.channels[unitCalibration.content.main_channel].standard_deviation
        ? parseFloat(
            unitCalibration.content.channels[
              unitCalibration.content.main_channel
            ].standard_deviation.toFixed(6),
          )
        : '',
    nb_points: Object.values(
      unitCalibration.content.channels[unitCalibration.content.main_channel]?.included,
    )?.filter(Boolean)?.length,
  }
}

export function rebuildUnitaryAnalysesLims_OLD(unitAnalyse, analyse) {
  let channelsFlags = {}
  Object.entries(unitAnalyse.content.channels).forEach(function (entry) {
    if (entry[1].flags.length !== 0)
      channelsFlags[entry[0]] = entry[1].flags.sort(function (a, b) {
        if (a.title < b.title) return -1
        if (a.title > b.title) return 1
        return 0
      })
  })
  let channelsClassification = {}
  Object.entries(unitAnalyse.content.channels).forEach(function (entry) {
    channelsClassification[entry[0]] = entry[1].classification
  })
  return {
    extraFlags: extractExtraFlagInfo(unitAnalyse.content.flags),
    analyse: unitAnalyse.content.analysis.name,
    analyseId: unitAnalyse.content.analysis.id,
    molecule: unitAnalyse.content.molecule,
    event: unitAnalyse.content.molecule.event,
    reported: unitAnalyse.content.reported ? 'True' : 'False',
    reported_msg: unitAnalyse.content.reported_msg ? unitAnalyse.content.reported_msg : '',
    mass_conc: unitAnalyse.content.channels[unitAnalyse.content.main_channel].concentration_mass
      ? parseFloat(
          unitAnalyse.content.channels[unitAnalyse.content.main_channel].concentration_mass,
        )
      : '',
    mass_conc_rdt: unitAnalyse.content.channels[unitAnalyse.content.main_channel]
      .concentration_mass_rdt
      ? parseFloat(
          unitAnalyse.content.channels[unitAnalyse.content.main_channel].concentration_mass_rdt,
        )
      : '',
    rdt: unitAnalyse.content.channels[unitAnalyse.content.main_channel].rdt
      ? parseFloat(unitAnalyse.content.channels[unitAnalyse.content.main_channel].rdt)
      : '',
    amt_conc: unitAnalyse.content.channels[unitAnalyse.content.main_channel].concentration
      ? parseFloat(unitAnalyse.content.channels[unitAnalyse.content.main_channel].concentration)
      : '',
    chromatography_type: analyse && analyse.content.chromatography_type,
    weighing: analyse && analyse.content.sample_infos ? analyse.content.pesee : '',
    v_ext: analyse && analyse.content.sample_infos ? analyse.content.formula_infos.vol_sol_ext : '',
    dilution:
      analyse && analyse.content.sample_infos ? analyse.content.formula_infos.facteur_dilution : '',
    sm_concentration:
      analyse && analyse.content.sample_infos ? analyse.content.sm_concentration : '',
    status: unitAnalyse.content.classification,
    validation: unitAnalyse.content.validation,
    name: unitAnalyse.name,
    _id: unitAnalyse._id,
    batch_name: unitAnalyse.content.batch.name,
    requested: unitAnalyse.content.requested ? 'True' : 'False',
    modification_date: new Date(unitAnalyse.modificationDate * 1000).toLocaleString(),
    analyseType: unitAnalyse.content.analysis_type,
    flags: {
      globals: unitAnalyse.content.flags.sort(function (a, b) {
        if (a.title < b.title) return -1
        if (a.title > b.title) return 1
        return 0
      }),
      channels: channelsFlags,
    },
    mainChannel: unitAnalyse.content.main_channel,
    global_classification: unitAnalyse.content.classification,
    main_peak_base:
      unitAnalyse.content.channels[unitAnalyse.content.main_channel].peak &&
      unitAnalyse.content.channels[unitAnalyse.content.main_channel].peak.base
        ? unitAnalyse.content.channels[unitAnalyse.content.main_channel].peak.base
        : [],
    main_peak_base_left_init: unitAnalyse.content.channels[unitAnalyse.content.main_channel]
      .base_left_init
      ? unitAnalyse.content.channels[unitAnalyse.content.main_channel].base_left_init
      : [],
    main_peak_base_right_init: unitAnalyse.content.channels[unitAnalyse.content.main_channel]
      .base_right_init
      ? unitAnalyse.content.channels[unitAnalyse.content.main_channel].base_right_init
      : [],
    main_channel_data: {
      channelNumber: unitAnalyse.content.main_channel,
      data: unitAnalyse.content.time.map((v, i) => ({
        x: v,
        y: unitAnalyse.content.channels[unitAnalyse.content.main_channel].ampl[i],
      })),
      q1: unitAnalyse.content.molecule.channels[unitAnalyse.content.main_channel]
        ? unitAnalyse.content.molecule.channels[unitAnalyse.content.main_channel].q1
        : null,
      q3: unitAnalyse.content.molecule.channels[unitAnalyse.content.main_channel]
        ? unitAnalyse.content.molecule.channels[unitAnalyse.content.main_channel].q3
        : null,
      ampl_max: unitAnalyse.content.channels[unitAnalyse.content.main_channel].ampl_max,
    },
    channels_data: Object.keys(unitAnalyse.content.channels)
      .filter((key) => parseInt(key) !== unitAnalyse.content.main_channel)
      .map(function (key) {
        let channel = unitAnalyse.content.channels[key]
        return {
          channelNumber: parseInt(key),
          data: unitAnalyse.content.time.map((v, i) => ({
            x: v,
            y: channel.ampl[i],
          })),
          q1: unitAnalyse.content.molecule.channels[key]
            ? unitAnalyse.content.molecule.channels[key].q1
            : null,
          q3: unitAnalyse.content.molecule.channels[key]
            ? unitAnalyse.content.molecule.channels[key].q3
            : null,
        }
      }),
    rt_peak:
      unitAnalyse.content.channels[unitAnalyse.content.main_channel].peak &&
      unitAnalyse.content.channels[unitAnalyse.content.main_channel].peak.time
        ? parseFloat(unitAnalyse.content.channels[unitAnalyse.content.main_channel].peak.time)
        : '',
    classification: {
      global: unitAnalyse.content.classification,
      channels: channelsClassification,
    },
    modified: unitAnalyse.creationDate !== unitAnalyse.modificationDate,
    notes: unitAnalyse.content.notes ? unitAnalyse.content.notes : [],
    loq:
      unitAnalyse.content.parameters && unitAnalyse.content.parameters['loq']
        ? parseFloat(unitAnalyse.content.parameters['loq'])
        : '',
    lod:
      unitAnalyse.content.parameters && unitAnalyse.content.parameters['lod']
        ? parseFloat(unitAnalyse.content.parameters['lod'])
        : '',
    use_loq_default:
      unitAnalyse.content.parameters && unitAnalyse.content.parameters['use_loq_default']
        ? unitAnalyse.content.parameters['use_loq_default']
        : '',
    reported_conc: unitAnalyse.content.reported_conc
      ? parseFloat(unitAnalyse.content.reported_conc)
      : undefined,
    factor: unitAnalyse.content.factor ? parseFloat(unitAnalyse.content.factor) : undefined,
  }
}

export function rebuildUnitaryAnalysesLims(unitAnalyse) {
  if (unitAnalyse.content.channels) {
    let channelsFlags = {}
    Object.entries(unitAnalyse.content.channels).forEach(function (entry) {
      if (entry[1].flags.length !== 0)
        channelsFlags[entry[0]] = entry[1].flags.sort(function (a, b) {
          if (a.title < b.title) return -1
          if (a.title > b.title) return 1
          return 0
        })
    })
    let channelsClassification = {}
    Object.entries(unitAnalyse.content.channels).forEach(function (entry) {
      channelsClassification[entry[0]] = entry[1].classification
    })
    return {
      extraFlags: extractExtraFlagInfo(unitAnalyse.content.flags),
      homologation: unitAnalyse?.content?.ua_infos?.homologation,
      batchId: unitAnalyse.content.batch.id,
      batch_name: unitAnalyse.content.batch.name,
      event: unitAnalyse.content.event,
      analyse: unitAnalyse.content.analysis.name,
      analyseId: unitAnalyse.content.analysis.id,
      molecule: unitAnalyse.content.molecule,
      reported: unitAnalyse.content.reported ? 'True' : 'False',
      reported_msg: unitAnalyse.content.reported_msg ? unitAnalyse.content.reported_msg : '',
      mass_conc: unitAnalyse.content.channels[unitAnalyse.content.main_channel].concentration_mass
        ? parseFloat(
            unitAnalyse.content.channels[unitAnalyse.content.main_channel].concentration_mass,
          )
        : '',
      mass_conc_rdt: unitAnalyse.content.channels[unitAnalyse.content.main_channel]
        .concentration_mass_rdt
        ? parseFloat(
            unitAnalyse.content.channels[unitAnalyse.content.main_channel].concentration_mass_rdt,
          )
        : '',
      rdt: unitAnalyse.content.channels[unitAnalyse.content.main_channel].rdt
        ? parseFloat(unitAnalyse.content.channels[unitAnalyse.content.main_channel].rdt)
        : '',
      amt_conc: unitAnalyse.content.channels[unitAnalyse.content.main_channel].concentration
        ? parseFloat(unitAnalyse.content.channels[unitAnalyse.content.main_channel].concentration)
        : '',
      status: unitAnalyse.content.classification,
      validation: unitAnalyse.content.validation,
      _id: unitAnalyse._id,
      flags: {
        globals: unitAnalyse.content.flags.sort(function (a, b) {
          if (a.title < b.title) return -1
          if (a.title > b.title) return 1
          return 0
        }),
        channels: channelsFlags,
      },
      mainChannel: unitAnalyse.content.main_channel,
      classification: {
        global: unitAnalyse.content.classification,
        channels: channelsClassification,
      },
      loq:
        unitAnalyse.content.parameters && unitAnalyse.content.parameters['loq']
          ? parseFloat(unitAnalyse.content.parameters['loq'])
          : '',
      lod:
        unitAnalyse.content.parameters && unitAnalyse.content.parameters['lod']
          ? parseFloat(unitAnalyse.content.parameters['lod'])
          : '',
      use_loq_default:
        unitAnalyse.content.parameters && unitAnalyse.content.parameters['use_loq_default']
          ? unitAnalyse.content.parameters['use_loq_default']
          : '',
      reported_conc: unitAnalyse.content.reported_conc
        ? parseFloat(unitAnalyse.content.reported_conc)
        : undefined,
      factor: unitAnalyse.content.factor ? parseFloat(unitAnalyse.content.factor) : undefined,
      moc: unitAnalyse.content.moc,
    }
  } else {
    // UA generated only to displayed for the Analysis with only UA as non-detected
    // Only need the analysis id, name and validation != 1 to be take into account and invisible
    return {
      analyse: unitAnalyse.content.analysis.name,
      analyseId: unitAnalyse.content.analysis.id,
      validation: unitAnalyse.content.validation ? unitAnalyse.content.validation : null,
      molecule: {},
      reported: 'False',
      reported_msg: '',
      mass_conc: 0,
      mass_conc_rdt: 0,
      rdt: 0,
      amt_conc: 0,
      status: '',
      _id: unitAnalyse._id,
      flags: {
        globals: [],
        channels: [],
      },
      mainChannel: 1,
      classification: {
        global: '',
        channels: [],
      },
      loq: '',
      lod: '',
      use_loq_default: '',
      reported_conc: undefined,
      factor: undefined,
      moc: unitAnalyse.content.moc ? unitAnalyse.content.moc : null,
    }
  }
}
export const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

export const getAnalyseDemandeesTooltip = (message) => {
  const lines = message ? message.replace(/\* /g, '').split('\n') : []

  const content = lines.map((line) => {
    let columns = line.split('|')
    return (
      <table key={generateUUID()} style={{ overflowWrap: 'anywhere' }}>
        <tbody>
          <tr>
            <td>{columns[0]} </td>
          </tr>
          <tr style={{ fontSize: 12 }}>
            <td>
              <i>
                {columns[1]} {' , '} {columns[2]}
              </i>
            </td>
          </tr>
          <tr style={{ fontSize: 12 }}></tr>
        </tbody>
      </table>
    )
  })
  return (
    <React.Fragment>
      <div style={{ fontSize: 16, margin: 5, marginTop: 10, marginBottom: 10 }}>Package</div>
      {content}
    </React.Fragment>
  )
}

export const getModifications = (ua) => {
  let modification = []
  // Is peaks updated ?
  Object.keys(ua.content.channels).forEach((channel) => {
    const main_peak_base_left_init = ua.content.channels[channel].base_left_init
      ? ua.content.channels[channel].base_left_init
      : null
    const main_peak_base_right_init = ua.content.channels[channel].base_right_init
      ? ua.content.channels[channel].base_right_init
      : null
    const peaks = ua.content?.channels[channel]?.candidates?.[
      ua.content?.selected_policy
    ]?.peak?.hasOwnProperty('base')
      ? ua.content.channels[channel].candidates[ua.content.selected_policy].peak.base
      : null
    if (peaks && main_peak_base_left_init && main_peak_base_right_init) {
      if (peaks.left.x !== main_peak_base_left_init.x) {
        modification.push(
          t('view.common.utils.modifications.left_x') +
            ' ' +
            main_peak_base_left_init.x +
            ' -> ' +
            peaks.left.x,
        )
      }
      if (peaks.left.y !== main_peak_base_left_init.y) {
        modification.push(
          t('view.common.utils.modifications.left_y') +
            ' ' +
            main_peak_base_left_init.y +
            ' -> ' +
            peaks.left.y,
        )
      }
      if (peaks.right.x !== main_peak_base_right_init.x) {
        modification.push(
          t('view.common.utils.modifications.right_x') +
            ' ' +
            main_peak_base_right_init.x +
            ' -> ' +
            peaks.right.x,
        )
      }
      if (peaks.right.y !== main_peak_base_right_init.y) {
        modification.push(
          t('view.common.utils.modifications.right_y') +
            ' ' +
            main_peak_base_right_init.y +
            ' -> ' +
            peaks.right.y,
        )
      }
    }
  })
  return modification
}

/**************************** VALIDATION FUNCTION **********************************/
export const getClickAction = (dispatch, event, item, collection, open) => {
  event.stopPropagation()
  event.preventDefault()
  if (event.ctrlKey && event.altKey) {
    let db_collection = collection
    let target = item._id
    if (collection === 'unitary_compound') {
      db_collection = 'unitary_analysis'
    }
    dispatch(fetchDisplayJson(target, db_collection))
  } else if (event.ctrlKey) {
    let url = ''
    if (collection === 'batch') {
      url = `${window.location.origin}${window.location.pathname}?${collection}/${item._id}`
    } else {
      let target = item._id
      let url_collection = collection
      let batch_id = item.batchId
      switch (collection) {
        case 'analysis':
          batch_id = item.content?.batch?.id || item.batchId
          break
        case 'unitary_analysis':
        case 'unitary_calibration':
          target = item.event
          url_collection = 'compound'
          break
        case 'unitary_compound':
          target = item.analyseId
          url_collection = 'analysis'
          break
        default:
          break
      }
      url = `${window.location.origin}${window.location.pathname}?${batch_id}/${url_collection}/${target}`
    }
    openInNewTab(url)
  } else {
    open()
  }
}

/**************************** BATCH LEASE **********************************/
export const testBatchLease = (batch, dispatch, history) => {
  // TODO STEF catch error instead time
  // debugger
  // if (
  //   batch &&
  //   batch.content.lease &&
  //   Math.round(new Date().getTime() / 1000) < batch.content.lease
  // ) {
  //   if (history.location.pathname !== '/') {
  //     displayPopupMessage(
  //       dispatch,
  //       'warning',
  //       'Batch locked',
  //       <Fragment>
  //         <div>The batch {batch.name} cannot be opened at this time.</div>
  //         <div>
  //           An operation is runnning. Please wait until it is released.
  //         </div>
  //       </Fragment>,
  //       5000,
  //     )
  //     // history.push('/')
  //   }
  //   return <Fragment />
  // }
}

/**************************** CONVERT MASS VALUE **********************************/
export const convertMassConcentrationValue = (value, raw, channel, config) => {
  if (config === 'mass') {
    switch (localStorage.getItem('SETTINGS_mass_reported_concentration_unit')) {
      case 'ppb':
      case 'μg/kg':
        return value * 1000
      case 'ppt':
      case 'ng/kg':
        return value * 1000000
      case 'ppm':
      case 'mg/kg':
      default:
        return value
    }
  } else {
    return convertVolumeConcentrationValue(value, raw, channel, true)
  }
}

/**************************** CONVERT VOLUME VALUE **********************************/
export const convertVolumeConcentrationValue = (val, raw, channel, reportedConc) => {
  let value = val
  if (channel) {
    value = raw.channels[channel].concentration
  }
  switch (
    reportedConc
      ? localStorage.getItem('SETTINGS_vol_reported_concentration_unit')
      : localStorage.getItem('SETTINGS_volume_concentration_unit')
  ) {
    case 'mg/l':
      return value * (1 / 1000)
    case 'ng/l':
      return value * 1000
    case 'pg/l':
      return value * 1000000
    case 'n mol/l':
      return (
        value *
        (1000 / (raw.channels_data[0] && raw.channels_data[0].q1 ? raw.channels_data[0].q1 : 1))
      )
    case 'p mol/l':
      return (
        value *
        (1000000 / (raw.channels_data[0] && raw.channels_data[0].q1 ? raw.channels_data[0].q1 : 1))
      )
    case 'μg/l':
    default:
      return value
  }
}

/*********************** DISPLAY HUMAN READABLE VALUE **********************************/
export function displayHumanReadable(num, precision, fixed, percentage) {
  // precision: 0 -> 9
  const _min = [1, 0.1, 0.01, 0.001, 0.0001, 0.00001, 0.000001, 0.000001, 0.0000001, 0.00000001]
  if (Math.abs(num) >= 1000000 && !percentage) {
    return num.toExponential(2)
  } else if (Math.abs(num) <= _min[precision] && !percentage) {
    return num.toExponential(2)
  } else {
    const num_length = parseInt(num) === 0 ? 0 : parseInt(num).toString().length
    let _len = Math.max(0, precision - num_length)
    if (fixed && fixed !== '' && fixed !== null && num_length <= precision) {
      _len = fixed
    }
    // return (num - Math.floor(num)) !== 0 ? num.toLocaleString('en-US', { minimumFractionDigits: _len }) : num.toFixed(0)
    // return (toLocaleString('en-US', { minimumFractionDigits: 4 }))
    return num - Math.floor(num) !== 0 ? num.toFixed(_len) : num.toFixed(0)
  }
}

/************************ CUSTOM RENDER FORMAT & DECIMALS *************************/
export function customFormatDecimals(value, autoValue, columnInfos) {
  let tmpVal = value
  if (columnInfos && tmpVal && typeof tmpVal === 'number') {
    if (columnInfos.format === 'auto') {
      tmpVal = autoValue
    } else if (columnInfos.format === 'sci') {
      if (columnInfos.decimals !== -1) {
        tmpVal = tmpVal.toExponential(columnInfos.decimals)
      } else {
        tmpVal = tmpVal.toExponential()
      }
    } else if (typeof columnInfos.decimals === 'number' && columnInfos.decimals !== -1) {
      tmpVal = tmpVal.toFixed(columnInfos.decimals)
    } else {
      // Default format if no settings was present in localStorage
      tmpVal = autoValue ? autoValue : tmpVal.toFixed(3)
    }
  }
  return tmpVal
}
/************************ NORMALIZATION FILE SIZE *********************************/
export function humanFileSize(bytes, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B'
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
  let u = -1
  const r = 10 ** dp

  do {
    bytes /= thresh
    ++u
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1)

  return bytes.toFixed(dp) + ' ' + units[u]
}

/************************ FORMULAS DICTIONARY *********************************/
export const formulas_dico = {
  'F cal': {
    origin: 'formulas',
    correspondence: 'facteur_calcul',
  },
  PE: { origin: 'content', correspondence: 'pesee' },
  'F dil': {
    origin: 'formulas',
    correspondence: 'facteur_dilution',
  },
  Sm: { origin: 'content', correspondence: 'sm_concentration' },
  'V sol ext': {
    readOnly: true,
    origin: 'formulas',
    correspondence: 'vol_sol_ext',
  },
  'V élu': {
    readOnly: true,
    origin: 'formulas',
    correspondence: 'vol_elu',
  },
  'V ext dil': {
    readOnly: true,
    origin: 'formulas',
    correspondence: 'vol_ext_dilue',
  },
  'V col': {
    readOnly: true,
    origin: 'formulas',
    correspondence: 'vol_col',
  },
  'V tot': {
    readOnly: true,
    origin: 'formulas',
    correspondence: 'vol_total',
  },
  'F cor': {
    origin: 'formulas',
    correspondence: 'facteur_correction',
  },
  unite: {
    origin: 'formulas',
    correspondence: 'unite',
  },
}

export function saveAnalyseValue(dispatch, batch, newValue, analyse, paramValue, formula) {
  const paramUpper = paramValue.charAt(0).toUpperCase() + paramValue.slice(1)
  const title = (
    <Trans i18nKey="view.common.utils.save_value.value">{{ val: paramUpper }} value</Trans>
  )
  const taskId = new Date().getTime() + '_update_' + paramValue + analyse._id

  const formulasValue = formula ? (
    <span>
      :
      {Object.keys(newValue).map((formula) => (
        <div>
          <b>{analyse.content.formula_infos.resultat_calcule_sans_rdt}</b>
          <span> {t('view.common.utils.save_value.with')} </span>
          <div style={{ textAlign: 'end' }}>
            {Object.keys(newValue[formula])
              .map((dico) => dico + '=' + newValue[formula][dico])
              .join(', ')}
          </div>
        </div>
      ))}
    </span>
  ) : // .join(' , ')
  null

  const task = {
    id: taskId,
    title: title,
    operator: localStorage.getItem('SESSION_user_name'),
    date: new Date().getTime(),
    action: 'update_' + formula ? 'formulas' : paramValue,
    percentage: 50,
    state: { value: 'running', message: '' },
    operation: formula ? formulasValue : newValue,
    items: [
      {
        id: analyse._id,
        type: 'analysis',
        name: analyse.name,
        analysis: {
          id: analyse._id,
          name: analyse.name,
        },
        batch: {
          id: batch._id,
          name: batch.name,
        },
      },
    ],
  }
  dispatch(fetchAddTask(task))

  displayPopupMessage(
    dispatch,
    'info',
    title,
    <Fragment>
      <div>
        <Trans i18nKey="view.common.utils.save_value.info">
          The {{ val1: paramValue }} for the analysis <i>{{ val2: analyse.name }}</i> will be
          updated to <i>{{ val3: formula ? formulasValue : newValue + '.' }}</i>
        </Trans>
      </div>
      <div style={{ marginTop: 10 }}>
        <i>{t('view.common.utils.save_value.time')}</i>
      </div>
    </Fragment>,
  )

  dispatch(fetchUpdateAnalyse(newValue, analyse, paramValue, batch._id))
    .then(function (resp) {
      displayPopupMessage(
        dispatch,
        'success',
        title,
        <Fragment>
          <Trans i18nKey="view.common.utils.save_value.success">
            The {{ val1: formula ? 'formulas' : paramValue }} for the analysis
            {{ val2: analyse.name }} is now
            <i>{{ val3: formula ? formulasValue : newValue + '.' }}</i>
          </Trans>
        </Fragment>,
      )
      dispatch(fetchFinishTask(taskId, 'success', t('view.common.utils.save_value.fine')))
    })
    .catch(function (error) {
      displayPopupMessage(
        dispatch,
        'error',
        title,
        <Fragment>
          <div>
            <Trans i18nKey="view.common.utils.save_value.cannot">
              Can not update the {{ val1: formula ? 'formulas' : paramValue }} for the analysis
              <i>{{ val2: analyse.name }}</i>.
            </Trans>
          </div>
          {generateErrorPart(error)}
        </Fragment>,
      )
      dispatch(fetchFinishTask(taskId, 'error', error))
    })
}

export function saveBatchBackup(batchId) {
  let url = `${FLASK_URL()}/dump_batch/${batchId}`
  let link = document.createElement('a')
  link.href = url
  link.setAttribute('download', `batch_${batchId}_dump.zip`)
  document.body.appendChild(link)
  // Force download
  link.click()
  // Clean up and remove the link
  link.parentNode.removeChild(link)
}

export function computeAIAnalyse(dispatch, analyse, batch) {
  const taskId = new Date().getTime() + '_compute_ia_' + analyse.name
  const title = 'Compute AI'

  const task = {
    id: taskId,
    title: title,
    operator: localStorage.getItem('SESSION_user_name'),
    date: new Date().getTime(),
    action: 'compute_ia_' + analyse.name,
    percentage: 50,
    state: { value: 'running', message: '' },
    operation: 'Compute IA score for ' + analyse.name,
    items: [
      {
        id: analyse._id,
        type: 'analysis',
        name: analyse.name,
        analysis: {
          id: analyse._id,
          name: analyse.name,
        },
        batch: {
          id: batch._id,
          name: batch.name,
        },
      },
    ],
  }
  dispatch(fetchAddTask(task))

  displayPopupMessage(
    dispatch,
    'info',
    title,
    <Fragment>
      <div>
        AI score and classification for the analysis <i>{analyse.name}</i> will be updated
      </div>
      <div style={{ marginTop: 10 }}>
        <i>This action may take time, you will be notified when it is completed.</i>
      </div>
    </Fragment>,
  )

  dispatch(fetchComputeAIAnalyse(analyse, batch._id))
    .then(function (resp) {
      if (resp.status == 400) {
        displayPopupMessage(
          dispatch,
          'error',
          `${title}`,
          <Fragment>
            <div>
              Can not compute AI score for the analysis <i>{analyse.name}</i>.
            </div>
            <div>{resp.message}</div>
          </Fragment>,
        )
        dispatch(fetchFinishTask(taskId, 'error', resp.message))
      } else {
        displayPopupMessage(
          dispatch,
          'success',
          `${title}`,
          <Fragment>Compute AI score for the analysis {analyse.name} is done</Fragment>,
        )
        dispatch(fetchFinishTask(taskId, 'success', 'Everything is fine'))
      }
    })
    .catch(function (error) {
      if (error && error.status !== undefined && error.status === 401) {
        error.statusText = t('view.common.utils.lease_locked.code')
        displayPopupMessage(
          dispatch,
          'warning',
          `${title}`,
          <Fragment>
            <div>
              Can not compute AI score for the analysis <i>{analyse.name}</i>.
            </div>
            {generateErrorPart(error)}
          </Fragment>,
        )
      } else {
        displayPopupMessage(
          dispatch,
          'error',
          `${title}`,
          <Fragment>
            <div>
              Can not compute AI score for the analysis <i>{analyse.name}</i>.
            </div>
            {generateErrorPart(error)}
          </Fragment>,
        )
      }
      dispatch(fetchFinishTask(taskId, 'error', error))
    })
}

export function testServiceAvailability(dispatch) {
  const loadedService = SERVICE()
  // test if service are available
  //fetch(`${FLASK_BASE_URL()}/${loadedService}/version`, {
  fetch(`${FLASK_URL()}/version`, {
    method: 'GET',
    credentials: 'include',
  })
    .then((response) => {
      if (response.ok && response.status === 200) {
        return response.text()
      }
      throw new Error('Something went wrong')
    })
    .then((responseJson) => {
      // Service is online, do nothing
    })
    .catch((error) => {
      // Set a flag to say that service is not available
      dispatch(fetchChangeSettings('configurationService', true))
      displayPopupMessage(
        dispatch,
        'error',
        <Trans i18nKey="view.common.utils.test_service.title">Service not available</Trans>,
        <div>
          <div>
            <Trans i18nKey="view.common.utils.test_service.msg1">
              The service is currently not accessible.
            </Trans>
          </div>
          <div>
            <Trans i18nKey="view.common.utils.test_service.msg2">
              Please contact your administrator.
            </Trans>
          </div>
        </div>,
      )
    })
}

export function testDatabaseAvailability(dispatch) {
  return new Promise((resolve, reject) => {
    const loadedDatabase = DATABASE()
    // test if database are available
    let filter = encodeURIComponent(
      JSON.stringify([
        {
          property: 'name',
          value: '\\.batch',
          filterType: 'string',
          operator: 'like',
        },
      ]),
    )
    let url = `${API_URL()}/bo_classes/page/?filter=${filter}`
    // Get all available databases
    fetch(url, {
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => {
        if (response.ok && response.status === 200) {
          return response.json()
        }
        throw new Error('Something went wrong')
      })
      .then((responseJson) => {
        // Test if the database is in the list
        const listOfDBName = responseJson.result.map((db) => db.name.replace('.batch', ''))
        const selectedDB = localStorage.getItem('SERVICE_dataBase_name')
        const selectedDBAvailable = selectedDB && listOfDBName.indexOf(selectedDB) !== -1
        const defaultDBAvailable =
          responseJson.result
            .map((db) => db.name.replace('.batch', ''))
            .indexOf(localStorage.getItem('__config.json__DEFAULT_DATA_BASE_NAME')) !== -1

        // Test DB for message
        if (typeof selectedDB === 'string' && selectedDB !== '') {
          if (selectedDBAvailable) {
            // Case DB selected is available
            // Delete the list of available DB to indicate there is no need to select a DB
            dispatch(fetchChangeSettings('configurationDatabase', []))
            resolve(selectedDB)
          } else if (defaultDBAvailable) {
            // Case DB selected unavailable but default is available
            displayPopupMessage(
              dispatch,
              'warning',
              t('view.common.utils.test_db.title_1'),
              <Trans i18nKey="view.common.utils.test_db.msg_1">
                The selected database ({{ val1: selectedDB }}) is not accessible. The default
                database ({{ val2: localStorage.getItem('__config.json__DEFAULT_DATA_BASE_NAME') }})
                will be used.
              </Trans>,
            )
            // Delete the list of available DB to indicate there is no need to select a DB
            dispatch(fetchChangeSettings('configurationDatabase', []))
            resolve(localStorage.getItem('__config.json__DEFAULT_DATA_BASE_NAME'))
          } else {
            // Case selected and default is unavailable
            displayPopupMessage(
              dispatch,
              'error',
              t('view.common.utils.test_db.title'),
              <Trans i18nKey="view.common.utils.test_db.msg_2">
                The selected database ({{ val1: selectedDB }}) and the default one (
                {{ val2: localStorage.getItem('__config.json__DEFAULT_DATA_BASE_NAME') }}) are
                currently not accessible.
              </Trans>,
            )
            // Display the popup database selection to choose an available
            dispatch(
              fetchChangeSettings(
                'configurationDatabase',
                responseJson.result.map((db) => db.name.replace('.batch', '')),
              ),
            )
            reject()
          }
        } else if (defaultDBAvailable) {
          // Case default DB is available
          // Delete the list of available DB to indicate there is no need to select a DB
          dispatch(fetchChangeSettings('configurationDatabase', []))
          resolve(localStorage.getItem('__config.json__DEFAULT_DATA_BASE_NAME'))
        } else {
          // Case default DB is unavailable
          displayPopupMessage(
            dispatch,
            'error',
            t('view.common.utils.test_db.title_2'),
            <Trans i18nKey="view.common.utils.test_db.msg_3">
              The default database (
              {{ val2: localStorage.getItem('__config.json__DEFAULT_DATA_BASE_NAME') }}) is
              currently not accessible.
            </Trans>,
          )
          // Display the popup database selection to choose an available
          dispatch(
            fetchChangeSettings(
              'configurationDatabase',
              responseJson.result.map((db) => db.name.replace('.batch', '')),
            ),
          )
          reject()
        }
      })
      .catch((error) => {
        // In this case, it's not that the database is inaccessible, but the service is unavailable
        console.log('The database ' + loadedDatabase + ' is inaccessible.')
        displayPopupMessage(
          dispatch,
          'error',
          <Trans i18nKey="view.common.utils.test_db.title">Databases not available</Trans>,
          <div>
            <div>
              <Trans i18nKey="view.common.utils.test_db.msg1">
                The databases are currently not accessible.
              </Trans>
            </div>
            <div style={{ marginTop: 10 }}>
              <Trans i18nKey="view.common.utils.test_db.msg2">
                Please contact your administrator.
              </Trans>
            </div>
          </div>,
        )
        // Add a specific key as DB to indicate there is no DB to select due to an error
        dispatch(fetchChangeSettings('configurationDatabase', ['ERROR_DB_UNAVAILABLE']))
        reject()
      })
      .finally(() => {
        // Set the lock to say that the DB is loaded (or not)
        dispatch(fetchChangeSettings('dataBaseLoaded', true))
      })
  })
}

/************************ WIKI COD *********************************/
export function getWikiHelper(dispatch) {
  const myT = t
  const suffix = 'helper'
  const fisrtLang = localStorage.getItem('i18nextLng').slice(0, 2)
  const firstLangPromise = getHelperPromise(fisrtLang, suffix)
  firstLangPromise.then(
    function (returns) {
      dispatch(fetchChangeSettings('wikiCODHelper', returns))
    },
    function () {
      const defaultLangPromise = getHelperPromise('en', suffix)
      defaultLangPromise.then(
        function (returns) {
          dispatch(fetchChangeSettings('wikiCODHelper', returns))
          displayPopupMessage(
            dispatch,
            'warning',
            myT('view.common.table_multigrid.helper_title_error'),
            <div>
              <div>
                <Trans i18nKey="view.common.table_multigrid.helper_title_msg_first">
                  It is currently impossible to retrieve wiki information in the configured language
                  ({{ val: fisrtLang }}).
                </Trans>
              </div>
              <div>
                <Trans i18nKey="view.common.table_multigrid.helper_title_msg_second">
                  The wiki in the default language ({{ val: 'en' }}) will be loaded instead.
                </Trans>
              </div>
            </div>,
          )
        },
        function () {
          displayPopupMessage(
            dispatch,
            'error',
            myT('view.common.table_multigrid.helper_title_error'),
            <Trans i18nKey="view.common.table_multigrid.helper_title_msg_default">
              It is currently impossible to retrieve wiki information in the configured (
              {{ val1: fisrtLang }}) and default ({{ val2: 'en' }}) language.
            </Trans>,
          )
        },
      )
    },
  )
}

export function getWikiDocLanguage(dispatch) {
  const suffix = 'manual'
  const fisrtLang = localStorage.getItem('i18nextLng').slice(0, 2)
  const firstLangPromise = getHelperPromise(fisrtLang, suffix)
  firstLangPromise.then(
    function () {
      dispatch(fetchChangeSettings('wikiCODDoc', fisrtLang))
    },
    function () {
      const defaultLangPromise = getHelperPromise('en', suffix)
      defaultLangPromise.then(
        function () {
          dispatch(fetchChangeSettings('wikiCODDoc', 'en'))
        },
        function () {
          dispatch(fetchChangeSettings('wikiCODDoc', null))
        },
      )
    },
  )
}

export function getHelperPromise(lang, suffix) {
  return new Promise((resolve, reject) => {
    // const url = PUBLIC_URL + '/page_web/fujitsu_alphacod_prod_' + lang + '_helper.html'
    const url =
      localStorage.getItem('__config.json__ALPHACOD_WIKI_URL') +
      'doku.php?id=fujitsu:alphacod:prod:' +
      lang +
      ':' +
      suffix
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          const statusText = response.statusText
          const status = response.status
          const url = response.url
          return response.text().then((errorMessage) => {
            const error = new Error(`${statusText} : ${errorMessage}`)
            if (response.headers.get('content-type') === 'application/json') {
              error.stack = JSON.stringify(
                JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                null,
                2,
              )
            } else {
              error.stack = new Error().stack
            }
            error.statusText = statusText
            error.status = status
            error.url = url
            throw error
          })
        }
        return response.text()
      })
      .then((htmlContent) => {
        if (
          htmlContent.indexOf('<title>fujitsu:alphacod:prod:' + lang + ':' + suffix + ' [') !==
            -1 &&
          htmlContent.indexOf('id="this_topic_does_not_exist_yet"') === -1
        ) {
          resolve(htmlContent)
        } else {
          reject(new Error('Error'))
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// Give this function "window.location.hostname" and it will return the parent domain
export function getParentDomain(hostname) {
  var parts = hostname.split('.')
  if (parts.length > 2) {
    return parts[parts.length - 2] + '.' + parts[parts.length - 1]
  } else {
    return hostname
  }
}

export function copyToClipboard(event, text) {
  const myT = t
  event.stopPropagation()
  event.preventDefault()
  if (!navigator.clipboard) {
    let textArea = document.createElement('textarea')
    textArea.value = text

    // Avoid scrolling to bottom
    textArea.style.top = '0'
    textArea.style.left = '0'
    textArea.style.position = 'fixed'

    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()

    try {
      const successful = document.execCommand('copy')
      const msg = successful
        ? myT('view.errorBoundary.copy.succes')
        : myT('view.errorBoundary.copy.unsucces')
      console.log(myT('view.errorBoundary.copy.return_msg') + ' ' + msg)
    } catch (err) {
      console.error(myT('view.errorBoundary.copy.error_msg'), err)
    }

    document.body.removeChild(textArea)
    return
  }
  navigator.clipboard.writeText(text).then(
    function () {
      console.log(myT('view.errorBoundary.copy.async_succes_msg'))
    },
    function (err) {
      console.error(myT('view.errorBoundary.copy.error_msg'), err)
    },
  )
}

export function isretiredAvailableForUser(batch) {
  if (batch) {
    const user = JSON.parse(localStorage.getItem('SESSION_user_json'))
    if (user.admin) {
      return true
    }
    const listBatchId = batch.teams.map((bt) => bt.id)
    const userTeams = user.teams
    const userTeamsForBatch = userTeams.filter((ut) => listBatchId.includes(ut.id))
    return !userTeamsForBatch.every((utfb) => utfb.role !== 'admin')
  }
  return false
}

export function isSetCalibRefAvailableForUser(batch) {
  if (batch) {
    const user = JSON.parse(localStorage.getItem('SESSION_user_json'))
    if (user.admin) {
      return true
    }
    const listBatchId = batch.teams.map((bt) => bt.id)
    const userTeams = user.teams
    const userTeamsForBatch = userTeams.filter((ut) => listBatchId.includes(ut.id))
    return !userTeamsForBatch.every((utfb) => utfb.role !== 'admin')
  }
  return false
}

//TODO To be deleted after purchasing a license
export function hideWatermark() {
  function getElementByTextContent(selector, textContent) {
    let elements = document.querySelectorAll(selector)
    for (let i = 0; i < elements.length; i++) {
      if (
        elements[i].textContent.trim() === textContent.trim() &&
        !elements[i].hasAttribute('class')
      ) {
        const hasOnlyTextChildren = Array.from(elements[i].childNodes).every(function (child) {
          return (
            child.nodeType === 3 || (child.nodeType === 1 && child.tagName.toLowerCase() === 'br')
          )
        })
        if (hasOnlyTextChildren) {
          return elements[i]
        }
      }
    }
    return null
  }
  let divElement = getElementByTextContent('div', 'MUI X Missing license key')
  if (divElement) {
    divElement.style.display = 'none'
  }
}

export function canIModifyTheCalibration(batch) {
  if (batch) {
    const user = JSON.parse(localStorage.getItem('SESSION_user_json'))
    if (user.admin) {
      return true
    }
    const listBatchId = batch.teams.map((bt) => bt.id)
    const userTeams = user.teams
    const userTeamsForBatch = userTeams.filter((ut) => listBatchId.includes(ut.id))
    return !userTeamsForBatch.every((utfb) => utfb.role !== 'admin')
  } else {
    return false
  }
}

export const flagsDict = new Map([
  ['area', 'ISTD area'],
  ['area', 'Blank area'],
  // ['mass_conc', 'Concentration'],
  ['amt_conc', 'Concentration'],
  ['Bio matrix', 'Concentration'],
  ['Dev std end', 'Concentration'],
  ['s_n', 'S/N'],
  ['devAccuracy', 'Accuracy'],
  ['devRrt', 'Dev RRT Std end'],
  ['devRrt', 'Dev RRT Calibration'],
  ['areaRatio', 'Validity domain'],
  ...Array.from(
    {
      length: Number(
        localStorage.getItem('__config.json__NUMBER_CHANNEL_VISIBLE_FOR_VOLUMIC_CONCENTRATION'),
      ),
    },
    (_, i) => [`amt_conc_${i + 1}`, 'Concentration'],
  ),
])

export const customCellWithFlag = (flags, columnKey, classes) => {
  var flags_dict = new Map()
  flags_dict.set('', -1)
  flags_dict.set('info', 0)
  flags_dict.set('warning', 1)
  flags_dict.set('error', 2)
  var level = ''
  var flagFiltered = []

  function getUpperFlag(flag, tag, columnKey, level) {
    if (flag.title === columnKey) {
      let icon = null
      switch (flag.level) {
        case 'info':
          icon = <InfoIcon className={classes.flagInfo} />
          break
        case 'warning':
          icon = <WarningIcon className={classes.flagWarning} />
          break
        case 'error':
          icon = <ErrorIcon className={classes.flagError} />
          break
        default:
          icon = null
          break
      }
      let msg =
        // flagFiltered.push(icon + ' - ' + tag + ': ' + flag.reason)
        // flagFiltered.push(`${icon} + ' - ' + ${tag} + ': ' + ${flag.reason}`)
        flagFiltered.push(
          <Fragment>
            {' '}
            {icon} {tag}: {flag.reason}
          </Fragment>,
        )
    }
    if (flag.title === columnKey && flags_dict.get(flag.level) > flags_dict.get(level)) {
      return (level = flag.level)
    }
    return level
  }

  const flags_globals = flags.globals ? flags.globals : []
  flags_globals.forEach((flag) => {
    level = getUpperFlag(flag, '[Globals]', columnKey, level)
  })
  const flags_channels = flags.channels ? flags.channels : {}
  const flags_channels_entries = Object.entries(flags_channels)
  if (flags_channels_entries.length > 0 && flags_dict.get(level) !== 2) {
    flags_channels_entries.forEach((channel, index1) => {
      channel[1].forEach((flag) => {
        let index = index1 + 1
        level = getUpperFlag(flag, 'Ch.' + index, columnKey, level)
      })
    })
  }
  return [level, flagFiltered]
}

export function isExecutionTimeActive() {
  return sessionStorage.getItem('table_execution_time')
    ? sessionStorage.getItem('table_execution_time') === 'true'
    : false
}

export function isClippyActivated() {
  return localStorage.getItem('SETTINGS_assistant_extra')
    ? localStorage.getItem('SETTINGS_assistant_extra') === 'true'
    : false
}

export function tellMeMore(dispatch, row, matrix) {
  const message = t('view.dialogs.assistant.tell_me_more_msg', {
    val1: matrix ? matrix : 'apple',
    val2: row.name,
  })
  dispatch(fetchChangeSettings('assistantPopup', true))
  dispatch(
    fetchAddUserMessage({
      date: new Date(),
      msg: message,
      is_assistant: false,
      tell_me_more: true,
    }),
  )
}

export function tellMeMoreImg(dispatch, img, data) {
  const message = t('view.dialogs.assistant.tell_me_more_img', {
    val: data.name,
  })
  dispatch(fetchChangeSettings('assistantPopup', true))
  dispatch(
    fetchAddUserMessage({
      date: new Date(),
      img: img,
      msg: message,
      is_assistant: false,
      tell_me_more: true,
    }),
  )
}

/**************************** GET THE TOOLTIP WITH WIKI **********************************/
function getTooltipContent(key, wikiCODHelper) {
  const startMarker = 'tooltip_start_' + key + '"></a>'
  const endMarker = '<a name="' + 'tooltip_end_' + key
  const startIndex = wikiCODHelper?.indexOf(startMarker)
  const endIndex = wikiCODHelper?.indexOf(endMarker)

  if (startIndex !== -1 && endIndex !== -1) {
    return wikiCODHelper?.slice(startIndex + startMarker.length, endIndex)
  } else {
    return ''
  }
}
export function getHeaderTooltipCmp(params, wikiCODHelper, customHeader) {
  const tooltipContent = getTooltipContent(params.field, wikiCODHelper)
  return (
    <Tooltip
      disableHoverListener={tooltipContent === '' || params.colDef.hideHeaderTooltip}
      placement="top"
      arrow
      title={
        <div style={{ fontSize: 13, padding: 5, fontWeight: 100, lineHeight: '1.3em' }}>
          <div
            dangerouslySetInnerHTML={{
              __html: tooltipContent ? tooltipContent : '',
            }}
          />
        </div>
      }
    >
      <span
        style={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          fontWeight: 'var(--unstable_DataGrid-headWeight)',
        }}
      >
        {customHeader ? customHeader : params.colDef.headerName}
      </span>
    </Tooltip>
  )
}

/**************************** COLOR STYLE FOR DATAGRID **********************************/
export const StripedDataGridPro = styled(DataGridPro)(({ theme }) => {
  const secondaryColor = GET_SECONDARY_COLOR() || '#000000'

  const safeAlpha = (color, value) => {
    try {
      return alpha(color, value)
    } catch {
      return 'rgba(0, 0, 0, 0)'
    }
  }

  return {
    ['& .customCell, & .MuiDataGrid-cell, & .MuiDataGrid-columnHeaders, & .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within']:
      {
        border: 'none',
        outline: 'none',
      },
    [`& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within`]: {
      outline: 'none',
    },
    [`& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainerContent`]: {
      marginLeft: 13,
      width: 50,
      height: 50,
    },
    [`& .check-box-selection`]: {
      justifyContent: 'center',
    },
    [`& .loading`]: {
      opacity: 0.5,
      '& .customCell': {
        cursor: 'wait',
      },
    },
    [`& .selection`]: {
      backgroundColor: safeAlpha(secondaryColor, 0.12) + '!important',
      '&:hover, &.Mui-hovered': {
        backgroundColor: safeAlpha(secondaryColor, 0.3) + '!important',
      },
    },
    ['& .MuiDataGrid-row.Mui-selected']: {
      backgroundColor: '#ffffff',
      '&:hover, &.Mui-hovered': {
        backgroundColor: '#0000000a',
      },
    },
    [`& .even`]: {
      backgroundColor: '#fafafa',
      '&:hover, &.Mui-hovered': {
        backgroundColor: '#0000000a',
      },
      '&.Mui-selected': {
        backgroundColor: '#fafafa',
        '&:hover, &.Mui-hovered': {
          backgroundColor: '#0000000a',
        },
      },
    },
  }
})

/**************************** UUID GENERATOR **********************************/
export function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0
    const v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}
