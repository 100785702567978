import { GET_SETTINGS, CHANGE_SETTING } from './actions'
import React from 'react'
import { isClippyActivated } from '../../view/common/Utils'

const initialState = {
  configurationDatabase: [''], //list of available databases. Not empty means that we have to choose a database to run the application. By default, we have to have something.
  configurationProfile: false,
  configurationService: false,
  loginPopup: false,
  version: null,
  clickUpload: false,
  taskId: null,
  uploadOpenCompress: false,
  uploadSendMessage: null,
  uploadRemainingTime: 0,
  uploadGlobalProgress: 0,
  uploadCustomMessage: null,
  uploadAcceptedFiles: [],
  uploadIsDragActive: false,
  configJsonLoaded: false,
  dateTimeDelta: 0,
  wikiCODHelper: '',
  wikiCODDoc: null,
  dataBaseLoaded: false,
  helperPopup: false,
  wikiPopup: false,
  currentBatchSelected: null,
  openConfirmReceiveAllBatch: false,
  openConfirmUpdateBatch: false,
  openCreateTrainingBatch: false,
  displayAccountingDialog: false,
  displayScreeningServiceDialog: false,
  analysisToRename: null,
  logsPopup: false,
  servicePopup: false,
  assistantPopup: false,
  assistantType: isClippyActivated() ? 'clippy' : 'default',
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_SETTINGS:
      return {
        ...state,
      }
    case CHANGE_SETTING:
      // Force to update settings only if something change
      if (state[action.setting] !== action.value) {
        state[action.setting] = action.value
        return {
          ...state,
        }
      } else {
        return {
          ...state,
        }
      }
    default:
      return state
  }
}
