const initialState = {
  uaReceived: [],
  lastModificationDate: 0,
  numberDownloaded: 0,
}
export default function (state = initialState, action) {
  switch (action.type) {
    case 'UPDATE_LAST_MODIFICATION_DATE':
      return {
        ...state,
        lastModificationDate: action.lastModificationDate,
        numberDownloaded: action.numberDownloaded,
      }
    default:
      return state
  }
}
