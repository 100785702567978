import { openDatabase, addObjectsToCache, manageCacheSize, STORE_NAME } from '../indexedDbUtils'
import { DEFAULT_CACHE_MAX_SIZE } from '../../utils/config'

const initialState = {
  isPopulatingCache: false,
  cacheSize: 0,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case 'REQUEST_CACHE_UPDATE':
      return {
        ...state,
        isPopulatingCache: true,
      }
    case 'RECEIVE_CACHE_UPDATE':
      return {
        ...state,
        isPopulatingCache: false,
      }
    case 'SET_CACHE_SIZE':
      return {
        ...state,
        cacheSize: action.size,
      }
    default:
      return state
  }
}

// Thunks for async actions
export const requestCacheUpdate = () => ({ type: 'REQUEST_CACHE_UPDATE' })
export const receiveCacheUpdate = () => ({ type: 'RECEIVE_CACHE_UPDATE' })

export const updateCache = (toAdd) => async (dispatch) => {
  dispatch(requestCacheUpdate())

  try {
    const db = await openDatabase()
    await addObjectsToCache(db, toAdd)

    const maxCacheSize =
      (parseInt(localStorage.getItem('SETTINGS_cache_size'), 10) || DEFAULT_CACHE_MAX_SIZE) *
      1024 *
      1024 // 1000MB in bytes
    const cachePolicy = localStorage.getItem('SETTINGS_cache_policy') || 'fifo'
    await manageCacheSize(db, maxCacheSize, cachePolicy, dispatch)
  } catch (error) {
    console.error('Error updating cache', error)
  }
  dispatch(receiveCacheUpdate())
}

export const clearCache = () => async (dispatch) => {
  try {
    const db = await openDatabase()
    const transaction = db.transaction([STORE_NAME], 'readwrite')
    const store = transaction.objectStore(STORE_NAME)
    store.clear()

    transaction.oncomplete = function () {
      dispatch({ type: 'SET_CACHE_SIZE', size: 0 })
      // Restart the prefetching to 0
      localStorage.removeItem('CACHE_last_modificationDate')
    }

    transaction.onerror = function (event) {
      console.error('Error clearing cache', event.target.error)
    }
  } catch (error) {
    console.error('Error clearing cache', error)
  }
}
