import React, { Fragment, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { alpha, makeStyles, OutlinedInput, Tooltip } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton'
import StorageIcon from '@material-ui/icons/Storage'
import { TransitionBottom } from './Style'
import DialogTitle from '@material-ui/core/DialogTitle'
import Box from '@material-ui/core/Box'
import CardMedia from '@material-ui/core/CardMedia'
import {
  API_URL,
  CACHE_IS_USED,
  DATABASE,
  DEFAULT_CACHE_MAX_SIZE,
  DEFAULT_CACHE_POLLING,
  PREFETCH_LIMIT,
  PUBLIC_URL,
} from '../../utils/config'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import { displayPopupMessage, generateErrorPart } from './Message'
import ErrorBoundaryGuard from '../ErrorBoundaryGuard'
import { t } from 'i18next'
import { fetchChangeSettings } from '../../redux/settings/actions'
import { fetchClearCache } from '../../redux/cache/actions'
import { Trans } from 'react-i18next'
import sizeof from 'object-sizeof'
import Grid from '@material-ui/core/Grid'
import * as I18n from 'i18next'
import CleaningServicesIcon from '@mui/icons-material/CleaningServices'
import { TextField } from '@material-ui/core'
import InputAdornment from '@material-ui/core/InputAdornment'
import { FormHelperText, Stack } from '@mui/material'
import InputLabel from '@mui/material/InputLabel'
import NewFormControl from '@mui/material/FormControl'
import NewSelect from '@mui/material/Select'
import { initializeCacheSize } from '../../redux/indexedDbUtils'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import SettingsIcon from '@mui/icons-material/Settings'
import Popover from '@mui/material/Popover'

const useStyles = makeStyles((theme) => ({
  newMessageIcon: {
    color: '#ffc800',
    position: 'absolute',
    top: -10,
    right: 13,
  },
  header: {
    boxShadow: 'inset 0px 0px 82px 10px rgba(0,0,0,1)',
  },
  cardImg: {
    opacity: 0.8,
  },
  titleCard: {
    position: 'absolute',
    top: 55,
    left: 34,
    fontSize: 40,
    color: theme.palette.secondary.main,
    textShadow: '2px 1px 10px white',
  },
  badgeDatabase: {
    '& .MuiBadge-badge': {
      right: 9,
      top: 10,
    },
  },
  colorPickerCnt: {
    border: '1px solid #c4c4c4',
    width: 'calc(50% - 5px)',
    display: 'inline-flex',
    borderRadius: 4,
  },
  colorPickerTitle: {
    background:
      'linear-gradient(0deg, rgb(255 255 255) 56%, rgba(255,255,255,0) 56%, rgba(255,255,255,0) 100%)',
    position: 'absolute',
    color: 'rgba(0, 0, 0, 0.6)',
    padding: '0px 7px',
    fontSize: '1rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: '0.00938em',
    transform: 'translate(-4px, -8px) scale(0.75)',
  },
  progressBarColor: {
    backgroundColor: alpha(theme.palette.secondary.main, 0.8),
  },
  newSelectSmall: {
    '& > .MuiSelect-select': {
      padding: '9px 15px 8px',
    },
    '& > fieldset': {
      borderRadius: '4px 0px 0px 4px',
    },
  },
  newPrefetchSmall: {
    '& > .MuiSelect-select': {
      padding: '9px 15px 8px',
    },
  },
  questionButton: {
    '&:hover svg': {
      color: theme.palette.secondary.main,
    },
  },
}))

function formatBytes(bytes) {
  if (bytes === 0) return [0, 'Bytes']

  const k = 1024
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))

  const value = parseFloat((bytes / Math.pow(k, i)).toFixed(2))
  const unit = sizes[i]

  return [value, unit]
}

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
})

const DatabaseCmp = withRouter((props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [databaseList, setDatabaseList] = React.useState([DATABASE()])
  const [configurationDatabase, setConfigurationDatabase] = React.useState(DATABASE())

  const [openDatabase, setOpenDatabase] = React.useState(false)

  const cacheLimitInit =
    parseInt(localStorage.getItem('SETTINGS_cache_size'), 10) || DEFAULT_CACHE_MAX_SIZE
  const pollingTimeInit =
    parseInt(localStorage.getItem('SETTINGS_cache_polling'), 10) || DEFAULT_CACHE_POLLING
  const pageSizeInit = PREFETCH_LIMIT()
  const [cacheLimit, setCacheLimit] = React.useState(cacheLimitInit)
  const [pollingTime, setPollingTime] = React.useState(pollingTimeInit)
  const [pageSize, setPageSize] = React.useState(pageSizeInit)
  const cachePolicyInit = localStorage.getItem('SETTINGS_cache_policy') || 'fifo'
  const cachePrefetchInit = localStorage.getItem('SETTINGS_cache_prefetch') === 'true'
  const [policy, setPolicy] = React.useState(cachePolicyInit)
  const [prefetch, setPrefetch] = React.useState(cachePrefetchInit)

  const cacheSize = useSelector((state) => state.cache.cacheSize)
  const cacheUpdating = useSelector((state) => state.cache.isPopulatingCache)

  const [shouldUpdate, setShouldUpdate] = React.useState(false)
  const [prevCacheUpdating, setPrevCacheUpdating] = React.useState(cacheUpdating)

  const [tooltipOpened, setTooltipOpened] = React.useState(false)

  const [anchorPrefetchMenuEl, setAnchorPrefetchMenuEl] = React.useState(null)
  const openPrefetchMenu = Boolean(anchorPrefetchMenuEl)
  const handleOpenFetchSettings = (event) => {
    setAnchorPrefetchMenuEl(event.currentTarget)
  }
  const handlePrefetchMenuClose = () => {
    setAnchorPrefetchMenuEl(null)
  }

  // Initiate the cache size
  useEffect(() => {
    if (CACHE_IS_USED()) {
      dispatch(initializeCacheSize())
    }
  }, [dispatch])

  // Reset settings at the opening
  useEffect(() => {
    if (openDatabase) {
      setPrefetch(cachePrefetchInit)
      setCacheLimit(cacheLimitInit)
      setPolicy(cachePolicyInit)
      setPollingTime(pollingTimeInit)
      setPageSize(pageSizeInit)
    }
  }, [openDatabase])

  // useEffect to watch for the transition of cacheUpdating from true to false
  useEffect(() => {
    if (prevCacheUpdating && !cacheUpdating) {
      setShouldUpdate((prev) => !prev) // Toggle the state to trigger useMemo
    }
    setPrevCacheUpdating(cacheUpdating) // Update the previous value
  }, [cacheUpdating, prevCacheUpdating])

  const [formattedSize, formattedUnit, percentageSize] = useMemo(() => {
    const [formattedSize, formattedUnit] = formatBytes(cacheSize)
    const percentageSize = Math.min(100, (cacheSize / (cacheLimit * 1024 * 1024)) * 100)
    return [formattedSize, formattedUnit, percentageSize]
  }, [cacheSize, shouldUpdate, cacheLimit])

  useEffect(function () {
    let filter = encodeURIComponent(
      JSON.stringify([
        {
          property: 'name',
          value: '\\.batch',
          filterType: 'string',
          operator: 'like',
        },
      ]),
    )
    // if (openDatabase) {
    let url = `${API_URL()}/bo_classes/page/?filter=${filter}`
    fetch(url, {
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => {
        if (!response.ok) {
          const statusText = response.statusText
          const status = response.status
          const url = response.url
          return response.text().then((errorMessage) => {
            const error = new Error(`${statusText} : ${errorMessage}`)
            if (response.headers.get('content-type') === 'application/json') {
              error.stack = JSON.stringify(
                JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                null,
                2,
              )
            } else {
              error.stack = new Error().stack
            }
            error.statusText = statusText
            error.status = status
            error.url = url
            throw error
          })
        }
        return response.json()
      })
      .then((json) => {
        let dataBaseList = json.result.map((db) => db.name.replace('.batch', ''))
        setDatabaseList(
          dataBaseList.sort(function (a, b) {
            if (a < b) return -1
            if (a > b) return 1
            return 0
          }),
        )
      })
      .catch((error) => {
        if (error.status === 403) {
          dispatch(fetchChangeSettings('loginPopup', true))
        }
        displayPopupMessage(
          dispatch,
          'error',
          t('view.common.databaseInput.title'),
          <Fragment>
            <div>{t('view.common.databaseInput.error')}</div>
            {generateErrorPart(error)}
            <div>{t('view.common.databaseInput.contact')}</div>
          </Fragment>,
        )
      })
    // }
  }, [])

  function handleCloseDatabase(evt, save) {
    const cacheIsUsed = CACHE_IS_USED()
    setOpenDatabase(false)
    const isDataBaseChanged = DATABASE() !== configurationDatabase
    const isCacheSizeChanged = cacheLimitInit !== cacheLimit
    const isCachePolicyChanged = cachePolicyInit !== policy
    const isCachePrefetchChanged = cachePrefetchInit !== prefetch
    const isPollingTimeChanged = pollingTimeInit !== pollingTime
    const isPageSizeChanged = pageSizeInit !== pageSize
    if (save) {
      if (isDataBaseChanged) {
        localStorage.setItem('SERVICE_dataBase_name', configurationDatabase)
      }
      if (cacheIsUsed) {
        if (isCacheSizeChanged) {
          localStorage.setItem('SETTINGS_cache_size', cacheLimit)
        }
        if (isCachePolicyChanged) {
          localStorage.setItem('SETTINGS_cache_policy', policy)
        }
        if (isCachePrefetchChanged) {
          localStorage.setItem('SETTINGS_cache_prefetch', prefetch)
        }
        if (isPollingTimeChanged) {
          localStorage.setItem('SETTINGS_cache_polling', pollingTime)
        }
        if (isPageSizeChanged) {
          localStorage.setItem('SETTINGS_cache_page_size', pageSize)
        }
      }
      displayPopupMessage(
        dispatch,
        'success',
        t('view.dialogs.config.close_msg.title'),
        <Fragment>
          {isDataBaseChanged && (
            <div>
              <Trans i18nKey="view.dialogs.config.close_msg.msg_database">
                The database is now:
                {{
                  val: configurationDatabase,
                }}
                .
              </Trans>
            </div>
          )}
          {cacheIsUsed && isCachePrefetchChanged && (
            <div>
              <Trans i18nKey="view.dialogs.config.close_msg.msg_cache_prefetch">
                Prefetching mechanism:
                {{
                  val: prefetch
                    ? t('view.dashboardToolbar.dialogs.settings.active')
                    : t('view.dashboardToolbar.dialogs.settings.not_active'),
                }}{' '}
                MB.
              </Trans>
            </div>
          )}
          {cacheIsUsed && isCacheSizeChanged && (
            <div>
              <Trans i18nKey="view.dialogs.config.close_msg.msg_cache_size">
                The cache size is now:
                {{
                  val: cacheLimit,
                }}{' '}
                MB.
              </Trans>
            </div>
          )}
          {cacheIsUsed && isCachePolicyChanged && (
            <div>
              <Trans i18nKey="view.dialogs.config.close_msg.msg_cache_policy">
                The cache policy is now:
                {{
                  val: policy,
                }}
                .
              </Trans>
            </div>
          )}
          {cacheIsUsed && isPollingTimeChanged && (
            <div>
              <Trans i18nKey="view.dialogs.config.close_msg.msg_cache_polling">
                The prefetch polling time is now:
                {{
                  val: pollingTime,
                }}
                sec.
              </Trans>
            </div>
          )}
          {cacheIsUsed && isPageSizeChanged && (
            <div>
              <Trans i18nKey="view.dialogs.config.close_msg.msg_cache_page_size">
                The prefetch page size is now:
                {{
                  val: pageSize,
                }}
                .
              </Trans>
            </div>
          )}
        </Fragment>,
      )
      if (isDataBaseChanged) {
        // Refresh the page
        window.location.reload()
      }
    }
  }

  function handleOpenDatabase() {
    setOpenDatabase(true)
  }

  const handleConfigurationDatabaseChanged = (event) => {
    const value = event.target.value
    setConfigurationDatabase(value)
  }

  const handleClearCache = (event) => {
    dispatch(fetchClearCache())
  }

  const handlePolicyChange = (event) => {
    const newPolicy = event.target.value
    setPolicy(newPolicy)
  }

  const handlePrefetchChange = (event) => {
    const newPrefetch = event.target.value
    setPrefetch(newPrefetch)
  }

  let settingsTimer = null
  const onSizeMaxChanged = (event, forceSave) => {
    const saveTheNewSize = function (val) {
      setCacheLimit(val)
    }
    const value = parseFloat(event.currentTarget.value)
    clearTimeout(settingsTimer)
    if (forceSave) {
      saveTheNewSize(value)
    } else {
      settingsTimer = setTimeout(function () {
        saveTheNewSize(value)
      }, 1500)
    }
  }

  const handleTooltipClose = () => {
    setTooltipOpened(false)
  }
  const handleTooltipOpen = () => {
    setTooltipOpened((prevValue) => !prevValue)
  }

  let pollingTimer = null
  const onPollingChanged = (event, forceSave) => {
    const saveTheNewPolling = function (val) {
      setPollingTime(val)
    }
    const value = parseFloat(event.currentTarget.value)
    clearTimeout(pollingTimer)
    if (forceSave) {
      saveTheNewPolling(value)
    } else {
      pollingTimer = setTimeout(function () {
        saveTheNewPolling(value)
      }, 1500)
    }
  }

  let pageSizeTimer = null
  const onPageSizeChanged = (event, forceSave) => {
    const saveTheNewPageSize = function (val) {
      setPageSize(val)
    }
    const value = parseFloat(event.currentTarget.value)
    clearTimeout(pageSizeTimer)
    if (forceSave) {
      saveTheNewPageSize(value)
    } else {
      pageSizeTimer = setTimeout(function () {
        saveTheNewPageSize(value)
      }, 1500)
    }
  }

  return (
    <div
      style={{
        margin: '0px 15px 0px 20px',
      }}
    >
      <Dialog
        disableEnforceFocus
        open={openDatabase}
        onClose={(event) => handleCloseDatabase(event, false)}
        TransitionComponent={TransitionBottom}
        aria-labelledby="dialog-database"
        // fullWidth
        // maxWidth={'lg'}
      >
        <DialogTitle style={{ padding: 0 }}>
          <Box className={classes.header}>
            <CardMedia
              className={classes.cardImg}
              component="img"
              alt="header image"
              height="130"
              image={PUBLIC_URL + '/images/database_bkg.jpg'}
              title="Header image"
            />
            <span className={classes.titleCard}>{t('view.common.databaseCmp.database')}</span>
          </Box>
        </DialogTitle>
        <DialogContent style={{ overflow: 'hidden', minHeight: 60 }}>
          <DialogContentText style={{ marginTop: 20, marginBottom: 20 }}>
            {t('view.common.databaseCmp.msg1')}
          </DialogContentText>
          <ErrorBoundaryGuard isDialog>
            <FormControl
              style={{ background: 'white' }}
              required
              fullWidth
              variant="outlined"
              className={classes.formControlProfile}
              error={databaseList.indexOf(configurationDatabase) === -1}
            >
              <Select
                variant="outlined"
                onChange={handleConfigurationDatabaseChanged}
                value={configurationDatabase}
              >
                {databaseList.map((db) => (
                  <MenuItem key={db} value={db}>
                    {db}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </ErrorBoundaryGuard>
        </DialogContent>
        {CACHE_IS_USED() && (
          <DialogContent style={{ overflow: 'hidden', minHeight: 60, marginTop: 20 }}>
            <div className={classes.colorPickerCnt} style={{ width: '100%' }}>
              <Grid
                container
                spacing={2}
                justifyContent="center"
                alignItems="center"
                className={classes.root}
                style={{ margin: 10 }}
              >
                {/*<div*/}
                {/*  style={{*/}
                {/*    border: '1px solid #c4c4c4',*/}
                {/*    width: '100%',*/}
                {/*    height: 40,*/}
                {/*    display: 'flex',*/}
                {/*    justifyContent: 'center',*/}
                {/*    alignItems: 'center',*/}
                {/*    borderRadius: 4,*/}
                {/*    marginTop: 10,*/}
                {/*  }}*/}
                {/*>*/}
                {/*  Size : {formattedSize} {formattedUnit}*/}
                {/*</div>*/}
                <div
                  style={{
                    backgroundColor: '#e6e6e6',
                    height: 40,
                    borderRadius: 5,
                    width: '100%',
                    marginTop: 10,
                  }}
                >
                  <div
                    className={classes.progressBarColor}
                    style={{
                      height: 'inherit',
                      textAlign: 'center',
                      width: (percentageSize ? percentageSize : 0) + '%',
                      borderRadius: 5,
                      transition: 'width 500ms ease-in',
                    }}
                  />
                  <div
                    style={{
                      textAlign: 'center',
                      top: '-79%',
                      position: 'relative',
                    }}
                  >
                    {formattedSize} {formattedUnit}
                  </div>
                </div>
                <div style={{ marginTop: 20, width: '100%' }}>
                  <NewFormControl size="small" style={{ width: 130 }}>
                    <InputLabel>{t('view.common.databaseCmp.cache.prefetch')}</InputLabel>
                    <NewSelect
                      size="small"
                      className={classes.newSelectSmall}
                      value={prefetch}
                      label={t('view.common.databaseCmp.cache.prefetch')}
                      onChange={handlePrefetchChange}
                    >
                      {['Yes', 'No'].map((p, index) => {
                        const prefetchValue = p.toLowerCase() === 'yes'
                        return (
                          <MenuItem key={p + '_' + index} value={prefetchValue}>
                            <div>{t('view.common.LimsAutoVal.' + p.toLowerCase())}</div>
                          </MenuItem>
                        )
                      })}
                    </NewSelect>
                  </NewFormControl>
                  <Button
                    className={classes.questionButton}
                    disableElevation
                    variant="outlined"
                    startIcon={<SettingsIcon />}
                    style={{
                      marginRight: 15,
                      color: '#6b6b6b',
                      height: 40,
                      minWidth: 40,
                      width: 40,
                      paddingLeft: 23,
                      borderRadius: '0px 4px 4px 0px',
                      borderLeft: 0,
                    }}
                    onClick={handleOpenFetchSettings}
                  ></Button>
                  <TextField
                    label={t('view.common.databaseCmp.cache.size')}
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      inputProps: { min: 1 },
                      endAdornment: <InputAdornment position="end">MB</InputAdornment>,
                    }}
                    defaultValue={cacheLimit}
                    onChange={(e) => onSizeMaxChanged(e)}
                    onBlur={(e) => onSizeMaxChanged(e, true)}
                    variant="outlined"
                    size="small"
                    style={{ width: 160 }}
                  />
                  <div style={{ float: 'right' }}>
                    <NewFormControl size="small" style={{ marginLeft: 10, width: 130 }}>
                      <InputLabel>{t('view.common.databaseCmp.cache.policy')}</InputLabel>
                      <NewSelect
                        size="small"
                        className={classes.newSelectSmall}
                        value={policy}
                        label={t('view.common.databaseCmp.cache.policy')}
                        onChange={handlePolicyChange}
                      >
                        {['FIFO', 'LRU', 'LFU', 'Random'].map((p, index) => {
                          const policyLowerCase = p.toLowerCase()
                          return (
                            <MenuItem key={p + '_' + index} value={policyLowerCase}>
                              <div>{p}</div>
                            </MenuItem>
                          )
                        })}
                      </NewSelect>
                    </NewFormControl>
                    <CustomWidthTooltip
                      PopperProps={{
                        disablePortal: true,
                      }}
                      onClose={handleTooltipClose}
                      open={tooltipOpened}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      placement={'top'}
                      title={
                        <div style={{ fontSize: 13, padding: 5, fontWeight: 300 }}>
                          {['FIFO', 'LRU', 'LFU', 'Random'].map((p, index) => {
                            const policyLowerCase = p.toLowerCase()
                            return (
                              <span key={p + '_' + index}>
                                <div style={{ marginTop: 5, fontWeight: 500 }}>
                                  {p +
                                    ' (' +
                                    t(`view.common.databaseCmp.cache.${policyLowerCase}`) +
                                    '):'}
                                </div>
                                <div
                                  style={{ marginBottom: 10, marginTop: 5, textAlign: 'justify' }}
                                >
                                  {t(`view.common.databaseCmp.cache.${policyLowerCase}_detail`)}
                                </div>
                              </span>
                            )
                          })}
                        </div>
                      }
                    >
                      <Button
                        className={classes.questionButton}
                        disableElevation
                        variant="outlined"
                        startIcon={
                          tooltipOpened ? (
                            <KeyboardArrowDownIcon color="secondary" />
                          ) : (
                            <QuestionMarkIcon />
                          )
                        }
                        style={{
                          color: '#6b6b6b',
                          height: 40,
                          minWidth: 40,
                          width: 40,
                          paddingLeft: 23,
                          borderRadius: '0px 4px 4px 0px',
                          borderLeft: 0,
                        }}
                        onClick={handleTooltipOpen}
                      ></Button>
                    </CustomWidthTooltip>
                  </div>
                </div>
                <div style={{ marginTop: 10, width: '100%' }}>
                  <Button
                    color="secondary"
                    disableElevation
                    variant="contained"
                    startIcon={<CleaningServicesIcon />}
                    onClick={handleClearCache}
                    style={{ float: 'right', height: 40 }}
                  >
                    {t('view.common.databaseCmp.cache.clean')}
                  </Button>
                </div>
              </Grid>
              <div className={classes.colorPickerTitle} style={{ left: 30 }}>
                {t('view.common.databaseCmp.cache.title')}
              </div>
            </div>
            <Popover
              id={openPrefetchMenu ? 'simple-popover' : undefined}
              anchorEl={anchorPrefetchMenuEl}
              open={openPrefetchMenu}
              onClose={handlePrefetchMenuClose}
            >
              <Stack spacing={2} style={{ padding: '25px 20px' }}>
                <TextField
                  label={t('view.common.databaseCmp.cache.polling')}
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    inputProps: { min: 1 },
                    endAdornment: <InputAdornment position="end">sec</InputAdornment>,
                  }}
                  defaultValue={pollingTime}
                  onChange={(e) => onPollingChanged(e)}
                  onBlur={(e) => onPollingChanged(e, true)}
                  variant="outlined"
                  size="small"
                />
                <TextField
                  label={t('view.common.databaseCmp.cache.page_size')}
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    inputProps: { min: 1 },
                  }}
                  defaultValue={pageSize}
                  onChange={(e) => onPageSizeChanged(e)}
                  onBlur={(e) => onPageSizeChanged(e, true)}
                  variant="outlined"
                  size="small"
                />
              </Stack>
            </Popover>
          </DialogContent>
        )}
        <DialogActions>
          <Button
            onClick={(event) => handleCloseDatabase(event, true)}
            color="primary"
            disabled={
              configurationDatabase === '' ||
              configurationDatabase === null ||
              configurationDatabase === undefined ||
              databaseList.indexOf(configurationDatabase) === -1
            }
          >
            {t('view.common.databaseCmp.save')}
          </Button>
          <Button onClick={(event) => handleCloseDatabase(event, false)} color="primary">
            {t('view.common.databaseCmp.close')}
          </Button>
        </DialogActions>
      </Dialog>
      <Tooltip
        arrow
        title={
          <div style={{ fontSize: 13, padding: 5 }}>
            {configurationDatabase === '' ||
            configurationDatabase === null ||
            configurationDatabase === undefined ||
            databaseList.indexOf(configurationDatabase) === -1
              ? t('view.common.databaseCmp.noDB')
              : DATABASE()}
          </div>
        }
      >
        <IconButton
          aria-label="database"
          style={{
            color: 'inherit',
          }}
          onClick={handleOpenDatabase}
        >
          <StorageIcon />
        </IconButton>
      </Tooltip>
    </div>
  )
})
export default DatabaseCmp
