import { fetchChangeSettings } from '../settings/actions'
import {
  addObjectsToCache,
  addObjectsToCacheInWorker,
  getIdListToDownload,
  manageCacheSize,
  openDatabase,
  terminateTheWorker,
} from '../indexedDbUtils'
import {
  API_BO_URL,
  DEFAULT_CACHE_MAX_SIZE,
  FLASK_URL,
  GET_LAST_CACHE_MODIFICATION,
  PREFETCH_LIMIT,
} from '../../utils/config'
import { displayPopupMessage, generateErrorPart } from '../../view/common/Message'
import React, { Fragment } from 'react'
import { clearCache } from '../cache/reducers'

export function fetchAllUnitaryAnalysesForBatch_old(batchId) {
  return async (dispatch, getState) => {
    const { prefetch } = getState()
    // dispatch(AllUnitaryAnalysesForBatch(batchId))

    let filter = [
      {
        property: 'content.batch.id',
        value: batchId,
        filterType: 'string',
        operator: 'eq',
      },
      {
        property: 'modificationDate',
        value: prefetch.lastModificationDate,
        filterType: 'number',
        operator: 'gte',
      },
    ]
    let p = encodeURIComponent(JSON.stringify(filter))

    const url = `${API_BO_URL()}.unitary_analysis/page/?limit=${PREFETCH_LIMIT()}&filter=${p}&sort=modificationDate&dir=ASC`
    let promise = fetch(url, {
      method: 'GET',
      credentials: 'include',
    }).then((response) => {
      if (!response.ok) {
        const statusText = response.statusText
        const status = response.status
        const url = response.url
        return response.text().then((errorMessage) => {
          const error = new Error(`${statusText} : ${errorMessage}`)
          if (response.headers.get('content-type') === 'application/json') {
            error.stack = JSON.stringify(
              JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
              null,
              2,
            )
          } else {
            error.stack = new Error().stack
          }
          error.statusText = statusText
          error.status = status
          error.url = url
          throw error
        })
      }
      return response.json()
    })
    return promise
      .then(async ({ result, size }) => {
        try {
          const db = await openDatabase()
          await addObjectsToCache(db, result)

          const maxCacheSize =
            (parseInt(localStorage.getItem('SETTINGS_cache_size'), 10) || DEFAULT_CACHE_MAX_SIZE) *
            1024 *
            1024 // 1000MB in bytes
          const cachePolicy = localStorage.getItem('SETTINGS_cache_policy') || 'fifo'
          await manageCacheSize(db, maxCacheSize, cachePolicy, dispatch)
          return dispatch(receiveAllUnitaryAnalysesForBatch(result, false))
        } catch (error) {
          throw error
        }
      })
      .catch((error) => {
        if (error.status === 403) {
          dispatch(fetchChangeSettings('loginPopup', true))
        }
        displayPopupMessage(
          dispatch,
          'error',
          'Failed to prefetch unitary analyses',
          <Fragment>
            <div>An error does not allow to prefetch unitary analyses:</div>
            {generateErrorPart(error)}
          </Fragment>,
        )
      })
  }
}

export function receiveAllUnitaryAnalysesForBatch(result) {
  return {
    type: 'UPDATE_LAST_MODIFICATION_DATE',
    lastModificationDate: result?.[result.length - 1]?.modificationDate,
    numberDownloaded: result.length,
  }
}

export function fetchAllUnitaryAnalysesForBatch(batchId) {
  return async (dispatch /*, getState*/) => {
    // const { prefetch } = getState()
    let filter = [
      {
        property: 'content.batch.id',
        value: batchId,
        filterType: 'string',
        operator: 'eq',
      },
      {
        property: 'modificationDate',
        value: GET_LAST_CACHE_MODIFICATION()?.['modificationDate'],
        filterType: 'number',
        operator: 'gte',
      },
    ]
    let p = encodeURIComponent(JSON.stringify(filter))
    const url = `${API_BO_URL()}.unitary_analysis/page/?limit=${PREFETCH_LIMIT()}&filter=${p}&sort=modificationDate&dir=ASC`

    const requestOptions = {
      method: 'GET',
      credentials: 'include',
    }
    const includes = '&include=' + ['_id', 'modificationDate'].join('&include=')
    // Retrieving data via the first query
    return fetch(url + includes, requestOptions)
      .then((response) => response.json())
      .then(async (result) => {
        let data1 = result
        if (
          !data1.hasOwnProperty('size') &&
          !data1.hasOwnProperty('result') &&
          typeof data1 === 'object'
        ) {
          // It's because the request return just an UA, not an array of UA
          // Typically on the global view UA detail
          data1 = { result: [data1], size: 1 }
        }
        const uas_to_download = await getIdListToDownload(data1.result)
        if (uas_to_download?.length > 0) {
          // Request with GET/HEAD method cannot have body -> Use POST
          // Get the UAs object not present in cache
          return fetch(`${FLASK_URL()}/unitary_analysis`, {
            ...requestOptions,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify([
              {
                property: '_id',
                value: uas_to_download,
                filterType: 'array',
                operator: 'in',
              },
            ]),
          })
            .then((response) => response.json())
            .then(async (result) => {
              const maxCacheSize =
                (parseInt(localStorage.getItem('SETTINGS_cache_size'), 10) ||
                  DEFAULT_CACHE_MAX_SIZE) *
                1024 *
                1024 // 100MB
              const cachePolicy = localStorage.getItem('SETTINGS_cache_policy') || 'fifo'

              // Utilisation du Web Worker pour ajouter les objets au cache
              addObjectsToCacheInWorker(result, maxCacheSize, cachePolicy, dispatch)

              // return dispatch(receiveAllUnitaryAnalysesForBatch(result, false))
              localStorage.setItem(
                'CACHE_last_modificationDate',
                JSON.stringify({
                  modificationDate: result?.[result.length - 1]?.modificationDate,
                  batchId: batchId,
                }),
              )
              return data1.result.length
            })
        } else {
          localStorage.setItem(
            'CACHE_last_modificationDate',
            JSON.stringify({
              modificationDate: data1.result?.[data1.result.length - 1]?.modificationDate,
              batchId: batchId,
            }),
          )
          return data1.result.length
        }
      })
      .catch((error) => {
        if (error.status === 403) {
          dispatch(fetchChangeSettings('loginPopup', true))
        }
        displayPopupMessage(
          dispatch,
          'error',
          'Failed to prefetch unitary analyses',
          <Fragment>
            <div>An error occurred while prefetching unitary analyses:</div>
            {generateErrorPart(error)}
          </Fragment>,
        )
        throw error
      })

    // return fetch(url, {
    //   method: 'GET',
    //   credentials: 'include',
    // })
    //   .then((response) => response.json())
    //   .then(async ({ result, size }) => {
    //     const maxCacheSize =
    //       (parseInt(localStorage.getItem('SETTINGS_cache_size'), 10) || DEFAULT_CACHE_MAX_SIZE) *
    //       1024 *
    //       1024 // 100MB
    //     const cachePolicy = localStorage.getItem('SETTINGS_cache_policy') || 'fifo'
    //
    //     // Utilisation du Web Worker pour ajouter les objets au cache
    //     addObjectsToCacheInWorker(result, maxCacheSize, cachePolicy, dispatch)
    //
    //     // return dispatch(receiveAllUnitaryAnalysesForBatch(result, false))
    //     localStorage.setItem(
    //       'CACHE_last_modificationDate',
    //       result?.[result.length - 1]?.modificationDate,
    //     )
    //     return result.length
    //   })
    //   .catch((error) => {
    //     if (error.status === 403) {
    //       dispatch(fetchChangeSettings('loginPopup', true))
    //     }
    //     displayPopupMessage(
    //       dispatch,
    //       'error',
    //       'Failed to prefetch unitary analyses',
    //       <Fragment>
    //         <div>An error occurred while prefetching unitary analyses:</div>
    //         {generateErrorPart(error)}
    //       </Fragment>,
    //     )
    //   })
  }
}

export function fetchTerminateTheWorker() {
  return async () => {
    terminateTheWorker()
  }
}
