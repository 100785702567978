import React, { Fragment, useMemo } from 'react'
import { Stack } from '@mui/material'
import DialogContentText from '@mui/material/DialogContentText'
import SettingsIcon from '@mui/icons-material/Settings'
import { useTranslation } from 'react-i18next'
import Progress from '../../Progress'
import Chip from '@mui/material/Chip'
import { DataGrid } from '@mui/x-data-grid'
import { humanFileSize } from '../../common/Utils'
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { alpha } from '@material-ui/core'
import ErrorBoundaryGuard from '../../ErrorBoundaryGuard'

const useStyles = makeStyles((theme) => ({
  settingsInfoText: {
    paddingTop: 15,
    paddingBottom: 20,
    fontSize: '1rem',
  },
  chiplevel: {
    height: 20,
    marginLeft: 10,
    '& span': {
      padding: 5,
    },
  },
  datagrid: {
    height: 300,
    display: 'inline-flex',
    width: '100%',
    marginTop: 30,
    '& .MuiDataGrid-row': {
      cursor: 'pointer',
    },
    '& .MuiDataGrid-row:nth-child(even):hover': {
      backgroundColor: '#0000000F',
    },
    '& .MuiDataGrid-row:nth-child(odd)': {
      backgroundColor: '#00000008',
    },
    '& .MuiDataGrid-row:nth-child(odd):hover': {
      backgroundColor: '#00000012',
    },
  },
  dialogTitle: {
    backgroundImage: 'linear-gradient(' + alpha(theme.palette.primary.main, 0.12) + ', white)',
    backgroundColor: 'white',
    borderTop: '4px solid ' + theme.palette.primary.main,
  },
}))

export default function ZipPanel({
  globalProgress,
  currentFile,
  totalFiles,
  zippedFiles,
  acceptedFiles,
  zipLevel,
  remainingTime,
}) {
  const classes = useStyles()
  const { t, i18n } = useTranslation()

  const [sortModel, setSortModel] = React.useState(
    localStorage.getItem('DATAGRID_dropzone_sort')
      ? JSON.parse(localStorage.getItem('DATAGRID_dropzone_sort'))
      : [],
  )

  const rows = useMemo(() => {
    return acceptedFiles.map((file, index) => ({
      id: index,
      path: file.path,
      name: file.name,
      date: file.lastModifiedDate,
      size: file.size,
    }))
  }, [JSON.stringify(acceptedFiles)])

  const columns = [
    {
      field: 'name',
      headerClassName: classes.header,
      headerName: t('view.panel.dropzone.header_name.name'),
      flex: 1,
    },
    {
      field: 'size',
      headerClassName: classes.header,
      headerName: t('view.panel.dropzone.header_name.size'),
      width: 90,
      valueFormatter: (params) => {
        return humanFileSize(params.value, true)
      },
    },
    {
      field: 'actions',
      headerClassName: classes.header,
      headerName: t('view.panel.dropzone.header_name.status'),
      width: 70,
      renderCell: function (params) {
        let result = <PauseCircleOutlineIcon />
        if (params.row.name === currentFile && globalProgress !== 100) {
          result = <SettingsIcon style={{ animation: 'rotation 2s infinite linear' }} />
        } else if (zippedFiles.indexOf(params.row.name) !== -1) {
          result = <CheckCircleIcon />
        }
        return result
      },
    },
  ]

  return (
    <Fragment>
      {/*<div style={{ filter: globalProgress === 0 ? 'blur(4px)' : 'none' }}>*/}
      <div>
        <DialogContentText component="span" className={classes.settingsInfoText}>
          {t('view.dialogs.compress.level') + ': '}
          <Chip color="secondary" label={zipLevel} className={classes.chiplevel} />
        </DialogContentText>
        <ErrorBoundaryGuard isDialog>
          <Progress progress={globalProgress} width={'100%'} />
        </ErrorBoundaryGuard>
        {globalProgress !== 100 && currentFile && (
          <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
            <SettingsIcon
              style={{
                animation: 'rotation 2s infinite linear',
                marginTop: 8,
                color: 'rgba(0, 0, 0, 0.6)',
              }}
            />
            <DialogContentText style={{ marginTop: 10 }}>{currentFile}</DialogContentText>
          </Stack>
        )}

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          style={{ marginTop: 10 }}
        >
          <DialogContentText component="span">
            {globalProgress !== 100 && remainingTime && (
              <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                <AccessTimeFilledIcon />
                <div>{remainingTime}</div>
              </Stack>
            )}
          </DialogContentText>
          <DialogContentText>
            {t('view.dialogs.compress.total') +
              ': ' +
              (zippedFiles.length !== 0
                ? globalProgress === 100
                  ? zippedFiles.length
                  : zippedFiles.length - 1
                : 0) +
              '/' +
              totalFiles}
          </DialogContentText>
        </Stack>
        <ErrorBoundaryGuard>
          <DataGrid
            className={classes.datagrid}
            density={'compact'}
            rows={rows}
            columns={columns}
            disableSelectionOnClick
            // style={{ border: 'none' }}
            sortModel={sortModel}
            onSortModelChange={function (newSortModel) {
              localStorage.setItem('DATAGRID_dropzone_sort', JSON.stringify(newSortModel))
              setSortModel(newSortModel)
            }}
          />
        </ErrorBoundaryGuard>
      </div>
      {/*<div*/}
      {/*  style={{*/}
      {/*    display: globalProgress === 0 ? 'block' : 'none',*/}
      {/*    position: 'absolute',*/}
      {/*    top: '36%',*/}
      {/*    // verticalAlign: 'middle',*/}
      {/*    // alignContent: 'center',*/}
      {/*    // textAlign: 'center',*/}
      {/*    width: '60%',*/}
      {/*    left: '15%',*/}
      {/*    // padding: 30,*/}
      {/*    // paddingTop: 40,*/}
      {/*    background: 'white',*/}
      {/*    boxShadow:*/}
      {/*      '0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)',*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <DialogTitle className={classes.dialogTitle}>*/}
      {/*    {I18n.t('view.dialogs.compress.upload_title')}*/}
      {/*  </DialogTitle>*/}
      {/*  <div*/}
      {/*    style={{*/}
      {/*      padding: 30,*/}
      {/*      paddingTop: 40,*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <Stack direction="column" justifyContent="center" alignItems="center" spacing={3}>*/}
      {/*      <Loading mode={'bubbles'} style={{ position: 'relative' }} />*/}
      {/*      <div>{t('view.dialogs.compress.uploading')}</div>*/}
      {/*    </Stack>*/}
      {/*    <Stack*/}
      {/*      direction="row"*/}
      {/*      justifyContent="left"*/}
      {/*      alignItems="center"*/}
      {/*      spacing={1}*/}
      {/*      style={{ marginTop: 20 }}*/}
      {/*    >*/}
      {/*      <UploadFileIcon />*/}
      {/*      <div>*/}
      {/*        {totalFiles}{' '}*/}
      {/*        {totalFiles > 1 ? t('view.dialogs.compress.files') : t('view.dialogs.compress.file')}{' '}*/}
      {/*        ({totalSize})*/}
      {/*      </div>*/}
      {/*    </Stack>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </Fragment>
  )
}
