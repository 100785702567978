import { CHAT_URL, FLASK_URL } from '../../utils/config'
import { fetchChangeSettings } from '../settings/actions'
import { t } from 'i18next'
import { generateErrorPart } from '../../view/common/Message'
import React from 'react'
import { Trans } from 'react-i18next'

export const GET_AI_MESSAGES = 'GET_AI_MESSAGES'
export const ADD_AI_MESSAGE = 'ADD_AI_MESSAGE'
export const ADD_USER_MESSAGE = 'ADD_USER_MESSAGE'
export const RECEIVE_AI_MESSAGE = 'RECEIVE_AI_MESSAGE'
export const RECEIVE_ASSISTANT_STATUS = 'RECEIVE_ASSISTANT_STATUS'
export const CLEAR_AI_MESSAGES = 'CLEAR_AI_MESSAGES'
export const TELL_ME_MORE = 'TELL_ME_MORE'
export const CMD_SYSTEM = 'CMD_SYSTEM'

export function fetchAssitantStatus() {
  const url = `${CHAT_URL()}/version`
  return async (dispatch) => {
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: 'GET',
        credentials: 'include',
      }
      fetch(url, requestOptions)
        .then((response) => {
          if (!response.ok) {
            const statusText = response.statusText
            const status = response.status
            const url = response.url
            return response.text().then((errorMessage) => {
              const error = new Error(`${statusText} : ${errorMessage}`)
              if (response.headers.get('content-type') === 'application/json') {
                error.stack = JSON.stringify(
                  JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                  null,
                  2,
                )
              } else {
                error.stack = new Error().stack
              }
              error.statusText = statusText
              error.status = status
              error.url = url
              throw error
            })
          }
          return response.text()
        })
        .then((data) => {
          dispatch(receiveAssistantStatus('online'))
          resolve('online')
        })
        .catch((error) => {
          let errorMsg = t('view.dialogs.assistant.errorMsg') + '\n' + generateErrorPart(error)
          if (error.status && error.statusText) {
            errorMsg =
              t('view.dialogs.assistant.errorMsg') +
              '\n' +
              (error.status ? t('view.dialogs.assistant.error') + ' ' + error.status + ':' : '') +
              (error.statusText ? error.statusText : '')
          }
          dispatch(receiveAssistantStatus('offline'))
          const authenticationError = error.status === 403
          if (authenticationError) {
            dispatch(fetchChangeSettings('loginPopup', true))
          }
          reject(errorMsg)
        })
    })
  }
}

export function receiveAssistantStatus(status) {
  return {
    type: RECEIVE_ASSISTANT_STATUS,
    status: status,
  }
}
export function fetchAddAIMessage(message) {
  return async (dispatch) => {
    return dispatch(addAIMessage(message))
  }
}
export function fetchAddUserMessage(message) {
  // return async (dispatch) => {
  //   return dispatch(addMessage(message))
  // }
  return async (dispatch, getState) => {
    const { global } = getState()
    const aiAssistantActivated =
      typeof global?.featuresConfig?.content?.ai_assistant === 'boolean' &&
      global?.featuresConfig?.content?.ai_assistant

    if (!aiAssistantActivated) {
      const error = new Error(`Assistant is not activated`)
      throw error
    }
    // Do not add user message is it's a "tell_me_more" message. We only see the response
    if (message.tell_me_more) {
      dispatch(tellMeMore())
    } else if (message.cmd_system) {
      dispatch(cmdSystem())
    } else {
      dispatch(addMessage(message))
    }
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          body: message,
        }),
      }
      fetch(`${FLASK_URL()}/chat`, requestOptions)
        .then((response) => {
          if (!response.ok) {
            const statusText = response.statusText
            const status = response.status
            const url = response.url
            return response.text().then((errorMessage) => {
              const error = new Error(`${statusText} : ${errorMessage}`)
              if (response.headers.get('content-type') === 'application/json') {
                error.stack = JSON.stringify(
                  JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                  null,
                  2,
                )
              } else {
                error.stack = new Error().stack
              }
              error.statusText = statusText
              error.status = status
              error.url = url
              throw error
            })
          }
          return response.json()
        })
        .then((data) => {
          let respMsg = {
            date: new Date(),
          }
          if (message.cmd_system) {
            respMsg = {
              ...respMsg,
              msg: data.ANS,
              cmd_system: true,
            }
          } else {
            respMsg = {
              ...respMsg,
              msg: data.ANS,
              is_assistant: true,
            }
            if (message.tell_me_more && message.img) {
              respMsg['img'] = message.img
            }
          }
          dispatch(receiveAIMessage(respMsg))
          resolve(data)
        })
        .catch((error) => {
          // const errorMsg = t('view.dialogs.assistant.errorMsg') + '\n' + generateErrorPart(error)
          const errorMsg =
            t('view.dialogs.assistant.errorMsg') +
            '\n' +
            (error.status || error.statusText
              ? error.status + ': ' + error.statusText
              : error.toString())
          dispatch(
            receiveAIMessage({
              date: new Date(),
              msg: errorMsg,
              is_assistant: true,
              error: true,
            }),
          )
          const authenticationError = error.status === 403
          if (authenticationError) {
            dispatch(fetchChangeSettings('loginPopup', true))
          }
          reject(error)
        })
    })
  }
}

export function tellMeMore() {
  return {
    type: TELL_ME_MORE,
  }
}

export function cmdSystem() {
  return {
    type: CMD_SYSTEM,
  }
}

export function addMessage(message) {
  return {
    type: ADD_USER_MESSAGE,
    message: message,
  }
}
export function addAIMessage(message) {
  return {
    type: ADD_AI_MESSAGE,
    message: message,
  }
}
export function fetchGetAIMessages() {
  return async (dispatch) => {
    return dispatch(getMessages())
  }
}
export function fetchClearMessages() {
  return async (dispatch) => {
    dispatch(
      fetchAddUserMessage({
        date: new Date(),
        msg: '/efface la conversation',
        cmd_system: true,
      }),
    )
    return dispatch(clearMessages())
  }
}
export function getMessages() {
  return {
    type: GET_AI_MESSAGES,
  }
}
export function clearMessages() {
  return {
    type: CLEAR_AI_MESSAGES,
  }
}

export function receiveAIMessage(msg) {
  return {
    type: RECEIVE_AI_MESSAGE,
    ai_message: msg,
  }
}
