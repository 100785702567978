import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import Dialog from '@mui/material/Dialog'
import { DialogTitle } from '@mui/material'
import { t } from 'i18next'
import Button from '@mui/material/Button'
import { generateUUID } from './common/Utils'

class ErrorBoundaryGuard extends React.Component {
  constructor(props) {
    super(props)
    this.state = { error: false, errorInfo: null, openDialog: false }
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
      openDialog: true,
    })
    // You can also log error messages to an error reporting service here
  }

  render() {
    const { t, style, isDialog, key } = this.props

    var me = this
    function onClipboardButtonClick() {
      const text =
        me.state.error && me.state.errorInfo && me.state.errorInfo.componentStack
          ? me.state.error.stack +
            me.state.errorInfo.componentStack
              .split('\n')
              .map((line) => '    ' + line)
              .join('\n')
          : ''
      if (!navigator.clipboard) {
        var textArea = document.createElement('textarea')
        textArea.value = text

        // Avoid scrolling to bottom
        textArea.style.top = '0'
        textArea.style.left = '0'
        textArea.style.position = 'fixed'

        document.body.appendChild(textArea)
        textArea.focus()
        textArea.select()

        try {
          const successful = document.execCommand('copy')
          const msg = successful
            ? t('view.errorBoundary.copy.succes')
            : t('view.errorBoundary.copy.unsucces')
          console.log(t('view.errorBoundary.copy.return_msg') + ' ' + msg)
        } catch (err) {
          console.error(t('view.errorBoundary.copy.error_msg'), err)
        }

        document.body.removeChild(textArea)
        return
      }
      navigator.clipboard.writeText(text).then(
        function () {
          console.log(t('view.errorBoundary.copy.async_succes_msg'))
        },
        function (err) {
          console.error(t('view.errorBoundary.copy.error_msg'), err)
        },
      )
    }

    function onHomeButtonClick() {
      me.props.history.push('/')
    }
    function onReloadButtonClick() {
      // const current = '/' + me.props.location.search
      // me.props.history.replace(`/reload`)
      // setTimeout(() => {
      //   me.props.history.replace(current)
      // })
      window.location.reload()
    }

    function handleCloseDialog() {
      me.setState({ openDialog: false })
    }

    if (this.state.errorInfo) {
      // Error path
      const errorInfoMsg = (
        <div
          key={key ? key : generateUUID()}
          style={{
            whiteSpace: 'pre-wrap',
            height: isDialog ? 'calc(100% - 36px)' : '100%',
            width: '100%',
            overflow: 'auto',
            padding: isDialog ? 20 : 5,
            paddingBottom: isDialog ? 0 : 5,
            userSelect: 'text',
            display: 'flex',
            flexDirection: 'column',
            background: '#fbf2f2',
            color: 'black',
            border: '5px solid ' + (isDialog ? '#fbf2f2' : '#ffe7e7'),
            ...style,
          }}
        >
          <h2 style={{ color: 'red', margin: '0px 0px 10px' }}>
            {t('view.errorBoundary.title')}
            <button
              title={t('view.errorBoundary.title_button.clipboard')}
              style={{
                border: 'none',
                background: 'none',
                cursor: 'pointer',
                color: 'red',
                fontSize: 18,
                marginLeft: 10,
                outline: 'none',
              }}
              type="button"
              onClick={onClipboardButtonClick}
            >
              &#128203;
            </button>
            {!isDialog && (
              <button
                title={t('view.errorBoundary.title_button.goHome')}
                style={{
                  border: 'none',
                  background: 'none',
                  cursor: 'pointer',
                  color: 'red',
                  fontSize: 18,
                  outline: 'none',
                }}
                type="button"
                onClick={onHomeButtonClick}
              >
                &#127968;
              </button>
            )}
            {!isDialog && (
              <button
                title={t('view.errorBoundary.title_button.reload')}
                style={{
                  border: 'none',
                  background: 'none',
                  cursor: 'pointer',
                  color: 'red',
                  fontSize: 18,
                  outline: 'none',
                }}
                type="button"
                onClick={onReloadButtonClick}
              >
                &#128260;
              </button>
            )}
          </h2>

          {this.state.error && this.state.error.toString()}
          <br />
          <details
            style={{
              whiteSpace: 'pre-wrap',
              overflow: 'auto',
              marginTop: 5,
              minHeight: 20,
            }}
          >
            <summary style={{ outline: 'none' }}>{t('view.errorBoundary.stacktrace')}:</summary>
            {this.state.error.stack}
            {this.state.errorInfo.componentStack
              .split('\n')
              .map((line) => '    ' + line)
              .join('\n')}
          </details>
        </div>
      )
      const errorDialog = (
        <Dialog
          onClose={handleCloseDialog}
          open={me.state.openDialog}
          key={key ? key : generateUUID()}
        >
          <span
            style={{ minWidth: 450, minHeight: 350, background: '#fbf2f2', display: 'contents' }}
          >
            {errorInfoMsg}
          </span>
          <Button
            style={{
              background: '#fbf2f2',
              color: 'black',
            }}
            size={'medium'}
            onClick={handleCloseDialog}
          >
            {t('view.errorBoundary.close')}
          </Button>
        </Dialog>
      )
      return isDialog ? errorDialog : errorInfoMsg
    }
    // Normally, just render children
    return this.props.children
  }
}
export default withRouter(connect()(withTranslation()(ErrorBoundaryGuard)))
