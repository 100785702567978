import React, { useRef } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core'

import ChromatoScatter from './ChromatoScatter'
import { alpha } from '@material-ui/core/styles'
import ErrorBoundaryGuard from '../../ErrorBoundaryGuard'

const useStyles = makeStyles((theme) => ({
  chromatoGrid: {
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.04)',
      '& button': {
        opacity: 1,
      },
    },
    // '&:hover span.fullscreenMode': {
    //   opacity: '0.5 !important',
    //   transition: '0.1s',
    // },
    // '&:hover span.fullscreenMode:hover': {
    //   opacity: '1 !important',
    //   transition: '0.1s',
    // },
  },
  blink: {
    // animation: 'blink-fade 1s ease-in-out 0s',
    background: alpha(theme.palette.secondary.light, 0.19),
    '&:hover': {
      background: alpha(theme.palette.secondary.light, 0.37),
    },
  },
  noBlink: {
    cursor: 'pointer',
  },
}))

export default function ChromatoGraph({
  bypassLockChromatoEdition,
  getSelectedChannel,
  onChangeChannel,
  unitaryAnalyse,
  style,
  selected,
  chromatoSize,
  dispatch,
  uidKey,
  onClick,
  onDoubleClick,
  title,
  collection,
  isIstd,
  checkSelected,
  onSelect,
  index,
  selection,
  canEdit,
  aiAssistantActivated,
}) {
  const classes = useStyles()
  const clickTimeout = useRef(null)
  const isDoubleClick = useRef(false)

  return (
    <div
      style={style}
      key={uidKey}
      className={clsx(
        'unitaryAnalyse',
        classes.chromatoGrid,
        selected ? classes.blink : classes.noBlink,
      )}
      event={unitaryAnalyse.event}
      unitaryanalysesid={unitaryAnalyse._id}
      onClick={(event) => {
        // Do not trigger the clic if it's just a selection for the checkbox
        if (event.target.type === 'checkbox') return
        // Delai to avoid in case of double clic
        clickTimeout.current = setTimeout(() => {
          if (!isDoubleClick.current) {
            onClick(unitaryAnalyse)
            clickTimeout.current = null
          }
          isDoubleClick.current = false
        }, 250)
      }}
      onDoubleClick={(event) => {
        if (event.target.type === 'checkbox') return
        // Cancel the simple clic in case of double clic
        isDoubleClick.current = true
        // Cancel timeout for the simple clic
        clearTimeout(clickTimeout.current)
        onDoubleClick(event)
      }}
    >
      <ErrorBoundaryGuard>
        <ChromatoScatter
          aiAssistantActivated={aiAssistantActivated}
          bypassLockChromatoEdition={bypassLockChromatoEdition}
          isSelected={selected}
          canEdit={canEdit}
          index={index}
          checkSelected={checkSelected}
          selection={selection}
          onSelect={onSelect}
          updateCalibrationAfterPeakUpdate={onClick}
          getSelectedChannel={getSelectedChannel}
          onChangeChannel={onChangeChannel}
          keyValue={`scatter_${uidKey}`}
          unitaryAnalyse={unitaryAnalyse}
          dispatch={dispatch}
          collection={collection}
          title={title(unitaryAnalyse)}
          chromatoSize={chromatoSize}
          showChannels={true}
          currentPeaks={
            unitaryAnalyse.mainChannel === unitaryAnalyse.selectedChannel
              ? unitaryAnalyse.main_peak_base &&
                unitaryAnalyse.main_peak_base.left &&
                unitaryAnalyse.main_peak_base.right
                ? [unitaryAnalyse.main_peak_base.left, unitaryAnalyse.main_peak_base.right]
                : []
              : unitaryAnalyse.channels_data.filter(
                  (ch) => ch.channelNumber === unitaryAnalyse.selectedChannel,
                ).length !== 0 &&
                unitaryAnalyse.channels_data.filter(
                  (ch) => ch.channelNumber === unitaryAnalyse.selectedChannel,
                )[0].peak &&
                unitaryAnalyse.channels_data.filter(
                  (ch) => ch.channelNumber === unitaryAnalyse.selectedChannel,
                )[0].peak.base
              ? [
                  unitaryAnalyse.channels_data.filter(
                    (ch) => ch.channelNumber === unitaryAnalyse.selectedChannel,
                  )[0].peak.base.left,
                  unitaryAnalyse.channels_data.filter(
                    (ch) => ch.channelNumber === unitaryAnalyse.selectedChannel,
                  )[0].peak.base.right,
                ]
              : []
          }
        />
      </ErrorBoundaryGuard>
    </div>
  )
}
