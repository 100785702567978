import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { StyledDialogTitle, TransitionTop } from '../common/Style'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import { useDispatch, useSelector } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import { Checkbox, FormLabel, makeStyles, Radio, RadioGroup, TextField } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import {
  CONFIGURATION_PROFILE,
  DATABASE,
  FLASK_URL,
  PUBLIC_URL,
  RELEASE_VERSION_PATH,
  SERVICE,
} from '../../utils/config'
import { displayPopupMessage } from '../common/Message'
import FaceTwoToneIcon from '@material-ui/icons/FaceTwoTone'
import DnsTwoToneIcon from '@material-ui/icons/DnsTwoTone'
import SettingsTwoToneIcon from '@material-ui/icons/SettingsTwoTone'
import ArchiveTwoToneIcon from '@material-ui/icons/ArchiveTwoTone'
import Tooltip from '@material-ui/core/Tooltip'
import HelpTwoToneIcon from '@material-ui/icons/HelpTwoTone'
import KoIcon from '@material-ui/icons/Clear'
import OkIcon from '@material-ui/icons/Done'
import Chip from '@material-ui/core/Chip'
import Typography from '@material-ui/core/Typography'
import { fetchChangeSettings } from '../../redux/settings/actions'
import Box from '@material-ui/core/Box'
import CardMedia from '@material-ui/core/CardMedia'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { FormControlLabel } from '@mui/material'
import { canIModifyTheCalibration, changeAnalysesType, formulas_dico } from '../common/Utils'
import { t } from 'i18next'
import DialogContentText from '@material-ui/core/DialogContentText'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import { withRouter } from 'react-router-dom'
import { getBatchById } from '../../redux/batches/selectors'

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    '& legend': {
      width: 0,
    },
  },
  tableRow: {
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
    '& .MuiTableCell-sizeSmall': {
      height: 60,
      padding: '5px 7px',
    },
  },
  container: {
    '& .MuiOutlinedInput-input': {
      padding: 10,
    },
    '& .MuiSelect-outlined.MuiSelect-outlined': {
      paddingRight: 32,
    },
  },
  moveChip: {
    margin: 3,
    backgroundColor: theme.palette.secondary.main + ' !important',
    color: 'white !important',
  },
  headerTitle: {
    color: theme.palette.primary.main,
  },
  exportCheckbox: {
    marginTop: 20,
  },
}))

const MoveDialog = withRouter((props) => {
  const { open, closeFct, selection, origin } = props
  const dispatch = useDispatch()
  const classes = useStyles()
  const batch = useSelector((state) => getBatchById(state.batches, props.match.params.batchId))

  const featuresConfig = useSelector((state) => state.global.featuresConfig)
  const canEditCalib = useMemo(() => {
    if (featuresConfig) {
      const edit_calibration = featuresConfig?.content?.edit_calibration
      const param_exist = typeof edit_calibration === 'boolean'
      const getRights = canIModifyTheCalibration(batch)
      return param_exist ? edit_calibration && getRights : true
    } else {
      return true
    }
  }, [featuresConfig])

  const [moveQuality, setMoveQuality] = useState({})
  const [recomputeCalibrationCheckbox, setRecomputeCalibrationCheckbox] = useState(false)

  const configSelected = useSelector(function (state) {
    return state.configuration.configurations.filter(
      (config) => config._id === CONFIGURATION_PROFILE(),
    )[0]
  })

  useEffect(
    function () {
      if (open) {
        setMoveQuality(
          selection.reduce(function (map, obj) {
            map[obj._id] = {
              value: obj.name.match(/^std ([0-9]*).*$/i)
                ? obj.name.match(/^std ([0-9]*).*$/i)[1]
                : obj.name.match(/^([0-9]{6}).*$/i)
                ? obj.name.match(/^([0-9]{6}).*$/i)[1]
                : undefined,
              type: obj.name.match(/^([0-9]{6}).*$/i) ? 'sample' : 'calibration',
            }
            if (!obj.name.match(/^([0-9]{6}).*$/i)) {
              map[obj._id]['suffix'] = 'default'
            }
            return map
          }, {}),
        )
      }
    },
    [open],
  )

  function handleClose(event, confirm) {
    closeFct(event, typeof confirm === 'boolean' && confirm)
    if (typeof confirm === 'boolean' && confirm) {
      const result = JSON.parse(JSON.stringify(selection)).map(function (select) {
        select.content['value'] = moveQuality[select._id].value
        select.content['type'] = moveQuality[select._id].type
        if (moveQuality[select._id].type === 'calibration') {
          select.content['suffix'] = moveQuality[select._id].suffix
        }
        return select
      })
      changeAnalysesType(
        dispatch,
        props.match.params.batchId,
        t('view.batch.othersTable.' + origin),
        result,
        !Object.keys(moveQuality).every(
          (id) =>
            !(
              moveQuality[id].type === 'calibration' &&
              (moveQuality[id].suffix === 'default' || !moveQuality[id].hasOwnProperty('suffix'))
            ),
        )
          ? recomputeCalibrationCheckbox
          : false,
      )
    }
  }

  function handleChangeType(event, row) {
    setMoveQuality({
      ...moveQuality,
      [row._id]: {
        value: moveQuality[row._id].value
          ? moveQuality[row._id].value
          : event.target.value === 'matrix_bio_doped'
          ? 1
          : moveQuality[row._id].type === 'std_end'
          ? 1
          : '',
        type: event.target.value,
      },
    })
  }

  function handleChangeConcentration(event, row) {
    setMoveQuality({
      ...moveQuality,
      [row._id]: { ...moveQuality[row._id], value: event.target.value },
    })
  }

  function handleChangeSuffix(event, row) {
    setMoveQuality({
      ...moveQuality,
      [row._id]: { ...moveQuality[row._id], suffix: event.target.value },
    })
  }

  return (
    <Dialog
      maxWidth={'md'}
      open={open}
      onClose={handleClose}
      TransitionComponent={TransitionTop}
      aria-labelledby="draggable-dialog-title"
    >
      <StyledDialogTitle>{t('view.batch.othersTable.move_outside_' + origin)}</StyledDialogTitle>
      <DialogContent>
        <DialogContentText style={{ marginBottom: 5 }}>
          {t('view.batch.othersTable.sure_move_outside')}
        </DialogContentText>
        <DialogContentText>{t('view.batch.othersTable.will_be_moved')}</DialogContentText>
        <TableContainer className={classes.container}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell
                  key="name"
                  style={{
                    minWidth: 100,
                    backgroundColor: 'white',
                    borderWidth: 0,
                  }}
                ></TableCell>
                <TableCell
                  key="separator"
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 0,
                  }}
                ></TableCell>
                <TableCell
                  key="concentration"
                  className={classes.headerTitle}
                  style={{
                    minWidth: 100,
                    textAlign: 'center',
                    // borderColor: '#165c90',
                    borderWidth: 0,
                    backgroundColor: 'white',
                  }}
                >
                  {/*Concentration*/}
                </TableCell>
                <TableCell
                  key="type"
                  className={classes.headerTitle}
                  style={{
                    minWidth: 100,
                    textAlign: 'center',
                    borderWidth: 0,
                    backgroundColor: 'white',
                  }}
                >
                  {/*Type*/}
                </TableCell>
                <TableCell
                  key="suffix"
                  className={classes.headerTitle}
                  style={{
                    minWidth: 100,
                    textAlign: 'center',
                    borderWidth: 0,
                    backgroundColor: 'white',
                  }}
                >
                  {/*Type*/}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selection.map((row, index) => (
                <TableRow hover className={classes.tableRow} key={row._id + '_' + index}>
                  <TableCell style={{ border: 'none', whiteSpace: 'nowrap', fontWeight: 500 }}>
                    {/*{row.name}*/}
                    <Chip
                      // variant="outlined"
                      // size="small"
                      className={classes.moveChip}
                      label={row.name}
                    />
                  </TableCell>
                  <TableCell style={{ border: 'none', whiteSpace: 'nowrap', fontWeight: 500 }}>
                    :
                  </TableCell>

                  <TableCell style={{ border: 'none' }}>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <InputLabel
                        style={{ padding: '0px 5px', backgroundColor: 'white' }}
                        id="demo-simple-select-outlined-label"
                      >
                        Type
                      </InputLabel>
                      <Select
                        error={
                          !canEditCalib &&
                          moveQuality[row._id] &&
                          moveQuality[row._id].type === 'calibration'
                        }
                        value={
                          moveQuality[row._id]
                            ? moveQuality[row._id].type
                            : !canEditCalib
                            ? 'other'
                            : 'calibration'
                        }
                        onChange={(event) => handleChangeType(event, row)}
                        label={t('view.batch.othersTable.type')}
                      >
                        <MenuItem value={'blank'}>{t('view.batch.othersTable.blank')}</MenuItem>
                        <MenuItem disabled={!canEditCalib} value={'calibration'}>
                          {t('view.batch.othersTable.calibration')}
                        </MenuItem>
                        <MenuItem value={'matrix_bio'}>
                          {t('view.batch.othersTable.matrix')}
                        </MenuItem>
                        <MenuItem value={'matrix_bio_doped'}>
                          {t('view.batch.othersTable.matrix_doped')}
                        </MenuItem>
                        <MenuItem value={'other'}>{t('view.batch.othersTable.other')}</MenuItem>
                        <MenuItem value={'sample'}>{t('view.batch.othersTable.sample')}</MenuItem>
                        <MenuItem value={'std_end'}>{t('view.batch.othersTable.std_end')}</MenuItem>
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell style={{ border: 'none' }}>
                    {moveQuality[row._id] &&
                      moveQuality[row._id].type !== 'matrix_bio' &&
                      moveQuality[row._id].type !== 'other' &&
                      moveQuality[row._id].type !== 'blank' && (
                        <TextField
                          type={
                            moveQuality[row._id] && moveQuality[row._id].type === 'sample'
                              ? 'text'
                              : 'number'
                          }
                          label={
                            moveQuality[row._id] && moveQuality[row._id].type === 'sample'
                              ? t('view.batch.othersTable.num')
                              : t('view.batch.othersTable.level')
                          }
                          required={
                            moveQuality[row._id] &&
                            (moveQuality[row._id].type === 'sample' ||
                              moveQuality[row._id].type === 'calibration')
                              ? true
                              : false
                          }
                          error={
                            moveQuality[row._id] &&
                            (moveQuality[row._id].type === 'sample' ||
                              moveQuality[row._id].type === 'calibration')
                              ? moveQuality[row._id].value === undefined ||
                                moveQuality[row._id].value === ''
                              : false
                          }
                          placeholder={
                            moveQuality[row._id] && moveQuality[row._id].type === 'matrix_bio_doped'
                              ? '1'
                              : moveQuality[row._id] && moveQuality[row._id].type === 'std_end'
                              ? '1'
                              : row.name.match(/^std ([0-9]*).*$/i)
                              ? row.name.match(/^std ([0-9]*).*$/i)[1] || ''
                              : moveQuality[row._id] &&
                                moveQuality[row._id].type === 'sample' &&
                                row.name.match(/^([0-9]{6}).*$/i)
                              ? row.name.match(/^([0-9]{6}).*$/i)[1] || ''
                              : (moveQuality[row._id] && moveQuality[row._id].value) || ''
                          }
                          inputProps={{ min: 0 }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                          onChange={(event) => handleChangeConcentration(event, row)}
                          // defaultValue={
                          //   moveQuality[row._id] &&
                          //   moveQuality[row._id].type === 'matrix_bio_doped'
                          //     ? 100
                          //     : moveQuality[row._id] &&
                          //       moveQuality[row._id].type === 'std_end'
                          //     ? 10
                          //     : row.name.match(/^std ([0-9]*) .*$/i)
                          //     ? row.name.match(/^std ([0-9]*) .*$/i)[1]
                          //     : ''
                          // }
                        />
                      )}
                  </TableCell>
                  <TableCell style={{ border: 'none' }}>
                    {moveQuality[row._id] && moveQuality[row._id].type === 'calibration' && (
                      <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel
                          style={{ padding: '0px 5px', backgroundColor: 'white' }}
                          id="suffix-simple-select-outlined-label"
                        >
                          {t('view.batch.othersTable.suffix')}
                        </InputLabel>
                        <Select
                          defaultValue={'default'}
                          onChange={(event) => handleChangeSuffix(event, row)}
                          label={t('view.batch.othersTable.default')}
                        >
                          <MenuItem key={'default'} value={'default'}>
                            {t('view.batch.othersTable.default')}
                          </MenuItem>
                          {configSelected &&
                            configSelected.content.calibration_suffixes &&
                            Object.entries(configSelected.content.calibration_suffixes).map(
                              (config, idx) => (
                                <MenuItem key={idx} value={config[0]}>
                                  {config[1].name}
                                </MenuItem>
                              ),
                            )}
                        </Select>
                      </FormControl>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {!Object.keys(moveQuality).every(
          (id) =>
            !(
              moveQuality[id].type === 'calibration' &&
              (moveQuality[id].suffix === 'default' || !moveQuality[id].hasOwnProperty('suffix'))
            ),
        ) && (
          <FormControlLabel
            className={classes.exportCheckbox}
            control={
              <Checkbox
                checked={recomputeCalibrationCheckbox}
                onChange={(event) => setRecomputeCalibrationCheckbox(event.target.checked)}
                name="checkedCalibration"
              />
            }
            label={t('view.batch.othersTable.recompute_calib')}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={(event) => handleClose(event, true)}
          color="primary"
          disabled={
            !Object.entries(moveQuality).every(
              // (item) => item[1].value !== undefined && item[1].value !== '',
              function (item) {
                if (!canEditCalib && item[1].type === 'calibration') {
                  return false
                } else if (item[1].type === 'sample' || item[1].type === 'calibration') {
                  return item[1].value !== undefined && item[1].value !== ''
                } else {
                  return true
                }
              },
            )
          }
        >
          {t('view.batch.othersTable.move')}
        </Button>
        <Button onClick={closeFct} color="primary">
          {t('view.batch.othersTable.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  )
})
export default MoveDialog
