import React, { Fragment } from 'react'
import Paper from '@material-ui/core/Paper'
import { connect } from 'react-redux'
import { alpha, withStyles } from '@material-ui/core/styles'
import Title from '../Title'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import TableMultiGrid from '../common/Table_MultiGrid'
import clsx from 'clsx'
import {
  getComparator,
  stableSort,
  getClickAction,
  canIModifyTheCalibration,
} from '../common/Utils'
import { TransitionTop } from '../common/Style'
import AlphacodFlagIcon from '../common/AlphacodFlagIcon'
import LinearProgress from '@material-ui/core/LinearProgress'
import { withRouter } from 'react-router-dom'
import Tooltip from '@material-ui/core/Tooltip'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import SwapHorizIcon from '@material-ui/icons/SwapHoriz'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions/DialogActions'
import Button from '@material-ui/core/Button/Button'
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined'
import ThumbDownAltOutlinedIcon from '@material-ui/icons/ThumbDownAltOutlined'
import AlphacodModifiedButtonIcon from '../common/AlphacodModifiedButtonIcon'
import NoteButton from '../common/NoteButton'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import ErrorBoundaryGuard from '../ErrorBoundaryGuard'
import { t } from 'i18next'
import MoveDialog from './MoveDialog'
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline'
import { Stack } from '@mui/material'
import { fetchChangeSettings } from '../../redux/settings/actions'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import { displayPopupMessage, generateErrorPart } from '../common/Message'
import BlockIcon from '@material-ui/icons/Block'
import {
  fetchAddAnalysisVisibilityTag,
  fetchDisableQC,
  fetchRemoveAnalysisVisibilityTag,
} from '../../redux/analyses/actions'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { Trans } from 'react-i18next'

const styles = (theme) => ({
  paper: {
    // boxShadow:
    //   '0px 2px 7px 1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    padding: theme.spacing(2),
    height: '100%',
    overflow: 'hidden',
  },
  tableContainer: {
    height: 'calc(100% - 35px)',
    overflow: 'hidden',
    '& .ReactVirtualized__Grid:focus': {
      outline: 'none',
    },
  },
  selected: {
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  selectedRow: {
    cursor: 'pointer',
    background: 'rgba(0, 0, 0, 0.1)',
    '& .nameAdorment': {
      display: 'flex !important',
    },
  },
  blink: {
    background: alpha(theme.palette.secondary.light, 0.19),
  },
  selectedRowBlink: {
    background: alpha(theme.palette.secondary.light, 0.37),
  },
  noBlink: {
    cursor: 'pointer',
  },
  cellFlags: {
    padding: 7,
  },
  tooltip: {
    fontSize: 13,
    padding: 5,
  },
  toolbarButton: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.03),
    marginRight: 5,
    width: 34,
    height: 34,
    padding: 8,
    '&:hover': {
      color: theme.palette.secondary.main,
      backgroundColor: alpha(theme.palette.common.black, 0.03),
    },
  },
  toolbar: {
    position: 'absolute',
    top: 48,
    left: 70,
    background: '#fff',
    padding: 5,
    paddingRight: 1,
    border: '2px solid',
    borderColor: theme.palette.secondary.main,
    borderRadius: 8,
  },
  disabled: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
  visibilityButton: {
    padding: 0,
    '&:hover': {
      color: theme.palette.secondary.main,
      backgroundColor: 'transparent',
    },
  },
  visibilityDisabled: {
    opacity: 0.5,
  },
})

const mapStateToProps = (state) => {
  const { analyses, global } = state
  return {
    analysis: analyses.items,
    isFetching: analyses.isFetching,
    analysesSentToLims: analyses.analysesSentToLims,
    analysesNotSentToLims: analyses.analysesNotSentToLims,
    featuresConfig: global.featuresConfig,
  }
}

const BorderLinearProgress = withStyles({
  root: {
    top: 5,
    height: 10,
    borderRadius: 10,
  },
  bar: {
    borderRadius: 10,
  },
})(LinearProgress)

const CustomDialog = withStyles((theme) => ({
  paper: {
    height: '100%',
  },
}))(Dialog)

export default withRouter(
  connect(mapStateToProps)(
    withStyles(styles)(
      class QualityControlTable extends React.Component {
        constructor(props) {
          super(props)
          this.state = {
            error: null,
            selection: [],
            openBatchMove: false,
            direction: 'asc',
            order: 'name',
            openExtentedView: false,
            recomputeCalibrationCheckbox: false,
          }
          this.tableMultiGridRef = React.createRef()
          this.onSelect = this.onSelect.bind(this)
          this.multiValidate = this.multiValidate.bind(this)
        }

        canEditCalib() {
          const { batch, featuresConfig } = this.props
          const edit_calibration = featuresConfig?.content?.edit_calibration
          const param_exist = typeof edit_calibration === 'boolean'
          const getRights = canIModifyTheCalibration(batch)
          return param_exist ? edit_calibration && getRights : true
        }

        onSelect(selected) {
          if (!this.canEditCalib()) {
            const updatedSelection = selected.filter(
              (s) => this.getEchClient()[s].content.type !== 'calibration',
            )
            if (updatedSelection.length !== selected.length) {
              selected = updatedSelection
              this.tableMultiGridRef.current.customSelection(updatedSelection)
              displayPopupMessage(
                this.props.dispatch,
                'info',
                t('view.batch.qualityControlTable.cantSelect.title'),
                t('view.batch.qualityControlTable.cantSelect.msg'),
              )
            }
          }
          this.setState({
            selection: selected.map((i) => this.getEchClient()[i]),
          })
        }

        multiValidate(value) {
          this.setState({ openBatchMove: true })
        }

        onChangeVisibility(event, item) {
          const { dispatch } = this.props
          event.stopPropagation()
          event.preventDefault()

          const displayMessage = (mode, error) => {
            displayPopupMessage(
              dispatch,
              error ? 'error' : 'success',
              t('view.batch.othersTable.visibility_title'),
              <Fragment>
                {error ? (
                  <Fragment>
                    <div>
                      <Trans i18nKey="view.batch.othersTable.visibility_msg1">
                        The visibility for the analysis <i>{{ val: item.name }}</i> can't be
                        updated:
                      </Trans>
                    </div>
                    {generateErrorPart(error)}
                  </Fragment>
                ) : (
                  <div>
                    <Trans i18nKey="view.batch.othersTable.visibility_msg2">
                      The analysis <i>{{ val1: item.name }}</i> is now
                      {{
                        val2:
                          mode === 'add'
                            ? t('view.batch.othersTable.visible')
                            : t('view.batch.othersTable.hidden'),
                      }}{' '}
                      in the navigation panel.
                    </Trans>
                  </div>
                )}
              </Fragment>,
            )
          }
          // Add or Remove tag off_visibility on analysis object
          if (item.visibility) {
            dispatch(fetchRemoveAnalysisVisibilityTag(item))
              .then(function (resp) {
                displayMessage('remove')
              })
              .catch(function (error) {
                displayMessage('remove', error)
              })
          } else {
            dispatch(fetchAddAnalysisVisibilityTag(item))
              .then(function (resp) {
                if (resp.error) {
                  displayMessage('add', resp.error)
                } else {
                  displayMessage('add')
                }
              })
              .catch(function (error) {
                displayMessage('add', error)
              })
          }
        }

        getEchClient() {
          const { analysis, analysesSentToLims, analysesNotSentToLims } = this.props
          const { direction, order } = this.state
          const analysisFiltered = analysis
            .filter(
              (analyse) =>
                (analyse.content.type === 'blank' ||
                  analyse.content.type === 'matrix_bio' ||
                  analyse.content.type === 'matrix_bio_doped' ||
                  analyse.content.type === 'std_end' ||
                  analyse.content.type === 'calibration') &&
                !analyse.content.inProgress,
            )
            .map((ech) => ({
              ...ech,
              level: ech.content.level,
              disabled: ech.content.disabled,
              type: ech.content.type,
              nb_ok: ech.content.nb_ok,
              nb_ko: ech.content.nb_ko,
              suffix: ech.content.suffix,
              sentToLims:
                analysesSentToLims && analysesSentToLims.indexOf(ech._id) !== -1
                  ? true
                  : analysesNotSentToLims && analysesNotSentToLims.indexOf(ech._id) !== -1
                  ? false
                  : null,
              visibility: !(ech.tags && ech.tags.toString().indexOf('off_visibility') !== -1),
            }))
          return stableSort(analysisFiltered, getComparator(direction, order))
        }

        render() {
          const {
            classes,
            batch,
            dispatch,
            tableItemSelectedForColor,
            isFetching,
            featuresConfig,
          } = this.props
          const { selection, openBatchMove, openExtentedView } = this.state

          const tableMultiGridRef = this.tableMultiGridRef
          const me = this

          const disabled = (selection) => {
            const { dispatch } = this.props
            selection.forEach((data) => dispatch(fetchDisableQC(data)))
            tableMultiGridRef.current.clearSelection()
          }

          const onDisplayClient = (id) => {
            localStorage.setItem('BATCH_previous_selection', id)
            this.props.history.push('/?' + this.props.match.params.batchId + '/analysis/' + id)
          }

          const selectionEmpty = this.state.selection.length === 0

          const handleCloseBatchMove = (event, save) => {
            const tableMultiGridRef = this.tableMultiGridRef
            const me = this
            me.setState({ openBatchMove: false })
            if (typeof save === 'boolean' && save) {
              tableMultiGridRef.current.clearSelection()
            }
          }

          const echClient = this.getEchClient()

          const handleOpenRenameDialog = (event, row) => {
            event.stopPropagation()
            event.preventDefault()
            dispatch(fetchChangeSettings('analysisToRename', row))
          }

          const getRenderCmp = (fullscreenMode) => {
            return (
              <Fragment>
                <ErrorBoundaryGuard isDialog>
                  <MoveDialog
                    selection={selection}
                    open={openBatchMove}
                    closeFct={handleCloseBatchMove}
                    origin={'qc'}
                  />
                </ErrorBoundaryGuard>
                <Paper className={classes.paper} elevation={0}>
                  <Title>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      {/*<Grid item>*/}
                      <Box>{t('view.batch.qualityControlTable.qc')}</Box>
                      {!fullscreenMode && (
                        <Tooltip
                          arrow
                          placement="top"
                          title={
                            <div className={classes.tooltip}>
                              {t('view.batch.qualityControlTable.full_screen')}
                            </div>
                          }
                        >
                          <IconButton
                            size="small"
                            aria-label="extend"
                            onClick={() => {
                              this.setState({ openExtentedView: true })
                            }}
                          >
                            <OpenInNewIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      {/*</Grid>*/}
                    </Grid>
                  </Title>
                  <div className={classes.tableContainer}>
                    <ErrorBoundaryGuard>
                      <TableMultiGrid
                        ref={this.tableMultiGridRef}
                        collection={'analysis'}
                        onMoleculeClick={(item) => onDisplayClient(item._id)}
                        onMoleculeDoubleClick={() => true}
                        idTable={'myQualityControlTable'}
                        tableItemSelectedForColor={tableItemSelectedForColor}
                        orderBy={'name'}
                        selection
                        onSelect={this.onSelect}
                        data={echClient}
                        isFetchingData={isFetching}
                        sortAction={function (order, direction) {
                          tableMultiGridRef.current.clearSelection()
                          me.setState({
                            order: order,
                            direction: direction,
                          })
                        }}
                        columns={[
                          {
                            key: 'visibility',
                            label: t('view.batch.othersTable.visibility'),
                            size: 70,
                          },
                          {
                            key: 'flags',
                            label: t('view.batch.qualityControlTable.column.flags'),
                            size: 70,
                          },
                          // {
                          //   key: 'disabled',
                          //   label: t('view.batch.qualityControlTable.column.disabled'),
                          //   size: 70,
                          //   customDisplay: function (value) {
                          //     return value ? <BlockIcon style={{ color: '#0000008a' }} /> : null
                          //   },
                          // },
                          {
                            key: 'sentToLims',
                            label: t('view.batch.samplesTable.column.reported'),
                            size: 70,
                            customDisplay: function (value) {
                              return value ? (
                                <CheckIcon style={{ color: '#0000008a' }} />
                              ) : value === false ? (
                                <CloseIcon style={{ color: '#0000008a' }} />
                              ) : null
                            },
                            customTitle: function () {
                              return (
                                <Tooltip
                                  title={
                                    <div className={classes.tooltip}>
                                      {t('view.batch.samplesTable.column.reported_lims')}
                                    </div>
                                  }
                                >
                                  <span>{t('view.batch.samplesTable.column.reported')}</span>
                                </Tooltip>
                              )
                            },
                          },
                          {
                            key: 'notes',
                            label: t('view.batch.qualityControlTable.column.notes'),
                            size: 60,
                          },
                          // {
                          //   key: 'modified',
                          //   label: 'Modified',
                          //   size: 75,
                          // },
                          {
                            key: 'name',
                            label: t('view.batch.qualityControlTable.column.name'),
                            size: 150,
                          },
                          {
                            key: 'level',
                            label: t('view.batch.qualityControlTable.column.level'),
                            size: 70,
                          },
                          {
                            key: 'type',
                            label: t('view.batch.qualityControlTable.column.type'),
                            size: 100,
                          },
                          {
                            key: 'nb_ok',
                            label: t('view.batch.qualityControlTable.column.ok'),
                            size: 50,
                            textAlign: 'start',
                            customTitle: function () {
                              return (
                                <Tooltip
                                  title={
                                    <div className={classes.tooltip}>
                                      {t('view.batch.qualityControlTable.column.nbr_ok')}
                                    </div>
                                  }
                                >
                                  <ThumbUpAltOutlinedIcon style={{ opacity: 0.8 }} />
                                </Tooltip>
                              )
                            },
                          },
                          {
                            key: 'nb_ko',
                            label: t('view.batch.qualityControlTable.column.ko'),
                            size: 50,
                            textAlign: 'start',
                            customTitle: function () {
                              return (
                                <Tooltip
                                  title={
                                    <div className={classes.tooltip}>
                                      {t('view.batch.qualityControlTable.column.nbr_ko')}
                                    </div>
                                  }
                                >
                                  <ThumbDownAltOutlinedIcon style={{ opacity: 0.8 }} />
                                </Tooltip>
                              )
                            },
                          },
                          {
                            key: 'suffix',
                            label: t('view.batch.qualityControlTable.column.suffix'),
                            size: 100,
                          },
                          {
                            key: 'progression',
                            label: t('view.batch.qualityControlTable.column.progress'),
                            size: 150,
                          },
                        ]}
                        fixedRowCount={1}
                        rowHeight={40}
                        getColumnRender={function (
                          columnIndex,
                          key,
                          rowIndex,
                          style,
                          item,
                          rowClassNames,
                          columnKey,
                          moleculeOverState,
                          moleculeOver,
                          selected,
                        ) {
                          if (columnKey === 'visibility') {
                            return (
                              <div
                                style={style}
                                key={key}
                                className={clsx(
                                  item.visibility ? null : classes.visibilityDisabled,
                                  item.disabled ? classes.disabled : null,
                                  rowClassNames,
                                  moleculeOverState === rowIndex && classes.selectedRow,
                                  selected === item._id ? classes.blink : classes.noBlink,
                                  moleculeOverState === rowIndex &&
                                    selected === item._id &&
                                    classes.selectedRowBlink,
                                )}
                                onMouseEnter={() =>
                                  moleculeOver(rowIndex, columnIndex, true, item._id)
                                }
                                onMouseLeave={() => moleculeOver(rowIndex, columnIndex, false)}
                                // onClick={() => onMoleculeClick(item)}
                                onClick={function (event) {
                                  const openFct = () => {
                                    onDisplayClient(item._id)
                                  }
                                  getClickAction(dispatch, event, item, 'analysis', openFct)
                                }}
                              >
                                <Tooltip
                                  arrow
                                  title={
                                    <div className={classes.tooltip}>
                                      {t('view.batch.othersTable.show_hide')}
                                    </div>
                                  }
                                >
                                  <IconButton
                                    aria-label="changeVisibility"
                                    className={classes.visibilityButton}
                                    onClick={(event) => me.onChangeVisibility(event, item)}
                                  >
                                    {!item.visibility ? (
                                      <VisibilityOffIcon
                                        // style={{ color: '#AF0000' }}
                                        className={'visibilityOffIcon'}
                                      />
                                    ) : (
                                      <VisibilityIcon className={'visibilityIcon'} />
                                    )}
                                  </IconButton>
                                </Tooltip>
                              </div>
                            )
                          }
                          if (columnKey === 'name') {
                            return (
                              <Tooltip
                                key={key}
                                arrow
                                title={<div className={classes.tooltip}>{item.content.file}</div>}
                              >
                                <div
                                  style={style}
                                  className={clsx(
                                    item.disabled || !item.visibility ? classes.disabled : null,
                                    rowClassNames,
                                    moleculeOverState === rowIndex && classes.selectedRow,
                                    selected === item._id ? classes.blink : classes.noBlink,
                                    moleculeOverState === rowIndex &&
                                      selected === item._id &&
                                      classes.selectedRowBlink,
                                  )}
                                  onMouseEnter={() =>
                                    moleculeOver(rowIndex, columnIndex, true, item._id)
                                  }
                                  onMouseLeave={() => moleculeOver(rowIndex, columnIndex, false)}
                                  onClick={function (event) {
                                    const openFct = () => {
                                      onDisplayClient(item._id)
                                    }
                                    getClickAction(dispatch, event, item, 'analysis', openFct)
                                  }}
                                >
                                  <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    spacing={0}
                                  >
                                    <div>{item.name}</div>
                                    {((item?.content?.type === 'calibration' &&
                                      me.canEditCalib()) ||
                                      item?.content?.type !== 'calibration') && (
                                      <div
                                        style={{ display: 'none', height: 20 }}
                                        className={'nameAdorment'}
                                      >
                                        <IconButton
                                          style={{ padding: 0 }}
                                          onClick={(event) => handleOpenRenameDialog(event, item)}
                                          onMouseDown={(event) => event.preventDefault()}
                                        >
                                          <Tooltip
                                            key={key}
                                            arrow
                                            placement={'top'}
                                            title={
                                              <div className={classes.tooltip}>
                                                {t('view.batch.qualityControlTable.rename')}
                                              </div>
                                            }
                                          >
                                            <DriveFileRenameOutlineIcon />
                                          </Tooltip>
                                        </IconButton>
                                      </div>
                                    )}
                                  </Stack>
                                </div>
                              </Tooltip>
                            )
                          }
                          if (columnKey === 'notes') {
                            return (
                              <div
                                style={style}
                                key={key}
                                className={clsx(
                                  item.disabled || !item.visibility ? classes.disabled : null,
                                  rowClassNames,
                                  moleculeOverState === rowIndex && classes.selectedRow,
                                  selected === item._id ? classes.blink : classes.noBlink,
                                  moleculeOverState === rowIndex &&
                                    selected === item._id &&
                                    classes.selectedRowBlink,
                                )}
                                onMouseEnter={() =>
                                  moleculeOver(rowIndex, columnIndex, true, item._id)
                                }
                                onMouseLeave={() => moleculeOver(rowIndex, columnIndex, false)}
                                // onClick={() => onDisplayClient(item._id)}
                                onClick={function (event) {
                                  const openFct = () => {
                                    onDisplayClient(item._id)
                                  }
                                  getClickAction(dispatch, event, item, 'analysis', openFct)
                                }}
                              >
                                <ErrorBoundaryGuard isDialog>
                                  <NoteButton
                                    row={{
                                      _id: item._id,
                                      notes: item.content.notes ? item.content.notes : [],
                                    }}
                                    collection={'analysis'}
                                  />
                                </ErrorBoundaryGuard>
                              </div>
                            )
                          }
                          if (columnKey === 'modified') {
                            return (
                              <div
                                style={style}
                                key={key}
                                className={clsx(
                                  item.disabled || !item.visibility ? classes.disabled : null,
                                  rowClassNames,
                                  moleculeOverState === rowIndex && classes.selectedRow,
                                  selected === item._id ? classes.blink : classes.noBlink,
                                  moleculeOverState === rowIndex &&
                                    selected === item._id &&
                                    classes.selectedRowBlink,
                                )}
                                onMouseEnter={() =>
                                  moleculeOver(rowIndex, columnIndex, true, item._id)
                                }
                                onMouseLeave={() => moleculeOver(rowIndex, columnIndex, false)}
                                // onClick={() => onDisplayClient(item._id)}
                                onClick={function (event) {
                                  const openFct = () => {
                                    onDisplayClient(item._id)
                                  }
                                  getClickAction(dispatch, event, item, 'analysis', openFct)
                                }}
                              >
                                <ErrorBoundaryGuard isDialog>
                                  <AlphacodModifiedButtonIcon item={item} />
                                </ErrorBoundaryGuard>
                              </div>
                            )
                          }
                          if (columnKey === 'flags') {
                            return (
                              <div
                                style={style}
                                key={item._id + columnKey + rowIndex + key}
                                className={clsx(
                                  item.disabled || !item.visibility ? classes.disabled : null,
                                  'cellFlags',
                                  rowClassNames,
                                  selected === item._id ? classes.blink : classes.noBlink,
                                  moleculeOverState === rowIndex && classes.selectedRow,
                                  moleculeOverState === rowIndex &&
                                    selected === item._id &&
                                    classes.selectedRowBlink,
                                )}
                                onMouseEnter={() =>
                                  moleculeOver(rowIndex, columnIndex, true, item._id)
                                }
                                onMouseLeave={() => moleculeOver(rowIndex, columnIndex, false)}
                                // onClick={() => onDisplayClient(item._id)}
                                onClick={function (event) {
                                  const openFct = () => {}
                                  getClickAction(dispatch, event, item, 'analysis', openFct)
                                }}
                              >
                                <ErrorBoundaryGuard isDialog>
                                  <AlphacodFlagIcon item={item.content} hideGeneralTab={true} />
                                </ErrorBoundaryGuard>
                              </div>
                            )
                          }
                          if (columnKey === 'progression') {
                            return (
                              <Tooltip
                                key={item._id + columnKey + rowIndex + key}
                                arrow
                                title={
                                  <div className={classes.tooltip}>
                                    <div>
                                      {t('view.batch.qualityControlTable.column.progress') +
                                        `: ${
                                          item.content.progression_requested
                                            ? item.content.progression_requested.toFixed(2)
                                            : '0.00'
                                        } %`}
                                    </div>
                                    <div style={{ marginTop: 10 }}>
                                      {t('view.batch.qualityControlTable.column.ok') +
                                        `: ${
                                          item.content.nb_ok_validated
                                            ? item.content.nb_ok_validated
                                            : '0'
                                        }`}
                                    </div>
                                    <div>
                                      {t('view.batch.qualityControlTable.column.ko') +
                                        `: ${
                                          item.content.nb_ko_validated
                                            ? item.content.nb_ko_validated
                                            : '0'
                                        }`}
                                    </div>
                                  </div>
                                }
                              >
                                <div
                                  style={style}
                                  className={clsx(
                                    item.disabled || !item.visibility ? classes.disabled : null,
                                    rowClassNames,
                                    selected === item._id ? classes.blink : classes.noBlink,
                                    moleculeOverState === rowIndex && classes.selectedRow,
                                    moleculeOverState === rowIndex &&
                                      selected === item._id &&
                                      classes.selectedRowBlink,
                                  )}
                                  onMouseEnter={() =>
                                    moleculeOver(rowIndex, columnIndex, true, item._id)
                                  }
                                  onMouseLeave={() => moleculeOver(rowIndex, columnIndex, false)}
                                  // onClick={() => onDisplayClient(item._id)}
                                  onClick={function (event) {
                                    const openFct = () => {
                                      onDisplayClient(item._id)
                                    }
                                    getClickAction(dispatch, event, item, 'analysis', openFct)
                                  }}
                                >
                                  <ErrorBoundaryGuard isDialog>
                                    <BorderLinearProgress
                                      // className={classes.margin}
                                      variant="determinate"
                                      // color="secondary"
                                      value={
                                        item.content.progression_requested
                                          ? item.content.progression_requested
                                          : 0
                                      }
                                    />
                                  </ErrorBoundaryGuard>
                                </div>
                              </Tooltip>
                            )
                          }
                          return null
                        }}
                      />
                    </ErrorBoundaryGuard>
                  </div>
                  {!selectionEmpty && (
                    <span
                      className={classes.toolbar}
                      style={{ visibility: selectionEmpty ? 'hidden' : 'visible' }}
                    >
                      <div>
                        <Divider orientation="vertical" />
                        <Tooltip
                          arrow
                          title={
                            <div className={classes.tooltip}>
                              {t('view.batch.qualityControlTable.move_to_others')}
                            </div>
                          }
                        >
                          <IconButton
                            className={classes.toolbarButton}
                            aria-label="MoveToOthers"
                            onClick={() => this.multiValidate()}
                          >
                            <SwapHorizIcon />
                          </IconButton>
                        </Tooltip>
                        {/*<Tooltip*/}
                        {/*  arrow*/}
                        {/*  title={*/}
                        {/*    <div className={classes.tooltip}>*/}
                        {/*      {t('view.batch.qualityControlTable.disabled')}*/}
                        {/*    </div>*/}
                        {/*  }*/}
                        {/*>*/}
                        {/*  <IconButton*/}
                        {/*    className={classes.toolbarButton}*/}
                        {/*    aria-label="Disabled"*/}
                        {/*    onClick={() => disabled(selection)}*/}
                        {/*  >*/}
                        {/*    <BlockIcon />*/}
                        {/*  </IconButton>*/}
                        {/*</Tooltip>*/}
                      </div>
                    </span>
                  )}
                </Paper>
              </Fragment>
            )
          }

          return (
            <Fragment>
              <CustomDialog
                fullWidth={true}
                maxWidth={'xl'}
                open={openExtentedView}
                onClose={() => this.setState({ openExtentedView: false })}
                TransitionComponent={TransitionTop}
                aria-labelledby="draggable-dialog-title"
              >
                {getRenderCmp(true)}
                <DialogActions>
                  <Button
                    onClick={() => this.setState({ openExtentedView: false })}
                    color="primary"
                  >
                    {t('view.batch.qualityControlTable.close')}
                  </Button>
                </DialogActions>
              </CustomDialog>
              {getRenderCmp()}
            </Fragment>
          )
        }
      },
    ),
  ),
)
