import React, { useRef, useState, useMemo, useEffect, Fragment } from 'react'
import { makeStyles, Tooltip } from '@material-ui/core'
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications'
import IconButton from '@material-ui/core/IconButton'
import { ChromatoSettings, DisplaySettings, TransitionBottom, TransitionLeft } from '../Style'
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle'
import Box from '@material-ui/core/Box'
import CardMedia from '@material-ui/core/CardMedia'
import {
  GET_CHROMATO_COLORS,
  GET_CHROMATO_OPACITY,
  GET_CHROMATO_WEIGHT,
  GET_DEFAULT_WINDOW_CHROMATO_IS_PEAK,
  LAYOUT_CONFIG_VALUE,
  PUBLIC_URL, USE_OLD_TABLE,
  ZOOM_DEFAULT_FACTO,
} from '../../../utils/config'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import RotateLeftIcon from '@material-ui/icons/RotateLeft'
import Dialog from '@material-ui/core/Dialog'
import { displayPopupMessage } from '../Message'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import TableChartIcon from '@material-ui/icons/TableChart'
import ShowChartIcon from '@material-ui/icons/ShowChart'
import TimelineIcon from '@material-ui/icons/Timeline'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import TableTab from './TableTab'
import ChromatoTab from './ChromatoTab'
import { useDispatch } from 'react-redux'
import LayoutTab from './LayoutTab'
import DashboardIcon from '@material-ui/icons/Dashboard'
import ErrorBoundaryGuard from '../../ErrorBoundaryGuard'
import { t } from 'i18next'

const useStyles = makeStyles((theme) => ({
  settingsButton: {
    width: 25,
    height: 25,
    top: -6,
    padding: 0,
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.secondary.main,
      backgroundColor: 'transparent',
    },
  },
  tooltip: {
    fontSize: 13,
    padding: 5,
  },
  header: {
    boxShadow: 'inset 0px 0px 82px 10px rgba(0,0,0,1)',
  },
  cardImg: {
    opacity: 0.8,
  },
  title: {
    position: 'absolute',
    top: 10,
    left: 34,
    fontSize: 40,
    color: theme.palette.secondary.main,
    textShadow: '2px 1px 10px white',
  },
  deletePrefsButton: {
    color: 'white',
    background: theme.palette.common.error,
    '&:hover': {
      backgroundColor: '#a10404',
    },
  },
  tabPanel: {
    position: 'absolute',
    width: '100%',
    top: 80,
    background: '#ffffff99',
    '& .MuiTabs-indicator': {
      height: 5,
    },
  },
  paperWidthSm: {
    maxWidth: 800,
  },
}))

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      style={{ overflow: 'hidden' }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

export default function SettingsView({ idTable, propsColumns, parentMultiGridRef, origin }) {
  const classes = useStyles()
  const dispatch = useDispatch()

  const tableOldVersion = useMemo(() => {
    return USE_OLD_TABLE()
  }, [])

  const [openSettings, setOpenSettings] = useState(false)
  const [activeTab, setActiveTab] = React.useState(0)

  // const [settingsVisible, setSettingsVisible] = useState(
  //   localStorage.getItem('TABLE_' + idTable + '_columns_visible')
  //     ? localStorage
  //         .getItem('TABLE_' + idTable + '_columns_visible')
  //         .split('[|,|]')
  //         .reduce(function (map, obj) {
  //           map[obj.split('[|:|]')[0]] = obj.split('[|:|]')[1] === 'true'
  //           return map
  //         }, {})
  //     : propsColumns.reduce(function (map, obj) {
  //         map[obj.key] = obj.visible ? obj.visible : true
  //         return map
  //       }, {}),
  // )
  // const [settingsSize, setSettingsSize] = useState(
  //   localStorage.getItem('TABLE_' + idTable + '_columns_size')
  //     ? localStorage
  //         .getItem('TABLE_' + idTable + '_columns_size')
  //         .split('[|,|]')
  //         .reduce(function (map, obj) {
  //           map[obj.split('[|:|]')[0]] = parseInt(obj.split('[|:|]')[1])
  //           return map
  //         }, {})
  //     : propsColumns.reduce(function (map, obj) {
  //         map[obj.key] = obj.size
  //         return map
  //       }, {}),
  // )
  // const [settingsOrder, setSettingsOrder] = useState(
  //   localStorage.getItem('TABLE_' + idTable + '_columns_order')
  //     ? localStorage.getItem('TABLE_' + idTable + '_columns_order').split('[|,|]')
  //     : propsColumns.map((column) => column.key),
  // )

  const settingsVisibilitySaved = localStorage.getItem('TABLE_' + idTable + '_columns_visible')
    ? localStorage
        .getItem('TABLE_' + idTable + '_columns_visible')
        .split('[|,|]')
        .reduce(function (map, obj) {
          map[obj.split('[|:|]')[0]] = obj.split('[|:|]')[1] === 'true'
          return map
        }, {})
    : {}
  let settingsOrderSaved = localStorage.getItem('TABLE_' + idTable + '_columns_order')
    ? localStorage.getItem('TABLE_' + idTable + '_columns_order').split('[|,|]')
    : []
  // Add new columns unknown from the preferences
  settingsOrderSaved.push(
    ...propsColumns
      .map((column) => column.key)
      .filter((key) =>
        localStorage.getItem('TABLE_' + idTable + '_columns_order')
          ? localStorage
              .getItem('TABLE_' + idTable + '_columns_order')
              .split('[|,|]')
              .indexOf(key) == -1
          : key,
      ),
  )
  // Remove old columns unknown from the table
  settingsOrderSaved = settingsOrderSaved.filter(
    (setting) => propsColumns.map((column) => column.key).indexOf(setting) !== -1,
  )
  const settingsSizeSaved = localStorage.getItem('TABLE_' + idTable + '_columns_size')
    ? localStorage
        .getItem('TABLE_' + idTable + '_columns_size')
        .split('[|,|]')
        .reduce(function (map, obj) {
          map[obj.split('[|:|]')[0]] = parseInt(obj.split('[|:|]')[1])
          return map
        }, {})
    : {}

  const settingsDecimalsSaved = localStorage.getItem('TABLE_' + idTable + '_columns_decimals')
    ? localStorage
        .getItem('TABLE_' + idTable + '_columns_decimals')
        .split('[|,|]')
        .reduce(function (map, obj) {
          map[obj.split('[|:|]')[0]] = parseInt(obj.split('[|:|]')[1])
          return map
        }, {})
    : {}

  const settingsFormatSaved = localStorage.getItem('TABLE_' + idTable + '_columns_format')
    ? localStorage
        .getItem('TABLE_' + idTable + '_columns_format')
        .split('[|,|]')
        .reduce(function (map, obj) {
          map[obj.split('[|:|]')[0]] = obj.split('[|:|]')[1]
          return map
        }, {})
    : {}

  const [settingsOrder, setSettingsOrder] = useState(
    localStorage.getItem('TABLE_' + idTable + '_columns_order')
      ? settingsOrderSaved
      : propsColumns.map((column) => column.key),
  )
  const [settingsVisible, setSettingsVisible] = useState(
    localStorage.getItem('TABLE_' + idTable + '_columns_visible')
      ? propsColumns.reduce(function (map, obj) {
          map[obj.key] =
            typeof settingsVisibilitySaved[obj.key] === 'boolean'
              ? settingsVisibilitySaved[obj.key]
              : true
          return map
        }, {})
      : propsColumns.reduce(function (map, obj) {
          map[obj.key] = typeof obj.visible === 'boolean' ? obj.visible : true
          return map
        }, {}),
  )
  const [settingsSize, setSettingsSize] = useState(
    localStorage.getItem('TABLE_' + idTable + '_columns_size')
      ? propsColumns.reduce(function (map, obj) {
          map[obj.key] =
            typeof settingsSizeSaved[obj.key] === 'number' ? settingsSizeSaved[obj.key] : obj.size
          return map
        }, {})
      : propsColumns.reduce(function (map, obj) {
          map[obj.key] = obj.size
          return map
        }, {}),
  )
  const [settingsDecimals, setSettingsDecimals] = useState(
    localStorage.getItem('TABLE_' + idTable + '_columns_decimals')
      ? propsColumns.reduce(function (map, obj) {
          map[obj.key] =
            typeof settingsDecimalsSaved[obj.key] === 'number' ? settingsDecimalsSaved[obj.key] : 3
          return map
        }, {})
      : propsColumns.reduce(function (map, obj) {
          map[obj.key] = 3
          return map
        }, {}),
  )
  const [settingsFormat, setSettingsFormat] = useState(
    localStorage.getItem('TABLE_' + idTable + '_columns_format')
      ? propsColumns.reduce(function (map, obj) {
          map[obj.key] =
            typeof settingsFormatSaved[obj.key] === 'string' ? settingsFormatSaved[obj.key] : 'auto'
          return map
        }, {})
      : propsColumns.reduce(function (map, obj) {
          map[obj.key] = 'auto'
          return map
        }, {}),
  )

  const [labelVisibility, setLabelVisibility] = React.useState({
    area: localStorage.getItem('CHROMATO_option_area')
      ? localStorage.getItem('CHROMATO_option_area') === 'true'
      : false,
    height: localStorage.getItem('CHROMATO_option_height')
      ? localStorage.getItem('CHROMATO_option_height') === 'true'
      : false,
    concVol: localStorage.getItem('CHROMATO_option_concVol')
      ? localStorage.getItem('CHROMATO_option_concVol') === 'true'
      : false,
    rt: localStorage.getItem('CHROMATO_option_rt')
      ? localStorage.getItem('CHROMATO_option_rt') === 'true'
      : false,
    transitions: localStorage.getItem('CHROMATO_option_transitions')
      ? localStorage.getItem('CHROMATO_option_transitions') === 'true'
      : true,
    flag: localStorage.getItem('CHROMATO_option_flag')
      ? localStorage.getItem('CHROMATO_option_flag') === 'true'
      : true,
    rtCal: localStorage.getItem('CHROMATO_option_rtCal')
      ? localStorage.getItem('CHROMATO_option_rtCal') === 'true'
      : true,
    rtCor: localStorage.getItem('CHROMATO_option_rtCor')
      ? localStorage.getItem('CHROMATO_option_rtCor') === 'true'
      : true,
    rtMth: localStorage.getItem('CHROMATO_option_rtMth')
      ? localStorage.getItem('CHROMATO_option_rtMth') === 'true'
      : true,
    validation: localStorage.getItem('CHROMATO_option_validation')
      ? localStorage.getItem('CHROMATO_option_validation') === 'true'
      : false,
    lockChromatoEdition: localStorage.getItem('CHROMATO_option_lockChromatoEdition')
      ? localStorage.getItem('CHROMATO_option_lockChromatoEdition') === 'true'
      : false,
  })

  const [chromatoHeight, setChromatoHeight] = React.useState(ZOOM_DEFAULT_FACTO())
  const [chromatoOpacity, setChromatoOpacity] = React.useState(GET_CHROMATO_OPACITY())
  const [chromatoWeight, setChromatoWeight] = React.useState(GET_CHROMATO_WEIGHT())
  const [isChromatoPeakAsDefaultWindow, setIsChromatoPeakAsDefaultWindow] = React.useState(
    GET_DEFAULT_WINDOW_CHROMATO_IS_PEAK(),
  )
  const [tmpChromatoColors, setTmpChromatoColors] = React.useState(GET_CHROMATO_COLORS())

  const [selectedLayout, setSelectedLayout] = React.useState(LAYOUT_CONFIG_VALUE(origin))

  useEffect(() => {
    if (openSettings) {
      setChromatoOpacity(GET_CHROMATO_OPACITY())
    }
  }, [openSettings])

  function handleCloseSettings(save) {
    setOpenSettings(false)
    if (save) {
      // Table
      if (tableOldVersion) {
        localStorage.setItem('TABLE_' + idTable + '_columns_order', settingsOrder.join('[|,|]'))
        localStorage.setItem(
          'TABLE_' + idTable + '_columns_visible',
          Object.entries(settingsVisible)
            .map((visible) => visible[0] + '[|:|]' + visible[1])
            .join('[|,|]'),
        )
        localStorage.setItem(
          'TABLE_' + idTable + '_columns_size',
          Object.entries(settingsSize)
            .map((size) => size[0] + '[|:|]' + size[1])
            .join('[|,|]'),
        )
        localStorage.setItem(
          'TABLE_' + idTable + '_columns_format',
          Object.entries(settingsFormat)
            .map((format) => format[0] + '[|:|]' + format[1])
            .join('[|,|]'),
        )
        localStorage.setItem(
          'TABLE_' + idTable + '_columns_decimals',
          Object.entries(settingsDecimals)
            .map((decimals) => decimals[0] + '[|:|]' + decimals[1])
            .join('[|,|]'),
        )
      }

      // Chromato
      Object.entries(labelVisibility).forEach((lv) =>
        localStorage.setItem('CHROMATO_option_' + lv[0], lv[1]),
      )
      localStorage.setItem('CHROMATO_option_peakHeight', chromatoHeight)
      localStorage.setItem('CHROMATO_option_opacity', chromatoOpacity)
      localStorage.setItem('CHROMATO_option_weight', chromatoWeight)
      localStorage.setItem('CHROMATO_option_isPeakAsDefaultWindow', isChromatoPeakAsDefaultWindow)
      localStorage.setItem('CHROMATO_option_colors', tmpChromatoColors)

      // Layout
      localStorage.setItem('LAYOUT_config_value_' + origin, selectedLayout)

      // Force to refresh the table after modification
      setTimeout(() => {
        // reloadMultiGrid()
        parentMultiGridRef?.current && parentMultiGridRef.current.recomputeGridSize()
      }, 500)

      displayPopupMessage(
        dispatch,
        'info',
        t('view.common.settingsView.settingsView.title'),
        <span>
          <div>{t('view.common.settingsView.settingsView.msg')}</div>
          <div style={{ marginTop: 10 }}>
            <b>{t('view.common.settingsView.settingsView.reload')}</b>
          </div>
        </span>,
      )
      // refChromatoTab.onSaveSettings()
    } else {
      if (tableOldVersion) {
        setSettingsOrder(
          localStorage.getItem('TABLE_' + idTable + '_columns_order')
            ? settingsOrderSaved
            : propsColumns.map((column) => column.key),
        )
        setSettingsVisible(
          localStorage.getItem('TABLE_' + idTable + '_columns_visible')
            ? propsColumns.reduce(function (map, obj) {
                map[obj.key] =
                  typeof settingsVisibilitySaved[obj.key] === 'boolean'
                    ? settingsVisibilitySaved[obj.key]
                    : true
                return map
              }, {})
            : propsColumns.reduce(function (map, obj) {
                map[obj.key] = typeof obj.visible === 'boolean' ? obj.visible : true
                return map
              }, {}),
        )
        setSettingsSize(
          localStorage.getItem('TABLE_' + idTable + '_columns_size')
            ? propsColumns.reduce(function (map, obj) {
                map[obj.key] =
                  typeof settingsSizeSaved[obj.key] === 'number'
                    ? settingsSizeSaved[obj.key]
                    : obj.size
                return map
              }, {})
            : propsColumns.reduce(function (map, obj) {
                map[obj.key] = obj.size
                return map
              }, {}),
        )
      }
      setLabelVisibility({
        area: localStorage.getItem('CHROMATO_option_area')
          ? localStorage.getItem('CHROMATO_option_area') === 'true'
          : false,
        height: localStorage.getItem('CHROMATO_option_height')
          ? localStorage.getItem('CHROMATO_option_height') === 'true'
          : false,
        concVol: localStorage.getItem('CHROMATO_option_concVol')
          ? localStorage.getItem('CHROMATO_option_concVol') === 'true'
          : false,
        rt: localStorage.getItem('CHROMATO_option_rt')
          ? localStorage.getItem('CHROMATO_option_rt') === 'true'
          : false,
        transitions: localStorage.getItem('CHROMATO_option_transitions')
          ? localStorage.getItem('CHROMATO_option_transitions') === 'true'
          : true,
        flag: localStorage.getItem('CHROMATO_option_flag')
          ? localStorage.getItem('CHROMATO_option_flag') === 'true'
          : true,
        rtCal: localStorage.getItem('CHROMATO_option_rtCal')
          ? localStorage.getItem('CHROMATO_option_rtCal') === 'true'
          : true,
        rtCor: localStorage.getItem('CHROMATO_option_rtCor')
          ? localStorage.getItem('CHROMATO_option_rtCor') === 'true'
          : true,
        rtMth: localStorage.getItem('CHROMATO_option_rtMth')
          ? localStorage.getItem('CHROMATO_option_rtMth') === 'true'
          : true,
        validation: localStorage.getItem('CHROMATO_option_validation')
          ? localStorage.getItem('CHROMATO_option_validation') === 'true'
          : false,
        lockChromatoEdition: localStorage.getItem('CHROMATO_option_lockChromatoEdition')
          ? localStorage.getItem('CHROMATO_option_lockChromatoEdition') === 'true'
          : false,
      })
      setChromatoHeight(ZOOM_DEFAULT_FACTO())
      setChromatoOpacity(GET_CHROMATO_OPACITY())
      setChromatoWeight(GET_CHROMATO_WEIGHT())
      setTmpChromatoColors(GET_CHROMATO_COLORS())
      setSelectedLayout(LAYOUT_CONFIG_VALUE(origin))
      setIsChromatoPeakAsDefaultWindow(GET_DEFAULT_WINDOW_CHROMATO_IS_PEAK())
    }
  }

  const cleanUserInfos = async () => {
    // refTableTab.onResetSettings()
    // refChromatoTab.onResetSettings()
    //TABLE
    localStorage.removeItem('TABLE_' + idTable + '_columns_order')
    localStorage.removeItem('TABLE_' + idTable + '_columns_visible')
    localStorage.removeItem('TABLE_' + idTable + '_columns_size')
    localStorage.removeItem('TABLE_' + idTable + '_columns_format')
    localStorage.removeItem('TABLE_' + idTable + '_columns_decimals')

    if (tableOldVersion) {
      setSettingsOrder(propsColumns.map((column) => column.key))
      setSettingsVisible(
        propsColumns.reduce(function (map, obj) {
          map[obj.key] = typeof obj.visible === 'boolean' ? obj.visible : true
          return map
        }, {}),
      )
      setSettingsSize(
        propsColumns.reduce(function (map, obj) {
          map[obj.key] = obj.size
          return map
        }, {}),
      )
      setSettingsFormat(
        propsColumns.reduce(function (map, obj) {
          map[obj.key] = 'auto'
          return map
        }, {}),
      )
      setSettingsDecimals(
        propsColumns.reduce(function (map, obj) {
          map[obj.key] = 3
          return map
        }, {}),
      )
    }
    // Chromato
    Object.entries(labelVisibility).forEach((lv) =>
      localStorage.setItem('CHROMATO_option_' + lv[0], false),
    )
    setLabelVisibility({
      area: false,
      height: false,
      concVol: false,
      rt: false,
      transitions: true,
      flag: true,
      rtCal: true,
      rtCor: true,
      rtMth: true,
      validation: false,
    })
    localStorage.removeItem('CHROMATO_option_peakHeight')
    setChromatoHeight(ZOOM_DEFAULT_FACTO())
    localStorage.removeItem('CHROMATO_option_opacity')
    setChromatoOpacity(GET_CHROMATO_OPACITY())
    localStorage.removeItem('CHROMATO_option_isPeakAsDefaultWindow')
    setIsChromatoPeakAsDefaultWindow(GET_DEFAULT_WINDOW_CHROMATO_IS_PEAK())
    localStorage.removeItem('CHROMATO_option_weight')
    setChromatoWeight(GET_CHROMATO_WEIGHT())
    localStorage.removeItem('LAYOUT_config_value_' + origin)
    setSelectedLayout(LAYOUT_CONFIG_VALUE(origin))
    localStorage.removeItem('CHROMATO_option_colors')
    setTmpChromatoColors(GET_CHROMATO_COLORS())

    displayPopupMessage(dispatch, 'info', 'Settings view', 'All settings have been reset.')

    // Force to refresh the table after modification
    setTimeout(() => {
      parentMultiGridRef.current.recomputeGridSize()
    }, 500)
    setOpenSettings(false)
  }

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue)
  }

  return (
    <Fragment>
      <Dialog
        open={openSettings}
        onClose={() => handleCloseSettings(false)}
        TransitionComponent={TransitionBottom}
        aria-labelledby="settings-dialog-title"
        classes={{ paperWidthSm: classes.paperWidthSm }}
      >
        <DialogTitle style={{ padding: 0 }}>
          <Box className={classes.header}>
            <CardMedia
              className={classes.cardImg}
              component="img"
              alt="header image"
              height="152"
              image={PUBLIC_URL + '/images/ua_settings.jpg'}
              title="Header image"
            />
            <span className={classes.title}>
              {t('view.common.settingsView.settingsView.title')}
            </span>
          </Box>
        </DialogTitle>
        <DialogContent style={{ padding: 0, width: 800, height: 325 }}>
          <Tabs
            className={classes.tabPanel}
            value={activeTab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            {tableOldVersion && (
              <Tab
                icon={<TableChartIcon />}
                label={t('view.common.settingsView.settingsView.table')}
              />
            )}
            <Tab
              icon={<ChromatoSettings />}
              label={t('view.common.settingsView.settingsView.chromato')}
            />
            <Tab
              icon={<DashboardIcon />}
              label={t('view.common.settingsView.settingsView.layout')}
            />
            {/*<Tab icon={<TimelineIcon />} label="Calibration" />*/}
          </Tabs>
          {tableOldVersion && (
            <TabPanel value={activeTab} index={0}>
              <ErrorBoundaryGuard>
                <TableTab
                  propsColumns={propsColumns}
                  settingsVisible={settingsVisible}
                  settingsSize={settingsSize}
                  settingsOrder={settingsOrder}
                  settingsDecimals={settingsDecimals}
                  settingsFormat={settingsFormat}
                  setSettingsVisible={setSettingsVisible}
                  setSettingsSize={setSettingsSize}
                  setSettingsOrder={setSettingsOrder}
                  setSettingsDecimals={setSettingsDecimals}
                  setSettingsFormat={setSettingsFormat}
                />
              </ErrorBoundaryGuard>
            </TabPanel>
          )}
          <TabPanel value={activeTab} index={tableOldVersion ? 1 : 0}>
            <ErrorBoundaryGuard>
              <ChromatoTab
                labelVisibility={labelVisibility}
                setLabelVisibility={setLabelVisibility}
                chromatoHeight={chromatoHeight}
                setChromatoHeight={setChromatoHeight}
                chromatoOpacity={chromatoOpacity}
                setChromatoOpacity={setChromatoOpacity}
                isChromatoPeakAsDefaultWindow={isChromatoPeakAsDefaultWindow}
                setIsChromatoPeakAsDefaultWindow={setIsChromatoPeakAsDefaultWindow}
                chromatoWeight={chromatoWeight}
                setChromatoWeight={setChromatoWeight}
                tmpChromatoColors={tmpChromatoColors}
                setTmpChromatoColors={setTmpChromatoColors}
              />
            </ErrorBoundaryGuard>
          </TabPanel>
          <TabPanel value={activeTab} index={tableOldVersion ? 2 : 1}>
            <ErrorBoundaryGuard>
              <LayoutTab
                selectedLayout={selectedLayout}
                setSelectedLayout={setSelectedLayout}
                origin={origin}
              />
            </ErrorBoundaryGuard>
          </TabPanel>
          {/*<TabPanel value={activeTab} index={2}>*/}
          {/*  Item Three*/}
          {/*</TabPanel>*/}
        </DialogContent>
        <DialogActions style={{ marginTop: 0 }}>
          <Button
            variant="contained"
            className={classes.deletePrefsButton}
            startIcon={<RotateLeftIcon />}
            onClick={cleanUserInfos}
          >
            {t('view.common.settingsView.settingsView.reset')}
          </Button>
          <div style={{ width: 'calc(100% - 258px)' }}></div>
          <Button autoFocus onClick={() => handleCloseSettings(true)} color="primary">
            {t('view.common.settingsView.settingsView.save')}
          </Button>
          <Button onClick={() => handleCloseSettings(false)} color="primary">
            {t('view.common.settingsView.settingsView.cancel')}
          </Button>
        </DialogActions>
      </Dialog>
      <Tooltip
        arrow
        title={
          <div className={classes.tooltip}>{t('view.common.settingsView.settingsView.title')}</div>
        }
      >
        <IconButton
          aria-label="delete"
          className={classes.settingsButton}
          onClick={() => setOpenSettings(true)}
        >
          <DisplaySettings style={{ width: 30, height: 30 }} />
        </IconButton>
      </Tooltip>
    </Fragment>
  )
}
