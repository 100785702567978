import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withStyles, alpha } from '@material-ui/core/styles'
import Badge from '@material-ui/core/Badge/Badge'
import BubbleChartIcon from '@material-ui/icons/BubbleChart'
import Tooltip from '@material-ui/core/Tooltip'
import ErrorIcon from '@material-ui/icons/Error'
import TableCell from '@material-ui/core/TableCell'
import LinearProgress from '@material-ui/core/LinearProgress'
import clsx from 'clsx'
import { t } from 'i18next'

const styles = (theme) => ({
  badgeLims: {
    '& .MuiBadge-badge': {
      width: 15,
      height: 15,
      padding: 0,
      minWidth: 0,
      right: -8,
      color: alpha(theme.palette.primary.main, 0.6),
      background: 'white',
      // boxShadow: '0px 2px 5px 1px rgba(0,0,0,0.15)',
      border: '1px solid lightgrey',
    },
  },
  badgeProgress: {
    width: '100%',
    '& .MuiBadge-badge': {
      top: -4,
      right: 0,
    },
  },
  iconLims: {
    fontSize: 17,
    position: 'relative',
    color: theme.palette.common.warning,
  },
})

const BorderLinearProgress = withStyles({
  root: {
    height: 12,
    borderRadius: 10,
    width: '100%',
  },
  bar: {
    borderRadius: 10,
  },
})(LinearProgress)

export default connect()(
  withStyles(styles)(
    class LimsBadge extends React.Component {
      getProgressionCmp(row) {
        return (
          <Tooltip
            key={row._id + row.content.progression_requested + 'tooltip'}
            arrow
            title={
              <div style={{ fontSize: 13, padding: 5 }}>
                {`${
                  row.content.progression_requested
                    ? row.content.progression_requested.toFixed(2)
                    : '0.00'
                } %`}
              </div>
            }
          >
            <BorderLinearProgress
              variant="determinate"
              value={row.content.progression_requested ? row.content.progression_requested : 0}
            />
          </Tooltip>
        )
      }

      render() {
        const { classes, analysis, column } = this.props

        return (column === 'name' &&
          (analysis.istd.ko !== 0 || analysis.istd.none || analysis.istd.relance !== 0)) ||
          (column === 'progression' && analysis.content.progression_requested !== 100) ? (
          <Badge
            className={clsx(
              classes.badgeLims,
              column === 'progression' ? classes.badgeProgress : '',
            )}
            badgeContent={
              <Tooltip
                key={'tooltip_lims'}
                placement="right"
                arrow
                title={
                  column === 'name' ? (
                    <Fragment>
                      <div style={{ fontSize: 12, padding: 10 }}>
                        {t('view.batch.limsBadge.title')}
                      </div>
                      <table>
                        <tbody>
                          <tr>
                            <td style={{ paddingLeft: 10, paddingRight: 10 }}>
                              {t('view.batch.limsBadge.ok')}
                            </td>
                            <td>{analysis.istd.ok}</td>
                          </tr>
                          <tr>
                            <td style={{ paddingLeft: 10, paddingRight: 10 }}>
                              {t('view.batch.limsBadge.ko')}
                            </td>
                            <td>{analysis.istd.ko}</td>
                          </tr>
                          <tr>
                            <td style={{ paddingLeft: 10, paddingRight: 10 }}>
                              {t('view.batch.limsBadge.relaunch')}
                            </td>
                            <td>{analysis.istd.relance}</td>
                          </tr>
                          <tr>
                            <td style={{ paddingLeft: 10, paddingRight: 10 }}>
                              {t('view.batch.limsBadge.none')}
                            </td>
                            <td>{analysis.istd.none}</td>
                          </tr>
                        </tbody>
                      </table>
                    </Fragment>
                  ) : (
                    <div style={{ fontSize: 12, padding: 10 }}>
                      {t('view.batch.limsBadge.not_complete')}
                    </div>
                  )
                }
              >
                <ErrorIcon className={classes.iconLims} />
              </Tooltip>
            }
          >
            {column === 'name' ? analysis.name : this.getProgressionCmp(analysis)}
          </Badge>
        ) : column === 'name' ? (
          analysis.name
        ) : (
          this.getProgressionCmp(analysis)
        )
      }
    },
  ),
)
