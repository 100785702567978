import React, { Fragment, useEffect, useRef } from 'react'
import { Stack, TextField } from '@mui/material'
import Switch from '@material-ui/core/Switch'
import Button from '@mui/material/Button'
import { useTranslation } from 'react-i18next'
import SaveIcon from '@mui/icons-material/Save'
import FmdBadIcon from '@mui/icons-material/FmdBad'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { alpha } from '@material-ui/core'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import { useSelector } from 'react-redux'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import PsychologyIcon from '@mui/icons-material/Psychology'
import DateFnsUtils from '@date-io/date-fns'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { fetchChangeSettings } from '../../../redux/settings/actions'

const useStyles = makeStyles((theme) => ({
  settingsInfoText: {
    paddingTop: 15,
    paddingBottom: 20,
    fontSize: '1rem',
  },
  chiplevel: {
    height: 20,
    marginLeft: 10,
    '& span': {
      padding: 5,
    },
  },
  datagrid: {
    height: 300,
    display: 'inline-flex',
    width: '100%',
    marginTop: 30,
    '& .MuiDataGrid-row': {
      cursor: 'pointer',
    },
    '& .MuiDataGrid-row:nth-child(even):hover': {
      backgroundColor: '#0000000F',
    },
    '& .MuiDataGrid-row:nth-child(odd)': {
      backgroundColor: '#00000008',
    },
    '& .MuiDataGrid-row:nth-child(odd):hover': {
      backgroundColor: '#00000012',
    },
  },
  dialogTitle: {
    backgroundImage: 'linear-gradient(' + alpha(theme.palette.primary.main, 0.12) + ', white)',
    backgroundColor: 'white',
    borderTop: '4px solid ' + theme.palette.primary.main,
  },
  input: {
    '& input': {
      padding: '16.5px 14px',
    },
    '& .MuiInputBase-multiline': {
      padding: '16.5px 14px',
    },
  },
  aiModeCnt: {
    border: '1px solid #c4c4c4',
    width: 'calc(50% - 5px)',
    display: 'inline-flex',
    borderRadius: 4,
  },
  aiModeTitle: {
    background:
      'linear-gradient(0deg, rgb(255 255 255) 56%, rgba(255,255,255,0) 56%, rgba(255,255,255,0) 100%)',
    position: 'absolute',
    color: 'rgba(0, 0, 0, 0.6)',
    padding: '0px 7px',
    fontSize: '1rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: '0.00938em',
    transform: 'translate(-4px, -8px) scale(0.75)',
  },
  datePickerClass: {
    '& input': {
      paddingTop: 15,
    },
  },
}))

export default function InfosPanel({
  batchDefault,
  batchName,
  batchDescription,
  batchDevice,
  setBatchDevice,
  batchDate,
  setBatchDate,
  setBatchName,
  setBatchDescription,
  configuration,
  setConfiguration,
  aiModeActive,
  setAiModeActive,
  config_ai_mode,
  setConfig_ai_mode,
}) {
  const classes = useStyles()
  const { t, i18n } = useTranslation()
  const ref = useRef(null)
  const refButton = React.createRef()

  const [tmpBatchName, setTmpBatchName] = React.useState(batchDefault)
  const [tmpDevice, setTmpDevice] = React.useState(batchDevice)
  const [tmpDate, setTmpDate] = React.useState(batchDate)
  const [tmpConfigurationId, setTmpConfigurationId] = React.useState(configuration)
  const [tmpBatchDescription, setTmpBatchDescription] = React.useState(batchDescription)
  const [errorBatchName, setErrorBatchName] = React.useState('')
  const [errorDevice, setErrorDevice] = React.useState('')
  const [tmpAiModeActive, setTmpAiModeActive] = React.useState(aiModeActive)

  const regex = new RegExp(/^[A-Za-z][A-Za-z0-9\\._-]*$/)
  const configurations = useSelector((state) => state.configuration.configurations)

  const validModes = { on: true, off: true, demo: true }

  useEffect(() => {
    // Get the ai_score from configuration object selected
    const ai_mode = configurations.filter((config) => config._id === tmpConfigurationId)?.[0]
      ?.content?.features?.ai_mode
    if (validModes[ai_mode]) {
      setConfig_ai_mode(ai_mode)
    } else {
      setConfig_ai_mode('off')
    }
  }, [tmpConfigurationId])

  function _handleNameChange(event) {
    if (regex.test(event.target.value)) {
      setErrorBatchName('')
    } else {
      setErrorBatchName(
        t('view.dialogs.infosPanel.errorChars.part1') +
          ': a-z,A-z,0-9,-,_,. (' +
          t('view.dialogs.infosPanel.errorChars.part2') +
          ')',
      )
    }
    setTmpBatchName(event.target.value)
  }

  function _handleDescriptionChange(event) {
    setTmpBatchDescription(event.target.value)
  }

  function _handleDeviceChange(event) {
    // if (regex.test(event.target.value)) {
    //   setErrorDevice('')
    // } else {
    //   setErrorDevice(
    //     t('view.dialogs.infosPanel.errorChars.part1') +
    //       ': a-z,A-z,0-9,-,_,. (' +
    //       t('view.dialogs.infosPanel.errorChars.part2') +
    //       ')',
    //   )
    // }
    setTmpDevice(event.target.value)
  }
  function _handleConfigurationChanged(event) {
    setTmpConfigurationId(event.target.value)
  }
  function _handleAiModeActive(event) {
    setTmpAiModeActive(event.target.checked)
  }
  function _handleDateChange(date) {
    setTmpDate(date)
  }

  function _keyPress(e, step) {
    if (e.keyCode === 13) {
      switch (step) {
        case 0:
          ref.current.focus()
          break
        case 1:
          refButton.current.focus()
          break
        case 2:
          if (tmpBatchName && !errorBatchName && tmpDevice && !errorDevice) {
            setBatchDate(tmpDate)
            setBatchName(tmpBatchName)
            setBatchDevice(tmpDevice)
            setBatchDescription(tmpBatchDescription)
            setConfiguration(tmpConfigurationId)
            setAiModeActive(tmpAiModeActive)
          }
          break
      }
    }
  }

  return (
    <Fragment>
      <Stack spacing={5} style={{ marginTop: 0 }}>
        <Stack spacing={2} style={{ width: '100%' }}>
          <div>
            <TextField
              autoFocus
              error={tmpBatchName === '' || !!errorBatchName}
              helperText={errorBatchName}
              style={{ width: 'calc(100% - 190px)', marginRight: 10 }}
              required
              label={t('view.dialogs.infosPanel.name')}
              // defaultValue={batchDefault}
              value={tmpBatchName}
              onChange={_handleNameChange}
              InputProps={{
                readOnly: batchName !== '',
              }}
              onKeyDown={(e) => _keyPress(e, 0)}
              className={classes.input}
            />
            {batchName === '' ? (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  style={{ width: 180, margin: 0 }}
                  disableToolbar
                  inputVariant="outlined"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="date-picker-d&d"
                  label={t('view.dialogs.infosPanel.date')}
                  value={tmpDate}
                  onChange={_handleDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  className={classes.datePickerClass}
                />
              </MuiPickersUtilsProvider>
            ) : (
              <TextField
                style={{ width: 180, margin: 0 }}
                label={t('view.dialogs.infosPanel.date')}
                value={tmpDate.toLocaleDateString('en')}
                InputProps={{
                  readOnly: true,
                }}
                className={classes.input}
              />
            )}
          </div>
          <div>
            <FormControl
              required
              variant="outlined"
              style={{ width: 'calc(50% - 5px)', marginRight: 10 }}
            >
              <InputLabel>{t('view.dialogs.infosPanel.configuration')}</InputLabel>
              <Select
                variant="outlined"
                onChange={_handleConfigurationChanged}
                label={t('view.dialogs.infosPanel.configuration')}
                value={tmpConfigurationId}
                style={{ height: 52 }}
                inputProps={{ readOnly: batchName !== '' }}
              >
                {configurations &&
                  configurations.map((conf) => (
                    <MenuItem key={conf.name} value={conf._id}>
                      {conf.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <TextField
              error={tmpDevice === '' || !!errorDevice}
              helperText={errorDevice}
              style={{ width: 'calc(50% - 5px)' }}
              required
              label={t('view.dialogs.infosPanel.device')}
              // defaultValue={batchDefault}
              value={tmpDevice}
              onChange={_handleDeviceChange}
              InputProps={{
                readOnly: batchName !== '',
              }}
              onKeyDown={(e) => _keyPress(e, 0)}
              className={classes.input}
            />
          </div>
          <TextField
            inputRef={ref}
            label={t('view.dialogs.infosPanel.description')}
            fullWidth
            multiline
            minRows={2}
            value={tmpBatchDescription}
            onChange={_handleDescriptionChange}
            InputProps={{
              readOnly: batchName !== '',
            }}
            onKeyDown={(e) => _keyPress(e, 1)}
            className={classes.input}
          />
          <div
            className={classes.aiModeCnt}
            style={{
              width: '100%',
              height: '100%',
              background: 'white',
              opacity: config_ai_mode === 'on' ? 1 : 0.5,
            }}
          >
            <span style={{ margin: '12px 14px', width: '100%' }}>
              <FormControlLabel
                // style={{ marginTop: 0, marginLeft: 50, color: 'rgba(0, 0, 0, 0.87)' }}
                control={
                  <Switch
                    disabled={!(config_ai_mode === 'on') || batchName !== ''}
                    checked={config_ai_mode === 'on' ? tmpAiModeActive : false}
                    onChange={_handleAiModeActive}
                  />
                }
                label={
                  config_ai_mode === 'on'
                    ? tmpAiModeActive
                      ? t('view.dialogs.infosPanel.aiModeActive')
                      : t('view.dialogs.infosPanel.aiModeNotActive')
                    : t('view.dialogs.infosPanel.aiModeDisabled')
                }
              />
              <PsychologyIcon
                style={{ float: 'right' }}
                fontSize="large"
                color={tmpAiModeActive ? 'secondary' : 'disabled'}
              />
            </span>
            <div className={classes.aiModeTitle} style={{ left: 26 }}>
              {t('view.dialogs.infosPanel.aiMode')}
            </div>
          </div>
          {batchName === '' && (
            <Button
              ref={refButton}
              disabled={
                tmpBatchName === '' ||
                tmpBatchName === null ||
                !!errorBatchName ||
                tmpDevice === '' ||
                tmpDevice === null ||
                !!errorDevice
              }
              variant={'text'}
              style={{
                height: 54,
              }}
              onClick={() => {
                setBatchDate(tmpDate)
                setBatchName(tmpBatchName)
                setBatchDevice(tmpDevice)
                setBatchDescription(tmpBatchDescription)
                setConfiguration(tmpConfigurationId)
                setAiModeActive(tmpAiModeActive)
              }}
              startIcon={<SaveIcon sx={{ fontSize: '1.5rem' }} />}
              endIcon={<ArrowForwardIosIcon />}
              onKeyDown={(e) => _keyPress(e, 2)}
            >
              {t('view.dialogs.infosPanel.next')}
            </Button>
          )}
        </Stack>
        {batchName === '' && (
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}
            style={{ color: '#484848' }}
          >
            <FmdBadIcon />
            <div>
              <div>{t('view.dialogs.infosPanel.infoMsg')}.</div>
            </div>
          </Stack>
        )}
      </Stack>
    </Fragment>
  )
}
