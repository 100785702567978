import React, { Fragment, useRef } from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles, fade } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import Navigation from '../Navigation'
import { getBatchById } from '../../redux/batches/selectors'
import { fetchAnalyses, searchAnalyses } from '../../redux/analyses/actions'
import {
  fetchChangeSelectedChannel,
  fetchQCUnitaryAnalysesCompound,
  fetchUnitaryAnalysesAllISTD,
  fetchUnitaryAnalysesFiltered,
  sortUnitaryAnalyses,
} from '../../redux/unitaryAnalyses/actions'
import { fetchBatch } from '../../redux/batches/actions'
import Information from './Information'
import Loading from '../Loading'
import SplitterLayout from 'react-splitter-layout'
import clsx from 'clsx'
import CheckIcon from '@material-ui/icons/Check'
import ClearIcon from '@material-ui/icons/Clear'
import IconButton from '@material-ui/core/IconButton'
import TableMultiGrid from '../common/Table_MultiGrid'
import GridMultiGrid from '../common/Grid_MultiGrid'
import {
  getUnitaryAnalysisCompoundById,
  getUnitaryAnalysisFilteredById,
} from '../../redux/unitaryAnalyses/selectors'
import {
  callValidationDispatch,
  checkBatchRetired,
  convertMassConcentrationValue,
  convertVolumeConcentrationValue,
  getClickAction,
  handleValidation,
  resetUnitaryAnalyses,
  saveUaParameters,
  testBatchLease,
  updateMainChannel,
  displayHumanReadable,
  customFormatDecimals,
  canIModifyTheCalibration,
  getHeaderTooltipCmp,
} from '../common/Utils'
import {
  StyledDialogTitle,
  TransitionLeft,
  Molecule,
  Nothing,
  Relance,
  TransitionTop,
} from '../common/Style'
import Paper from '@material-ui/core/Paper'
import AlphacodFlagIcon from '../common/AlphacodFlagIcon'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import Draggable from 'react-draggable'
import Toolbar from '@material-ui/core/Toolbar'
import Divider from '@material-ui/core/Divider'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'
import { Tooltip } from '@material-ui/core'
import InputBase from '@material-ui/core/InputBase'
import Calibration from '../common/Calibration'
import PersonPinIcon from '@material-ui/icons/PersonPin'
import Badge from '@material-ui/core/Badge'
import SettingsIcon from '@material-ui/icons/Settings'
import CardMedia from '@material-ui/core/CardMedia'
import {
  CONFIGURATION_PROFILE,
  DRAWER_WIDTH,
  LAYOUT_CONFIG_VALUE,
  PUBLIC_URL,
} from '../../utils/config'
import Box from '@material-ui/core/Box'
import Slide from '@material-ui/core/Slide'
import { getAnalysisById } from '../../redux/analyses/selectors'
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore'
import Chip from '@material-ui/core/Chip'
import WarningIcon from '@material-ui/icons/Warning'
import AnalysisMultiSelectionDialog from '../dialog/AnalysisMultiSelectionDialog'
import PanToolIcon from '@material-ui/icons/PanTool'
import EditIcon from '@material-ui/icons/Edit'
import EditLocationIcon from '@material-ui/icons/EditLocation'
import AlphacodModifiedButtonIcon from '../common/AlphacodModifiedButtonIcon'
import MetaboliteBadge from '../common/MetaboliteBadge'
import NoteButton from '../common/NoteButton'
import { displayPopupMessage } from '../common/Message'
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import RetiredCmp from '../common/RetiredCmp'
import { fetchFilters } from '../../redux/filters/actions'
import AIScoreTableTooltip from '../common/AIScoreTableTooltip'
import { fetchChangeSettings } from '../../redux/settings/actions'
import ErrorBoundaryGuard from '../ErrorBoundaryGuard'
import { withTranslation } from 'react-i18next'
import { t } from 'i18next'
import { Trans } from 'react-i18next'
import { fetchConfigurationFeatures } from '../../redux/configuration/actions'
import EmptyAnalysis from './EmptyAnalysis'
import { fetchDisplayChromatoDetail } from '../../redux/detail/actions'
import PersonIcon from '@mui/icons-material/Person'
import AutoGraphIcon from '@mui/icons-material/AutoGraph'

const styles = (theme) => ({
  tableContainer: {
    height: '100%',
  },
  mainSplitter: {
    height: 'inherit',
    paddingLeft: 60,
    '& > .layout-splitter': {
      display: 'none',
    },
  },
  mainSplitterFull: {
    height: 'calc(100% - 28px)',
    // paddingLeft: 241,
    '& > .layout-splitter': {
      display: 'none',
    },
  },
  centerSplitter: {
    padding: '0px 5px',
    '& > .layout-pane': {
      paddingTop: 0,
      overflow: 'hidden',
    },
    '& > .layout-pane:first-child': {
      padding: '0px 5px 12px 2px',
      height: 'auto !important',
      // minHeight: 110,
    },
    '& > .layout-splitter': {
      height: '0 !important',
      opacity: 0,
    },
  },
  containerSplitter: {
    '& > .layout-pane': {
      padding: '6px 5px 0px 0px',
      overflow: 'hidden',
    },
    '& > .layout-pane:first-child': {
      padding: '0px 5px 6px 2px',
    },
  },
  deeperSplitter: {
    '& > .layout-pane': {
      padding: '1px 6px 14px 5px',
      overflow: 'hidden',
    },
    '& > .layout-pane:first-child': {
      padding: '13px 5px 2px 2px',
      minWidth: 580,
    },
  },
  containerCustomSplitter: {
    '& > .layout-pane': {
      paddingTop: 4,
      overflow: 'hidden',
    },
    '& > .layout-pane:first-child': {
      top: 3,
      padding: '13px 5px 0px 2px',
      minWidth: 580,
    },
  },
  deeperCustomSplitter: {
    '& > .layout-pane': {
      padding: '5px 5px 12px 5px',
      overflow: 'hidden',
    },
    '& > .layout-pane:first-child': {
      padding: '0px 5px 5px 5px',
    },
  },
  actionContainer: {
    '& button': {
      padding: 0,
      marginRight: 5,
      width: 24,
    },
    '& > button:hover': {
      color: theme.palette.secondary.main,
      backgroundColor: 'transparent',
    },
  },
  toolbarButton: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.black, 0.03),
    // backgroundColor: 'white',
    marginRight: 5,
    width: 34,
    height: 34,
    padding: 8,
    '&:hover': {
      color: theme.palette.secondary.main,
      backgroundColor: fade(theme.palette.common.black, 0.03),
    },
  },
  selectedRow: {
    cursor: 'pointer',
    background: 'rgba(0, 0, 0, 0.1)',
  },
  blink: {
    // animation: 'blink-fade 1s ease-in-out 0s',
    background: fade(theme.palette.secondary.light, 0.19),
    '&:hover': {
      background: fade(theme.palette.secondary.light, 0.37),
    },
  },
  selectedRowBlink: {
    background: fade(theme.palette.secondary.light, 0.37),
  },
  noBlink: {
    cursor: 'pointer',
  },
  success: {
    color: theme.palette.common.success,
  },
  error: {
    color: theme.palette.common.error,
  },
  noInfo: {
    opacity: 0,
  },
  multiGrid: {
    // boxShadow:
    //   '0px 2px 7px 1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    padding: 10,
    '& .ReactVirtualized__Grid:focus': {
      outline: 'none',
    },
  },
  cellFlags: {
    padding: 7,
  },
  selected: {
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  notSelected: {
    opacity: 0.5,
    '&:hover': {
      opacity: 1,
    },
  },
  formControl: {
    width: '100%',
    '& .MuiInput-root::before': {
      display: 'none',
    },
    '& .MuiSelect-select.MuiSelect-select': {
      padding: '0px 24px 0px 0px !important',
      fontSize: 14,
      textOverflow: 'ellipsis',
    },
  },
  typography: {
    padding: theme.spacing(2),
  },

  whiteSpace: {
    flexGrow: 1,
  },
  tooltip: {
    fontSize: 13,
    padding: 5,
  },
  requestedIcon: {
    opacity: 0.6,
  },
  toolbar: {
    position: 'absolute',
    top: 1,
    left: 62,
    background: '#fff',
    padding: 5,
    paddingRight: 1,
    border: '2px solid',
    borderColor: theme.palette.secondary.main,
    borderRadius: 8,
  },
  settingsIcon: {
    position: 'absolute',
  },
  settingsButton: {
    '&:hover': {
      color: theme.palette.secondary.main,
      backgroundColor: 'transparent',
    },
  },
  header: {
    boxShadow: 'inset 0px 0px 82px 10px rgba(0,0,0,1)',
  },
  cardImg: {
    opacity: 0.8,
  },
  title: {
    position: 'absolute',
    top: 100,
    left: 34,
    fontSize: 40,
    color: theme.palette.secondary.main,
    textShadow: '2px 1px 10px white',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: 'calc(100% - 40px)',
    whiteSpace: 'nowrap',
  },
  moveChip: {
    margin: 3,
    marginBottom: 15,
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
  },
  warning: {
    color: theme.palette.common.warning,
    position: 'relative',
    left: -30,
  },
  firstSplitter: {
    height: 'calc(100% - 64px)',
    '& > .layout-pane': {
      padding: '1px 8px 14px 0px',
      overflow: 'hidden',
    },
    '& > .layout-pane:first-child': {
      padding: '13px 0px 2px 2px',
      minWidth: DRAWER_WIDTH(),
    },
  },
  transitionNumber: {
    border: '1px solid',
    borderRadius: 14,
    padding: '0px 8px',
    marginRight: 10,
  },
  picIconColor: {
    opacity: 0.5,
    color: 'black',
    marginTop: -2,
  },
})

const mapStateToProps = (state, ownProps) => {
  const { batches, unitaryAnalyses, drawer, analyses, configuration, settings, global } = state
  const batch = getBatchById(batches, ownProps.match.params.batchId)
  const matrice = analyses.items.find(
    (analysis) => analysis._id === ownProps.match.params.analysisId,
  )?.content?.sample_infos?.matrice
  return {
    batch,
    pinDrawer: drawer.pin,
    store: unitaryAnalyses,
    batchNotFound: batches.batchNotFound,
    authenticationError: batches.error,
    unitaryAnalysesFiltered: unitaryAnalyses.unitaryAnalysesFiltered,
    isFetchingUnitaryAnalysesFiltered: unitaryAnalyses.isFetchingUnitaryAnalysesFiltered,
    allUnitaryAnalysesIstd: unitaryAnalyses.allUnitaryAnalysesIstd,
    isFetchingAllUnitaryAnalysesIstd: unitaryAnalyses.isFetchingAllUnitaryAnalysesIstd,
    configFeatures: configuration.configFeatures,
    isFetchingConfigFeatures: configuration.isFetchingConfigFeatures,
    isDatabaseOffline: settings.configurationDatabase.length > 0,
    isGlobalConfigsLoaded: global.isGlobalConfigsLoaded,
    customerConfig: global.customerConfig,
    config: state.configuration.configurations.filter(
      (config) => config._id === CONFIGURATION_PROFILE(),
    )[0],
    featuresConfig: global.featuresConfig,
    matrixes: [
      {
        [ownProps.match.params.analysisId]: matrice,
      },
    ],
  }
}

const logout = (props) => {
  console.log("Please login, you're not logged.")
  localStorage.removeItem('SESSION_user_name')
  localStorage.removeItem('SESSION_user_json')
  props.history.push('/?login')
}

let parametersUpdated = {}

const StyledBadge = withStyles((theme) => ({
  badge: {
    background: '#d3d3d340',
    padding: 5,
    height: 14,
    color: '#000000b8',
  },
}))(Badge)

export default withRouter(
  connect(mapStateToProps)(
    withStyles(styles)(
      withTranslation()(
        class Analysis extends React.Component {
          constructor(props) {
            super(props)
            this.state = {
              error: null,
              isLoaded: false,
              isLoadedAnalyses: false,
              isLoadedBatch: false,
              isFetching: false,
              items: [],
              analyse: null,
              batch: null,
              isFetchingUnitaryAnalysesFiltered: false,
              open: false,
              newMainChannel: 0,
              itemToUpdate: null,
              selection: [],
              scrollTableId: 0,
              tableItemSelectedForColor: null,
              search: '',
              timer: null,
              openSettings: false,
              settings: {},
              settingsInfo: {},
              isKOOK: '',
              openConfirmReset: false,
              resetUA: null,
              multiReset: false,
              openSelectionMultiDialog: false,
              selectionType: [],
              contextMenu: null,
            }

            this.navigationRef = React.createRef()
            this.contentRef = React.createRef()
            this.contentCalibrationRef = React.createRef()
            this.tableMultiGridRef = React.createRef()
            this.contentCustomRef = React.createRef()
            this.contentCustomCalibrationRef = React.createRef()
            this.contentFirstCntRef = React.createRef()
            this.onSelect = this.onSelect.bind(this)
            this.multiValidate = this.multiValidate.bind(this)
            this.handleReset = this.handleReset.bind(this)
            this.handleConfirmResetClose = this.handleConfirmResetClose.bind(this)
            this.openSelectionMultipleDialog = this.openSelectionMultipleDialog.bind(this)
            this.handleMultiSelectionConfirm = this.handleMultiSelectionConfirm.bind(this)

            this.elementRef = null
            this.handleKeyDown = this.handleKeyDown.bind(this)
          }
          refCalibration = React.createRef()
          refGridChromato = React.createRef()
          refTableAnalyse = React.createRef()

          handleKeyDown(event) {
            const { dispatch, unitaryAnalysesFiltered } = this.props
            const { tableItemSelectedForColor } = this.state
            let next_item = null

            if (event.keyCode === 38 && unitaryAnalysesFiltered.length > 1) {
              // Flèche haut
              if (tableItemSelectedForColor) {
                // Select the next item
                let currentIndex = null
                unitaryAnalysesFiltered.forEach((analysis, index) => {
                  if (analysis._id === tableItemSelectedForColor) {
                    currentIndex = index
                    return
                  }
                })
                next_item = unitaryAnalysesFiltered[currentIndex - 1]
                // ? unitaryAnalysesFiltered[currentIndex - 1]
                // : unitaryAnalysesFiltered[unitaryAnalysesFiltered.length - 1]
              } else {
                // Select the last item
                next_item = unitaryAnalysesFiltered[unitaryAnalysesFiltered.length - 1]
              }
            } else if (event.keyCode === 40 && unitaryAnalysesFiltered.length > 1) {
              // Flèche bas
              if (tableItemSelectedForColor) {
                // Select the next item
                let currentIndex = null
                unitaryAnalysesFiltered.forEach((analysis, index) => {
                  if (analysis._id === tableItemSelectedForColor) {
                    currentIndex = index
                    return
                  }
                })
                next_item = unitaryAnalysesFiltered[currentIndex + 1]
                // ? unitaryAnalysesFiltered[currentIndex + 1]
                // : unitaryAnalysesFiltered[0]
              } else {
                // Select the first item
                next_item = unitaryAnalysesFiltered[0]
              }
            }
            if (next_item) {
              this.setState({ scrollTableId: next_item._id })
              this.setState({ tableItemSelectedForColor: next_item._id })
              this.refGridChromato.current.setGridItemSelectedForColor(next_item._id)
              this.refCalibration.current.displayCalib(
                next_item.event,
                next_item.selectedChannel,
                null,
                next_item,
              )
              this.refGridChromato.current.scrollTo(next_item._id)
            }
          }

          async componentDidMount() {
            const { dispatch, isDatabaseOffline, match, history } = this.props
            if (!isDatabaseOffline) {
              // Open the chromato detail if an ID is set in the URL
              const chromatoId = match.params.chromatoId
              if (chromatoId) {
                const regex = /ua=/i
                dispatch(fetchDisplayChromatoDetail(chromatoId.replace(regex, '')))
              }

              let scope = this
              const analysisId = this.props.match.params.analysisId
              const initRequested = localStorage.getItem('ANALYSIS_filter_requested')
                ? JSON.parse(localStorage.getItem('ANALYSIS_filter_requested')) === true
                : true
              const initUntreated = localStorage.getItem('ANALYSIS_filter_untreated')
                ? JSON.parse(localStorage.getItem('ANALYSIS_filter_untreated')) === true
                : false
              const initValidationDetected = localStorage.getItem('ANALYSIS_filter_detected')
                ? JSON.parse(localStorage.getItem('ANALYSIS_filter_detected')) === true
                : false
              const initValidationNotDetected = localStorage.getItem('ANALYSIS_filter_notDetected')
                ? JSON.parse(localStorage.getItem('ANALYSIS_filter_notDetected')) === true
                : false
              const initValidationRelaunch = localStorage.getItem('ANALYSIS_filter_relaunch')
                ? JSON.parse(localStorage.getItem('ANALYSIS_filter_relaunch')) === true
                : false
              dispatch(fetchAnalyses(this.props.match.params.batchId)).then(function (resp) {
                const analyse = resp.analyses.filter((analyse) => analyse._id === analysisId)[0]
                if (!analyse) {
                  displayPopupMessage(
                    dispatch,
                    'warning',
                    t('view.analysis.analysis.analysis_not_found'),
                    <Trans i18nKey="view.analysis.analysis.analysis_with_id">
                      The analysis with id {{ val: analysisId }} can not be found.
                    </Trans>,
                  )
                  history.push('/')
                } else {
                  const isKOOK =
                    analyse.content.type !== 'sample' && analyse.content.type !== 'other'
                  const classification = !isKOOK
                    ? 'ANALYSIS_filter_classification'
                    : 'ANALYSIS_filter_classification2'
                  const initFilter = localStorage.getItem(classification)
                    ? parseInt(localStorage.getItem(classification))
                    : !isKOOK
                    ? 1
                    : 4
                  scope.setState({ isKOOK: isKOOK })
                  // ['isKOOK'] = isKOOK
                  dispatch(
                    fetchUnitaryAnalysesFiltered(
                      analysisId,
                      initFilter,
                      initRequested,
                      initUntreated,
                      initValidationDetected,
                      initValidationNotDetected,
                      initValidationRelaunch,
                      localStorage.getItem('ANALYSIS_sorter'),
                    ),
                  )
                  dispatch(fetchFilters(analyse.content.chromatography_type))
                  if (localStorage.getItem('SETTINGS_chromato_display') === 'istd_combi') {
                    dispatch(fetchUnitaryAnalysesAllISTD(analysisId))
                  }
                }
              })
              dispatch(fetchBatch(this.props.match.params.batchId)).then(function (resp) {
                if (resp && resp.type === 'ITEM_NOT_FOUND') {
                  displayPopupMessage(
                    dispatch,
                    'warning',
                    'Batch not found',
                    <Trans i18nKey="view.analysis.analysis.not_found_id">
                      The batch with id {{ val: match.params.batchId }} can not be found.
                    </Trans>,
                  )
                  history.push('/')
                }
                // Load the configuration features from the batch
                if (resp && resp.batch && resp.batch.content && resp.batch.content.configuration) {
                  dispatch(fetchConfigurationFeatures(resp.batch.content.configuration)).then(
                    function (resp) {
                      // Store the batch configuration id
                      resp.configFeatures?._id &&
                        localStorage.setItem(
                          'SETTINGS_configuration_profile',
                          resp.configFeatures._id,
                        )
                    },
                  )
                }
              })
            }
          }
          componentDidUpdate() {
            if (this.elementRef) {
              this.elementRef.addEventListener('keydown', this.handleKeyDown)
            }
          }
          componentWillUnmount() {
            if (this.elementRef) {
              this.elementRef.removeEventListener('keydown', this.handleKeyDown)
            }
          }

          applyFromSelection() {
            const { unitaryAnalysesFiltered, isFetchingUnitaryAnalysesFiltered } = this.props

            if (!isFetchingUnitaryAnalysesFiltered && unitaryAnalysesFiltered) {
              const fromSelection = unitaryAnalysesFiltered.filter(
                (uaf) =>
                  uaf.event === parseInt(localStorage.getItem('NAVIGATION_current_moleculeEvent')),
              )[0]
              if (fromSelection) {
                localStorage.removeItem('NAVIGATION_current_moleculeEvent')
                setTimeout(
                  function () {
                    // this.scrollToTable(fromSelection)
                    // this.scrollToGrid(fromSelection)

                    this.setState({
                      tableItemSelectedForColor: fromSelection._id,
                      scrollTableId: fromSelection._id,
                    })
                    if (this.refGridChromato.current) {
                      this.refGridChromato.current.setGridItemSelectedForColor(fromSelection._id)
                    }
                    if (this.refCalibration.current) {
                      this.refCalibration.current.displayCalib(
                        fromSelection.event,
                        fromSelection.mainChannel,
                        null,
                        fromSelection,
                      )
                    }
                  }.bind(this),
                  500,
                )
              }
            }
          }

          scrollToTable(item) {
            const { dispatch } = this.props
            this.setState({ tableItemSelectedForColor: item._id })
            this.setState({ scrollTableId: item._id })
            this.refGridChromato.current.setGridItemSelectedForColor(item._id)
            this.refCalibration.current.displayCalib(item.event, item.selectedChannel, null, item)
            dispatch(fetchQCUnitaryAnalysesCompound(this.props.match.params.batchId, item.event))
          }
          scrollToGrid(item) {
            const { dispatch } = this.props
            this.setState({ tableItemSelectedForColor: item._id })
            this.refGridChromato.current.scrollTo(item._id)
            this.refCalibration.current.displayCalib(item.event, item.selectedChannel, null, item)
            dispatch(fetchQCUnitaryAnalysesCompound(this.props.match.params.batchId, item.event))
          }
          onChangeChannel(event, ua, mainChannel) {
            const { dispatch } = this.props
            event.stopPropagation()
            event.preventDefault()
            dispatch(fetchChangeSelectedChannel(ua._id, mainChannel, 'analyse')).then(
              this.setState({ tableItemSelectedForColor: ua._id }),
              this.refGridChromato.current.setGridItemSelectedForColor(ua._id),
              this.refCalibration.current.displayCalib(ua.event, mainChannel, null, ua),
            )
          }

          multiValidate(value) {
            this.tableMultiGridRef.current.clearSelection()
            const selection = this.state.selection
            const dispatch = this.props.dispatch
            callValidationDispatch(
              'unitary_analysis',
              dispatch,
              selection,
              value,
              null,
              this.props.match.params.batchId,
            )
          }

          handleMultiSelectionConfirm(event, option) {
            const { selection } = this.state
            let customSelection = []
            switch (option) {
              case 1:
                // Keep all selection do nothing
                break
              case 2:
                // Keep samples
                selection.forEach(function (sel, index) {
                  if (sel.status !== 'ok' && sel.status !== 'ko') {
                    customSelection.push(index)
                  }
                })
                this.tableMultiGridRef.current.customSelection(customSelection)
                break
              case 3:
                // Keep others
                selection.forEach(function (sel, index) {
                  if (sel.status === 'ok' || sel.status === 'ko') {
                    customSelection.push(index)
                  }
                })
                this.tableMultiGridRef.current.customSelection(customSelection)
                break
              default:
                this.tableMultiGridRef.current.customSelection(customSelection)
                break
            }
            this.setState({ openSelectionMultiDialog: false })
          }

          openSelectionMultipleDialog(rows) {
            const { unitaryAnalysesFiltered } = this.props
            const infos = []
            rows.map(function (i) {
              let type = 'detectedNotDetected'
              if (
                unitaryAnalysesFiltered[i].status === 'ok' ||
                unitaryAnalysesFiltered[i].status === 'ko'
              ) {
                type = 'okKo'
              }
              infos[type] = infos[type] ? infos[type] + 1 : 1
              return type
            })

            if (Object.keys(infos).length > 1) {
              this.setState({
                openSelectionMultiDialog: true,
              })
            }
          }

          moleculeDoubleClick(event) {
            this.props.history.push('/?' + this.props.match.params.batchId + '/compound/' + event)
          }

          canEditCalib(item) {
            const { batch, featuresConfig } = this.props
            const edit_calibration = featuresConfig?.content?.edit_calibration
            const param_exist = typeof edit_calibration === 'boolean'
            const getRights = canIModifyTheCalibration(batch)
            // In this case we want to know if the item can be edited or not
            if (item) {
              if (item.sampleType === 'Standard') {
                return param_exist ? edit_calibration && getRights : true
              } else {
                return true
              }
            } else {
              // Here it's to know if the edition is active or not
              return param_exist ? edit_calibration && getRights : true
            }
          }

          onSelect(selected) {
            const { unitaryAnalysesFiltered } = this.props
            let selection = selected.map((i) => unitaryAnalysesFiltered[i])
            const infos = []
            if (!this.canEditCalib()) {
              const updatedSelected = selected.filter(
                (s) => unitaryAnalysesFiltered[s]?.sampleType !== 'Standard',
              )
              const updatedSelection = updatedSelected.map((i) => unitaryAnalysesFiltered[i])
              if (updatedSelection.length !== selected.length) {
                selected = updatedSelected
                selection = updatedSelection
                this.tableMultiGridRef.current.customSelection(updatedSelected)
                displayPopupMessage(
                  this.props.dispatch,
                  'info',
                  t('view.batch.qualityControlTable.cantSelect.title'),
                  t('view.batch.qualityControlTable.cantSelect.msg'),
                )
              }
            }
            const types = selected
              .map(function (i) {
                let type = 'detectedNotDetected'
                if (
                  unitaryAnalysesFiltered[i].status === 'ok' ||
                  unitaryAnalysesFiltered[i].status === 'ko'
                ) {
                  type = 'okKo'
                }
                infos[type] = infos[type] ? infos[type] + 1 : 1
                return type
              })
              .filter(function (item, pos, self) {
                return self.indexOf(item) === pos
              })
              .map((type) => ({ name: type, value: infos[type] }))
            this.setState({
              selection: selection,
              selectionType: types,
            })
          }

          handleConfirmResetClose(event, save) {
            const { resetUA, multiReset, selection } = this.state
            const { dispatch } = this.props
            this.setState({ openConfirmReset: false })
            if (save) {
              let uAIds = [resetUA]
              if (multiReset) {
                uAIds = selection
              }
              resetUnitaryAnalyses(event, uAIds, dispatch)
            }
          }

          handleReset(event, multi, unitaryAnalyse) {
            event.stopPropagation()
            event.preventDefault()
            this.setState({ openConfirmReset: true, resetUA: unitaryAnalyse, multiReset: multi })
          }

          render() {
            const {
              classes,
              pinDrawer,
              dispatch,
              batch,
              store,
              batchNotFound,
              unitaryAnalysesFiltered,
              isFetchingUnitaryAnalysesFiltered,
              allUnitaryAnalysesIstd,
              isFetchingAllUnitaryAnalysesIstd,
              configFeatures,
              isFetchingConfigFeatures,
              isDatabaseOffline,
              isGlobalConfigsLoaded,
              customerConfig,
              i18n,
              config,
              featuresConfig,
              matrixes,
            } = this.props
            const {
              error,
              open,
              itemToUpdate,
              newMainChannel,
              openSettings,
              settings,
              settingsInfo,
              isKOOK,
              openConfirmReset,
              resetUA,
              multiReset,
              openSelectionMultiDialog,
              selection,
              selectionType,
              newSize,
              contextMenu,
            } = this.state

            const me = this
            const currentLanguage = i18n.language.slice(0, 2)
            const parentMultiGridRef = React.createRef()
            const layout = LAYOUT_CONFIG_VALUE('analysis')
            const idTableConst = 'myAnalyseMoleculesTable'
            const tableMultiGridRef = this.tableMultiGridRef
            //Save the current UA for futur navigation in other panel
            localStorage.setItem(
              'NAVIGATION_current_analysisId',
              this.props.match.params.analysisId,
            )

            const setContextMenu = (context) => {
              this.setState({ contextMenu: context })
            }

            const selectionEmpty = this.state.selection.length === 0
            const selectionMulti = this.state.selectionType.length === 2
            const selectedRowsLength = this.state.selection.length
            let settingsTimer = null

            const aiAssistantActivated =
              featuresConfig &&
              typeof featuresConfig?.content?.ai_assistant === 'boolean' &&
              featuresConfig?.content?.ai_assistant

            const concentration_report =
              config &&
              config.content.analysis_parameters_by_analysis &&
              config.content.analysis_parameters_by_analysis.sample &&
              config.content.analysis_parameters_by_analysis.sample.formula_infos &&
              config.content.analysis_parameters_by_analysis.sample.formula_infos.unite
                .slice(-1)
                .toLowerCase() === 'l'
                ? 'vol'
                : 'mass'

            if (this.props.authenticationError) {
              // logout(this.props)
              dispatch(fetchChangeSettings('loginPopup', true))
            }
            if (error) {
              return <div>{t('view.analysis.analysis.error') + error.message}</div>
            }
            if (isDatabaseOffline) {
              return <EmptyAnalysis />
            }
            // if (batchNotFound) {
            //   displayPopupMessage(
            //     dispatch,
            //     'warning',
            //     'Batch not found',
            //     <Trans i18nKey="view.analysis.analysis.not_found_id">
            //       The batch with id {{ val: this.props.match.params.batchId }} can not be found.
            //     </Trans>,
            //   )
            //   this.props.history.push('/')
            //   return <EmptyAnalysis />
            // }
            if (
              /*isFetchingUnitaryAnalysesFiltered || isFetchingUnitaryAnalyses ||*/ !batch ||
              isFetchingConfigFeatures
            ) {
              return <Loading mode="alpha" size={10} />
            }

            // testBatchLease(batch, dispatch, this.props.history)

            const onWhichUpdateMainChannel = function (event, item) {
              this.setState({ open: true, newMainChannel: event.target.value, itemToUpdate: item })
            }.bind(this)

            const handleClose = function (event, multiAnalyse) {
              this.setState({ open: false })
              if (typeof multiAnalyse === 'boolean')
                updateMainChannel(dispatch, newMainChannel, itemToUpdate, 'analyse', multiAnalyse)
            }.bind(this)

            const handleSettings = function (event, item) {
              parametersUpdated = item.parameters
              this.setState({
                openSettings: true,
                settings: item.parameters,
                settingsInfo: { name: item.name, id: item._id, type: item.sampleType },
              })
            }.bind(this)

            const handleSettingsClose = function (save) {
              const { settings, settingsInfo } = this.state
              this.setState({ openSettings: false })
              if (save) {
                saveUaParameters(
                  dispatch,
                  parametersUpdated,
                  settingsInfo,
                  this.props.match.params.batchId,
                  this.props.match.params.analysisId,
                )
              }
            }.bind(this)

            const handleSettingChange = function (event, forceOnBlur) {
              const { settings } = this.state
              const key = event.currentTarget.id
              const value = parseFloat(event.currentTarget.value)

              clearTimeout(settingsTimer)
              if (forceOnBlur) {
                // this.setState({ settings: { ...settings, [key]: value } })
                parametersUpdated = { ...parametersUpdated, [key]: value }
              } else {
                settingsTimer = setTimeout(function () {
                  // this.setState({ settings: { ...settings, [key]: value } })
                  parametersUpdated = { ...parametersUpdated, [key]: value }
                }, 1500)
              }
            }.bind(this)

            const onSplitterSizeSave = function () {
              localStorage.setItem(
                'LAYOUT_navigation_secondaryPaneSize',
                this.navigationRef.current.state.secondaryPaneSize,
              )
            }.bind(this)

            const onContentSplitterSizeSave = function () {
              localStorage.setItem(
                'LAYOUT_analyses_secondaryPaneSize',
                this.contentRef.current.state.secondaryPaneSize,
              )
              this.refGridChromato.current.updateCharts()
            }.bind(this)

            const onContentFirstSplitterSizeSave = function () {
              localStorage.setItem(
                'LAYOUT_first_secondaryPaneSize',
                this.contentFirstCntRef.current.state.secondaryPaneSize,
              )
            }.bind(this)

            const onContentCalibrationSplitterSizeSave = function () {
              localStorage.setItem(
                'LAYOUT_calibration_secondaryPaneSize',
                this.contentCalibrationRef.current.state.secondaryPaneSize,
              )
              this.refGridChromato.current.updateCharts()
            }.bind(this)

            const onContentCustomSplitterSizeSave = function () {
              localStorage.setItem(
                'LAYOUT_compound_secondaryPaneSize_custom',
                this.contentCustomRef.current.state.secondaryPaneSize,
              )
              this.refGridChromato.current.updateCharts()
            }.bind(this)

            const onContentCustomCalibrationSplitterSizeSave = function () {
              localStorage.setItem(
                'LAYOUT_calibration_secondaryPaneSize_custom',
                this.contentCustomCalibrationRef.current.state.secondaryPaneSize,
              )
              this.refGridChromato.current.updateCharts()
            }.bind(this)

            if (localStorage.getItem('NAVIGATION_current_moleculeEvent')) {
              this.applyFromSelection()
            }

            const reportedConcentrationUnit =
              concentration_report === 'vol'
                ? localStorage.getItem('SETTINGS_vol_reported_concentration_unit')
                  ? localStorage.getItem('SETTINGS_vol_reported_concentration_unit')
                  : 'µg/l'
                : localStorage.getItem('SETTINGS_mass_reported_concentration_unit')
                ? localStorage.getItem('SETTINGS_mass_reported_concentration_unit')
                : 'mg/kg'

            const volumeConcentrationUnit = localStorage.getItem(
              'SETTINGS_volume_concentration_unit',
            )
              ? localStorage.getItem('SETTINGS_volume_concentration_unit')
              : 'µg/l'

            const getGlobalScoreTitle = function () {
              if (
                isGlobalConfigsLoaded &&
                customerConfig &&
                customerConfig.content.hasOwnProperty('columnIA') &&
                currentLanguage &&
                customerConfig.content.columnIA.hasOwnProperty(currentLanguage)
              ) {
                return customerConfig.content.columnIA[currentLanguage]
              } else {
                return t('view.analysis.analysis.column.ai_score')
              }
            }

            let columns = [
              {
                key: 'flags',
                label: t('view.analysis.analysis.column.flags'),
                size: 65,
                type: 'custom',
              },
              //  { key: 'notes', label: 'Notes', size: 60 },
              {
                key: 'requested',
                label: t('view.analysis.analysis.column.requested'),
                size: 60,
                type: 'boolean',
                customDisplay: function (value) {
                  if (value === 'True') {
                    return <PersonPinIcon title="Requested" className={classes.requestedIcon} />
                  }
                  return ''
                },
              },
              {
                key: 'modified',
                label: t('view.analysis.analysis.column.modif'),
                type: 'boolean',
                size: 75,
              },
              {
                key: 'peak',
                label: t('view.analysis.analysis.column.modifPeak'),
                type: 'boolean',
                size: 75,
              },
              {
                key: 'event',
                label: t('view.analysis.analysis.column.event'),
                type: 'number',
                size: 65,
              },
              {
                key: 'peakName',
                label: t('view.analysis.analysis.column.peak'),
                size: 180,
                type: 'text',
                customDisplay: function (molecule) {
                  // let name = molecule.known_name ? molecule.known_name : molecule.name
                  return (
                    <ErrorBoundaryGuard isDialog>
                      <MetaboliteBadge molecule={molecule} />
                    </ErrorBoundaryGuard>
                  )
                },
              },
              {
                key: 'status',
                type: 'text',
                label: t('view.analysis.analysis.column.status'),
                size: 100,
              },
              {
                key: 'global_score',
                hideWikiTooltip:
                  isGlobalConfigsLoaded &&
                  customerConfig &&
                  customerConfig.content.hasOwnProperty('columnIA') &&
                  customerConfig.content.columnIA.hasOwnProperty('hideWikiTooltip') &&
                  customerConfig.content.columnIA.hideWikiTooltip,
                label: getGlobalScoreTitle(),
                type: 'number',
                size: 100,
                customDisplay: function (value, item, columnInfos) {
                  const expertMode = localStorage.getItem('SETTINGS_expert_mode') === 'true'
                  const returnValue =
                    value && value !== '' && value !== null
                      ? // ? displayHumanReadable(value * 100, 2, 2, true) + '%'
                        customFormatDecimals(
                          value * 100,
                          displayHumanReadable(value * 100, 2, 2, true),
                          columnInfos,
                        ) + '%'
                      : value
                  return expertMode ? (
                    <ErrorBoundaryGuard isDialog>
                      <AIScoreTableTooltip item={item} value={returnValue} />
                    </ErrorBoundaryGuard>
                  ) : (
                    returnValue
                  )
                },
              },
              {
                key: 'validation',
                label: t('view.analysis.analysis.column.validation'),
                type: 'custom',
                size: 100,
              },
              {
                key: 'recovery',
                label: t('view.analysis.analysis.column.recovery'),
                size: 110,
                type: 'number',
                customDisplay: function (value, item, columnInfos) {
                  return typeof value === 'number'
                    ? customFormatDecimals(
                        value * 100,
                        displayHumanReadable(value * 100, 2, 2, true),
                        columnInfos,
                      ) + '%'
                    : value
                },
              },
              {
                key: 'recovery_factor',
                label: t('view.analysis.analysis.column.recovery_factor'),
                size: 110,
                type: 'number',
                customDisplay: function (value, item, columnInfos) {
                  return typeof value === 'number'
                    ? customFormatDecimals(value, displayHumanReadable(value, 2, 2), columnInfos)
                    : value
                },
              },
            ]

            if (configFeatures && typeof configFeatures.lmr === 'boolean' && configFeatures.lmr) {
              columns.push({
                key: 'lmr',
                label: t('view.analysis.analysis.column.lmr'),
                size: 100,
                type: 'text',
                // customDisplay: function (value) {
                //   if (value && value !== '' && value !== null) return value.toFixed(1)
                //   return value
                // },
              })
            }

            columns.push(
              ...[
                {
                  key: 'rt',
                  label: t('view.analysis.analysis.column.rt_cal'),
                  size: 85,
                  type: 'number',
                  customDisplay: function (value, item, columnInfos) {
                    if (value && value !== '' && value !== null)
                      return customFormatDecimals(value, value.toFixed(1), columnInfos)
                    return value
                  },
                },
                {
                  key: 'dev_ion_ratio',
                  label: t('view.analysis.analysis.column.dev_ion_ratio'),
                  size: 110,
                  type: 'number',
                  customDisplay: function (value, item, columnInfos) {
                    return value
                      ? customFormatDecimals(
                          value,
                          displayHumanReadable(value, 2, 2, true),
                          columnInfos,
                        ) + '%'
                      : value
                  },
                },
                {
                  key: 'ion_ratio',
                  label: t('view.analysis.analysis.column.ion_ratio'),
                  size: 110,
                  type: 'number',
                  customDisplay: function (value, item, columnInfos) {
                    return value
                      ? customFormatDecimals(value, displayHumanReadable(value, 2, 2), columnInfos)
                      : value
                  },
                },
                {
                  key: 'mass_conc',
                  // label: 'Mass conc',
                  label: (
                    <Trans i18nKey="view.analysis.analysis.column.conc">
                      Conc report({{ val: reportedConcentrationUnit }})
                    </Trans>
                  ),
                  size: 115,
                  type: 'number',
                  customDisplay: function (value, item, columnInfos) {
                    if (value && value !== '' && value !== null) {
                      const con_val = convertMassConcentrationValue(
                        value,
                        item,
                        null,
                        concentration_report,
                      )
                      return customFormatDecimals(
                        con_val,
                        displayHumanReadable(con_val, 4),
                        columnInfos,
                      )
                    }
                    return value
                  },
                },
              ],
            )

            for (
              let step = 1;
              step <=
              Number(
                localStorage.getItem(
                  '__config.json__NUMBER_CHANNEL_VISIBLE_FOR_VOLUMIC_CONCENTRATION',
                ),
              );
              step++
            ) {
              columns.push({
                key: `mass_conc_${step}`,
                // label: 'Mass conc',
                label: (
                  <Trans i18nKey="view.analysis.analysis.column.conc_header_ch">
                    Conc report ch.{{ val1: step }} ({{ val2: reportedConcentrationUnit }})
                  </Trans>
                ),
                size: 160,
                type: 'number',
                customDisplay: function (value, item, columnInfos) {
                  if (value && value !== '' && value !== null) {
                    const con_val = convertMassConcentrationValue(
                      value,
                      item,
                      null,
                      concentration_report,
                    )
                    return customFormatDecimals(
                      con_val,
                      displayHumanReadable(con_val, 4),
                      columnInfos,
                    )
                  }
                  return value
                },
                customTitle: function () {
                  return (
                    <StyledBadge badgeContent={'ch' + step}>
                      <Trans i18nKey="view.analysis.analysis.column.conc">
                        Conc report({{ val: reportedConcentrationUnit }})
                      </Trans>
                    </StyledBadge>
                  )
                },
              })
            }

            columns.push(
              ...[
                {
                  key: 'amt_conc',
                  // label: 'Vol conc',
                  label: (
                    <Trans i18nKey="view.analysis.analysis.column.conc_inj">
                      Conc({{ val: volumeConcentrationUnit }})
                    </Trans>
                  ),
                  size: 100,
                  type: 'number',
                  customDisplay: function (value, item, columnInfos) {
                    if (value && value !== '' && value !== null) {
                      // return convertVolumeConcentrationValue(displayHumanReadable(value, 4), item)
                      return customFormatDecimals(
                        convertVolumeConcentrationValue(value, item),
                        convertVolumeConcentrationValue(displayHumanReadable(value, 4), item),
                        columnInfos,
                      )
                    }
                    return value
                  },
                },
                {
                  key: 'mass_conc_rdt',
                  label: (
                    <Trans i18nKey="view.analysis.analysis.column.conc_rdt">
                      Conc RDT({{ val: reportedConcentrationUnit }})
                    </Trans>
                  ),
                  size: 130,
                  type: 'number',
                  customDisplay: function (value, item, columnInfos) {
                    if (value && value !== '' && value !== null) {
                      const con_val = convertMassConcentrationValue(
                        value,
                        item,
                        null,
                        concentration_report,
                      )
                      return customFormatDecimals(
                        con_val,
                        displayHumanReadable(con_val, 4),
                        columnInfos,
                      )
                    }
                    return value
                  },
                },
              ],
            )

            for (
              let step = 1;
              step <=
              Number(
                localStorage.getItem(
                  '__config.json__NUMBER_CHANNEL_VISIBLE_FOR_VOLUMIC_CONCENTRATION',
                ),
              );
              step++
            ) {
              columns.push({
                key: `amt_conc_${step}`,
                // label: 'Vol conc',
                label: (
                  <Trans i18nKey="view.analysis.analysis.column.conc_ch">
                    Conc inj ch.{{ val1: step }} ({{ val2: volumeConcentrationUnit }})
                  </Trans>
                ),
                size: 105,
                type: 'number',
                customDisplay: function (value, item, columnInfos) {
                  if (value && value !== '' && value !== null) {
                    return customFormatDecimals(
                      convertVolumeConcentrationValue(value, item),
                      convertVolumeConcentrationValue(displayHumanReadable(value, 4), item),
                      columnInfos,
                    )
                  }
                  return value
                },
                customTitle: function () {
                  return (
                    <StyledBadge badgeContent={'ch' + step}>
                      <Trans i18nKey="view.analysis.analysis.column.conc_inj">
                        Conc inj ({{ val: volumeConcentrationUnit }})
                      </Trans>
                    </StyledBadge>
                  )
                },
              })
            }

            columns.push(
              ...[
                // {
                //   key: 'amt_conc',
                //   // label: 'Vol conc',
                //   label: 'Conc(' + volumeConcentrationUnit + ')',
                //   size: 100,
                //   customDisplay: function (value, item) {
                //     if (value && value !== '' && value !== null) {
                //       return convertVolumeConcentrationValue(value, item).toFixed(4)
                //     }
                //     return value
                //   },
                // },
                {
                  key: 'loq',
                  label: (
                    <Trans i18nKey="view.analysis.analysis.column.loq">
                      LQ({{ val: volumeConcentrationUnit }})
                    </Trans>
                  ),
                  size: 100,
                  type: 'number',
                  customDisplay: function (value, item, columnInfos) {
                    if (value && value !== '' && value !== null) {
                      const con_val = convertVolumeConcentrationValue(value, item)
                      return customFormatDecimals(
                        con_val,
                        displayHumanReadable(con_val, 2, 2),
                        columnInfos,
                      )
                    }
                    return value
                  },
                },
                {
                  key: 'area',
                  label: t('view.analysis.analysis.column.area'),
                  size: 85,
                  type: 'number',
                  customDisplay: function (value, item, columnInfos) {
                    if (value && value !== '' && value !== null) {
                      return customFormatDecimals(
                        value,
                        displayHumanReadable(value, 0),
                        columnInfos,
                      )
                    }
                    return value
                  },
                },
                {
                  key: 'isArea',
                  size: 85,
                  label: t('view.analysis.analysis.column.isArea'),
                  type: 'number',
                  customDisplay: function (value, item, columnInfos) {
                    if (value && value !== '' && value !== null) {
                      return customFormatDecimals(
                        value,
                        displayHumanReadable(value, 0),
                        columnInfos,
                      )
                    }
                    return value
                  },
                },
                {
                  key: 'areaRatio',
                  size: 92,
                  label: t('view.analysis.analysis.column.area_ratio'),
                  type: 'number',
                  customDisplay: function (value, item, columnInfos) {
                    if (value && value !== '' && value !== null) {
                      return customFormatDecimals(
                        value,
                        displayHumanReadable(value, 2, 2),
                        columnInfos,
                      )
                    }
                    return value
                  },
                },
                {
                  key: 'isPeak_istdGroup',
                  label: t('view.analysis.analysis.column.istd'),
                  type: 'text',
                  size: 180,
                },
                {
                  key: 'istd_state',
                  label: t('view.analysis.analysis.column.istd_state'),
                  type: 'text',
                  size: 92,
                },
                {
                  key: 'istd_dev_area',
                  label: t('view.analysis.analysis.column.istd_dev_area') + ' (%)',
                  size: 120,
                  type: 'number',
                  customDisplay: function (value, item, columnInfos) {
                    if (value && value !== '' && value !== null) {
                      return (
                        customFormatDecimals(
                          value,
                          displayHumanReadable(value, 2, 2, true),
                          columnInfos,
                        ) + ' %'
                      )
                    }
                    return value
                  },
                },
                {
                  key: 's_n',
                  label: t('view.analysis.analysis.column.s_n'),
                  size: 85,
                  type: 'number',
                  customDisplay: function (value, item, columnInfos) {
                    if (value && value !== '' && value !== null) {
                      if (value < 100) {
                        return customFormatDecimals(
                          value,
                          displayHumanReadable(value, 1, 1),
                          columnInfos,
                        )
                      } else {
                        return '> 100'
                      }
                    }
                    return value
                  },
                },
                {
                  key: 'level',
                  label: t('view.analysis.analysis.column.level'),
                  type: 'number',
                  size: 50,
                },
                {
                  key: 'conc_target',
                  label: (
                    <Trans i18nKey="view.analysis.analysis.column.conc_target">
                      Conc target({{ val: volumeConcentrationUnit }})
                    </Trans>
                  ),
                  size: 140,
                  type: 'number',
                  customDisplay: function (value, item, columnInfos) {
                    if (value && value !== '' && value !== null) {
                      return customFormatDecimals(
                        convertVolumeConcentrationValue(value, item),
                        displayHumanReadable(convertVolumeConcentrationValue(value, item), 4),
                        columnInfos,
                      )
                    }
                    return value
                  },
                },
                {
                  key: 'height',
                  label: t('view.analysis.analysis.column.height'),
                  size: 100,
                  type: 'number',
                  customDisplay: function (value, item, columnInfos) {
                    if (value && value !== '' && value !== null) {
                      return customFormatDecimals(
                        value,
                        displayHumanReadable(value, 2, 2),
                        columnInfos,
                      )
                    }
                    return value
                  },
                },
                {
                  key: 'type',
                  label: t('view.analysis.analysis.column.type'),
                  type: 'text',
                  size: 100,
                },
                {
                  key: 'm_z',
                  label: t('view.analysis.analysis.column.m_z'),
                  type: 'custom',
                  size: 180,
                },
                {
                  key: 'refIons',
                  label: t('view.analysis.analysis.column.refIons'),
                  type: 'custom',
                  size: 120,
                },
                {
                  key: 'rt_peak',
                  label: t('view.analysis.analysis.column.rt_peak'),
                  size: 85,
                  type: 'number',
                  customDisplay: function (value, item, columnInfos) {
                    if (value && value !== '' && value !== null) {
                      return customFormatDecimals(
                        value,
                        displayHumanReadable(value, 2, 2),
                        columnInfos,
                      )
                    }
                    return value
                  },
                },
                {
                  key: 'devRt',
                  label: t('view.analysis.analysis.column.dev_rt'),
                  size: 100,
                  type: 'number',
                  customDisplay: function (value, item, columnInfos) {
                    if (value && value !== '' && value !== null) {
                      return (
                        customFormatDecimals(
                          value,
                          displayHumanReadable(value, 2, 2, true),
                          columnInfos,
                        ) + ' %'
                      )
                    }
                    return value
                  },
                },
                {
                  key: 'devRrt',
                  label: t('view.analysis.analysis.column.dev_ret'),
                  size: 100,
                  type: 'number',
                  customDisplay: function (value, item, columnInfos) {
                    if (value && value !== '' && value !== null) {
                      return (
                        customFormatDecimals(
                          value,
                          displayHumanReadable(value, 2, 2, true),
                          columnInfos,
                        ) + ' %'
                      )
                    }
                    return value
                  },
                },
                {
                  key: 'devAccuracy',
                  label: t('view.analysis.analysis.column.dev_accu'),
                  size: 100,
                  type: 'number',
                  customDisplay: function (value, item, columnInfos) {
                    if (value && value !== '' && value !== null) {
                      return (
                        customFormatDecimals(
                          value,
                          displayHumanReadable(value, 2, 2, true),
                          columnInfos,
                        ) + ' %'
                      )
                    }
                    return value
                  },
                },
                {
                  key: 'tailing_factor',
                  label: t('view.analysis.analysis.column.tailing_factor'),
                  size: 85,
                  type: 'number',
                  customDisplay: function (value, item, columnInfos) {
                    if (value && value !== '' && value !== null) {
                      return customFormatDecimals(
                        value,
                        displayHumanReadable(value, 3, 3),
                        columnInfos,
                      )
                    }
                    return value
                  },
                },
                {
                  key: 'asymetry_factor',
                  label: t('view.analysis.analysis.column.asymetry_factor'),
                  size: 85,
                  type: 'number',
                  customDisplay: function (value, item, columnInfos) {
                    if (value && value !== '' && value !== null) {
                      return customFormatDecimals(
                        value,
                        displayHumanReadable(value, 3, 3),
                        columnInfos,
                      )
                    }
                    return value
                  },
                },
                {
                  key: 'settings',
                  label: t('view.analysis.analysis.column.actions'),
                  size: 80,
                },
              ],
            )

            const tableComponent = (
              <Paper
                elevation={0}
                style={{ height: '100%', width: '100%' }}
                className={classes.multiGrid}
                id="myAnalyseMoleculesTable"
              >
                <div className={classes.tableContainer}>
                  <ErrorBoundaryGuard>
                    <TableMultiGrid
                      contextMenu={contextMenu}
                      setContextMenu={setContextMenu}
                      matrixes={matrixes}
                      aiAssistantActivated={aiAssistantActivated}
                      chromatoGridRef={this.refGridChromato} // Ref to the chromato grid for selection
                      parentMultiGridRef={parentMultiGridRef}
                      ref={this.tableMultiGridRef}
                      collection={'unitary_analysis'}
                      batch={batch}
                      settingsOption={false}
                      onOpenMultiSelectionDialog={this.openSelectionMultipleDialog}
                      tableItemSelectedForColor={this.state.tableItemSelectedForColor}
                      scrollTo={this.state.scrollTableId}
                      onMoleculeClick={(item) => this.scrollToGrid(item)}
                      onMoleculeDoubleClick={(item) => this.moleculeDoubleClick(item.event)}
                      selection
                      onSelect={this.onSelect}
                      idTable={idTableConst}
                      orderBy={
                        localStorage.getItem('ANALYSIS_sorter')
                          ? localStorage.getItem('ANALYSIS_sorter')
                          : 'asc$event'
                      }
                      data={unitaryAnalysesFiltered}
                      isFetchingData={isFetchingUnitaryAnalysesFiltered}
                      sortAction={function (order, direction) {
                        tableMultiGridRef.current.clearSelection()
                        localStorage.setItem('ANALYSIS_sorter', direction + '$' + order)
                        dispatch(sortUnitaryAnalyses(direction, order))
                      }}
                      columns={columns}
                      fixedColumnCount={0}
                      fixedRowCount={1}
                      rowHeight={40}
                      getColumnRender={function (
                        columnIndex,
                        key,
                        rowIndex,
                        style,
                        item,
                        rowClassNames,
                        columnKey,
                        moleculeOverState,
                        moleculeOver,
                        selected,
                        onMoleculeClick,
                        onMoleculeDoubleClick,
                      ) {
                        const value = item[columnKey]
                        // if (columnKey === 'status') {
                        //   return (
                        //     <div
                        //       style={style}
                        //       key={key}
                        //       className={clsx(
                        //         rowClassNames,
                        //         moleculeOverState === rowIndex && classes.selectedRow,
                        //         selected === item._id ? classes.blink : classes.noBlink,
                        //       )}
                        //       onMouseEnter={(event) => moleculeOver(rowIndex, true)}
                        //       onMouseLeave={(event) => moleculeOver(rowIndex, false)}
                        //       onClick={(event) => onMoleculeClick(item)}
                        //       onDoubleClick={(event) => onMoleculeDoubleClick(item._id)}
                        //     >
                        //       {value === 'ok' ? (
                        //         <CheckIcon className={classes.success} />
                        //       ) : value === 'ko' ? (
                        //         <ClearIcon className={classes.error} />
                        //       ) : (
                        //         <HelpOutlineIcon className={classes.noInfo} />
                        //       )}
                        //     </div>
                        //   )
                        // }
                        if (columnKey === 'notes') {
                          return (
                            <div
                              style={style}
                              key={key}
                              className={clsx(
                                rowClassNames,
                                moleculeOverState === rowIndex && classes.selectedRow,
                                selected === item._id ? classes.blink : classes.noBlink,
                                moleculeOverState === rowIndex &&
                                  selected === item._id &&
                                  classes.selectedRowBlink,
                              )}
                              onMouseEnter={() =>
                                moleculeOver(rowIndex, columnIndex, true, item._id)
                              }
                              onMouseLeave={() => moleculeOver(rowIndex, columnIndex, false)}
                              // onClick={() => onMoleculeClick(item)}
                              onClick={function (event) {
                                const openFct = () => {
                                  onMoleculeClick(item)
                                }
                                getClickAction(dispatch, event, item, 'unitary_analysis', openFct)
                              }}
                              onDoubleClick={() => onMoleculeDoubleClick(item)}
                              onContextMenu={(event) => {
                                event.stopPropagation()
                                event.preventDefault()
                                setContextMenu(
                                  contextMenu === null
                                    ? {
                                        mouseX: event.clientX - 2,
                                        mouseY: event.clientY - 4,
                                        item: item,
                                      }
                                    : null,
                                )
                              }}
                            >
                              <ErrorBoundaryGuard isDialog>
                                <NoteButton row={item} collection={'unitary_analysis'} />
                              </ErrorBoundaryGuard>
                            </div>
                          )
                        }
                        if (columnKey === 'modified') {
                          return (
                            <div
                              style={style}
                              key={key}
                              className={clsx(
                                rowClassNames,
                                moleculeOverState === rowIndex && classes.selectedRow,
                                selected === item._id ? classes.blink : classes.noBlink,
                                moleculeOverState === rowIndex &&
                                  selected === item._id &&
                                  classes.selectedRowBlink,
                              )}
                              onMouseEnter={() =>
                                moleculeOver(rowIndex, columnIndex, true, item._id)
                              }
                              onMouseLeave={() => moleculeOver(rowIndex, columnIndex, false)}
                              // onClick={() => onMoleculeClick(item)}
                              onClick={function (event) {
                                const openFct = () => {
                                  onMoleculeClick(item)
                                }
                                getClickAction(dispatch, event, item, 'unitary_analysis', openFct)
                              }}
                              onDoubleClick={() => onMoleculeDoubleClick(item)}
                              onContextMenu={(event) => {
                                event.stopPropagation()
                                event.preventDefault()
                                setContextMenu(
                                  contextMenu === null
                                    ? {
                                        mouseX: event.clientX - 2,
                                        mouseY: event.clientY - 4,
                                        item: item,
                                      }
                                    : null,
                                )
                              }}
                            >
                              <ErrorBoundaryGuard isDialog>
                                <AlphacodModifiedButtonIcon item={item} />
                              </ErrorBoundaryGuard>
                            </div>
                          )
                        }
                        if (columnKey === 'peak') {
                          return (
                            <div
                              style={style}
                              key={key}
                              className={clsx(
                                rowClassNames,
                                moleculeOverState === rowIndex && classes.selectedRow,
                                selected === item._id ? classes.blink : classes.noBlink,
                                moleculeOverState === rowIndex &&
                                  selected === item._id &&
                                  classes.selectedRowBlink,
                              )}
                              onMouseEnter={() =>
                                moleculeOver(rowIndex, columnIndex, true, item._id)
                              }
                              onMouseLeave={() => moleculeOver(rowIndex, columnIndex, false)}
                              // onClick={() => onMoleculeClick(item)}
                              onClick={function (event) {
                                const openFct = () => {
                                  onMoleculeClick(item)
                                }
                                getClickAction(dispatch, event, item, 'unitary_analysis', openFct)
                              }}
                              onDoubleClick={() => onMoleculeDoubleClick(item)}
                              onContextMenu={(event) => {
                                event.stopPropagation()
                                event.preventDefault()
                                setContextMenu(
                                  contextMenu === null
                                    ? {
                                        mouseX: event.clientX - 2,
                                        mouseY: event.clientY - 4,
                                        item: item,
                                      }
                                    : null,
                                )
                              }}
                            >
                              {(item.peak === 2000 || item.peak === 3000) && (
                                <Tooltip
                                  arrow
                                  placement="top"
                                  title={
                                    <div
                                      style={{
                                        fontSize: 13,
                                        padding: 5,
                                      }}
                                    >
                                      {item.peak === 2000
                                        ? t('view.analysis.analysis.peakUpdate.auto')
                                        : t('view.analysis.analysis.peakUpdate.user')}
                                    </div>
                                  }
                                >
                                  {item.peak === 2000 ? (
                                    <AutoGraphIcon className={classes.picIconColor} />
                                  ) : (
                                    <PersonIcon className={classes.picIconColor} />
                                  )}
                                </Tooltip>
                              )}
                            </div>
                          )
                        }
                        if (columnKey === 'status') {
                          return (
                            <div
                              style={style}
                              key={key}
                              className={clsx(
                                rowClassNames,
                                moleculeOverState === rowIndex && classes.selectedRow,
                                selected === item._id ? classes.blink : classes.noBlink,
                                moleculeOverState === rowIndex &&
                                  selected === item._id &&
                                  classes.selectedRowBlink,
                              )}
                              onMouseEnter={() =>
                                moleculeOver(rowIndex, columnIndex, true, item._id)
                              }
                              onMouseLeave={() => moleculeOver(rowIndex, columnIndex, false)}
                              // onClick={() => onMoleculeClick(item)}
                              onClick={function (event) {
                                const openFct = () => {
                                  onMoleculeClick(item)
                                }
                                getClickAction(dispatch, event, item, 'unitary_analysis', openFct)
                              }}
                              onDoubleClick={() => onMoleculeDoubleClick(item)}
                              onContextMenu={(event) => {
                                event.stopPropagation()
                                event.preventDefault()
                                setContextMenu(
                                  contextMenu === null
                                    ? {
                                        mouseX: event.clientX - 2,
                                        mouseY: event.clientY - 4,
                                        item: item,
                                      }
                                    : null,
                                )
                              }}
                            >
                              {item.status}
                            </div>
                          )
                        }
                        if (columnKey === 'validation') {
                          return (
                            <div
                              style={style}
                              key={key}
                              className={clsx(
                                rowClassNames,
                                moleculeOverState === rowIndex && classes.selectedRow,
                                selected === item._id ? classes.blink : classes.noBlink,
                                moleculeOverState === rowIndex &&
                                  selected === item._id &&
                                  classes.selectedRowBlink,
                              )}
                              onMouseEnter={() =>
                                moleculeOver(rowIndex, columnIndex, true, item._id)
                              }
                              onMouseLeave={() => moleculeOver(rowIndex, columnIndex, false)}
                              // onClick={() => onMoleculeClick(item)}
                              onClick={function (event) {
                                const openFct = () => {
                                  onMoleculeClick(item)
                                }
                                getClickAction(dispatch, event, item, 'unitary_analysis', openFct)
                              }}
                              onDoubleClick={() => onMoleculeDoubleClick(item)}
                              onContextMenu={(event) => {
                                event.stopPropagation()
                                event.preventDefault()
                                setContextMenu(
                                  contextMenu === null
                                    ? {
                                        mouseX: event.clientX - 2,
                                        mouseY: event.clientY - 4,
                                        item: item,
                                      }
                                    : null,
                                )
                              }}
                            >
                              <div className={classes.actionContainer}>
                                <IconButton
                                  disabled={!me.canEditCalib(item)}
                                  aria-label="detected"
                                  onClick={(event) => {
                                    handleValidation(event, item, '1', 'unitary_analysis', dispatch)
                                  }}
                                >
                                  {value === '1' ? (
                                    item.status === 'ok' || item.status === 'ko' ? (
                                      <CheckIcon className={classes.selected} />
                                    ) : (
                                      <Molecule className={classes.selected} />
                                    )
                                  ) : item.status === 'ok' || item.status === 'ko' ? (
                                    <CheckIcon className={classes.notSelected} />
                                  ) : (
                                    <Molecule className={classes.notSelected} />
                                  )}
                                </IconButton>
                                <IconButton
                                  disabled={!me.canEditCalib(item)}
                                  aria-label="notDetected"
                                  onClick={(event) =>
                                    handleValidation(event, item, '2', 'unitary_analysis', dispatch)
                                  }
                                >
                                  {value === '2' ? (
                                    item.status === 'ok' || item.status === 'ko' ? (
                                      <ClearIcon className={classes.selected} />
                                    ) : (
                                      <Nothing className={classes.selected} />
                                    )
                                  ) : item.status === 'ok' || item.status === 'ko' ? (
                                    <ClearIcon className={classes.notSelected} />
                                  ) : (
                                    <Nothing className={classes.notSelected} />
                                  )}
                                </IconButton>
                                <IconButton
                                  disabled={!me.canEditCalib(item)}
                                  aria-label="relaunch"
                                  onClick={(event) =>
                                    handleValidation(event, item, '3', 'unitary_analysis', dispatch)
                                  }
                                >
                                  {value === '3' ? (
                                    <Relance className={classes.selected} />
                                  ) : (
                                    <Relance className={classes.notSelected} />
                                  )}
                                </IconButton>
                              </div>
                            </div>
                          )
                        }
                        if (columnKey === 'flags') {
                          return (
                            <div
                              style={style}
                              key={key}
                              className={clsx(
                                'cellFlags',
                                rowClassNames,
                                moleculeOverState === rowIndex && classes.selectedRow,
                                selected === item._id ? classes.blink : classes.noBlink,
                                moleculeOverState === rowIndex &&
                                  selected === item._id &&
                                  classes.selectedRowBlink,
                              )}
                              onMouseEnter={() =>
                                moleculeOver(rowIndex, columnIndex, true, item._id)
                              }
                              onMouseLeave={() => moleculeOver(rowIndex, columnIndex, false)}
                              // onClick={() => onMoleculeClick(item)}
                              onClick={function (event) {
                                const openFct = () => {}
                                getClickAction(dispatch, event, item, 'unitary_analysis', openFct)
                              }}
                              onContextMenu={(event) => {
                                event.stopPropagation()
                                event.preventDefault()
                                setContextMenu(
                                  contextMenu === null
                                    ? {
                                        mouseX: event.clientX - 2,
                                        mouseY: event.clientY - 4,
                                        item: item,
                                      }
                                    : null,
                                )
                              }}
                            >
                              <ErrorBoundaryGuard isDialog>
                                <AlphacodFlagIcon
                                  item={item}
                                  hideGeneralTab={false}
                                  collection={'analyse'}
                                />
                              </ErrorBoundaryGuard>
                            </div>
                          )
                        }
                        if (columnKey === 'm_z') {
                          return (
                            <div
                              style={style}
                              key={key}
                              className={clsx(
                                rowClassNames,
                                moleculeOverState === rowIndex && classes.selectedRow,
                                selected === item._id ? classes.blink : classes.noBlink,
                                moleculeOverState === rowIndex &&
                                  selected === item._id &&
                                  classes.selectedRowBlink,
                              )}
                              onMouseEnter={() =>
                                moleculeOver(rowIndex, columnIndex, true, item._id)
                              }
                              onMouseLeave={() => moleculeOver(rowIndex, columnIndex, false)}
                              // onClick={() => onMoleculeClick(item)}
                              onClick={function (event) {
                                const openFct = () => {
                                  onMoleculeClick(item)
                                }
                                getClickAction(dispatch, event, item, 'unitary_analysis', openFct)
                              }}
                              onContextMenu={(event) => {
                                event.stopPropagation()
                                event.preventDefault()
                                setContextMenu(
                                  contextMenu === null
                                    ? {
                                        mouseX: event.clientX - 2,
                                        mouseY: event.clientY - 4,
                                        item: item,
                                      }
                                    : null,
                                )
                              }}
                            >
                              <FormControl className={classes.formControl}>
                                <Select
                                  value={item.m_z}
                                  name={'m_z'}
                                  onChange={(event) => onWhichUpdateMainChannel(event, item)}
                                  displayEmpty
                                  autoWidth
                                  inputProps={{
                                    readOnly: !me.canEditCalib(item),
                                    'aria-label': 'Main Channel',
                                  }}
                                >
                                  {[item.main_channel_data]
                                    .concat(item.channels_data)
                                    .map((channel, index) => (
                                      <MenuItem key={index} value={channel.channelNumber}>
                                        <span className={classes.transitionNumber}>
                                          {channel.channelNumber}
                                        </span>
                                        {`${channel.q1} > ${channel.q3} ${
                                          channel.concentration &&
                                          channel.concentration !== '' &&
                                          channel.concentration !== null
                                            ? '(' +
                                              t('view.analysis.analysis.concentration') +
                                              '=' +
                                              parseFloat(channel.concentration).toFixed(1) +
                                              ')'
                                            : ''
                                        }`}
                                      </MenuItem>
                                    ))}
                                </Select>
                              </FormControl>
                            </div>
                          )
                        }
                        if (columnKey === 'settings') {
                          return (
                            <div
                              style={style}
                              key={key}
                              className={clsx(
                                rowClassNames,
                                moleculeOverState === rowIndex && classes.selectedRow,
                                selected === item._id ? classes.blink : classes.noBlink,
                                moleculeOverState === rowIndex &&
                                  selected === item._id &&
                                  classes.selectedRowBlink,
                              )}
                              onMouseEnter={() =>
                                moleculeOver(rowIndex, columnIndex, true, item._id)
                              }
                              onMouseLeave={() => moleculeOver(rowIndex, columnIndex, false)}
                              // onClick={() => onMoleculeClick(item)}
                              onClick={function (event) {
                                const openFct = () => {
                                  onMoleculeClick(item)
                                }
                                getClickAction(dispatch, event, item, 'unitary_analysis', openFct)
                              }}
                              onContextMenu={(event) => {
                                event.stopPropagation()
                                event.preventDefault()
                                setContextMenu(
                                  contextMenu === null
                                    ? {
                                        mouseX: event.clientX - 2,
                                        mouseY: event.clientY - 4,
                                        item: item,
                                      }
                                    : null,
                                )
                              }}
                            >
                              {/*<Tooltip*/}
                              {/*key={'tooltip_reset'}*/}
                              {/*arrow*/}
                              {/*title={*/}
                              {/*<div style={{ fontSize: 13, padding: 5 }}>*/}
                              {/*{'Reset the UA'}*/}
                              {/*</div>*/}
                              {/*}*/}
                              {/*>*/}
                              {/*<IconButton*/}
                              {/*className={classes.settingsButton}*/}
                              {/*aria-label="reset"*/}
                              {/*onClick={(event) => {*/}
                              {/*me.handleReset(event, false, item)*/}
                              {/*}}*/}
                              {/*>*/}
                              {/*<SettingsBackupRestoreIcon className={classes.settingsIcon} />*/}
                              {/*</IconButton>*/}
                              {/*</Tooltip>*/}
                              <Tooltip
                                key={'tooltip_settings'}
                                arrow
                                title={
                                  <div style={{ fontSize: 13, padding: 5 }}>
                                    {t('view.analysis.analysis.change_settings')}
                                  </div>
                                }
                              >
                                <IconButton
                                  className={classes.settingsButton}
                                  aria-label="settings"
                                  onClick={(event) => {
                                    handleSettings(event, item)
                                  }}
                                >
                                  <SettingsIcon className={classes.settingsIcon} />
                                </IconButton>
                              </Tooltip>
                            </div>
                          )
                        }
                        return null
                      }}
                    />
                  </ErrorBoundaryGuard>
                </div>
                {!selectionEmpty && (
                  <span
                    className={classes.toolbar}
                    style={{ visibility: selectionEmpty ? 'hidden' : 'visible' }}
                  >
                    <div>
                      <Tooltip
                        arrow
                        title={
                          <div className={classes.tooltip}>
                            {selectionMulti
                              ? t('view.analysis.analysis.selection_as_detect_ok')
                              : selectionType[0].name === 'okKo'
                              ? t('view.analysis.analysis.selection_as_ok')
                              : t('view.analysis.analysis.selection_as_detected')}
                          </div>
                        }
                      >
                        <IconButton
                          className={classes.toolbarButton}
                          style={selectionMulti ? { width: 67 } : {}}
                          aria-label="detected"
                          onClick={() => this.multiValidate(1)}
                        >
                          {selectionMulti ? (
                            <Fragment>
                              <Molecule /> / <CheckIcon />
                            </Fragment>
                          ) : selectionType[0].name === 'okKo' ? (
                            <CheckIcon />
                          ) : (
                            <Molecule />
                          )}
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        arrow
                        title={
                          <div className={classes.tooltip}>
                            {selectionMulti
                              ? t('view.analysis.analysis.selection_as_not_detect_ko')
                              : selectionType[0].name === 'okKo'
                              ? t('view.analysis.analysis.selection_as_ko')
                              : t('view.analysis.analysis.selection_as_not_detected')}
                          </div>
                        }
                      >
                        <IconButton
                          className={classes.toolbarButton}
                          style={selectionMulti ? { width: 67 } : {}}
                          aria-label="notDetected"
                          onClick={() => this.multiValidate(2)}
                        >
                          {selectionMulti ? (
                            <Fragment>
                              <Nothing /> / <ClearIcon />
                            </Fragment>
                          ) : selectionType[0].name === 'okKo' ? (
                            <ClearIcon />
                          ) : (
                            <Nothing />
                          )}
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        arrow
                        title={
                          <div className={classes.tooltip}>
                            {t('view.analysis.analysis.selection_relaunch')}
                          </div>
                        }
                      >
                        <IconButton
                          className={classes.toolbarButton}
                          aria-label="relaunch"
                          onClick={() => this.multiValidate(3)}
                        >
                          <Relance />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </span>
                )}
              </Paper>
            )
            const chromatoComponent = (
              <ErrorBoundaryGuard>
                <GridMultiGrid
                  aiAssistantActivated={aiAssistantActivated}
                  featuresConfig={featuresConfig}
                  tableGridRef={this.tableMultiGridRef} // Ref to the table for selection
                  selection // Active or not the checkbox for selection
                  newSize={newSize}
                  batch={batch}
                  wrappedComponentRef={this.refGridChromato}
                  onChromatoClick={(item) => this.scrollToTable(item)}
                  data={unitaryAnalysesFiltered}
                  istd={allUnitaryAnalysesIstd}
                  isFetchingIstd={isFetchingAllUnitaryAnalysesIstd}
                  store={store}
                  collection={'analyse'}
                  isFetchingData={isFetchingUnitaryAnalysesFiltered}
                  getAnalyseFunction={getUnitaryAnalysisFilteredById}
                  title={function (item) {
                    return item.name
                  }}
                  onChangeChannel={(event, uaId, mainChannel) =>
                    this.onChangeChannel(event, uaId, mainChannel)
                  }
                />
              </ErrorBoundaryGuard>
            )

            const calibrationComponent = (
              <ErrorBoundaryGuard>
                <Calibration
                  batch={batch}
                  wrappedComponentRef={this.refCalibration}
                  collection={'analyse'}
                />
              </ErrorBoundaryGuard>
            )

            const innerCmpNavigation = (
              <ErrorBoundaryGuard>
                <Navigation batch={batch} />
              </ErrorBoundaryGuard>
            )
            const innerCmp = (
              <SplitterLayout
                primaryIndex={1}
                secondaryInitialSize={0}
                customClassName={pinDrawer ? classes.mainSplitterFull : classes.mainSplitter}
              >
                <Fragment></Fragment>
                <SplitterLayout
                  vertical
                  primaryIndex={1}
                  secondaryInitialSize={185}
                  customClassName={classes.centerSplitter}
                >
                  <ErrorBoundaryGuard>
                    <Information
                      batch={batch}
                      isKOOK={isKOOK}
                      tableMultiGridRef={tableMultiGridRef}
                      parentMultiGridRef={parentMultiGridRef}
                      idTable={idTableConst}
                      propsColumns={columns}
                    />
                  </ErrorBoundaryGuard>
                  {layout === 'default' ? (
                    <SplitterLayout
                      vertical
                      percentage
                      customClassName={classes.containerSplitter}
                      ref={this.contentRef}
                      onDragEnd={onContentSplitterSizeSave}
                      secondaryInitialSize={
                        localStorage.getItem('LAYOUT_analyses_secondaryPaneSize')
                          ? parseFloat(localStorage.getItem('LAYOUT_analyses_secondaryPaneSize'))
                          : 50
                      }
                    >
                      {tableComponent}
                      <SplitterLayout
                        customClassName={classes.deeperSplitter}
                        ref={this.contentCalibrationRef}
                        onDragEnd={onContentCalibrationSplitterSizeSave}
                        primaryMinSize={580}
                        secondaryInitialSize={
                          localStorage.getItem('LAYOUT_calibration_secondaryPaneSize')
                            ? parseFloat(
                                localStorage.getItem('LAYOUT_calibration_secondaryPaneSize'),
                              )
                            : 300
                        }
                      >
                        {chromatoComponent}
                        {calibrationComponent}
                      </SplitterLayout>
                    </SplitterLayout>
                  ) : (
                    <SplitterLayout
                      customClassName={classes.containerCustomSplitter}
                      ref={this.contentCustomRef}
                      onDragEnd={onContentCustomSplitterSizeSave}
                      primaryMinSize={580}
                      secondaryInitialSize={
                        localStorage.getItem('LAYOUT_compound_secondaryPaneSize_custom')
                          ? parseFloat(
                              localStorage.getItem('LAYOUT_compound_secondaryPaneSize_custom'),
                            )
                          : 300
                      }
                    >
                      {chromatoComponent}
                      <SplitterLayout
                        vertical
                        percentage
                        customClassName={classes.deeperCustomSplitter}
                        ref={this.contentCustomCalibrationRef}
                        onDragEnd={onContentCustomCalibrationSplitterSizeSave}
                        secondaryInitialSize={
                          localStorage.getItem('LAYOUT_calibration_secondaryPaneSize_custom')
                            ? parseFloat(
                                localStorage.getItem('LAYOUT_calibration_secondaryPaneSize_custom'),
                              )
                            : 50
                        }
                      >
                        {tableComponent}
                        {calibrationComponent}
                      </SplitterLayout>
                    </SplitterLayout>
                  )}
                </SplitterLayout>
              </SplitterLayout>
            )

            return (
              <Fragment>
                <ErrorBoundaryGuard isDialog>
                  <RetiredCmp batch={batch} />
                </ErrorBoundaryGuard>
                <ErrorBoundaryGuard isDialog>
                  <AnalysisMultiSelectionDialog
                    open={openSelectionMultiDialog}
                    closeFct={this.handleMultiSelectionConfirm}
                    selection={selection}
                    selectionType={selectionType}
                  />
                </ErrorBoundaryGuard>
                <Dialog
                  open={openConfirmReset}
                  onClose={(event) => this.handleConfirmResetClose(event, false)}
                  TransitionComponent={TransitionTop}
                  aria-labelledby="dialog-reset-title"
                >
                  <StyledDialogTitle>
                    <Trans i18nKey="view.analysis.analysis.reset_ua">
                      Reset unitary
                      {{
                        val:
                          selectionEmpty || !multiReset
                            ? t('view.analysis.analysis.analysis')
                            : t('view.analysis.analysis.analyses'),
                      }}
                    </Trans>
                  </StyledDialogTitle>
                  <DialogContent>
                    <DialogContentText style={{ margin: 0 }}>
                      <Trans i18nKey="view.analysis.analysis.sure_reset">
                        Are you sure you want to reset the unitary
                        {{
                          val:
                            selectionEmpty || !multiReset
                              ? t('view.analysis.analysis.analysis')
                              : t('view.analysis.analysis.analyses'),
                        }}
                      </Trans>
                    </DialogContentText>
                    {selectionEmpty || !multiReset ? (
                      <Chip
                        key={resetUA ? resetUA.name : ''}
                        className={classes.moveChip}
                        label={resetUA ? resetUA.name : ''}
                      />
                    ) : (
                      <div style={{ maxWidth: 600, maxHeight: 400, overflow: 'scroll' }}>
                        {this.state.selection.map((item) => (
                          <Chip
                            className={classes.moveChip}
                            style={{ margin: 3 }}
                            label={item.name}
                          />
                        ))}
                      </div>
                    )}
                    <DialogContentText style={{ margin: 0, marginTop: 15, paddingLeft: 34 }}>
                      <WarningIcon className={classes.warning} />
                      <Trans i18nKey="view.analysis.analysis.will_reset">
                        This action will be reset the <i>calibration</i>, the <i>reintegration</i>,
                        the <i>main channel</i> and the <i>validation</i>.
                      </Trans>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      autoFocus
                      onClick={(event) => this.handleConfirmResetClose(event, false)}
                      color="primary"
                    >
                      {t('view.analysis.analysis.cancel')}
                    </Button>
                    <Button
                      onClick={(event) => this.handleConfirmResetClose(event, true)}
                      color="primary"
                    >
                      {t('view.analysis.analysis.yes')}
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={openSettings}
                  onClose={() => handleSettingsClose()}
                  TransitionComponent={TransitionLeft}
                  aria-labelledby="draggable-dialog-settings"
                >
                  <DialogTitle style={{ padding: 0 }}>
                    <Box className={classes.header}>
                      <CardMedia
                        className={classes.cardImg}
                        component="img"
                        alt="header image"
                        height="184"
                        image={PUBLIC_URL + '/images/ua_settings.jpg'}
                        title="Header image"
                      />
                      <Tooltip
                        arrow
                        placement="top"
                        title={<div style={{ fontSize: 13, padding: 5 }}>{settingsInfo.name}</div>}
                      >
                        <span className={classes.title}>{settingsInfo.name}</span>
                      </Tooltip>
                    </Box>
                  </DialogTitle>
                  <DialogContent>
                    {Object.entries(settings).map((entry, index) => (
                      <TextField
                        disabled={!me.canEditCalib({ sampleType: settingsInfo.type })}
                        key={entry[0] + index}
                        id={entry[0]}
                        label={entry[0]}
                        defaultValue={entry[1]}
                        className={classes.textField}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        style={{ margin: '10px 0px' }}
                        onChange={(e) => handleSettingChange(e)}
                        onBlur={(e) => handleSettingChange(e, true)}
                      />
                    ))}
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => handleSettingsClose(true)} color="primary">
                      {t('view.analysis.analysis.save')}
                    </Button>
                    <Button onClick={() => handleSettingsClose()} color="primary">
                      {t('view.analysis.analysis.close')}
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  TransitionComponent={TransitionTop}
                  aria-labelledby="draggable-dialog-title"
                >
                  <StyledDialogTitle>
                    {t('view.analysis.analysis.main_ch_update')}
                  </StyledDialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      {t('view.analysis.analysis.main_ch_update_msg')}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button autoFocus onClick={(event) => handleClose(event, true)} color="primary">
                      {t('view.analysis.analysis.all')}
                    </Button>
                    <Button onClick={(event) => handleClose(event, false)} color="primary">
                      {t('view.analysis.analysis.current')}
                    </Button>
                  </DialogActions>
                </Dialog>
                <div
                  style={{ height: 'inherit', width: 'inherit', outline: 'none' }}
                  ref={(element) => {
                    this.elementRef = element
                  }}
                  tabIndex={0}
                >
                  {pinDrawer ? (
                    <SplitterLayout
                      customClassName={classes.firstSplitter}
                      ref={this.contentFirstCntRef}
                      onDragEnd={onContentFirstSplitterSizeSave}
                      secondaryMinSize={DRAWER_WIDTH()}
                      primaryMinSize={600}
                      primaryIndex={1}
                      secondaryInitialSize={
                        localStorage.getItem('LAYOUT_first_secondaryPaneSize')
                          ? parseFloat(localStorage.getItem('LAYOUT_first_secondaryPaneSize'))
                          : DRAWER_WIDTH()
                      }
                    >
                      {innerCmpNavigation}
                      {innerCmp}
                    </SplitterLayout>
                  ) : (
                    <Fragment>
                      {innerCmpNavigation} {innerCmp}
                    </Fragment>
                  )}
                </div>
              </Fragment>
            )
          }
        },
      ),
    ),
  ),
)
