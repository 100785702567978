import React, { Fragment } from 'react'
import Paper from '@material-ui/core/Paper'
import { connect } from 'react-redux'
import { alpha, withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { withRouter } from 'react-router-dom'
import Title from '../Title'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import IconButton from '@material-ui/core/IconButton'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Draggable from 'react-draggable'
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle'
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import { updateISTD } from '../common/Utils'
import {
  Molecule,
  Nothing,
  Relance,
  RecomputePeaks,
  TransitionBottom,
  XLSFileInvert,
  RemoveFilterIcon,
  ExcelIcon,
} from '../common/Style'
import { StyledDialogTitle, TransitionTop } from '../common/Style'
import { getAnalysisById } from '../../redux/analyses/selectors'
import InputLabel from '@material-ui/core/InputLabel'
import { getBatchById } from '../../redux/batches/selectors'
import Divider from '@material-ui/core/Divider'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import SearchIcon from '@material-ui/icons/Search'
import InputBase from '@material-ui/core/InputBase'
import clsx from 'clsx'
import ClearIcon from '@material-ui/icons/Clear'
import TimelineIcon from '@material-ui/icons/Timeline'
import {
  fetchRecomputePeaks,
  fetchUnitaryAnalysesCompound,
  fetchUnitaryAnalysesDistinct,
  fetchUnitaryAnalysesFiltered,
  fetchUnitaryCompoundDistinct,
  filteredLocalCompound,
} from '../../redux/unitaryAnalyses/actions'
import Tooltip from '@material-ui/core/Tooltip'
import Badge from '@material-ui/core/Badge'
import PersonPinIcon from '@material-ui/icons/PersonPin'
import NewReleasesIcon from '@material-ui/icons/NewReleases'
import CheckIcon from '@material-ui/icons/Check'
import Box from '@material-ui/core/Box'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import NoteButton from '../common/NoteButton'
import { displayPopupMessage, generateErrorPart } from '../common/Message'
import { fetchAddTask, fetchFinishTask } from '../../redux/tasks/actions'
import { Checkbox, FormGroup, Radio, RadioGroup, Zoom } from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning'
import Chip from '@material-ui/core/Chip'
import SettingsView from '../common/settingsView/SettingsView'
import Link from '@material-ui/core/Link/Link'
import { FLASK_URL, USE_OLD_TABLE } from '../../utils/config'
import FilterButton from './FilterButton'
import ErrorIcon from '@material-ui/icons/Error'
import HelpIcon from '@material-ui/icons/Help'
import CancelIcon from '@material-ui/icons/Cancel'
import ErrorBoundaryGuard from '../ErrorBoundaryGuard'
import { t } from 'i18next'
import { Trans } from 'react-i18next'
import CompoundFilterButton from './CompoundFilterButton'

const styles = (theme) => ({
  paper: {
    // boxShadow:
    //   '0px 2px 7px 1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    borderRadius: '0px 0px 4px 4px',
    padding: theme.spacing(2),
    paddingTop: 10,
    paddingBottom: 0,
    height: '100%',
    overflow: 'hidden',
    '&:hover .collapsor': {
      opacity: 1,
      bottom: 2,
    },
  },
  buttonCalib: {
    borderRadius: '18px !important',
    backgroundColor: '#e0e0e0',
    padding: '3px 12px 3px 14px',
    marginRight: theme.spacing(1),
    height: 32,
    border: '1px solid rgba(0, 0, 0, 0.12) !important',
  },
  spacer: {
    // flexGrow: 1,
    align: 'right',
  },
  progress: {
    paddingTop: '0px !important',
    paddingBottom: 10,
    // display:'flex'
  },
  divider: {
    // paddingTop: '0px !important',
    paddingBottom: 10,
    // display:'flex'
  },
  iconValid: {
    fontSize: 13,
  },
  filterIcon: {
    color: theme.palette.primary.main,
  },
  toggleGroup: {
    marginLeft: theme.spacing(2),
  },

  progression: {
    padding: 12,
    paddingTop: 0,
  },
  formControl: {
    position: 'relative',
    top: 2,
    marginLeft: 6,
    '& .MuiInput-root::before': {
      borderBottomWidth: 0,
    },
    '& .MuiInput-root': {
      border: '1px solid #bdbdbd',
      borderRadius: 4,
      padding: 5,
    },
    '& .MuiSelect-select.MuiSelect-select': {
      fontSize: '1.25rem',
      fontWeight: 500,
      color: theme.palette.primary.main,
    },
  },

  search: {
    position: 'relative',
    marginRight: '12px',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.03),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.06),
    },
    '& div:first-child': {
      color: alpha(theme.palette.common.black, 0.54),
    },
    '&:hover div:first-child': {
      color: theme.palette.secondary.main,
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    // pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  // inputRoot: {
  //   color: 'inherit',
  // },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '16ch',
        paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
      },
    },
  },
  clearIcon: {
    top: 0,
    right: 0,
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.secondary.main,
    },
  },
  badgeCountRect: {
    '& .MuiBadge-badge': {
      right: '6%',
      top: 0,
      background: 'white',
      color: alpha(theme.palette.primary.main, 0.6),
      boxShadow: '0px 2px 5px 1px rgba(0,0,0,0.15)',
    },
  },
  badgeCountRectSelected: {
    '& .MuiBadge-badge': {
      right: '6%',
      top: 0,
      background: 'white',
      color: alpha(theme.palette.secondary.main, 0.8),
      boxShadow: '0px 2px 5px 1px rgba(0,0,0,0.15)',
    },
  },
  filterButton: {
    borderRadius: 18,
    border: '1px solid #c5c5c5',
    backgroundColor: 'rgb(224 224 224)',
    marginRight: 5,
    marginLeft: 15,
    width: 50,
    height: 34,
    padding: 8,
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.05),
    },
  },
  selected: {
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  collapsor: {
    '&.collapsed': { opacity: 1, bottom: 2 },
    '&.notCollapsed': { opacity: 0, bottom: 12 },
    transition: 'all 125ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    background: 'white',
    width: 50,
    height: 10,
    left: 'calc(50% - 25px)',
    position: 'absolute',
    bottom: 2,
    borderRadius: '0px 0px 15px 15px',
    // boxShadow:
    //   '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
  },
  collapseButton: {
    width: 30,
    height: 30,
    padding: 0,
    bottom: -4,
    left: 'calc(50% - 15px)',
    position: 'absolute',
    transition: 'all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '&:hover': {
      background: 'transparent',
      color: theme.palette.secondary.main,
    },
  },
  customWidth: {
    maxWidth: 500,
    maxHeight: 500,
    overflow: 'auto',
  },
  colorBlue: {
    color: theme.palette.primary.main,
  },
  removeFilter: {
    color: theme.palette.primary.main,
  },
  tooltip: {
    fontSize: 13,
    padding: 5,
  },
})

const mapStateToProps = (state, ownProps) => {
  const { batches, unitaryAnalyses } = state
  const batch = getBatchById(batches, ownProps.match.params.batchId)

  return {
    batch: batch,
    compounds: unitaryAnalyses.unitaryAnalysesCompound,
    isFetchingCompounds: unitaryAnalyses.isFetchingUnitaryAnalysesCompound,
    isFetchingUnitaryCompoundDistinct: unitaryAnalyses.isFetchingUnitaryCompoundDistinct,
    unitaryCompoundDistinct: unitaryAnalyses.unitaryCompoundDistinct,
  }
}
const tableOldVersion = USE_OLD_TABLE()

export default withRouter(
  connect(mapStateToProps)(
    withStyles(styles)(
      class Information extends React.Component {
        constructor(props) {
          super(props)
          this.state = {
            error: null,
            isLoaded: false,
            items: [],
            analysis: null,
            order: 'asc',
            orderBy: '#',
            checked: true,
            newISTDGroup: null,
            open: false,
            itemToUpdate: null,
            requested: localStorage.getItem('COMPOUND_filter_requested')
              ? JSON.parse(localStorage.getItem('COMPOUND_filter_requested')) === true
              : true,
            untreatedChecked: localStorage.getItem('COMPOUND_filter_untreated')
              ? JSON.parse(localStorage.getItem('COMPOUND_filter_untreated')) === true
              : false,
            validationDetected: localStorage.getItem('COMPOUND_filter_detected')
              ? JSON.parse(localStorage.getItem('COMPOUND_filter_detected')) === true
              : false,
            validationNotDetected: localStorage.getItem('COMPOUND_filter_notDetected')
              ? JSON.parse(localStorage.getItem('COMPOUND_filter_notDetected')) === true
              : false,
            validationRelaunch: localStorage.getItem('COMPOUND_filter_relaunch')
              ? JSON.parse(localStorage.getItem('COMPOUND_filter_relaunch')) === true
              : false,
            classificationAllDetected: localStorage.getItem('COMPOUND_filter_allDetected')
              ? JSON.parse(localStorage.getItem('COMPOUND_filter_allDetected')) === true
              : false,
            classificationAllSuspected: localStorage.getItem('COMPOUND_filter_allSuspected')
              ? JSON.parse(localStorage.getItem('COMPOUND_filter_allSuspected')) === true
              : false,
            classificationAllExcluded: localStorage.getItem('COMPOUND_filter_allExcluded')
              ? JSON.parse(localStorage.getItem('COMPOUND_filter_allExcluded')) === true
              : false,
            collapsed: localStorage.getItem('COMPOUND_information_collapsed')
              ? JSON.parse(localStorage.getItem('COMPOUND_information_collapsed')) === true
              : false,
            standardFilter: localStorage.getItem('COMPOUND_filter_standard')
              ? JSON.parse(localStorage.getItem('COMPOUND_filter_standard')) === true
              : false,
            qualityFilter: localStorage.getItem('COMPOUND_filter_quality')
              ? JSON.parse(localStorage.getItem('COMPOUND_filter_quality')) === true
              : false,
            blankFilter: localStorage.getItem('COMPOUND_filter_blank')
              ? JSON.parse(localStorage.getItem('COMPOUND_filter_blank')) === true
              : false,
            samplesOthersFilter: localStorage.getItem('COMPOUND_filter_samplesOthers')
              ? JSON.parse(localStorage.getItem('COMPOUND_filter_samplesOthers')) === true
              : false,
            openRecomputeIntegration: false,
            recompute: 'compound',
            std: localStorage.getItem('COMPOUND_filter_std')
              ? JSON.parse(localStorage.getItem('COMPOUND_filter_std')) === true
              : false,
            mb: localStorage.getItem('COMPOUND_filter_mb')
              ? JSON.parse(localStorage.getItem('COMPOUND_filter_mb')) === true
              : false,
            mbd: localStorage.getItem('COMPOUND_filter_mbd')
              ? JSON.parse(localStorage.getItem('COMPOUND_filter_mbd')) === true
              : false,
            stde: localStorage.getItem('COMPOUND_filter_stde')
              ? JSON.parse(localStorage.getItem('COMPOUND_filter_stde')) === true
              : false,
            blk: localStorage.getItem('COMPOUND_filter_blk')
              ? JSON.parse(localStorage.getItem('COMPOUND_filter_blk')) === true
              : false,
            spl: localStorage.getItem('COMPOUND_filter_spl')
              ? JSON.parse(localStorage.getItem('COMPOUND_filter_spl')) === true
              : false,
            oth: localStorage.getItem('COMPOUND_filter_oth')
              ? JSON.parse(localStorage.getItem('COMPOUND_filter_oth')) === true
              : false,
          }
          this.onSearch = this.onSearch.bind(this)
          this.clearSearch = this.clearSearch.bind(this)
          this.collapse = this.collapse.bind(this)
          this.searchRef = React.createRef()
          this.tableMultiGridRef = props.tableMultiGridRef
        }

        componentDidMount() {
          const { dispatch } = this.props
          const { moleculeEvent } = this.props.match.params
          dispatch(fetchUnitaryCompoundDistinct(this.props.match.params.batchId, moleculeEvent))
        }
        componentDidUpdate(prevProps, prevState) {
          const { dispatch } = this.props
          const { moleculeEvent } = this.props.match.params
          if (moleculeEvent !== prevProps.match.params.moleculeEvent) {
            dispatch(fetchUnitaryCompoundDistinct(this.props.match.params.batchId, moleculeEvent))
            this.searchRef.current.children[0].value = ''
            this.setState({ search: '', displayClear: false })
          }
        }

        goToISTD(compound) {
          const { batch } = this.props
          if (
            batch.content.internal_standards &&
            batch.content.internal_standards[compound.istd] &&
            typeof batch.content.internal_standards[compound.istd].event === 'number'
          ) {
            this.props.history.push(
              '/?' +
                this.props.match.params.batchId +
                '/compound/' +
                batch.content.internal_standards[compound.istd].event,
            )
          }
        }

        onSearch(e) {
          const {
            timer,
            checked,
            untreatedChecked,
            requested,
            validationDetected,
            validationNotDetected,
            validationRelaunch,
          } = this.state
          const { dispatch } = this.props
          const newValue = e.target.value
          this.tableMultiGridRef.current.clearSelection()
          clearTimeout(timer)
          const newTimer = setTimeout(
            function () {
              this.setState({ search: newValue })
              dispatch(
                fetchUnitaryAnalysesCompound(
                  this.props.match.params.batchId,
                  this.props.match.params.moleculeEvent,
                  untreatedChecked,
                  this.state.requested,
                  this.state.validationDetected,
                  this.state.validationNotDetected,
                  this.state.validationRelaunch,
                  this.state.std,
                  this.state.mb,
                  this.state.mbd,
                  this.state.stde,
                  this.state.blk,
                  this.state.spl,
                  this.state.oth,
                  this.state.classificationAllDetected,
                  this.state.classificationAllSuspected,
                  this.state.classificationAllExcluded,
                  newValue,
                  localStorage.getItem('COMPOUND_sorter'),
                ),
              )
            }.bind(this),
            1500,
          )
          this.setState({ timer: newTimer, displayClear: newValue !== '' })
        }

        clearSearch() {
          const {
            checked,
            untreatedChecked,
            requested,
            validationDetected,
            validationNotDetected,
            validationRelaunch,
          } = this.state
          const { dispatch } = this.props
          this.searchRef.current.children[0].value = ''
          this.setState({ search: '', displayClear: false })
          dispatch(
            fetchUnitaryAnalysesCompound(
              this.props.match.params.batchId,
              this.props.match.params.moleculeEvent,
              untreatedChecked,
              this.state.requested,
              this.state.validationDetected,
              this.state.validationNotDetected,
              this.state.validationRelaunch,
              this.state.std,
              this.state.mb,
              this.state.mbd,
              this.state.stde,
              this.state.blk,
              this.state.spl,
              this.state.oth,
              this.state.classificationAllDetected,
              this.state.classificationAllSuspected,
              this.state.classificationAllExcluded,
              null,
              localStorage.getItem('COMPOUND_sorter'),
            ),
          )
        }

        collapse() {
          const { collapsed } = this.state
          this.setState({ collapsed: !collapsed })
          localStorage.setItem('COMPOUND_information_collapsed', !collapsed)
        }

        render() {
          const {
            dispatch,
            classes,
            batch,
            compounds,
            isFetchingCompounds,
            isFetchingUnitaryCompoundDistinct,
            unitaryCompoundDistinct,
            idTable,
            propsColumns,
            parentMultiGridRef,
          } = this.props
          const {
            newISTDGroup,
            open,
            itemToUpdate,
            untreatedChecked,
            requested,
            validationDetected,
            validationNotDetected,
            validationRelaunch,
            classificationAllDetected,
            classificationAllSuspected,
            classificationAllExcluded,
            collapsed,
            openRecomputeIntegration,
            recompute,
            samplesOthersFilter,
            blankFilter,
            qualityFilter,
            standardFilter,
          } = this.state
          // const analyseID = this.props.match.params.analysisId

          const compound = batch.content.molecules[this.props.match.params.moleculeEvent]

          const onWhichUpdateISTD = function (event, item) {
            // Change the ISTD only if the selection isn't "No ISTD"
            if (event.target.value !== -1) {
              this.setState({ open: true, newISTDGroup: event.target.value, itemToUpdate: item })
            }
          }.bind(this)

          if (compound) {
            document.title =
              compound.name + ' - ' + localStorage.getItem('__config.json__APPLICATION_TITLE')
          } else {
            document.title = localStorage.getItem('__config.json__APPLICATION_TITLE')
          }

          const handleClose = function (event, confirm) {
            this.setState({ open: false })
            if (typeof confirm === 'boolean' && confirm)
              updateISTD(
                dispatch,
                newISTDGroup,
                itemToUpdate.event,
                batch,
                itemToUpdate,
                'compound',
              )
          }.bind(this)

          const handleConfirmRecomputeIntegration = function (event, confirm) {
            this.setState({ openRecomputeIntegration: false })
            if (confirm) {
              recomputePeaks()
            }
          }.bind(this)

          const handleApplyFilters = (activeFilters) => {
            this.tableMultiGridRef.current.clearSelection()
            this.setState(activeFilters)
            dispatch(
              fetchUnitaryAnalysesCompound(
                this.props.match.params.batchId,
                this.props.match.params.moleculeEvent,
                this.state.untreatedChecked,
                this.state.requested,
                this.state.validationDetected,
                this.state.validationNotDetected,
                this.state.validationRelaunch,
                activeFilters['std'],
                activeFilters['mb'],
                activeFilters['mbd'],
                activeFilters['stde'],
                activeFilters['blk'],
                activeFilters['spl'],
                activeFilters['oth'],
                this.state.classificationAllDetected,
                this.state.classificationAllSuspected,
                this.state.classificationAllExcluded,
                this.state.search,
                localStorage.getItem('COMPOUND_sorter'),
              ),
            )
          }

          const handleRequestedChange = () => {
            this.tableMultiGridRef.current.clearSelection()
            dispatch(
              fetchUnitaryAnalysesCompound(
                this.props.match.params.batchId,
                this.props.match.params.moleculeEvent,
                this.state.untreatedChecked,
                !this.state.requested,
                this.state.validationDetected,
                this.state.validationNotDetected,
                this.state.validationRelaunch,
                this.state.std,
                this.state.mb,
                this.state.mbd,
                this.state.stde,
                this.state.blk,
                this.state.spl,
                this.state.oth,
                this.state.classificationAllDetected,
                this.state.classificationAllSuspected,
                this.state.classificationAllExcluded,
                this.state.search,
                localStorage.getItem('COMPOUND_sorter'),
              ),
            )
            localStorage.setItem('COMPOUND_filter_requested', (!this.state.requested).toString())
            this.setState({ requested: !this.state.requested })
          }
          const handleChangeValidationDetected = () => {
            this.tableMultiGridRef.current.clearSelection()
            const pressed = !this.state.validationDetected
            if (pressed) {
              this.setState({
                untreatedChecked: false,
              })
              localStorage.setItem('COMPOUND_filter_untreated', 'false')
            }

            dispatch(
              fetchUnitaryAnalysesCompound(
                this.props.match.params.batchId,
                this.props.match.params.moleculeEvent,
                pressed ? false : this.state.untreatedChecked,
                this.state.requested,
                !this.state.validationDetected,
                this.state.validationNotDetected,
                this.state.validationRelaunch,
                this.state.std,
                this.state.mb,
                this.state.mbd,
                this.state.stde,
                this.state.blk,
                this.state.spl,
                this.state.oth,
                this.state.classificationAllDetected,
                this.state.classificationAllSuspected,
                this.state.classificationAllExcluded,
                this.state.search,
                localStorage.getItem('COMPOUND_sorter'),
              ),
            )
            localStorage.setItem(
              'COMPOUND_filter_detected',
              (!this.state.validationDetected).toString(),
            )
            this.setState({ validationDetected: !this.state.validationDetected })
          }
          const handleChangeValidationNotDetected = () => {
            this.tableMultiGridRef.current.clearSelection()
            const pressed = !this.state.validationNotDetected
            if (pressed) {
              this.setState({
                untreatedChecked: false,
              })
              localStorage.setItem('COMPOUND_filter_untreated', 'false')
            }

            dispatch(
              fetchUnitaryAnalysesCompound(
                this.props.match.params.batchId,
                this.props.match.params.moleculeEvent,
                pressed ? false : this.state.untreatedChecked,
                this.state.requested,
                this.state.validationDetected,
                !this.state.validationNotDetected,
                this.state.validationRelaunch,
                this.state.std,
                this.state.mb,
                this.state.mbd,
                this.state.stde,
                this.state.blk,
                this.state.spl,
                this.state.oth,
                this.state.classificationAllDetected,
                this.state.classificationAllSuspected,
                this.state.classificationAllExcluded,
                this.state.search,
                localStorage.getItem('COMPOUND_sorter'),
              ),
            )
            localStorage.setItem(
              'COMPOUND_filter_notDetected',
              (!this.state.validationNotDetected).toString(),
            )
            this.setState({ validationNotDetected: !this.state.validationNotDetected })
          }
          const handleChangeValidationRelaunch = () => {
            this.tableMultiGridRef.current.clearSelection()
            const pressed = !this.state.validationRelaunch
            if (pressed) {
              this.setState({
                untreatedChecked: false,
              })
              localStorage.setItem('COMPOUND_filter_untreated', 'false')
            }

            dispatch(
              fetchUnitaryAnalysesCompound(
                this.props.match.params.batchId,
                this.props.match.params.moleculeEvent,
                pressed ? false : this.state.untreatedChecked,
                this.state.requested,
                this.state.validationDetected,
                this.state.validationNotDetected,
                !this.state.validationRelaunch,
                this.state.std,
                this.state.mb,
                this.state.mbd,
                this.state.stde,
                this.state.blk,
                this.state.spl,
                this.state.oth,
                this.state.classificationAllDetected,
                this.state.classificationAllSuspected,
                this.state.classificationAllExcluded,
                this.state.search,
                localStorage.getItem('COMPOUND_sorter'),
              ),
            )
            localStorage.setItem(
              'COMPOUND_filter_relaunch',
              (!this.state.validationRelaunch).toString(),
            )
            this.setState({ validationRelaunch: !this.state.validationRelaunch })
          }

          const handleChangeClassificationAllDetected = () => {
            this.tableMultiGridRef.current.clearSelection()
            dispatch(
              fetchUnitaryAnalysesCompound(
                this.props.match.params.batchId,
                this.props.match.params.moleculeEvent,
                this.state.untreatedChecked,
                this.state.requested,
                this.state.validationDetected,
                this.state.validationNotDetected,
                this.state.validationRelaunch,
                this.state.std,
                this.state.mb,
                this.state.mbd,
                this.state.stde,
                this.state.blk,
                this.state.spl,
                this.state.oth,
                !this.state.classificationAllDetected,
                this.state.classificationAllSuspected,
                this.state.classificationAllExcluded,
                this.state.search,
                localStorage.getItem('COMPOUND_sorter'),
              ),
            )
            localStorage.setItem(
              'COMPOUND_filter_allDetected',
              (!this.state.classificationAllDetected).toString(),
            )
            this.setState({ classificationAllDetected: !this.state.classificationAllDetected })
          }
          const handleChangeClassificationAllSuspected = () => {
            this.tableMultiGridRef.current.clearSelection()
            dispatch(
              fetchUnitaryAnalysesCompound(
                this.props.match.params.batchId,
                this.props.match.params.moleculeEvent,
                this.state.untreatedChecked,
                this.state.requested,
                this.state.validationDetected,
                this.state.validationNotDetected,
                this.state.validationRelaunch,
                this.state.std,
                this.state.mb,
                this.state.mbd,
                this.state.stde,
                this.state.blk,
                this.state.spl,
                this.state.oth,
                this.state.classificationAllDetected,
                !this.state.classificationAllSuspected,
                this.state.classificationAllExcluded,
                this.state.search,
                localStorage.getItem('COMPOUND_sorter'),
              ),
            )
            localStorage.setItem(
              'COMPOUND_filter_allSuspected',
              (!this.state.classificationAllSuspected).toString(),
            )
            this.setState({ classificationAllSuspected: !this.state.classificationAllSuspected })
          }
          const handleChangeClassificationAllExcluded = () => {
            this.tableMultiGridRef.current.clearSelection()
            dispatch(
              fetchUnitaryAnalysesCompound(
                this.props.match.params.batchId,
                this.props.match.params.moleculeEvent,
                this.state.untreatedChecked,
                this.state.requested,
                this.state.validationDetected,
                this.state.validationNotDetected,
                this.state.validationRelaunch,
                this.state.std,
                this.state.mb,
                this.state.mbd,
                this.state.stde,
                this.state.blk,
                this.state.spl,
                this.state.oth,
                this.state.classificationAllDetected,
                this.state.classificationAllSuspected,
                !this.state.classificationAllExcluded,
                this.state.search,
                localStorage.getItem('COMPOUND_sorter'),
              ),
            )
            localStorage.setItem(
              'COMPOUND_filter_allExcluded',
              (!this.state.classificationAllExcluded).toString(),
            )
            this.setState({ classificationAllExcluded: !this.state.classificationAllExcluded })
          }

          const handleChangeStandardFilter = () => {
            this.tableMultiGridRef.current.clearSelection()
            localStorage.setItem(
              'COMPOUND_filter_standard',
              (!this.state.standardFilter).toString(),
            )
            this.setState({ standardFilter: !this.state.standardFilter })
            dispatch(
              fetchUnitaryAnalysesCompound(
                this.props.match.params.batchId,
                this.props.match.params.moleculeEvent,
                this.state.untreatedChecked,
                this.state.requested,
                this.state.validationDetected,
                this.state.validationNotDetected,
                this.state.validationRelaunch,
                this.state.std,
                this.state.mb,
                this.state.mbd,
                this.state.stde,
                this.state.blk,
                this.state.spl,
                this.state.oth,
                this.state.classificationAllDetected,
                this.state.classificationAllSuspected,
                this.state.classificationAllExcluded,
                this.state.search,
                localStorage.getItem('COMPOUND_sorter'),
              ),
            )
          }

          const handleChangeQualityFilter = () => {
            this.tableMultiGridRef.current.clearSelection()
            localStorage.setItem('COMPOUND_filter_quality', (!this.state.qualityFilter).toString())
            this.setState({ qualityFilter: !this.state.qualityFilter })
            dispatch(
              fetchUnitaryAnalysesCompound(
                this.props.match.params.batchId,
                this.props.match.params.moleculeEvent,
                this.state.untreatedChecked,
                this.state.requested,
                this.state.validationDetected,
                this.state.validationNotDetected,
                this.state.validationRelaunch,
                this.state.std,
                this.state.mb,
                this.state.mbd,
                this.state.stde,
                this.state.blk,
                this.state.spl,
                this.state.oth,
                this.state.classificationAllDetected,
                this.state.classificationAllSuspected,
                this.state.classificationAllExcluded,
                this.state.search,
                localStorage.getItem('COMPOUND_sorter'),
              ),
            )
          }

          const handleChangeBlankFilter = () => {
            this.tableMultiGridRef.current.clearSelection()
            localStorage.setItem('COMPOUND_filter_blank', (!this.state.blankFilter).toString())
            this.setState({ blankFilter: !this.state.blankFilter })
            dispatch(
              fetchUnitaryAnalysesCompound(
                this.props.match.params.batchId,
                this.props.match.params.moleculeEvent,
                this.state.untreatedChecked,
                this.state.requested,
                this.state.validationDetected,
                this.state.validationNotDetected,
                this.state.validationRelaunch,
                this.state.std,
                this.state.mb,
                this.state.mbd,
                this.state.stde,
                this.state.blk,
                this.state.spl,
                this.state.oth,
                this.state.classificationAllDetected,
                this.state.classificationAllSuspected,
                this.state.classificationAllExcluded,
                this.state.search,
                localStorage.getItem('COMPOUND_sorter'),
              ),
            )
          }

          const handleChangeSamplesOthersFilter = () => {
            this.tableMultiGridRef.current.clearSelection()
            localStorage.setItem(
              'COMPOUND_filter_samplesOthers',
              (!this.state.samplesOthersFilter).toString(),
            )
            this.setState({ samplesOthersFilter: !this.state.samplesOthersFilter })
            dispatch(
              fetchUnitaryAnalysesCompound(
                this.props.match.params.batchId,
                this.props.match.params.moleculeEvent,
                this.state.untreatedChecked,
                this.state.requested,
                this.state.validationDetected,
                this.state.validationNotDetected,
                this.state.validationRelaunch,
                this.state.std,
                this.state.mb,
                this.state.mbd,
                this.state.stde,
                this.state.blk,
                this.state.spl,
                this.state.oth,
                this.state.classificationAllDetected,
                this.state.classificationAllSuspected,
                this.state.classificationAllExcluded,
                this.state.search,
                localStorage.getItem('COMPOUND_sorter'),
              ),
            )
          }

          const handleUntreatedChange = () => {
            this.tableMultiGridRef.current.clearSelection()
            const pressed = !this.state.untreatedChecked
            if (pressed) {
              this.setState({
                validationDetected: false,
                validationNotDetected: false,
                validationRelaunch: false,
              })
              localStorage.setItem('COMPOUND_filter_detected', 'false')
              localStorage.setItem('COMPOUND_filter_notDetected', 'false')
              localStorage.setItem('COMPOUND_filter_relaunch', 'false')
            }
            dispatch(
              fetchUnitaryAnalysesCompound(
                this.props.match.params.batchId,
                this.props.match.params.moleculeEvent,
                !this.state.untreatedChecked,
                this.state.requested,
                pressed ? false : this.state.validationDetected,
                pressed ? false : this.state.validationNotDetected,
                pressed ? false : this.state.validationRelaunch,
                this.state.std,
                this.state.mb,
                this.state.mbd,
                this.state.stde,
                this.state.blk,
                this.state.spl,
                this.state.oth,
                this.state.classificationAllDetected,
                this.state.classificationAllSuspected,
                this.state.classificationAllExcluded,
                this.state.search,
                localStorage.getItem('COMPOUND_sorter'),
              ),
            )
            localStorage.setItem(
              'COMPOUND_filter_untreated',
              (!this.state.untreatedChecked).toString(),
            )
            this.setState({ untreatedChecked: !this.state.untreatedChecked })
          }

          const recomputePeaks = () => {
            displayPopupMessage(
              dispatch,
              'info',
              t('view.compound.information.recompute_peak.title'),
              <Fragment>
                <div>{t('view.compound.information.recompute_peak.msg1')}</div>
                <div style={{ marginTop: 10 }}>
                  <i>{t('view.compound.information.recompute_peak.msg2')}</i>
                </div>
              </Fragment>,
            )
            const taskId =
              new Date().getTime() + '_recompute_integration_' + this.props.match.params.batchId
            const task = {
              id: taskId,
              title: t('view.compound.information.recompute_peak.title'),
              operator: localStorage.getItem('SESSION_user_name'),
              date: new Date().getTime(),
              action: 'recompute_integration',
              percentage: 50,
              state: { value: 'running', message: '' },
              operation: 'recompute integration',
              items: [
                {
                  batch: {
                    id: batch._id,
                    name: batch.name,
                  },
                },
              ],
            }
            dispatch(fetchAddTask(task))
            dispatch(
              fetchRecomputePeaks(
                this.props.match.params.batchId,
                recompute === 'compound'
                  ? this.props.match.params.moleculeEvent
                  : batch.content.internal_standards[compound.istd].event,
              ),
            )
              .then(function (resp) {
                displayPopupMessage(
                  dispatch,
                  'success',
                  t('view.compound.information.recompute_peak.title'),
                  <Fragment>
                    <div>
                      <Trans i18nKey="view.compound.information.recompute_peak.done">
                        The integration recompute of <i>{{ val: batch.name }}</i> are done.
                      </Trans>
                    </div>
                  </Fragment>,
                )
                dispatch(
                  fetchFinishTask(
                    taskId,
                    'success',
                    t('view.compound.information.recompute_peak.fine'),
                  ),
                )
              })
              .catch(function (error) {
                if (error && error.status !== undefined && error.status === 401) {
                  error.statusText = t('view.common.utils.lease_locked.code')
                  displayPopupMessage(
                    dispatch,
                    'warning',
                    t('view.compound.information.recompute_peak.title'),
                    <Fragment>
                      <div>{t('view.compound.information.recompute_peak.warning')}</div>
                      {generateErrorPart(error)}
                    </Fragment>,
                  )
                } else {
                  displayPopupMessage(
                    dispatch,
                    'error',
                    t('view.compound.information.recompute_peak.title'),
                    <Fragment>
                      <div>
                        <Trans i18nKey="view.compound.information.recompute_peak.error">
                          The integration recompute of <i>{{ val: batch.name }}</i> can not be done.
                        </Trans>
                      </div>
                      <div>{error.toString()}</div>
                    </Fragment>,
                  )
                }
                dispatch(fetchFinishTask(taskId, 'error', error.toString()))
              })
          }

          const handleRecomputeChange = (event) => {
            const value = event.target.value
            this.setState({ recompute: value })
          }

          const calcRecompute = Object.entries(batch.content.molecules)
            .filter((molecule) => molecule[1].istd === compound.istd)
            .map((mol) => mol[1].name)

          return (
            <React.Fragment>
              <Dialog
                open={openRecomputeIntegration}
                onClose={(event) => handleConfirmRecomputeIntegration(event, false)}
                TransitionComponent={TransitionBottom}
                aria-labelledby="dialog-reset-title"
              >
                <StyledDialogTitle>
                  {t('view.compound.information.recompute_integ.title')}
                </StyledDialogTitle>
                <DialogContent>
                  <DialogContentText>
                    {t('view.compound.information.recompute_integ.msg')}
                  </DialogContentText>
                  <FormControl component="fieldset">
                    <RadioGroup name="recompute" value={recompute} onChange={handleRecomputeChange}>
                      <FormControlLabel
                        value="compound"
                        control={<Radio />}
                        label={t('view.compound.information.recompute_integ.label_compound')}
                      />
                      <FormControlLabel
                        value="istd"
                        disabled={
                          !(
                            batch.content.internal_standards &&
                            batch.content.internal_standards[compound.istd] &&
                            typeof batch.content.internal_standards[compound.istd].event ===
                              'number'
                          )
                        }
                        control={<Radio />}
                        label={t('view.compound.information.recompute_integ.label_istd')}
                      />
                    </RadioGroup>
                  </FormControl>
                  <DialogContentText component="span" style={{ marginTop: 20, height: 23 }}>
                    {recompute === 'istd' ? (
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <WarningIcon style={{ marginRight: 10 }} />
                        <span>
                          {t('view.compound.information.recompute_integ.time')}{' '}
                          <Tooltip
                            interactive
                            TransitionComponent={Zoom}
                            classes={{ tooltip: classes.customWidth }}
                            placement="top"
                            arrow
                            title={
                              <div style={{ fontSize: 13, padding: 5 }}>
                                {calcRecompute.map((cr, idx) => (
                                  <Chip
                                    key={idx}
                                    style={{ margin: 2 }}
                                    size="small"
                                    color="secondary"
                                    label={cr}
                                  />
                                ))}
                              </div>
                            }
                          >
                            <Chip size="small" variant="outlined" label={calcRecompute.length} />
                          </Tooltip>{' '}
                          {t('view.compound.information.recompute_integ.time2')}
                        </span>
                      </Grid>
                    ) : (
                      ' '
                    )}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    autoFocus
                    onClick={(event) => handleConfirmRecomputeIntegration(event, false)}
                    color="primary"
                  >
                    {t('view.compound.information.recompute_integ.cancel')}
                  </Button>
                  <Button
                    onClick={(event) => handleConfirmRecomputeIntegration(event, true)}
                    color="primary"
                  >
                    {t('view.compound.information.recompute_integ.yes')}
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog
                open={open}
                onClose={handleClose}
                TransitionComponent={TransitionTop}
                aria-labelledby="draggable-dialog-title"
              >
                <StyledDialogTitle>
                  {t('view.compound.information.istd_update.title')}
                </StyledDialogTitle>
                <DialogContent>
                  <DialogContentText>
                    {t('view.compound.information.istd_update.msg')}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button autoFocus onClick={(event) => handleClose(event, false)} color="primary">
                    {t('view.compound.information.istd_update.cancel')}
                  </Button>
                  <Button onClick={(event) => handleClose(event, true)} color="primary">
                    {t('view.compound.information.istd_update.ok')}
                  </Button>
                </DialogActions>
              </Dialog>
              <Paper
                elevation={0}
                className={classes.paper}
                style={{
                  height: collapsed ? '10px' : '100%',
                  paddingTop: collapsed ? '0px' : '10px',
                }}
              >
                <div style={{ opacity: collapsed ? 0 : 1 }}>
                  <Title>
                    <Grid container spacing={3} direction="row" justifyContent="space-between">
                      <Grid item>
                        <Tooltip
                          arrow
                          title={
                            <div style={{ fontSize: 13, padding: 5 }}>
                              {t('view.compound.information.tooltip.batch_name')}
                            </div>
                          }
                        >
                          <Box style={{ display: 'inline' }}>{batch.name}</Box>
                        </Tooltip>
                      </Grid>
                      <Grid item>
                        <Tooltip
                          arrow
                          title={
                            <div style={{ fontSize: 13, padding: 5 }}>
                              {t('view.compound.information.tooltip.compound_name')}
                            </div>
                          }
                        >
                          <Box style={{ display: 'inline' }}>{compound.name}</Box>
                        </Tooltip>
                      </Grid>
                      <Grid item>
                        {t('view.compound.information.tooltip.event')} {compound.event}
                      </Grid>
                      <Grid item>
                        {t('view.compound.information.tooltip.rt')} {compound.ret_time.toFixed(2)}
                      </Grid>
                      {/*<Grid item>*/}
                      {/*  <NoteButton*/}
                      {/*    title*/}
                      {/*    row={{*/}
                      {/*      _id: compound._id,*/}
                      {/*      notes: compound.notes ? compound.notes : [],*/}
                      {/*    }}*/}
                      {/*    collection={'unitary_analysis'}*/}
                      {/*    style={{ paddingTop: 0, paddingBottom: 0 }}*/}
                      {/*  />*/}
                      {/*</Grid>*/}
                      <Grid item style={{ height: 70, paddingTop: 5 }}>
                        {t('view.compound.information.tooltip.istd')}
                        {compound.istd_name !== compound.name ? (
                          <React.Fragment>
                            <FormControl className={classes.formControl}>
                              <Select
                                label={'ISTD'}
                                value={
                                  !(
                                    batch.content.internal_standards &&
                                    batch.content.internal_standards[compound.istd] &&
                                    typeof batch.content.internal_standards[compound.istd].event ===
                                      'number'
                                  )
                                    ? -1
                                    : compound.istd
                                }
                                name={'istd'}
                                onChange={(event) => onWhichUpdateISTD(event, compound)}
                                displayEmpty
                                autoWidth
                                inputProps={{ 'aria-label': 'ISTD' }}
                              >
                                {!(
                                  batch.content.internal_standards &&
                                  batch.content.internal_standards[compound.istd] &&
                                  typeof batch.content.internal_standards[compound.istd].event ===
                                    'number'
                                ) && (
                                  <MenuItem key={-1} value={-1}>
                                    <span style={{ color: '#a5a5a5' }}>
                                      {t('view.compound.information.no_istd')}
                                    </span>
                                  </MenuItem>
                                )}
                                {Object.entries(
                                  batch && batch.content && batch.content.internal_standards
                                    ? batch.content.internal_standards
                                    : [],
                                ).map((internal_standard, index) => (
                                  <MenuItem key={index} value={internal_standard[1].istd}>
                                    {internal_standard[1].name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            <IconButton
                              color="inherit"
                              onClick={() => this.goToISTD(compound)}
                              disabled={
                                !(
                                  batch.content.internal_standards &&
                                  batch.content.internal_standards[compound.istd] &&
                                  typeof batch.content.internal_standards[compound.istd].event ===
                                    'number'
                                )
                              }
                            >
                              <OpenInNewIcon />
                            </IconButton>
                          </React.Fragment>
                        ) : (
                          '  ' + compound.istd_name
                        )}
                      </Grid>
                      <Grid item style={{ paddingTop: 19 }}>
                        <Tooltip
                          arrow
                          title={
                            <div
                              style={{
                                fontSize: 13,
                                padding: 5,
                              }}
                            >
                              Report data
                            </div>
                          }
                        >
                          <Link
                            href={`${FLASK_URL()}/batch/${batch._id}/reporting`}
                            color="inherit"
                            onClick={() => {
                              displayPopupMessage(
                                dispatch,
                                'info',
                                t('view.compound.information.report_data'),
                                <Fragment>
                                  <div>
                                    {t('view.compound.information.batch_selected')} {batch.name}
                                  </div>
                                </Fragment>,
                              )
                            }}
                          >
                            <XLSFileInvert
                              style={{ marginRight: 8, width: 20, height: 24 }}
                              className={classes.colorBlue}
                            />
                          </Link>
                        </Tooltip>
                      </Grid>
                      <Grid item style={{ paddingTop: 18 }}>
                        <ErrorBoundaryGuard isDialog>
                          <SettingsView
                            idTable={idTable}
                            propsColumns={propsColumns}
                            parentMultiGridRef={parentMultiGridRef}
                            origin={'compound'}
                          />
                        </ErrorBoundaryGuard>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      spacing={3}
                      direction="row"
                      justifyContent="space-between"
                      style={{ marginTop: 5 }}
                    >
                      <Grid item style={{ paddingTop: 0, paddingBottom: 8 }}>
                        <ErrorBoundaryGuard isDialog>
                          <CompoundFilterButton
                            deviceType={batch.content.chromatography_type}
                            columns={propsColumns}
                          />
                        </ErrorBoundaryGuard>
                      </Grid>
                      <Grid item style={{ paddingTop: 0, paddingBottom: 16 }}>
                        <Tooltip
                          key={'tooltip_requested'}
                          arrow
                          title={
                            <div style={{ fontSize: 13, padding: 5 }}>
                              {t('view.compound.information.filter_req')}
                            </div>
                          }
                        >
                          <Badge
                            className={clsx(
                              classes.badgeCountRect,
                              requested ? classes.badgeCountRectSelected : null,
                            )}
                            max={10000}
                            showZero={!isFetchingUnitaryCompoundDistinct}
                            overlap="circular"
                            badgeContent={
                              !isFetchingUnitaryCompoundDistinct &&
                              unitaryCompoundDistinct.requested
                                ? unitaryCompoundDistinct.requested.count
                                : 0
                            }
                          >
                            <IconButton
                              style={{ marginLeft: 0 }}
                              aria-label="requested"
                              className={clsx(
                                classes.filterButton,
                                requested ? classes.selected : classes.notSelected,
                              )}
                              onClick={handleRequestedChange}
                            >
                              <PersonPinIcon />
                            </IconButton>
                          </Badge>
                        </Tooltip>
                        <Tooltip
                          key={'tooltip_untreated'}
                          arrow
                          title={
                            <div style={{ fontSize: 13, padding: 5 }}>
                              {t('view.compound.information.filter_untreated')}
                            </div>
                          }
                        >
                          <Badge
                            className={clsx(
                              classes.badgeCountRect,
                              untreatedChecked ? classes.badgeCountRectSelected : null,
                            )}
                            max={10000}
                            showZero={!isFetchingUnitaryCompoundDistinct}
                            overlap="circular"
                            badgeContent={
                              !isFetchingUnitaryCompoundDistinct && unitaryCompoundDistinct['0']
                                ? unitaryCompoundDistinct['0'].count
                                : 0
                            }
                          >
                            <IconButton
                              aria-label="untreated"
                              className={clsx(
                                classes.filterButton,
                                untreatedChecked ? classes.selected : classes.notSelected,
                              )}
                              onClick={handleUntreatedChange}
                            >
                              <NewReleasesIcon />
                            </IconButton>
                          </Badge>
                        </Tooltip>
                        <Tooltip
                          key={'tooltip_detected'}
                          arrow
                          title={
                            <div style={{ fontSize: 13, padding: 5 }}>
                              {t('view.compound.information.filter_detected')}
                            </div>
                          }
                        >
                          <Badge
                            className={clsx(
                              classes.badgeCountRect,
                              validationDetected ? classes.badgeCountRectSelected : null,
                            )}
                            max={10000}
                            showZero={!isFetchingUnitaryCompoundDistinct}
                            overlap="circular"
                            badgeContent={
                              !isFetchingUnitaryCompoundDistinct && unitaryCompoundDistinct['1']
                                ? unitaryCompoundDistinct['1'].count
                                : 0
                            }
                          >
                            <IconButton
                              aria-label="detected"
                              style={{ width: 80 }}
                              className={clsx(
                                classes.filterButton,
                                validationDetected ? classes.selected : classes.notSelected,
                              )}
                              onClick={handleChangeValidationDetected}
                            >
                              {/*{isKOOK ? <CheckIcon /> : <Molecule />}*/}
                              <Molecule /> / <CheckIcon />
                            </IconButton>
                          </Badge>
                        </Tooltip>
                        <Tooltip
                          key={'tooltip_notdetected'}
                          arrow
                          title={
                            <div style={{ fontSize: 13, padding: 5 }}>
                              {t('view.compound.information.filter_undetected')}
                            </div>
                          }
                        >
                          <Badge
                            className={clsx(
                              classes.badgeCountRect,
                              validationNotDetected ? classes.badgeCountRectSelected : null,
                            )}
                            max={10000}
                            showZero={!isFetchingUnitaryCompoundDistinct}
                            overlap="circular"
                            badgeContent={
                              !isFetchingUnitaryCompoundDistinct && unitaryCompoundDistinct['2']
                                ? unitaryCompoundDistinct['2'].count
                                : 0
                            }
                          >
                            <IconButton
                              aria-label="notDetected"
                              style={{ width: 80 }}
                              className={clsx(
                                classes.filterButton,
                                validationNotDetected ? classes.selected : classes.notSelected,
                              )}
                              onClick={handleChangeValidationNotDetected}
                            >
                              <Nothing /> / <ClearIcon />
                            </IconButton>
                          </Badge>
                        </Tooltip>
                        <Tooltip
                          key={'tooltip_relaunch'}
                          arrow
                          title={
                            <div style={{ fontSize: 13, padding: 5 }}>
                              {t('view.compound.information.filter_relaunch')}
                            </div>
                          }
                        >
                          <Badge
                            className={clsx(
                              classes.badgeCountRect,
                              validationRelaunch ? classes.badgeCountRectSelected : null,
                            )}
                            max={10000}
                            showZero={!isFetchingUnitaryCompoundDistinct}
                            overlap="circular"
                            badgeContent={
                              !isFetchingUnitaryCompoundDistinct && unitaryCompoundDistinct['3']
                                ? unitaryCompoundDistinct['3'].count
                                : 0
                            }
                          >
                            <IconButton
                              aria-label="relaunch"
                              className={clsx(
                                classes.filterButton,
                                validationRelaunch ? classes.selected : classes.notSelected,
                              )}
                              onClick={handleChangeValidationRelaunch}
                            >
                              <Relance />
                            </IconButton>
                          </Badge>
                        </Tooltip>
                      </Grid>
                      <ErrorBoundaryGuard>
                        <FilterButton validFilterFunction={handleApplyFilters} />
                      </ErrorBoundaryGuard>
                      {localStorage.getItem('COMPOUND_filter_spl') === 'true' && (
                        <Grid>
                          <Tooltip
                            key={'tooltip_filter_detected'}
                            arrow
                            title={
                              <div style={{ fontSize: 13, padding: 5 }}>
                                {t('view.compound.information.display_detected')}
                              </div>
                            }
                          >
                            <Badge
                              className={clsx(
                                classes.badgeCountRect,
                                classificationAllDetected ? classes.badgeCountRectSelected : null,
                              )}
                              max={10000}
                              showZero={!isFetchingUnitaryCompoundDistinct}
                              badgeContent={
                                !isFetchingUnitaryCompoundDistinct &&
                                unitaryCompoundDistinct['detected']
                                  ? unitaryCompoundDistinct['detected'].count
                                  : 0
                              }
                            >
                              <IconButton
                                aria-label="detected"
                                className={clsx(
                                  classes.filterButton,
                                  classificationAllDetected
                                    ? classes.selected
                                    : classes.notSelected,
                                )}
                                onClick={handleChangeClassificationAllDetected}
                              >
                                <ErrorIcon style={{ marginRight: 5 }} />
                              </IconButton>
                            </Badge>
                          </Tooltip>
                          <Tooltip
                            key={'tooltip_filter_suspected'}
                            arrow
                            title={
                              <div style={{ fontSize: 13, padding: 5 }}>
                                {t('view.compound.information.display_suspected')}
                              </div>
                            }
                          >
                            <Badge
                              className={clsx(
                                classes.badgeCountRect,
                                classificationAllSuspected ? classes.badgeCountRectSelected : null,
                              )}
                              max={10000}
                              showZero={!isFetchingUnitaryCompoundDistinct}
                              badgeContent={
                                !isFetchingUnitaryCompoundDistinct &&
                                unitaryCompoundDistinct['suspected']
                                  ? unitaryCompoundDistinct['suspected'].count
                                  : 0
                              }
                            >
                              <IconButton
                                aria-label="suspected"
                                className={clsx(
                                  classes.filterButton,
                                  classificationAllSuspected
                                    ? classes.selected
                                    : classes.notSelected,
                                )}
                                onClick={handleChangeClassificationAllSuspected}
                              >
                                <HelpIcon style={{ marginRight: 5 }} />
                              </IconButton>
                            </Badge>
                          </Tooltip>
                          <Tooltip
                            key={'tooltip_filter_excluded'}
                            arrow
                            title={
                              <div style={{ fontSize: 13, padding: 5 }}>
                                {t('view.compound.information.display_excluded')}
                              </div>
                            }
                          >
                            <Badge
                              className={clsx(
                                classes.badgeCountRect,
                                classificationAllExcluded ? classes.badgeCountRectSelected : null,
                              )}
                              max={10000}
                              showZero={!isFetchingUnitaryCompoundDistinct}
                              badgeContent={
                                !isFetchingUnitaryCompoundDistinct &&
                                unitaryCompoundDistinct['excluded']
                                  ? unitaryCompoundDistinct['excluded'].count
                                  : 0
                              }
                            >
                              <IconButton
                                aria-label="excluded"
                                className={clsx(
                                  classes.filterButton,
                                  classificationAllExcluded
                                    ? classes.selected
                                    : classes.notSelected,
                                )}
                                onClick={handleChangeClassificationAllExcluded}
                              >
                                <CancelIcon style={{ marginRight: 5 }} />
                              </IconButton>
                            </Badge>
                          </Tooltip>
                        </Grid>
                      )}
                      <Grid>
                        <Tooltip
                          arrow
                          placement="top"
                          title={
                            <div className={classes.tooltip}>
                              {t('view.compound.information.recompute_all')}
                            </div>
                          }
                        >
                          <IconButton
                            color="inherit"
                            onClick={() => this.setState({ openRecomputeIntegration: true })}
                          >
                            <TimelineIcon style={{ width: 25 }} />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                      {!tableOldVersion && (
                        <Grid>
                          <Tooltip
                            arrow
                            title={
                              <div className={classes.tooltip}>
                                {t('view.analysis.information.removeFilters')}
                              </div>
                            }
                          >
                            <IconButton
                              color="inherit"
                              aria-label="removeFilter"
                              onClick={() => {
                                this.tableMultiGridRef.current.clearFilters()
                              }}
                            >
                              <RemoveFilterIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip
                            arrow
                            title={
                              <div className={classes.tooltip}>
                                {t('view.analysis.information.download')}
                              </div>
                            }
                          >
                            <IconButton
                              color="inherit"
                              aria-label="download"
                              onClick={() => {
                                this.tableMultiGridRef.current.downloadCSV()
                              }}
                            >
                              <ExcelIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      )}
                      <Grid>
                        <div className={classes.search}>
                          <div className={classes.searchIcon}>
                            <SearchIcon />
                          </div>
                          <InputBase
                            placeholder={t('view.compound.information.search')}
                            classes={{
                              root: classes.inputRoot,
                              input: classes.inputInput,
                            }}
                            inputProps={{ 'aria-label': 'search' }}
                            onChange={this.onSearch}
                            ref={this.searchRef}
                          />
                          {this.state.displayClear && (
                            <IconButton
                              className={clsx(classes.clearIcon, classes.searchIcon)}
                              aria-label="clear"
                              onClick={this.clearSearch}
                            >
                              <ClearIcon />
                            </IconButton>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </Title>
                </div>
                <div
                  className={clsx(
                    collapsed ? 'collapsed' : 'notCollapsed',
                    'collapsor',
                    classes.collapsor,
                  )}
                >
                  <div
                    style={{
                      position: 'absolute',
                      width: 48,
                      height: 5,
                      top: -2,
                      left: 1,
                      background: 'white',
                    }}
                  ></div>
                  <IconButton
                    aria-label="collapse"
                    onClick={this.collapse}
                    className={classes.collapseButton}
                    style={{ transform: collapsed ? 'rotate(180deg)' : 'rotate(0deg)' }}
                  >
                    <ExpandLessIcon />
                  </IconButton>
                </div>
              </Paper>
            </React.Fragment>
          )
        }
      },
    ),
  ),
)
