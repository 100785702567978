import React, { useEffect, useMemo } from 'react'
import { Route, Switch, useRouteMatch, withRouter } from 'react-router-dom'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Container from '@material-ui/core/Container'
import Backlog from './backlog/Backlog'
import Batch from './batch/Batch'
import Global from './global/Global'
import Analysis from './analysis/Analysis'
import Compound from './compound/Compound'
import Analysis_old from './analysis/Analysis_old'
import Compound_old from './compound/Compound_old'
import NoMatch from './NoMatch'
import { fetchGetTasks, fetchGetToolbarTasks } from '../redux/tasks/actions'
import { fetchCleanConfigurationFeatures } from '../redux/configuration/actions'
import { useDispatch } from 'react-redux'
import { USE_OLD_TABLE } from '../utils/config'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    height: 'calc(100% - 0px)',
  },
}))
const RouterComponent = withRouter((props) => {
  const classes = useStyles()
  let match = useRouteMatch()
  const dispatch = useDispatch()

  // Storage of configuration id
  useEffect(function () {
    if (props.location.pathname === '/') {
      // Remove old batch configuration id stored
      localStorage.removeItem('SETTINGS_configuration_profile')
      dispatch(fetchCleanConfigurationFeatures())
    }
  }, [])

  const oldTable = useMemo(() => {
    return USE_OLD_TABLE()
  }, [])

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Switch>
        <Route exact path={`${match.path}:chromatoId?`} component={Backlog} />
        <Route path={`${match.path}batch/:batchId/:chromatoId?`} component={Batch} />
        <Route path={`${match.path}global/:batchId/:chromatoId?`} component={Global} />
        <Route
          path={`${match.path}:batchId/analysis/:analysisId/:chromatoId?`}
          component={oldTable ? Analysis_old : Analysis}
        />
        <Route
          path={`${match.path}:batchId/compound/:moleculeEvent/:chromatoId?`}
          component={oldTable ? Compound_old : Compound}
        />
        <Route path="/reload" component={null} key="reload" />
        <Route component={NoMatch} />
      </Switch>
    </Container>
  )
})
export default RouterComponent
