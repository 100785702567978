import React, { Fragment } from 'react'
import Paper from '@material-ui/core/Paper'
import { connect } from 'react-redux'
import { alpha, withStyles } from '@material-ui/core/styles'
import Title from '../Title'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import LinearProgress from '@material-ui/core/LinearProgress'
import TableMultiGrid from '../common/Table_MultiGrid'
import clsx from 'clsx'
import AlphacodFlagIcon from '../common/AlphacodFlagIcon'
import { withRouter } from 'react-router-dom'
import { API_BO_URL, FLASK_URL, PUBLIC_URL } from '../../utils/config'
import Tooltip from '@material-ui/core/Tooltip'
import {
  getComparator,
  stableSort,
  createSubBatch,
  moveSampleToSubBatch,
  fetchReportToLims,
  openInNewTab,
  getClickAction,
  fetchLmrReportToLims,
} from '../common/Utils'
import { TransitionTop, DialogTitleColored, StyledDialogTitle } from '../common/Style'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import Link from '@material-ui/core/Link'
import GetAppIcon from '@material-ui/icons/GetApp'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import LimsTable from './LimsTable'
import { fetchUnitaryAnalysesLims } from '../../redux/lims/actions'
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder'
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent/DialogContent'
import Button from '@material-ui/core/Button/Button'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import Chip from '@material-ui/core/Chip'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select/Select'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import { getBatchById } from '../../redux/batches/selectors'
import SwapHorizIcon from '@material-ui/icons/SwapHoriz'
import PersonPinIcon from '@material-ui/icons/PersonPin'
import ErrorIcon from '@material-ui/icons/ErrorOutline'
import HelpIcon from '@material-ui/icons/HelpOutline'
import CancelIcon from '@material-ui/icons/CancelOutlined'
import CallMadeIcon from '@material-ui/icons/CallMade'
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser'
import { fetchAddTask, fetchFinishTask } from '../../redux/tasks/actions'
import Switch from '@material-ui/core/Switch'
import AlphacodModifiedButtonIcon from '../common/AlphacodModifiedButtonIcon'
import NoteButton from '../common/NoteButton'
import { displayPopupMessage, generateErrorPart } from '../common/Message'
import FolderIcon from '@material-ui/icons/Folder'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import { fetchChangeSettings } from '../../redux/settings/actions'
import FunctionsIcon from '@material-ui/icons/Functions'
import FormulasCmp from './FormulasCmp'
import ErrorBoundaryGuard from '../ErrorBoundaryGuard'
import { t } from 'i18next'
import { Trans } from 'react-i18next'
import MoveDialog from './MoveDialog'
import { Stack } from '@mui/material'
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline'

const styles = (theme) => ({
  paper: {
    // boxShadow:
    //   '0px 2px 7px 1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    padding: theme.spacing(2),
    height: '100%',
    overflow: 'hidden',
  },
  tableContainer: {
    height: 'calc(100% - 35px)',
    overflow: 'hidden',
    '& .ReactVirtualized__Grid:focus': {
      outline: 'none',
    },
  },
  selected: {
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  notSelected: {
    opacity: 0.5,
    '&:hover': {
      opacity: 1,
    },
  },
  selectedRow: {
    cursor: 'pointer',
    background: 'rgba(0, 0, 0, 0.1)',
    '& .nameAdorment': {
      display: 'flex !important',
    },
  },
  blink: {
    background: alpha(theme.palette.secondary.light, 0.19),
  },
  selectedRowBlink: {
    background: alpha(theme.palette.secondary.light, 0.37),
  },
  noBlink: {
    cursor: 'pointer',
  },
  cellFlags: {
    padding: 7,
  },
  tooltip: {
    fontSize: 13,
    padding: 5,
  },
  toolbarButton: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.03),
    marginRight: 5,
    width: 34,
    height: 34,
    padding: 8,
    '&:hover': {
      color: theme.palette.secondary.main,
      backgroundColor: alpha(theme.palette.common.black, 0.03),
    },
  },
  toolbar: {
    position: 'absolute',
    top: 48,
    left: 70,
    background: '#fff',
    padding: 5,
    paddingRight: 1,
    border: '2px solid',
    borderColor: theme.palette.secondary.main,
    borderRadius: 8,
  },
  auditButton: {
    padding: 0,
    '&:hover': {
      color: theme.palette.secondary.main,
      backgroundColor: 'transparent',
    },
  },
  formControl: {
    marginTop: 15,
  },
  moveChip: {
    margin: 3,
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
  },
  dialogLims: {
    '& .MuiDialog-paperScrollPaper': {
      maxWidth: 'none !important',
    },
  },
  // actionButton: {
  //   '& > * + *': {
  //     marginLeft: theme.spacing(2),
  //   },
  // },
  optionLabel: {
    color: alpha(theme.palette.common.black, 0.54),
    fontSize: '1rem',
    marginRight: 20,
  },
  optionSelected: {
    color: theme.palette.secondary.main,
  },
})

const mapStateToProps = (state, ownProps) => {
  const { analyses, batches } = state
  const batch = getBatchById(batches, ownProps.match.params.batchId)
  return {
    batch: batch,
    analysis: analyses.items,
    isFetching: analyses.isFetching,
    isFetchingAnalysesSentToLims: analyses.isFetchingAnalysesSentToLims,
    analysesSentToLims: analyses.analysesSentToLims,
    analysesNotSentToLims: analyses.analysesNotSentToLims,
  }
}

const BorderLinearProgress = withStyles({
  root: {
    top: 5,
    height: 10,
    borderRadius: 10,
  },
  bar: {
    borderRadius: 10,
  },
})(LinearProgress)

const CustomDialog = withStyles((theme) => ({
  paper: {
    height: '100%',
  },
}))(Dialog)

export default withRouter(
  connect(mapStateToProps)(
    withStyles(styles)(
      class SamplesTable extends React.Component {
        constructor(props) {
          super(props)
          this.state = {
            error: null,
            selection: [],
            visibilyLimsTable: false,
            openBatchCreate: false,
            openBatchMove: false,
            createBatch: null,
            moveBatch: null,
            openLims: false,
            familyBatches: null,
            direction: 'asc',
            order: 'name',
            option: 'move',
            openExtentedView: false,
            openFormulasDialog: false,
            openMove: false,
            nonDetectedUA: [],
          }
          this.tableMultiGridRef = React.createRef()
          this.onSelect = this.onSelect.bind(this)
          this.multiValidate = this.multiValidate.bind(this)
          this.handleCloseLims = this.handleCloseLims.bind(this)
          this.handleCloseMove = this.handleCloseMove.bind(this)
          this.handleDisplayLims = this.handleDisplayLims.bind(this)
          this.onChangeMoveBatch = this.onChangeMoveBatch.bind(this)
          // this.onChangeCreateBatch = this.onChangeCreateBatch.bind(this)
          // this.onChangeMoveBatch = this.onChangeMoveBatch.bind(this)
        }

        onSelect(selected) {
          this.setState({
            selection: selected.map((i) => this.getEchClient()[i]),
          })
        }

        onChangeCreateBatch(event) {
          this.setState({ createBatch: event.target.value })
        }

        onChangeOption(event) {
          this.setState({ option: event.target.checked ? 'copy' : 'move' })
        }

        onChangeMoveBatch(event) {
          const { familyBatches } = this.state
          const name = familyBatches[event.target.value].name
          this.setState({ moveBatch: { value: event.target.value, name: name } })
        }

        async multiValidate(value) {
          const dispatch = this.props.dispatch
          switch (value) {
            case 0:
              this.setState({ openBatchCreate: true, createBatch: null })
              break
            case 1:
              this.setState({
                openBatchMove: true,
                moveBatch: null,
              })
              fetch(`${FLASK_URL()}/batch/${this.props.match.params.batchId}/family`, {
                method: 'GET',
                credentials: 'include',
              })
                .then((response) => {
                  if (!response.ok) {
                    const statusText = response.statusText
                    const status = response.status
                    const url = response.url
                    return response.text().then((errorMessage) => {
                      const error = new Error(`${statusText} : ${errorMessage}`)
                      if (response.headers.get('content-type') === 'application/json') {
                        error.stack = JSON.stringify(
                          JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                          null,
                          2,
                        )
                      } else {
                        error.stack = new Error().stack
                      }
                      error.statusText = statusText
                      error.status = status
                      error.url = url
                      throw error
                    })
                  }
                  return response.json()
                })
                .then((json) => {
                  this.setState({
                    familyBatches: json,
                  })
                })
                .catch((error) => {
                  if (error.status === 403) {
                    dispatch(fetchChangeSettings('loginPopup', true))
                  }
                  displayPopupMessage(
                    dispatch,
                    'error',
                    t('view.batch.othersTable.error_family_title'),
                    <Fragment>
                      <div>{t('view.batch.othersTable.error_family_msg')}</div>
                      {generateErrorPart(error)}
                    </Fragment>,
                  )
                })
              break
            case 2:
              // Open window to move to QC or Others
              this.setState({ openMove: true })
              break
            default:
              break
          }
        }

        handleCloseLims(event, save, exportResult, exportLMR, mocFilter) {
          const { dispatch, batch } = this.props
          const { selection, nonDetectedUA } = this.state
          const tableMultiGridRef = this.tableMultiGridRef
          const me = this
          if (typeof save === 'boolean' && save) {
            const selectionToSend = selection.filter(function (selectionUpdated) {
              return (
                selectionUpdated.limsUA &&
                selectionUpdated.limsUA.length !== 0 &&
                selectionUpdated.content.progression_requested !== 0
              )
            })
            const resultToSend = selectionToSend.map((ua) => ua._id)
            if (resultToSend.length === 0) {
              displayPopupMessage(
                dispatch,
                'warning',
                t('view.batch.samplesTable.export_lims_title'),
                <Fragment>
                  <div>{t('view.batch.samplesTable.export_lims_msg')}</div>
                </Fragment>,
              )
            } else {
              displayPopupMessage(
                dispatch,
                'info',
                t('view.batch.samplesTable.export_lims_title'),
                <Fragment>
                  <div>{t('view.batch.samplesTable.will_exported')}</div>
                  <div style={{ maxWidth: 600, maxHeight: 400, overflow: 'scroll' }}>
                    {selectionToSend.map((item, index) => (
                      <Chip
                        key={index}
                        variant="outlined"
                        size="small"
                        style={{ margin: 3 }}
                        label={item.name}
                      />
                    ))}
                  </div>
                  <div style={{ marginTop: 10 }}>
                    <i>{t('view.batch.samplesTable.time')}</i>
                  </div>
                </Fragment>,
              )
              const taskId = new Date().getTime() + '_export_lims_' + selectionToSend[0]._id
              const task = {
                id: taskId,
                title: t('view.batch.samplesTable.export_lims_title'),
                operator: localStorage.getItem('SESSION_user_name'),
                date: new Date().getTime(),
                action: 'export_to_lims',
                percentage: 50,
                state: { value: 'running', message: '' },
                operation: 'Commit',
                items: selectionToSend.map((orData) => ({
                  id: orData._id,
                  name: orData.name,
                  batch: {
                    id: batch._id,
                    name: batch.name,
                  },
                })),
              }
              dispatch(fetchAddTask(task))
              if (exportResult) {
                dispatch(fetchReportToLims(selectionToSend, batch._id, nonDetectedUA, mocFilter))
                  .then(function (resp) {
                    // "!exportLMR" -> Reload the UA to set Reported tick in table
                    me.responseReportToLimsSuccess(resp, taskId, !exportLMR)
                  })
                  .catch(function (error) {
                    me.responseReportToLimsError(error, taskId, selectionToSend)
                  })
              }
              if (exportLMR) {
                dispatch(fetchLmrReportToLims(selectionToSend, batch._id, mocFilter))
                  .then(function (resp) {
                    // Close the Export windows in this case (LMR exported with success)
                    me.setState({ openLims: false })
                    tableMultiGridRef.current.clearSelection()
                    me.responseReportToLimsSuccess(resp, taskId)
                  })
                  .catch(function (error) {
                    me.responseReportToLimsError(error, taskId, selectionToSend)
                  })
              }
            }
          } else {
            me.setState({ openLims: false })
          }
        }

        handleCloseMove(event, save) {
          const tableMultiGridRef = this.tableMultiGridRef
          const me = this
          me.setState({ openMove: false })
          if (typeof save === 'boolean' && save) {
            tableMultiGridRef.current.clearSelection()
          }
        }

        responseReportToLimsSuccess(resp, taskId, keepOpenAndRefresh) {
          const { dispatch } = this.props
          let severity = 'success'
          let message = (
            <Fragment>
              <div>
                <Trans i18nKey="view.batch.samplesTable.fully_exported">
                  The selected sample are <b>fully</b> exported to LIMS:
                </Trans>
              </div>
              <div style={{ maxWidth: 600, maxHeight: 400, overflow: 'scroll' }}>
                {resp.uas_ok.map((item) => (
                  <Tooltip arrow key={item._id + '-tooltip'} title={item.content.analysis.name}>
                    <Chip
                      variant="outlined"
                      size="small"
                      style={{ margin: 3 }}
                      key={item._id}
                      label={item.name}
                    />
                  </Tooltip>
                ))}
              </div>
            </Fragment>
          )
          if (resp.uas_ok && resp.uas_ok.length === 0) {
            severity = 'warning'
            message = (
              <Fragment>
                <div style={{ maxWidth: 600, maxHeight: 400, overflow: 'scroll' }}>
                  {t('view.batch.samplesTable.no_exported')}
                  {resp.uas_ko.map((item) => (
                    <Tooltip arrow key={item._id + '-tooltip'} title={item.content.analysis.name}>
                      <Chip
                        variant="outlined"
                        size="small"
                        style={{ margin: 3 }}
                        key={item._id}
                        label={
                          item.content.reported_msg
                            ? item.name + ': ' + item.content.reported_msg
                            : item.name + ': Not expected error'
                        }
                      />
                    </Tooltip>
                  ))}
                </div>
                {/*<div>{resp.message}</div>*/}
                {/*<div>Please contact your administrator.</div>*/}
              </Fragment>
            )
          } else {
            if (resp.uas_ko && resp.uas_ko.length > 0) {
              severity = 'warning'
              message = (
                <Fragment>
                  <div>
                    <Trans i18nKey="view.batch.samplesTable.partially_exported">
                      The selected sample are <b>partially</b> exported to LIMS.
                    </Trans>
                  </div>
                  <div style={{ maxWidth: 600, maxHeight: 400, overflow: 'scroll' }}>
                    {t('view.batch.samplesTable.successful_export')}
                    {resp.uas_ok.map((item) => (
                      <Tooltip arrow key={item._id + '-tooltip'} title={item.content.analysis.name}>
                        <Chip
                          variant="outlined"
                          size="small"
                          style={{ margin: 3 }}
                          key={item._id}
                          label={item.name}
                        />
                      </Tooltip>
                    ))}
                  </div>
                  <div style={{ maxWidth: 600, maxHeight: 400, overflow: 'scroll' }}>
                    {t('view.batch.samplesTable.cannot_export')}
                    {resp.uas_ko.map((item) => (
                      <Tooltip arrow key={item._id + '-tooltip'} title={item.content.analysis.name}>
                        <Chip
                          variant="outlined"
                          size="small"
                          style={{ margin: 3 }}
                          key={item._id}
                          label={
                            item.content.reported_msg
                              ? item.name + ': ' + item.content.reported_msg
                              : item.name + ': ' + t('view.batch.samplesTable.not_exported')
                          }
                        />
                      </Tooltip>
                    ))}
                  </div>
                </Fragment>
              )
            }
          }
          displayPopupMessage(
            dispatch,
            severity,
            t('view.batch.samplesTable.export_lims_title'),
            message,
          )
          dispatch(fetchFinishTask(taskId, 'success', t('view.batch.samplesTable.fine')))

          // Reload the UA to set Reported tick in table
          if (keepOpenAndRefresh) {
            const selectionFiltered = this.state.selection.filter(
              (analyse) => analyse.content.progression_requested !== 0,
            )
            dispatch(fetchUnitaryAnalysesLims(selectionFiltered))
          }
        }
        responseReportToLimsError(error, taskId, selectionToSend) {
          const { dispatch } = this.props
          if (error && error.status !== undefined && error.status === 401) {
            error.statusText = t('view.common.utils.lease_locked.code')
            displayPopupMessage(
              dispatch,
              'warning',
              t('view.batch.samplesTable.export_lims_title'),
              <Fragment>
                <div>{t('view.batch.samplesTable.cannot_export')}</div>
                {generateErrorPart(error)}
              </Fragment>,
            )
          } else {
            displayPopupMessage(
              dispatch,
              'error',
              t('view.batch.samplesTable.export_lims_title'),
              <Fragment>
                <div>Can not export to LIMS:</div>
                <div style={{ maxWidth: 600, maxHeight: 400, overflow: 'scroll' }}>
                  {selectionToSend.map((item) => (
                    <Chip
                      variant="outlined"
                      size="small"
                      style={{ margin: 3 }}
                      key={item._id}
                      label={item.name}
                    />
                  ))}
                </div>
                <div>{t('view.batch.samplesTable.admin')}</div>
                {generateErrorPart(error)}
              </Fragment>,
            )
          }
          dispatch(fetchFinishTask(taskId, 'error', error))
        }

        handleDisplayLims() {
          const { dispatch } = this.props
          const { selection } = this.state
          const me = this
          const selectionFiltered = selection.filter(
            (analyse) => analyse.content.progression_requested !== 0,
          )
          if (selectionFiltered.length === 0) {
            displayPopupMessage(
              dispatch,
              'warning',
              t('view.batch.samplesTable.export_lims_title'),
              <Fragment>
                <div>{t('view.batch.samplesTable.no_analysis')}</div>
                <div>{t('view.batch.samplesTable.validate')}</div>
              </Fragment>,
            )
          } else {
            this.setState({ openLims: true })
            dispatch(fetchUnitaryAnalysesLims(selectionFiltered))
              .then(function (resp) {
                me.setState({ nonDetectedUA: resp.lims.nonDetectedUA })
                displayPopupMessage(
                  dispatch,
                  'success',
                  t('view.batch.samplesTable.opening_export'),
                  t('view.batch.samplesTable.opening_ok'),
                )
              })
              .catch(function (error) {
                if (error && error.status !== undefined && error.status === 401) {
                  error.statusText = t('view.common.utils.lease_locked.code')
                  me.setState({ openLims: false })
                  displayPopupMessage(
                    dispatch,
                    'warning',
                    t('view.batch.samplesTable.opening_export'),
                    <Fragment>
                      <div>{t('view.batch.samplesTable.opening_ko')}</div>
                      <div>{generateErrorPart(error)}</div>
                    </Fragment>,
                  )
                } else {
                  displayPopupMessage(
                    dispatch,
                    'error',
                    t('view.batch.samplesTable.opening_export'),
                    <Fragment>
                      <div>{t('view.batch.samplesTable.opening_ko')}</div>
                      <div>
                        {typeof error === 'string'
                          ? error
                          : error.message
                          ? error.message
                          : error.toString()}
                      </div>
                    </Fragment>,
                  )
                }
              })
          }
        }

        getEchClient() {
          const { analysis, analysesSentToLims, analysesNotSentToLims } = this.props
          const { direction, order } = this.state
          const analysisFiltered = analysis
            .filter((analyse) => analyse.content.type === 'sample' && !analyse.content.inProgress)
            .map((ech) => ({
              ...ech,
              infos: ech.content.sample_infos,
              nb_detected: ech.content.nb_detected,
              nb_suspected: ech.content.nb_suspected,
              nb_excluded: ech.content.nb_excluded,
              sentToLims:
                analysesSentToLims && analysesSentToLims.indexOf(ech._id) !== -1
                  ? true
                  : analysesNotSentToLims && analysesNotSentToLims.indexOf(ech._id) !== -1
                  ? false
                  : null,
            }))
          return stableSort(analysisFiltered, getComparator(direction, order))
        }

        render() {
          const { classes, dispatch, isFetching, tableItemSelectedForColor, batch } = this.props
          const {
            openBatchCreate,
            openBatchMove,
            selection,
            createBatch,
            moveBatch,
            openLims,
            openMove,
            familyBatches,
            option,
            openExtentedView,
            openFormulasDialog,
          } = this.state

          const echClient = this.getEchClient()
          const tableMultiGridRef = this.tableMultiGridRef
          const me = this

          const handleOpenRenameDialog = (event, row) => {
            event.stopPropagation()
            event.preventDefault()
            dispatch(fetchChangeSettings('analysisToRename', row))
          }

          const onDisplayClient = (id) => {
            localStorage.setItem('BATCH_previous_selection', id)
            this.props.history.push('/?' + this.props.match.params.batchId + '/analysis/' + id)
          }

          const selectionEmpty = selection.length === 0

          const handleCloseBatchCreate = (event, confirm) => {
            this.setState({ openBatchCreate: false })
            // const batchName = this.createSubBatchRef.current.children[1].children[0].value
            if (typeof confirm === 'boolean' && confirm) {
              this.tableMultiGridRef.current.clearSelection()
              createSubBatch(
                dispatch,
                this.props.match.params.batchId,
                createBatch,
                selection,
                option,
              )
            }
          }

          const handleCloseBatchMove = (event, confirm) => {
            this.setState({ openBatchMove: false })
            if (typeof confirm === 'boolean' && confirm) {
              this.tableMultiGridRef.current.clearSelection()
              moveSampleToSubBatch(dispatch, moveBatch.value, moveBatch.name, selection, option)
            }
          }

          const openFormulasDialogClicked = () => {
            this.setState({ openFormulasDialog: true })
          }
          const closeFormulasDialogClicked = () => {
            this.setState({ openFormulasDialog: false })
          }

          const getRenderCmp = (fullscreenMode) => {
            return (
              <Fragment>
                {/*<Dialog*/}
                {/*  open={openLims}*/}
                {/*  onClose={this.handleCloseLims}*/}
                {/*  TransitionComponent={TransitionTop}*/}
                {/*  aria-labelledby="draggable-dialog-title"*/}
                {/*  className={classes.dialogLims}*/}
                {/*>*/}
                {/*  <StyledDialogTitle>Export to LIMS</StyledDialogTitle>*/}
                {/*  <DialogContent>*/}
                <ErrorBoundaryGuard isDialog>
                  <MoveDialog
                    selection={selection}
                    open={openMove}
                    closeFct={this.handleCloseMove}
                    origin={'sample'}
                  />
                </ErrorBoundaryGuard>
                <ErrorBoundaryGuard isDialog>
                  <LimsTable open={openLims} closeFct={this.handleCloseLims} />
                </ErrorBoundaryGuard>
                <Dialog
                  open={openBatchCreate}
                  onClose={handleCloseBatchCreate}
                  TransitionComponent={TransitionTop}
                  aria-labelledby="draggable-dialog-title"
                >
                  <StyledDialogTitle>{t('view.batch.samplesTable.move_copy')}</StyledDialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      {t('view.batch.samplesTable.selected_samples')}
                    </DialogContentText>
                    <div
                      style={{
                        maxWidth: 600,
                        maxHeight: 400,
                        overflow: 'scroll',
                        marginTop: 6,
                        marginBottom: 20,
                      }}
                    >
                      {selection.map((item, index) => (
                        <Chip
                          key={item.name + '_' + index}
                          className={classes.moveChip}
                          label={item.name}
                        />
                      ))}
                    </div>
                    <Grid
                      component="label"
                      container
                      alignItems="center"
                      spacing={1}
                      style={{ marginBottom: 4 }}
                    >
                      <Grid item className={classes.optionLabel}>
                        {t('view.batch.samplesTable.option')}
                      </Grid>
                      <Grid item className={option === 'move' ? classes.optionSelected : null}>
                        {t('view.batch.samplesTable.move')}
                      </Grid>
                      <Grid item>
                        <Switch
                          checked={option === 'copy'}
                          color="default"
                          inputProps={{ 'aria-label': 'checkbox with default color' }}
                          onChange={(event) => this.onChangeOption(event)}
                        />
                      </Grid>
                      <Grid item className={option === 'copy' ? classes.optionSelected : null}>
                        {t('view.batch.samplesTable.copy')}
                      </Grid>
                    </Grid>
                    <TextField
                      required
                      fullWidth
                      id="create-sub-batch"
                      label={t('view.batch.samplesTable.sub_batch_name')}
                      variant="outlined"
                      onChange={(event) => this.onChangeCreateBatch(event)}
                      error={
                        createBatch === '' || createBatch === null || createBatch === undefined
                      }
                      style={{ marginLeft: 75, marginBottom: 10, width: 'calc(100% - 75px)' }}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      autoFocus
                      onClick={(event) => handleCloseBatchCreate(event, true)}
                      disabled={
                        createBatch === '' || createBatch === null || createBatch === undefined
                      }
                      color="primary"
                    >
                      {t('view.batch.samplesTable.create')}
                    </Button>
                    <Button
                      onClick={(event) => handleCloseBatchCreate(event, false)}
                      color="primary"
                    >
                      {t('view.batch.samplesTable.cancel')}
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={openBatchMove}
                  onClose={handleCloseBatchMove}
                  TransitionComponent={TransitionTop}
                  aria-labelledby="draggable-dialog-title"
                  style={{ minWidth: 400 }}
                >
                  <StyledDialogTitle>{t('view.batch.samplesTable.move_copy_to')}</StyledDialogTitle>
                  {familyBatches ? (
                    <DialogContent>
                      <DialogContentText>
                        {t('view.batch.samplesTable.sure_to_move')}
                      </DialogContentText>
                      <DialogContentText>
                        {t('view.batch.samplesTable.will_be_moved')}
                        <div style={{ marginTop: 5 }}>
                          {selection.map((item) => (
                            <Chip key={item.name} className={classes.moveChip} label={item.name} />
                          ))}
                        </div>
                      </DialogContentText>
                      <Grid component="label" container alignItems="center" spacing={1}>
                        <Grid item className={classes.optionLabel}>
                          {t('view.batch.samplesTable.option')}
                        </Grid>
                        <Grid item className={option === 'move' ? classes.optionSelected : null}>
                          {t('view.batch.samplesTable.move')}
                        </Grid>
                        <Grid item>
                          <Switch
                            checked={option === 'copy'}
                            color="default"
                            inputProps={{ 'aria-label': 'checkbox with default color' }}
                            onChange={(event) => this.onChangeOption(event)}
                          />
                        </Grid>
                        <Grid item className={option === 'copy' ? classes.optionSelected : null}>
                          {t('view.batch.samplesTable.copy')}
                        </Grid>
                      </Grid>
                      <FormControl
                        required
                        fullWidth
                        variant="outlined"
                        className={classes.formControl}
                        error={moveBatch === '' || moveBatch === null || moveBatch === undefined}
                        style={{ marginLeft: 75, width: 'calc(100% - 75px)' }}
                      >
                        <InputLabel>{t('view.batch.samplesTable.batch')}</InputLabel>
                        <Select
                          variant="outlined"
                          onChange={this.onChangeMoveBatch}
                          label={t('view.batch.samplesTable.batch')}
                        >
                          {Object.entries(familyBatches).map((batch) => (
                            <MenuItem key={batch[0]} value={batch[0]}>
                              {batch[1].name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </DialogContent>
                  ) : (
                    <DialogContent>
                      <DialogContentText>
                        {t('view.batch.samplesTable.you_havent')}
                      </DialogContentText>
                      <div style={{ marginBottom: 20 }}>
                        {selection.map((item) => (
                          <Chip key={item.name} className={classes.moveChip} label={item.name} />
                        ))}
                      </div>
                      <DialogContentText>
                        {t('view.batch.samplesTable.create_before')}
                      </DialogContentText>
                    </DialogContent>
                  )}

                  <DialogActions>
                    <Button
                      autoFocus
                      onClick={(event) => handleCloseBatchMove(event, true)}
                      color="primary"
                      disabled={moveBatch === '' || moveBatch === null || moveBatch === undefined}
                    >
                      {t('view.batch.samplesTable.move')}
                    </Button>
                    <Button onClick={(event) => handleCloseBatchMove(event, false)} color="primary">
                      {t('view.batch.samplesTable.cancel')}
                    </Button>
                  </DialogActions>
                </Dialog>
                <ErrorBoundaryGuard isDialog>
                  <FormulasCmp
                    analyses={echClient}
                    batch={batch}
                    open={openFormulasDialog}
                    closeFct={closeFormulasDialogClicked}
                    // onSaveAnalyseValue={this.onSaveAnalyseValue}
                  />
                </ErrorBoundaryGuard>
                <Paper className={classes.paper} elevation={0}>
                  <Title>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      {/*<Grid item>*/}
                      <Box>{t('view.batch.samplesTable.customer_samples')}</Box>
                      <span>
                        <Tooltip
                          arrow
                          placement="top"
                          title={
                            <div className={classes.tooltip}>
                              {t('view.batch.samplesTable.apply_mass')}
                            </div>
                          }
                        >
                          <IconButton
                            style={{
                              padding: 3,
                            }}
                            className={classes.colorBlue}
                            aria-label="openFormulas"
                            onClick={openFormulasDialogClicked}
                          >
                            <FunctionsIcon />
                          </IconButton>
                        </Tooltip>
                        {!fullscreenMode && (
                          <Tooltip
                            arrow
                            placement="top"
                            title={
                              <div className={classes.tooltip}>
                                {t('view.batch.samplesTable.open_fullscreen')}
                              </div>
                            }
                          >
                            <IconButton
                              size="small"
                              aria-label="extend"
                              onClick={() => {
                                this.setState({ openExtentedView: true })
                              }}
                            >
                              <OpenInNewIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                      </span>
                      {/*</Grid>*/}
                      {/*Comment multiselection*/}
                      {/*<Grid item style={{ height: 56 }}>*/}
                      {/*<div>*/}
                      {/*<Divider orientation="vertical" />*/}
                      {/*<Tooltip arrow title={<div className={classes.tooltip}>Open WebLims</div>}>*/}
                      {/*<IconButton*/}
                      {/*className={classes.toolbarButton}*/}
                      {/*aria-label="Open WebLims"*/}
                      {/*onClick={() => {*/}
                      {/*openInNewTab(*/}
                      {/*'http://lims.phytocontrol.local/myweblims/index.php?r=site/page&view=techniciens',*/}
                      {/*)*/}
                      {/*}}*/}
                      {/*// onClick={() => this.openWebLims()}*/}
                      {/*>*/}
                      {/*<OpenInBrowserIcon />*/}
                      {/*</IconButton>*/}
                      {/*</Tooltip>*/}
                      {/*</div>*/}
                      {/*</Grid>*/}
                    </Grid>
                  </Title>
                  <div className={classes.tableContainer}>
                    <ErrorBoundaryGuard>
                      <TableMultiGrid
                        ref={this.tableMultiGridRef}
                        collection={'analysis'}
                        onMoleculeClick={(item) => onDisplayClient(item._id)}
                        onMoleculeDoubleClick={() => true}
                        idTable={'mySamplesTable'}
                        tableItemSelectedForColor={tableItemSelectedForColor}
                        orderBy={'name'}
                        selection
                        onSelect={this.onSelect}
                        data={echClient}
                        isFetchingData={isFetching}
                        sortAction={function (order, direction) {
                          tableMultiGridRef.current.clearSelection()
                          me.setState({
                            order: order,
                            direction: direction,
                          })
                        }}
                        columns={[
                          {
                            key: 'audit',
                            label: t('view.batch.samplesTable.column.audit'),
                            size: 50,
                          },
                          {
                            key: 'webLims',
                            label: t('view.batch.samplesTable.column.webLims'),
                            size: 70,
                          },
                          {
                            key: 'sentToLims',
                            label: t('view.batch.samplesTable.column.reported'),
                            size: 70,
                            customDisplay: function (value) {
                              return value ? (
                                <CheckIcon style={{ color: '#0000008a' }} />
                              ) : value === false ? (
                                <CloseIcon style={{ color: '#0000008a' }} />
                              ) : null
                            },
                            customTitle: function () {
                              return (
                                <Tooltip
                                  title={
                                    <div className={classes.tooltip}>
                                      {t('view.batch.samplesTable.column.reported_lims')}
                                    </div>
                                  }
                                >
                                  <span>{t('view.batch.samplesTable.column.reported')}</span>
                                </Tooltip>
                              )
                            },
                          },
                          {
                            key: 'flags',
                            label: t('view.batch.samplesTable.column.flags'),
                            size: 70,
                          },
                          {
                            key: 'notes',
                            label: t('view.batch.samplesTable.column.notes'),
                            size: 60,
                          },
                          // {
                          //   key: 'modified',
                          //   label: 'Modified',
                          //   size: 75,
                          // },
                          {
                            key: 'name',
                            label: t('view.batch.samplesTable.column.name'),
                            size: 130,
                          },
                          {
                            key: 'infos',
                            label: t('view.batch.samplesTable.column.matrix'),
                            size: 130,
                            customDisplay: function (value) {
                              // return (value && (value.matrice_id_labo || value.matrice)) || null
                              let matrix_name =
                                value && value.matrice_id_labo
                                  ? value.matrice_id_labo
                                  : value && value.matrice
                                  ? value.matrice
                                  : ''
                              return (
                                <Tooltip
                                  title={
                                    matrix_name !== '' ? (
                                      <div className={classes.tooltip}>{matrix_name}</div>
                                    ) : (
                                      matrix_name
                                    )
                                  }
                                >
                                  <div>{matrix_name}</div>
                                </Tooltip>
                              )
                            },
                          },
                          {
                            key: 'nb_detected',
                            label: t('view.batch.samplesTable.column.detected'),
                            size: 50,
                            textAlign: 'start',
                            customTitle: function () {
                              return (
                                <Tooltip
                                  title={
                                    <div className={classes.tooltip}>
                                      {t('view.batch.samplesTable.column.number_detected')}
                                    </div>
                                  }
                                >
                                  <ErrorIcon style={{ opacity: 0.8 }} />
                                </Tooltip>
                              )
                            },
                          },
                          {
                            key: 'nb_suspected',
                            label: t('view.batch.samplesTable.column.suspected'),
                            size: 50,
                            textAlign: 'start',
                            customTitle: function () {
                              return (
                                <Tooltip
                                  title={
                                    <div className={classes.tooltip}>
                                      {t('view.batch.samplesTable.column.number_suspected')}
                                    </div>
                                  }
                                >
                                  <HelpIcon style={{ opacity: 0.8 }} />
                                </Tooltip>
                              )
                            },
                          },
                          {
                            key: 'nb_excluded',
                            label: t('view.batch.samplesTable.column.excluded'),
                            size: 50,
                            textAlign: 'start',
                            customTitle: function () {
                              return (
                                <Tooltip
                                  title={
                                    <div className={classes.tooltip}>
                                      {t('view.batch.samplesTable.column.number_excluded')}
                                    </div>
                                  }
                                >
                                  <CancelIcon style={{ opacity: 0.8 }} />
                                </Tooltip>
                              )
                            },
                          },
                          {
                            key: 'progression',
                            label: t('view.batch.samplesTable.column.progression'),
                            size: 180,
                          },
                        ]}
                        fixedRowCount={1}
                        rowHeight={40}
                        getColumnRender={function (
                          columnIndex,
                          key,
                          rowIndex,
                          style,
                          item,
                          rowClassNames,
                          columnKey,
                          moleculeOverState,
                          moleculeOver,
                          selected,
                          onMoleculeClick,
                          onMoleculeDoubleClick,
                          columns,
                        ) {
                          if (columnKey === 'name') {
                            return (
                              <Tooltip
                                arrow
                                key={key}
                                title={<div className={classes.tooltip}>{item.content.file}</div>}
                              >
                                <div
                                  style={style}
                                  className={clsx(
                                    rowClassNames,
                                    moleculeOverState === rowIndex && classes.selectedRow,
                                    selected === item._id ? classes.blink : classes.noBlink,
                                    moleculeOverState === rowIndex &&
                                      selected === item._id &&
                                      classes.selectedRowBlink,
                                  )}
                                  onMouseEnter={() =>
                                    moleculeOver(rowIndex, columnIndex, true, item._id)
                                  }
                                  onMouseLeave={() => moleculeOver(rowIndex, columnIndex, false)}
                                  onClick={function (event) {
                                    const openFct = () => {
                                      onDisplayClient(item._id)
                                    }
                                    getClickAction(dispatch, event, item, 'analysis', openFct)
                                  }}
                                >
                                  <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    spacing={0}
                                  >
                                    <div>{item.name}</div>
                                    <div
                                      style={{ display: 'none', height: 20 }}
                                      className={'nameAdorment'}
                                    >
                                      <IconButton
                                        style={{ padding: 0 }}
                                        onClick={(event) => handleOpenRenameDialog(event, item)}
                                        onMouseDown={(event) => event.preventDefault()}
                                      >
                                        <Tooltip
                                          key={key}
                                          arrow
                                          placement={'top'}
                                          title={
                                            <div className={classes.tooltip}>
                                              {t('view.batch.qualityControlTable.rename')}
                                            </div>
                                          }
                                        >
                                          <DriveFileRenameOutlineIcon />
                                        </Tooltip>
                                      </IconButton>
                                    </div>
                                  </Stack>
                                </div>
                              </Tooltip>
                            )
                          }
                          if (columnKey === 'notes') {
                            return (
                              <div
                                style={style}
                                key={key}
                                className={clsx(
                                  rowClassNames,
                                  moleculeOverState === rowIndex && classes.selectedRow,
                                  selected === item._id ? classes.blink : classes.noBlink,
                                  moleculeOverState === rowIndex &&
                                    selected === item._id &&
                                    classes.selectedRowBlink,
                                )}
                                onMouseEnter={() =>
                                  moleculeOver(rowIndex, columnIndex, true, item._id)
                                }
                                onMouseLeave={() => moleculeOver(rowIndex, columnIndex, false)}
                                // onClick={() => onMoleculeClick(item)}
                                onClick={function (event) {
                                  const openFct = () => {
                                    onMoleculeClick(item)
                                  }
                                  getClickAction(dispatch, event, item, 'analysis', openFct)
                                }}
                                onDoubleClick={() => onMoleculeDoubleClick(item)}
                              >
                                <ErrorBoundaryGuard isDialog>
                                  <NoteButton
                                    row={{
                                      _id: item._id,
                                      notes: item.content.notes ? item.content.notes : [],
                                    }}
                                    collection={'analysis'}
                                  />
                                </ErrorBoundaryGuard>
                              </div>
                            )
                          }
                          if (columnKey === 'modified') {
                            return (
                              <div
                                style={style}
                                key={key}
                                className={clsx(
                                  rowClassNames,
                                  moleculeOverState === rowIndex && classes.selectedRow,
                                  selected === item._id ? classes.blink : classes.noBlink,
                                  moleculeOverState === rowIndex &&
                                    selected === item._id &&
                                    classes.selectedRowBlink,
                                )}
                                onMouseEnter={() =>
                                  moleculeOver(rowIndex, columnIndex, true, item._id)
                                }
                                onMouseLeave={() => moleculeOver(rowIndex, columnIndex, false)}
                                // onClick={() => onMoleculeClick(item)}
                                onClick={function (event) {
                                  const openFct = () => {
                                    onMoleculeClick(item)
                                  }
                                  getClickAction(dispatch, event, item, 'analysis', openFct)
                                }}
                                onDoubleClick={() => onMoleculeDoubleClick(item)}
                              >
                                <ErrorBoundaryGuard isDialog>
                                  <AlphacodModifiedButtonIcon item={item} />
                                </ErrorBoundaryGuard>
                              </div>
                            )
                          }
                          if (columnKey === 'flags') {
                            return (
                              <div
                                style={style}
                                key={item._id + columnKey + rowIndex + key}
                                className={clsx(
                                  'cellFlags',
                                  rowClassNames,
                                  selected === item._id ? classes.blink : classes.noBlink,
                                  moleculeOverState === rowIndex && classes.selectedRow,
                                  moleculeOverState === rowIndex &&
                                    selected === item._id &&
                                    classes.selectedRowBlink,
                                )}
                                onMouseEnter={() =>
                                  moleculeOver(rowIndex, columnIndex, true, item._id)
                                }
                                onMouseLeave={() => moleculeOver(rowIndex, columnIndex, false)}
                                // onClick={() => onDisplayClient(item._id)}
                                onClick={function (event) {
                                  const openFct = () => {}
                                  getClickAction(dispatch, event, item, 'analysis', openFct)
                                }}
                              >
                                <ErrorBoundaryGuard isDialog>
                                  <AlphacodFlagIcon item={item.content} hideGeneralTab={true} />
                                </ErrorBoundaryGuard>
                              </div>
                            )
                          }
                          if (columnKey === 'audit') {
                            return (
                              <div
                                style={style}
                                key={key}
                                className={clsx(
                                  rowClassNames,
                                  selected === item._id ? classes.blink : classes.noBlink,
                                  moleculeOverState === rowIndex && classes.selectedRow,
                                  moleculeOverState === rowIndex &&
                                    selected === item._id &&
                                    classes.selectedRowBlink,
                                )}
                                onMouseEnter={() =>
                                  moleculeOver(rowIndex, columnIndex, true, item._id)
                                }
                                onMouseLeave={() => moleculeOver(rowIndex, columnIndex, false)}
                                // onClick={() => onDisplayClient(item._id)}
                                onClick={function (event) {
                                  const openFct = () => {
                                    onDisplayClient(item._id)
                                  }
                                  getClickAction(dispatch, event, item, 'analysis', openFct)
                                }}
                              >
                                <Tooltip
                                  arrow
                                  title={
                                    <div className={classes.tooltip}>
                                      {t('view.batch.samplesTable.get_audit')}
                                    </div>
                                  }
                                >
                                  <Link
                                    href={`${FLASK_URL()}/analysis/${item._id}/audit_trail`}
                                    color="inherit"
                                    onClick={(event) => {
                                      event.stopPropagation()
                                      event.preventDefault()
                                      displayPopupMessage(
                                        dispatch,
                                        'info',
                                        t('view.batch.samplesTable.export_audit'),
                                        <Fragment>
                                          <div>
                                            {t('view.batch.samplesTable.analysis_selected') +
                                              ' ' +
                                              item.name}
                                          </div>
                                        </Fragment>,
                                      )
                                    }}
                                  >
                                    <IconButton
                                      className={classes.auditButton}
                                      aria-label="audit"
                                      // onClick={(event) =>
                                      //   handleActionReport(
                                      //     event,
                                      //     item,
                                      //     0,
                                      //     'analysis',
                                      //     dispatch,
                                      //   )
                                      // }
                                    >
                                      <GetAppIcon />
                                    </IconButton>
                                  </Link>
                                </Tooltip>
                              </div>
                            )
                          }
                          if (columnKey === 'webLims') {
                            return (
                              <div
                                style={style}
                                key={key}
                                className={clsx(
                                  rowClassNames,
                                  selected === item._id ? classes.blink : classes.noBlink,
                                  moleculeOverState === rowIndex && classes.selectedRow,
                                  moleculeOverState === rowIndex &&
                                    selected === item._id &&
                                    classes.selectedRowBlink,
                                )}
                                onMouseEnter={() =>
                                  moleculeOver(rowIndex, columnIndex, true, item._id)
                                }
                                onMouseLeave={() => moleculeOver(rowIndex, columnIndex, false)}
                                // onClick={() => onDisplayClient(item._id)}
                                onClick={function (event) {
                                  const openFct = () => {
                                    onDisplayClient(item._id)
                                  }
                                  getClickAction(dispatch, event, item, 'analysis', openFct)
                                }}
                              >
                                <Tooltip
                                  arrow
                                  title={
                                    <div className={classes.tooltip}>
                                      {t('view.batch.samplesTable.open_weblims')}
                                    </div>
                                  }
                                >
                                  <IconButton
                                    className={classes.auditButton}
                                    aria-label="webLims"
                                    onClick={(event) => {
                                      event.stopPropagation()
                                      event.preventDefault()
                                      openInNewTab(
                                        'http://lims.phytocontrol.local/myweblims/index.php?r=t_pes_calcul_multiresidus%2FchoixEch&service=GC&searchech=' +
                                          item.name,
                                      )
                                    }}
                                    // onClick={() => this.openWebLims()}
                                  >
                                    <OpenInBrowserIcon />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            )
                          }
                          if (columnKey === 'progression') {
                            return (
                              <Tooltip
                                key={item._id + columnKey + rowIndex + key}
                                arrow
                                title={
                                  <div className={classes.tooltip}>
                                    <div>
                                      {t('view.batch.samplesTable.column.progression') +
                                        `: ${
                                          item.content.progression_requested
                                            ? item.content.progression_requested.toFixed(2)
                                            : '0.00'
                                        } %`}
                                    </div>
                                    <div style={{ marginTop: 10 }}>
                                      {t('view.batch.samplesTable.column.detected') +
                                        `: ${
                                          item.content.nb_detected_validated
                                            ? item.content.nb_detected_validated
                                            : '0'
                                        }`}
                                    </div>
                                    <div>
                                      {t('view.batch.samplesTable.column.suspected') +
                                        `: ${
                                          item.content.nb_suspected_validated
                                            ? item.content.nb_suspected_validated
                                            : '0'
                                        }`}
                                    </div>
                                    <div>
                                      {t('view.batch.samplesTable.column.excluded') +
                                        `: ${
                                          item.content.nb_excluded_validated
                                            ? item.content.nb_excluded_validated
                                            : '0'
                                        }`}
                                    </div>
                                  </div>
                                }
                              >
                                <div
                                  style={style}
                                  // style={
                                  //   columnIndex === columns.length - 1
                                  //     ? {
                                  //         ...style,
                                  //         width: `calc(100% - ${
                                  //           columns.reduce((acc, col) => acc + col.size, 0) -
                                  //           columns[columnIndex].size
                                  //         }px)`,
                                  //       }
                                  //     : { style }
                                  // }
                                  className={clsx(
                                    rowClassNames,
                                    selected === item._id ? classes.blink : classes.noBlink,
                                    moleculeOverState === rowIndex && classes.selectedRow,
                                    moleculeOverState === rowIndex &&
                                      selected === item._id &&
                                      classes.selectedRowBlink,
                                  )}
                                  onMouseEnter={() =>
                                    moleculeOver(rowIndex, columnIndex, true, item._id)
                                  }
                                  onMouseLeave={() => moleculeOver(rowIndex, columnIndex, false)}
                                  // onClick={() => onDisplayClient(item._id)}
                                  onClick={function (event) {
                                    const openFct = () => {
                                      onDisplayClient(item._id)
                                    }
                                    getClickAction(dispatch, event, item, 'analysis', openFct)
                                  }}
                                >
                                  <ErrorBoundaryGuard isDialog>
                                    <BorderLinearProgress
                                      // className={classes.margin}
                                      variant="determinate"
                                      // color="secondary"
                                      value={
                                        item.content.progression_requested
                                          ? item.content.progression_requested
                                          : 0
                                      }
                                    />
                                  </ErrorBoundaryGuard>
                                </div>
                              </Tooltip>
                            )
                          }
                          return null
                        }}
                      />
                    </ErrorBoundaryGuard>
                  </div>
                  {!selectionEmpty && (
                    <span
                      className={classes.toolbar}
                      style={{ visibility: selectionEmpty ? 'hidden' : 'visible' }}
                    >
                      <div>
                        <Tooltip
                          arrow
                          title={
                            <div className={classes.tooltip}>
                              {t('view.batch.samplesTable.moveQCOthers')}
                            </div>
                          }
                        >
                          <IconButton
                            className={classes.toolbarButton}
                            aria-label="Move to QC Others"
                            onClick={() => this.multiValidate(2)}
                          >
                            <SwapHorizIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          arrow
                          title={
                            <div className={classes.tooltip}>
                              {t('view.batch.samplesTable.move_copy')}
                            </div>
                          }
                        >
                          <IconButton
                            className={classes.toolbarButton}
                            aria-label="Create sub-batch"
                            onClick={() => this.multiValidate(0)}
                          >
                            <CreateNewFolderIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          arrow
                          title={
                            <div className={classes.tooltip}>
                              {t('view.batch.samplesTable.move_copy_exist')}
                            </div>
                          }
                        >
                          <IconButton
                            className={classes.toolbarButton}
                            aria-label="Move to an existing batch"
                            onClick={() => this.multiValidate(1)}
                          >
                            <FolderIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          arrow
                          title={
                            <div className={classes.tooltip}>
                              {t('view.batch.samplesTable.open_export_lims')}
                            </div>
                          }
                        >
                          <IconButton
                            className={classes.toolbarButton}
                            aria-label="lims"
                            onClick={(event) => {
                              event.stopPropagation()
                              event.preventDefault()
                              this.handleDisplayLims(event.currentTarget)
                            }}
                            // onClick={function (event) {
                            //   dispatch(
                            //     fetchDisplayLims(item, 1, hideGeneralTab, event.currentTarget, collection),
                            //   )
                            // }}
                          >
                            <OpenInNewIcon />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </span>
                  )}
                </Paper>
              </Fragment>
            )
          }
          return (
            <Fragment>
              <CustomDialog
                fullWidth={true}
                maxWidth={'xl'}
                open={openExtentedView}
                onClose={() => this.setState({ openExtentedView: false })}
                TransitionComponent={TransitionTop}
                aria-labelledby="draggable-dialog-title"
              >
                {getRenderCmp(true)}
                <DialogActions>
                  <Button
                    onClick={() => this.setState({ openExtentedView: false })}
                    color="primary"
                  >
                    {t('view.batch.samplesTable.close')}
                  </Button>
                </DialogActions>
              </CustomDialog>
              {getRenderCmp()}
            </Fragment>
          )
        }
      },
    ),
  ),
)
