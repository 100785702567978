import './index.css'
import React, { Fragment, useMemo } from 'react'
import { BrowserRouter as Router, withRouter } from 'react-router-dom'
import Container from './view/Container'
// import configureStore from './redux/configureStore'
import store from './redux/configureStore'
import { Provider, useDispatch, useSelector } from 'react-redux'
import { hot } from 'react-hot-loader/root'
import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import 'animate.css/animate.min.css'
import { API_URL, APPLICATION_CONFIG_JSON, DATABASE, LANGUAGES } from './utils/config'
import { displayPopupMessage, generateErrorPart } from './view/common/Message'
import { fetchConfigurationFeatures } from './redux/configuration/actions'
import { fetchChangeSettings } from './redux/settings/actions'
import { StyledEngineProvider } from '@mui/material/styles'
import ErrorBoundaryGuard from './view/ErrorBoundaryGuard'
import * as I18n from 'i18next'
import { initReactI18next, Trans } from 'react-i18next'
import {
  getWikiHelper,
  testDatabaseAvailability,
  testServiceAvailability,
  getParentDomain,
  getWikiDocLanguage,
} from './view/common/Utils'
import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { LicenseInfo } from '@mui/x-license-pro'

LicenseInfo.setLicenseKey(
  '74973b754975b522e2026e308f33ac1aTz04MzAxMCxFPTE3Mzc4OTgxMjcwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
)

// const store = configureStore()
const baseName = window.location.pathname.split('/')[1]

// Load the config file properties (public/config.json) in the localStorage
fetch(APPLICATION_CONFIG_JSON)
  .then((res) => res.text())
  .then((data) => {
    const configJson = JSON.parse(data)
    Object.keys(configJson)
      .filter((key) => key !== '____WARNING____')
      .forEach((setting) => localStorage.setItem('__config.json__' + setting, configJson[setting]))
    // Store the database (prefix) in cookies
    document.cookie =
      'COD_db_prefix=' +
      DATABASE() +
      '; path=/;SameSite=None;Secure;Domain=' +
      getParentDomain(window.location.hostname)
  })
  .catch((error) =>
    displayPopupMessage(
      store.dispatch,
      'error',
      I18n.t('app.error_msg.title'),
      <Fragment>
        <div>
          <Trans i18nKey="app.error_msg.msg">
            There is something wrong in the <b>config.json</b> file
          </Trans>
          :
        </div>
        <div>{error.toString()}</div>
      </Fragment>,
      8000,
    ),
  )
  .finally(() => store.dispatch(fetchChangeSettings('configJsonLoaded', true)))

// Test if user is signed in to test service and DB
const cookies = Object.fromEntries(
  document.cookie.split('; ').map((v) => v.split(/=(.*)/s).map(decodeURIComponent)),
)
// If no ToriiSessionId, the user isn't connected
if (cookies.ToriiSessionId != null && cookies.ToriiSessionId !== '') {
  // Test if user is connected on server
  fetch(`${API_URL()}/users/whoami`, {
    method: 'GET',
    credentials: 'include',
  })
    .then((res) => {
      if (res.status === 403) {
        store.dispatch(fetchChangeSettings('loginPopup', true))
        store.dispatch(fetchChangeSettings('configurationDatabase', []))
      } else if (res.status === 200) {
        // If user is connected, test if service and DB are available
        testServiceAvailability(store.dispatch)
        testDatabaseAvailability(store.dispatch)
      } else {
        const statusText = res.statusText
        const status = res.status
        const url = res.url
        return res.text().then((errorMessage) => {
          const error = new Error(`${statusText} : ${errorMessage}`)
          if (res.headers.get('content-type') === 'application/json') {
            error.stack = JSON.stringify(
              JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
              null,
              2,
            )
          } else {
            error.stack = new Error().stack
          }
          error.statusText = statusText
          error.status = status
          error.url = url
          throw error
        })
      }
    })
    .catch((error) => {
      displayPopupMessage(
        store.dispatch,
        'error',
        <Trans i18nKey="app.user_msg.title">Verifying Authentication</Trans>,
        <Fragment>
          <div>
            <Trans i18nKey="app.user_msg.msg">
              It is currently impossible to verify whether you are correctly authenticated from the
              server.
            </Trans>
          </div>
          <div>{generateErrorPart(error)}</div>
        </Fragment>,
        10000,
      )
      // Force to display the windows that display that the server is
      // unavailable with some option to debug
      store.dispatch(fetchChangeSettings('configurationService', true))
      store.dispatch(fetchChangeSettings('configurationDatabase', ['ERROR_DB_UNAVAILABLE']))
      store.dispatch(fetchChangeSettings('dataBaseLoaded', true))
    })
} else {
  store.dispatch(fetchChangeSettings('loginPopup', true))
  store.dispatch(fetchChangeSettings('configurationDatabase', []))
}
i18n
  .use(Backend)
  .use(LanguageDetector)
  // .use(lngDetector)
  .use(initReactI18next)
  .init(
    {
      // resources,
      backend: {
        loadPath: `${window.location.pathname}locales/{{lng}}/{{ns}}.json`,
      },
      fallbackLng: 'en',
      nonExplicitSupportedLngs: true,
      detection: {
        checkWhitelist: true,
        // order: ['customDetector', 'querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag'],
      },

      debug: false,
      load: 'languageOnly',
      // supportedLngs: availableLanguages,
      supportedLngs: Object.keys(LANGUAGES),

      interpolation: {
        escapeValue: false,
      },
    },
    (err, t) => {
      if (err) {
        displayPopupMessage(
          store.dispatch,
          'error',
          'Loading interface language',
          <Fragment>
            <div>The interface language management mechanism does not currently work.</div>
            <div>{generateErrorPart(err)}</div>
          </Fragment>,
        )
      } else {
        // The language is loaded so we can load the wikiCOD
        getWikiHelper(store.dispatch)
        getWikiDocLanguage(store.dispatch)
      }
    },
  )

const App = () => (
  <Provider store={store}>
    <Router basename={`/${baseName}`}>
      <StyledEngineProvider injectFirst>
        <ReactNotifications className={'notification_cmp'} />
        <ErrorBoundaryGuard
          style={{ height: 'calc(100% - 20px)', width: 'calc(100% - 20px)', position: 'absolute' }}
        >
          <Container />
        </ErrorBoundaryGuard>
      </StyledEngineProvider>
    </Router>
  </Provider>
)

export default process.env.NODE_ENV === 'development' ? hot(App) : App
