import React, { Fragment } from 'react'
import { isAlreadyRetrieve, retrieveBatches, updateGlobalPeak } from '../common/Utils'
import { StyledDialogTitle, TransitionTop } from '../common/Style'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import { useDispatch } from 'react-redux'
import { t } from 'i18next'

export default function ReceiveAllBatchDialog({ open, closeFct }) {
  const dispatch = useDispatch()

  const handleConfirmClose = (event, confirm) => {
    closeFct()
    if (confirm) {
      // isAlreadyRetrieve().then(async (existingNotFinishedTasks) => {
      //   if (existingNotFinishedTasks.size > 0) {
      //     displayPopupMessage(
      // dispatch,
      //         'warning',
      //         `Retrieve batches`,
      //         <Fragment>
      //           <div>An existing task to retrieve batches is already present.</div>
      //           <div>This task is currently {existingNotFinishedTasks.result[0].status}.</div>
      //           <div>Please wait until it is finished before launching a new one.</div>
      //         </Fragment>,
      //       )
      //   } else {
      retrieveBatches(dispatch).then(async (response) => {
        console.log(response.status)
      })
      //   }
      // })
    }
  }

  return (
    <Dialog
      open={open}
      onClose={(event) => handleConfirmClose(event, false)}
      TransitionComponent={TransitionTop}
      aria-labelledby="dialog-reset-title"
    >
      <StyledDialogTitle>{t('view.dialogs.receiveAllBatch.title')}</StyledDialogTitle>
      <DialogContent>
        <DialogContentText component="span">
          <div>{t('view.dialogs.receiveAllBatch.msg1')}</div>
          <i>
            <div style={{ marginTop: 20 }}>{t('view.dialogs.receiveAllBatch.msg2')}</div>
            <div>{t('view.dialogs.receiveAllBatch.msg3')}</div>
          </i>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={(event) => handleConfirmClose(event, false)} color="primary">
          {t('view.dialogs.receiveAllBatch.cancel')}
        </Button>
        <Button onClick={(event) => handleConfirmClose(event, true)} color="primary">
          {t('view.dialogs.receiveAllBatch.yes')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
