import React, { useEffect } from 'react'
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Input,
  makeStyles,
  Radio,
} from '@material-ui/core'
import { alpha } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import { ChromatoHeight, ChromatoSettings } from '../Style'
import Slider from '@material-ui/core/Slider'
import Typography from '@material-ui/core/Typography'
import InputAdornment from '@material-ui/core/InputAdornment'
import { ZOOM_DEFAULT_FACTO } from '../../../utils/config'
import Paper from '@material-ui/core/Paper'
import TableChartIcon from '@material-ui/icons/TableChart'
import TimelineIcon from '@material-ui/icons/Timeline'
import NotesIcon from '@mui/icons-material/Notes'
import CropFreeIcon from '@mui/icons-material/CropFree'
import LooksOneIcon from '@mui/icons-material/LooksOne'
import { t } from 'i18next'

const useStyles = makeStyles((theme) => ({
  tooltip: {
    fontSize: 13,
    padding: 5,
  },
  sortableTableHeader: {
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    borderRadius: 3,
    margin: '0px 10px',
    padding: '5px 10px',
    background: alpha(theme.palette.primary.main, 0.02),
    minWidth: 80,
    cursor: 'grab',
  },
  sortableTableHeadertitle: {
    fontWeight: 600,
    whiteSpace: 'nowrap',
    borderBottom: '1px solid',
    borderBottomColor: alpha(theme.palette.primary.main, 0.35),
  },
  sorting: {
    zIndex: theme.zIndex.modal + 100,
  },
  columnSizetextfield: {
    width: 70,
    marginLeft: 'calc(50% - 35px)',
    marginTop: 8,
    '& input': {
      padding: 8,
      background: 'white',
    },
  },
  formControl: {
    margin: '0px 24px',
  },
  colorIcon: {
    '& svg': {
      color: 'rgba(0, 0, 0, 0.54)',
    },
    '& div': {
      textAlign: 'center',
      lineHeight: '100px',
    },
  },
}))
export default function LayoutTab({ selectedLayout, setSelectedLayout, origin }) {
  const classes = useStyles()

  const handleChange = (value) => {
    setSelectedLayout(value)
  }

  return (
    <span>
      <div style={{ padding: 0, margin: '0px 14px' }}>
        <div style={{ margin: '20px 0px 20px 10px', fontSize: '1.1rem' }}>
          {t('view.common.settingsView.layoutTable.specific')}
        </div>
      </div>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        style={{ margin: '0px 14px' }}
        className={classes.colorIcon}
      >
        <div style={{ width: 'calc(' + (origin === 'compound' ? '33% - 10px)' : '50% - 20px)') }}>
          <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
            <Radio
              checked={selectedLayout === 'default'}
              onChange={() => handleChange('default')}
              value="default"
              name="radio-button-demo"
              inputProps={{ 'aria-label': 'Default' }}
            />
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="stretch"
              style={{ height: 200, width: 'calc(100% - 42px)', cursor: 'pointer' }}
              onClick={() => handleChange('default')}
            >
              <Paper
                elevation={3}
                style={{
                  background: '#f9f9f9',
                  width: 'calc(100% - 10px)',
                  margin: 5,
                  height: 'calc(50% - 10px)',
                }}
              >
                <TableChartIcon />
              </Paper>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="stretch"
                style={{ height: '50%' }}
              >
                <Paper
                  elevation={3}
                  style={{
                    background: '#f9f9f9',
                    margin: 5,
                    width: 'calc(70% - 10px)',
                    height: 'calc(100% - 10px)',
                  }}
                >
                  <ChromatoSettings />
                </Paper>
                <Paper
                  elevation={3}
                  style={{
                    background: '#f9f9f9',
                    margin: 5,
                    width: 'calc(30% - 10px)',
                    height: 'calc(100% - 10px)',
                  }}
                >
                  <TimelineIcon />
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div style={{ width: 'calc(' + (origin === 'compound' ? '33% - 10px)' : '50% - 20px)') }}>
          <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
            <Radio
              checked={selectedLayout === 'custom'}
              onChange={() => handleChange('custom')}
              value="custom"
              name="radio-button-demo"
              inputProps={{ 'aria-label': 'Custom' }}
            />
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="stretch"
              style={{ height: 200, width: 'calc(100% - 42px)', cursor: 'pointer' }}
              onClick={() => handleChange('custom')}
            >
              <Paper
                elevation={3}
                style={{
                  background: '#f9f9f9',
                  width: 'calc(60% - 10px)',
                  margin: 5,
                  height: 'calc(100% - 10px)',
                }}
              >
                <ChromatoSettings />
              </Paper>
              <Grid
                container
                direction="column"
                justifyContent="space-between"
                alignItems="stretch"
                style={{ height: '100%', width: '40%' }}
              >
                <Paper
                  elevation={3}
                  style={{
                    background: '#f9f9f9',
                    margin: 5,
                    width: 'calc(100% - 10px)',
                    height: 'calc(50% - 10px)',
                  }}
                >
                  <TableChartIcon />
                </Paper>
                <Paper
                  elevation={3}
                  style={{
                    background: '#f9f9f9',
                    margin: 5,
                    width: 'calc(100% - 10px)',
                    height: 'calc(50% - 10px)',
                  }}
                >
                  <TimelineIcon />
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </div>
        {origin === 'compound' && (
          <div style={{ width: 'calc(33% - 10px)' }}>
            <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
              <Radio
                checked={selectedLayout === 'one'}
                onChange={() => handleChange('one')}
                value="one"
                name="radio-button-demo"
                inputProps={{ 'aria-label': 'one' }}
              />
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="stretch"
                style={{ height: 200, width: 'calc(100% - 42px)', cursor: 'pointer' }}
                onClick={() => handleChange('one')}
              >
                <Paper
                  elevation={3}
                  style={{
                    background: '#f9f9f9',
                    width: 'calc(33% - 10px)',
                    margin: 5,
                    height: 'calc(100% - 10px)',
                  }}
                >
                  <ChromatoSettings />
                  <LooksOneIcon />
                </Paper>
                <Grid
                  container
                  direction="column"
                  justifyContent="space-between"
                  alignItems="stretch"
                  style={{ height: '100%', width: '66%' }}
                >
                  <Paper
                    elevation={3}
                    style={{
                      background: '#f9f9f9',
                      margin: 5,
                      width: 'calc(100% - 10px)',
                      height: 'calc(50% - 10px)',
                    }}
                  >
                    <TableChartIcon />
                  </Paper>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="stretch"
                    style={{ height: '50%', width: '100%' }}
                  >
                    <Paper
                      elevation={3}
                      style={{
                        background: '#f9f9f9',
                        margin: 5,
                        width: 'calc(50% - 10px)',
                        height: 'calc(100% - 10px)',
                      }}
                    >
                      <TimelineIcon />
                    </Paper>
                    <Paper
                      elevation={3}
                      style={{
                        background: '#f9f9f9',
                        margin: 5,
                        width: 'calc(50% - 10px)',
                        height: 'calc(100% - 10px)',
                      }}
                    >
                      <NotesIcon />
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        )}
      </Grid>
    </span>
  )
}
