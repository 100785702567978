import React, { Fragment, useEffect, useMemo } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getClickAction, isLeasePassed } from '../common/Utils'
import { t } from 'i18next'
import { useGridApiRef } from '@mui/x-data-grid'
import Tooltip from '@mui/material/Tooltip'
import clsx from 'clsx'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import NoteButton from '../common/NoteButton'
import ErrorBoundaryGuard from '../ErrorBoundaryGuard'
import { CustomTooltip, XLSFileInvert } from '../common/Style'
import LoopIcon from '@material-ui/icons/Loop'
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser'
import {
  BorderLinearProgress,
  OperatorTooltip,
  FlagItem,
  onUpdatePriority,
  openBatch,
  openBatchInNewTab,
  callBuildATrainingBatch,
  handleUpdateBatch,
  handleStampBatch,
  exploreBatch,
  saveBatch,
  CustomNoRowsOverlay,
} from './Tools'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import SchoolIcon from '@material-ui/icons/School'
import ReplayIcon from '@material-ui/icons/Replay'
import ApprovalIcon from '@mui/icons-material/Approval'
import SearchIcon from '@mui/icons-material/Search'
import SaveAltIcon from '@mui/icons-material/SaveAlt'
import { displayPopupMessage } from '../common/Message'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import TreeView from '@material-ui/lab/TreeView'
import TreeItem from '@material-ui/lab/TreeItem'
import IconButton from '@material-ui/core/IconButton'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { alpha } from '@material-ui/core'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import { AZURE_ENV, FLASK_URL } from '../../utils/config'
import Link from '@material-ui/core/Link/Link'
import Menu from '@material-ui/core/Menu'
import ExtraMenu from '../batch/ExtraMenu'
import FolderDeleteOutlinedIcon from '@mui/icons-material/FolderDeleteOutlined'
import { Stack } from '@mui/material'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import FaceIcon from '@mui/icons-material/Face'

const useStyles = makeStyles((theme) => ({
  tableCmp: {
    top: 10,
    height: '100%',
    display: 'contents',
    '& .MuiDataGrid-cell': {
      cursor: 'pointer',
    },
  },
  tooltip: {
    fontSize: 14,
    fontWeight: 400,
    padding: 5,
    lineHeight: 1.5,
  },
  trainingButton: {
    width: 24,
    height: 24,
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.secondary.main,
      transform: 'scale(1.1)',
      transition: 'all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
  },
  actionMenu: { justifyContent: 'left', alignItem: 'start' },
  actionDivider: { borderTop: '1px solid lightgrey', padding: '0px !important' },
  formControlLoading: {
    '& .MuiSelect-select.Mui-disabled': {
      cursor: 'wait !important',
    },
  },
  container: {
    maxHeight: '100%',
    height: '100%',
  },
  treeDisplay: {
    padding: 20,
    overflow: 'scroll',
    '& .MuiTreeItem-label': {
      fontSize: 'large',
    },
  },
  treeItemCls: {
    '& .MuiTreeItem-label:hover': {
      backgroundColor: 'transparent',
    },
    '&.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label': {
      backgroundColor: 'transparent',
    },
    '&.MuiTreeItem-root:focus > .MuiTreeItem-content .MuiTreeItem-label': {
      backgroundColor: 'transparent',
    },
  },
  subBatch: {
    marginLeft: 30,
  },
  nodeLeaf: {
    padding: '15px 10px',
    paddingLeft: 53,
    marginBottom: 7,
    display: 'flex',
    borderRadius: 5,
    background: '#00000005',
    '&:hover': {
      background: alpha(theme.palette.secondary.main, 0.05),
    },
    '&.selected': {
      '&:hover': {
        backgroundColor: '#fafafa !important',
      },
      opacity: 0.2,
      cursor: 'wait !important',
    },
    treeItemSelected: {
      opacity: 0.2,
      cursor: 'wait !important',
    },
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
  },
  flagLeaf: {
    position: 'absolute',
    float: 'left',
    left: 20,
    top: 16,
  },
  treeLevelName: {
    background: '#0000000d',
    borderRadius: 5,
    padding: '1px 11px',
    color: 'rgba(0, 0, 0, 0.70)',
    '&:hover': {
      background: alpha(theme.palette.secondary.main, 0.05),
    },
  },
  loadingBatchIcon: {
    animation: 'MuiCircularProgress-keyframes-circular-rotate 0.5s linear infinite',
    animationDirection: 'reverse',
    fontSize: '2rem',
  },
  retired: {
    color: '#bd170b',
  },
}))

const DUE_DATE_TEMPLATE_ERROR = '0000-00-00 00:00:00'
const transformBatchesOnTree = function (batches) {
  let tree = {
    id: 'root',
    name: 'Tree',
    children: [],
  }
  const addNode = (children, arboArray, level, batch) => {
    let child = children.filter((child) => child.name === arboArray[level])[0]
    if (child) {
      if (level === arboArray.length) {
        child.push({
          id: batch._id,
          name: batch.name,
          batch: batch,
          children: [],
          subBatch: batch.subBatch !== null,
          lease: batch.lease,
        })
      } else {
        addNode(child.children, arboArray, level + 1, batch)
      }
    } else {
      if (level === arboArray.length) {
        children.push({
          id: batch._id,
          name: batch.name,
          batch: batch,
          children: [],
          subBatch: batch.subBatch !== null,
          lease: batch.lease,
        })
      } else {
        children.push({
          id: arboArray.join('_') + '_' + arboArray[level] + '_' + level,
          name: arboArray[level],
          children: [],
          lease: batch.lease,
        })
        addNode(
          children.filter((child) => child.name === arboArray[level])[0].children,
          arboArray,
          level + 1,
          batch,
        )
      }
    }
  }
  batches.forEach((batch) => {
    let arbo = batch.path.replace('//', '/').split(/\/([0-9]{4})\/([0-9]{2})\//)
    arbo.splice(0, 1)
    arbo = arbo.join('/').split('/')
    addNode(tree.children, arbo, 0, batch)
  })
  return tree
}

const TreeCmp = withRouter((props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { style, setCurrentUpdateBatch, setOpenConfirmUpdateBatch, setOpenCreateTrainingBatch } =
    props
  const apiRef = useGridApiRef()
  const autoSizeOptions = { includeHeaders: true, includeOutliers: true, expand: true }

  const delta = useSelector((state) => state.settings.dateTimeDelta)
  const items = useSelector((state) => state.batches.items)

  const showRetired = localStorage.getItem('SETTINGS_show_retired')
    ? localStorage.getItem('SETTINGS_show_retired') === 'true'
    : false

  // const isFetching = useSelector((state) => state.batches.isFetching)

  // const isLoading = function (row) {
  //   return !isLeasePassed(row.lease.date, delta) && row.lease.reason === 'treatment'
  // }

  const [otherMenuEl, setOtherMenuEl] = React.useState(null)

  function openOtherMenu(event, row) {
    setOtherMenuEl({ anchor: event.currentTarget, id: row.id, row: row })
  }
  const closeOtherMenu = function () {
    setOtherMenuEl(null)
  }

  const batches = useMemo(() => {
    if (items) {
      const itemsPrepared = items.map((item) => ({
        hierarchy: [item._id],
        id: item._id,
        _id: item._id,
        name: item.name,
        path: item.content.folder,
        org_path: item.content.org_path,
        chromatoType: item.content.chromatography_type,
        configuration: item.content.configuration,
        batchDate: item.content.date_ts,
        dueDate:
          item.content.due_date && item.content.due_date !== DUE_DATE_TEMPLATE_ERROR
            ? item.content.due_date
            : null,
        device: item.content.device,
        priority: item.content.priority,
        operator: item.content.operator,
        creationDate: item.creationDate,
        analyseProgression: item.content.analyses_progression_requested
          ? item.content.analyses_progression_requested.toFixed(2)
          : 0,
        calibrationProgression: item.content.calibration_progression_requested
          ? item.content.calibration_progression_requested.toFixed(2)
          : 0,
        content: {
          progression_requested:
            ((item.content.analyses_progression_requested
              ? item.content.analyses_progression_requested
              : 0) +
              (item.content.calibration_progression_requested
                ? item.content.calibration_progression_requested
                : 0)) /
            2,
        },
        progression:
          ((item.content.analyses_progression_requested
            ? item.content.analyses_progression_requested
            : 0) +
            (item.content.calibration_progression_requested
              ? item.content.calibration_progression_requested
              : 0)) /
          2,
        flags: { globals: item.content.flags },
        subBatch:
          item.content.root_batch &&
          item.content.root_batch.id &&
          item.content.root_batch.id !== item._id
            ? item.content.root_batch
            : null,
        notes: item.content.notes ? item.content.notes : [],
        child_notes: item.content.child_notes
          ? item.content.child_notes
          : { calibrations: 0, analysis: 0 },
        lockUpdate:
          (item.content.root_batch && item.content.root_batch.id !== item._id) ||
          item.content.category === 'training',
        newUpdated: item.newUpdated,
        version: item.content.version,
        lease:
          item.content.lease && typeof item.content.lease === 'number'
            ? { date: item.content.lease, operator: null, reason: null }
            : item.content.lease &&
              item.content.lease !== null &&
              item.content.lease !== undefined &&
              typeof item.content.lease === 'object'
            ? item.content.lease
            : { date: 0, operator: null, reason: null },
        stamp: item.content.stamp,
        retired: item.content.retired,
        teams: item.teams,
      }))
      return transformBatchesOnTree(
        // itemsPrepared.filter((item) => !item.subBatch),
        itemsPrepared.map((item) => {
          let tmpItem = { ...item }
          if (tmpItem.subBatch) {
            tmpItem.path = tmpItem.path + '/' + tmpItem.subBatch.name
          }
          return tmpItem
        }),
      )
    } else {
      return []
    }
  }, [items])

  // useEffect(() => {
  //   if (batches.length >= 100) {
  //     displayPopupMessage(
  //       dispatch,
  //       'info',
  //       t('view.backlog.info_date.title'),
  //       <Fragment>
  //         <div>{t('view.backlog.info_date.msg1')}</div>
  //         <div>{t('view.backlog.info_date.msg2')}</div>
  //       </Fragment>,
  //     )
  //   }
  // }, [batches])

  const handleToggle = (event, nodeIds) => {
    localStorage.setItem('BACKLOG_tree_expended', nodeIds.toString())
  }
  const handleNothing = (event) => {
    event.stopPropagation()
    event.preventDefault()
  }

  const renderTree = (nodes) => {
    // const { expanded } = this.state
    // if (expanded) {
    //   expanded.push(nodes.id)
    //   this.setState({ expanded: expanded })
    // }

    const isLoading = !isLeasePassed(nodes.lease.date, delta) && nodes.lease.reason === 'treatment'
    // const isLoading = true
    return (
      <TreeItem
        key={nodes.id}
        nodeId={nodes.id}
        className={clsx(
          classes.treeItemCls,
          nodes.batch && nodes.batch.subBatch ? classes.subBatch : null,
        )}
        label={
          <div
            className={clsx(
              isLoading ? 'selected' : '',
              nodes.batch ? classes.nodeLeaf : classes.labelRoot,
            )}
          >
            {nodes.batch && (
              <div className={classes.flagLeaf}>
                <FlagItem row={nodes.batch} isLoading={isLoading} />
              </div>
            )}
            <div
              style={{
                width: '35%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                marginRight: 10,
              }}
            >
              {isLoading ? (
                nodes.name
              ) : nodes.batch ? (
                <Tooltip title={<div className={classes.tooltip}>{nodes.name}</div>} arrow>
                  <span className={nodes.batch.retired ? classes.retired : ''}>{nodes.name}</span>
                </Tooltip>
              ) : (
                <span className={clsx(classes.treeLevelName)}>{nodes.name}</span>
              )}
            </div>
            {nodes.batch && (
              <Fragment>
                {showRetired && (
                  <div style={{ width: '5%', height: 20, position: 'relative', top: 0 }}>
                    {nodes.batch.retired ? (
                      <FolderDeleteOutlinedIcon className={classes.retired} />
                    ) : (
                      <span></span>
                    )}
                  </div>
                )}
                <div style={{ width: '5%', height: 20, position: 'relative', top: 0 }}>
                  {nodes.batch.stamp ? (
                    <Tooltip
                      // disableInteractive
                      placement={'top'}
                      title={
                        <div className={classes.tooltip}>
                          <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={2}
                          >
                            <AccessTimeIcon style={{ width: '0.7em', marginRight: 10 }} />
                            {nodes.batch?.stamp?.date
                              ? new Date((nodes.batch?.stamp?.date - delta) * 1000).toLocaleString()
                              : '--'}
                          </Stack>
                          <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={2}
                          >
                            <FaceIcon style={{ width: '0.7em', marginRight: 10 }} />
                            {nodes.batch?.stamp?.operator?.name
                              ? nodes.batch.stamp.operator?.name
                              : '--'}
                          </Stack>
                        </div>
                      }
                      arrow
                    >
                      <ApprovalIcon style={{ color: '#0000008a' }} />
                    </Tooltip>
                  ) : (
                    <span></span>
                  )}
                </div>
                <div style={{ width: '5%', height: 20, position: 'relative', top: 0 }}>
                  <ErrorBoundaryGuard isDialog>
                    <NoteButton row={nodes.batch} collection={'batch'} isLoading={isLoading} />
                  </ErrorBoundaryGuard>
                </div>
                <div style={{ width: '10%', height: 20, position: 'relative', top: 0 }}>
                  {nodes.batch.version}
                </div>
              </Fragment>
            )}
            {nodes.batch && (
              <Fragment>
                <div style={{ width: '20%' }}>
                  <Tooltip
                    placement={'right'}
                    title={
                      !isLoading ? (
                        <OperatorTooltip row={nodes.batch} defaultClasses={classes} />
                      ) : (
                        ''
                      )
                    }
                    arrow
                  >
                    <span>
                      {nodes.batch.operator &&
                        nodes.batch.operator[nodes.batch.operator.length - 1].name}
                    </span>
                  </Tooltip>
                </div>
                <div style={{ width: '15%' }} onClick={(event) => handleNothing(event)}>
                  {/*{nodes.batch.priority}*/}
                  <FormControl className={classes.formControl}>
                    <Select
                      value={nodes.batch.priority}
                      name={'priority'}
                      onChange={(event) => onUpdatePriority(event, nodes, dispatch)}
                      displayEmpty
                      disabled={isLoading}
                      autoWidth
                      inputProps={{ 'aria-label': 'Priority' }}
                    >
                      <MenuItem key={'normal'} value={'Normal'}>
                        {t('view.backlog.normal')}
                      </MenuItem>
                      <MenuItem key={'urgent'} value={'Urgent'}>
                        {t('view.backlog.urgent')}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div style={{ width: '20%', marginTop: 6 }}>
                  <Tooltip
                    title={
                      !isLoading ? (
                        <div className={classes.tooltip}>
                          <div>{`${t('view.backlog.calib')}: ${
                            nodes.batch.calibrationProgression
                          }%`}</div>
                          <div>{`${t('view.backlog.analysis')}: ${
                            nodes.batch.analyseProgression
                          }%`}</div>
                        </div>
                      ) : (
                        ''
                      )
                    }
                    arrow
                  >
                    <BorderLinearProgress variant="determinate" value={nodes.batch.progression} />
                  </Tooltip>
                </div>
                <div
                  style={{
                    width: 37,
                    marginLeft: 20,
                  }}
                >
                  {/*<ActionButton*/}
                  {/*  isLoading={isLoading}*/}
                  {/*  row={nodes.batch}*/}
                  {/*  defaultClasses={classes}*/}
                  {/*  dispatch={dispatch}*/}
                  {/*  setOpenConfirmUpdateBatch={setOpenConfirmUpdateBatch}*/}
                  {/*  setCurrentUpdateBatch={setCurrentUpdateBatch}*/}
                  {/*  setOpenCreateTrainingBatch={setOpenCreateTrainingBatch}*/}
                  {/*/>*/}
                  <IconButton
                    aria-label="others"
                    onClick={(event) => {
                      event.stopPropagation()
                      event.preventDefault()
                      openOtherMenu(event, nodes.batch)
                    }}
                    style={{ padding: 0 }}
                  >
                    <CustomTooltip
                      // disableInteractive
                      placement="top"
                      arrow
                      title={
                        isLoading ? (
                          <span>
                            {t('view.panel.batchesCmp.tooltip.loading')}
                            <div>
                              {t('view.panel.batchesCmp.tooltip.operator')}:{nodes.lease.operator}
                            </div>
                            <div>
                              {t('view.panel.batchesCmp.tooltip.reason')}: {nodes.lease.reason}
                            </div>
                          </span>
                        ) : (
                          t('view.panel.batchesCmp.tooltip.other')
                        )
                      }
                    >
                      {isLoading ? (
                        <LoopIcon className={classes.loadingBatchIcon} />
                      ) : (
                        <MoreVertIcon />
                      )}
                    </CustomTooltip>
                  </IconButton>
                </div>
              </Fragment>
            )}
          </div>
        }
        onLabelClick={function (event) {
          if (nodes.batch) {
            const openFct = () => {
              props.history.push('/?batch/' + nodes.batch._id)
            }
            getClickAction(dispatch, event, nodes.batch, 'batch', openFct)
          }
        }}
      >
        {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
      </TreeItem>
    )
  }

  // TODO can be removed
  const oldMenu = (
    <Menu
      anchorEl={otherMenuEl ? otherMenuEl.anchor : null}
      getcontentanchorel={null}
      // keepMounted
      open={Boolean(otherMenuEl)}
      onClose={closeOtherMenu}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <MenuItem
        disabled={
          (otherMenuEl &&
            !isLeasePassed(otherMenuEl.row.lease.date, delta) &&
            otherMenuEl.row.lease.reason === 'treatment') ||
          (otherMenuEl ? otherMenuEl.row.lockUpdate : true)
        }
        onClick={function () {
          openBatch(otherMenuEl.row, props.history)
          setOtherMenuEl(null)
        }}
      >
        <ListItemIcon>
          <OpenInBrowserIcon />
        </ListItemIcon>
        <Typography variant="inherit">{t('view.panel.batchesCmp.actions.open')}</Typography>
      </MenuItem>
      <MenuItem
        disabled={
          (otherMenuEl &&
            !isLeasePassed(otherMenuEl.row.lease.date, delta) &&
            otherMenuEl.row.lease.reason === 'treatment') ||
          (otherMenuEl ? otherMenuEl.row.lockUpdate : true)
        }
        onClick={() => openBatchInNewTab(otherMenuEl.row)}
      >
        <ListItemIcon>
          <OpenInNewIcon />
        </ListItemIcon>
        <Typography variant="inherit">{t('view.panel.batchesCmp.actions.openNew')}</Typography>
      </MenuItem>
      <Divider sx={{ my: 0.5 }} />
      <MenuItem
        disabled={
          (otherMenuEl &&
            !isLeasePassed(otherMenuEl.row.lease.date, delta) &&
            otherMenuEl.row.lease.reason === 'treatment') ||
          (otherMenuEl ? otherMenuEl.row.lockUpdate : true)
        }
        onClick={(event) => {
          // me.setState({ otherMenuEl: null })
          callBuildATrainingBatch(
            event,
            otherMenuEl.row,
            dispatch,
            // setOpenCreateTrainingBatch,
            // setCurrentUpdateBatch,
          )
        }}
      >
        <ListItemIcon>
          <Fragment>
            <SchoolIcon />
          </Fragment>
        </ListItemIcon>
        <Typography variant="inherit">{t('view.backlog.menu.training')}</Typography>
      </MenuItem>
      {AZURE_ENV ? null : (
        <MenuItem
          disabled={
            (otherMenuEl &&
              !isLeasePassed(otherMenuEl.row.lease.date, delta) &&
              otherMenuEl.row.lease.reason === 'treatment') ||
            (otherMenuEl ? otherMenuEl.row.lockUpdate : true)
          }
          onClick={(event) => {
            // me.setState({ otherMenuEl: null })
            handleUpdateBatch(
              event,
              otherMenuEl.row,
              dispatch,
              /*setOpenConfirmUpdateBatch,
            setCurrentUpdateBatch*/
            )
          }}
        >
          <ListItemIcon>
            {otherMenuEl &&
            !isLeasePassed(otherMenuEl.row.lease.date, delta) &&
            otherMenuEl.row.lease.reason === 'treatment' ? (
              <LoopIcon className={classes.loadingBatchIcon} />
            ) : (
              <ReplayIcon />
            )}
          </ListItemIcon>
          <Typography variant="inherit">{t('view.backlog.menu.replace')}</Typography>
        </MenuItem>
      )}

      <MenuItem
        disabled={
          (otherMenuEl &&
            !isLeasePassed(otherMenuEl.row.lease.date, delta) &&
            otherMenuEl.row.lease.reason === 'treatment') ||
          (otherMenuEl ? otherMenuEl.row.lockUpdate : true)
        }
      >
        <Link
          href={`${FLASK_URL()}/batch/${otherMenuEl ? otherMenuEl.row._id : ''}/reporting`}
          style={{ textDecoration: 'none !important', display: 'flex', padding: 0 }}
          color="inherit"
          onClick={() => {
            displayPopupMessage(
              dispatch,
              'info',
              t('view.backlog.data_extraction.title'),
              <Fragment>
                <div>
                  {t('view.backlog.data_extraction.title')} + +
                  {otherMenuEl ? otherMenuEl.row._id : ''}
                </div>
              </Fragment>,
            )
          }}
        >
          <ListItemIcon>
            <XLSFileInvert style={{ width: 26, height: 21 }} className={classes.colorBlue} />
          </ListItemIcon>
          <Typography variant="inherit">{t('view.panel.batchesCmp.actions.download')}</Typography>
        </Link>
      </MenuItem>
      <MenuItem
        disabled={false}
        onClick={(event) => {
          handleStampBatch(event, otherMenuEl.row, dispatch)
        }}
      >
        <ListItemIcon>
          <ApprovalIcon />
        </ListItemIcon>
        <Typography variant="inherit">{t('view.backlog.menu.stamp')}</Typography>
      </MenuItem>
      <Divider sx={{ my: 0.5 }} />
      <MenuItem
        onClick={function () {
          exploreBatch(otherMenuEl.row, dispatch)
          setOtherMenuEl(null)
        }}
      >
        <ListItemIcon>
          <SearchIcon />
        </ListItemIcon>
        <Typography variant="inherit">{t('view.panel.batchesCmp.actions.explore')}</Typography>
      </MenuItem>
      <MenuItem onClick={() => saveBatch(otherMenuEl.row)}>
        <ListItemIcon>
          <SaveAltIcon />
        </ListItemIcon>
        <Typography variant="inherit">{t('view.panel.batchesCmp.actions.save')}</Typography>
      </MenuItem>
    </Menu>
  )

  return (
    <div className={classes.tableCmp}>
      <ExtraMenu anchorEl={otherMenuEl} open={Boolean(otherMenuEl)} onClose={closeOtherMenu} />
      <ErrorBoundaryGuard>
        {batches.children.length > 0 ? (
          <TreeView
            className={clsx(classes.container, classes.treeDisplay)}
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            defaultExpanded={
              localStorage.getItem('BACKLOG_tree_expended')
                ? localStorage.getItem('BACKLOG_tree_expended').split(',')
                : []
            }
            onNodeToggle={handleToggle}
          >
            {batches.children.map((node) => renderTree(node))}
          </TreeView>
        ) : (
          <CustomNoRowsOverlay />
        )}
      </ErrorBoundaryGuard>
    </div>
  )
})
export default TreeCmp
