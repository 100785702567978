import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import NoteIcon from '@material-ui/icons/Note'
import Badge from '@material-ui/core/Badge'
import NoteAddIcon from '@material-ui/icons/NoteAdd'
import IconButton from '@material-ui/core/IconButton'
import Popover from '@material-ui/core/Popover'
import { NoteTooltip, StyledDialogTitle, TransitionTop } from './Style'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText'
import TextField from '@material-ui/core/TextField'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import clsx from 'clsx'
import { API_BO_URL } from '../../utils/config'
import { getAnalysisById } from '../../redux/analyses/selectors'
import { getBatchById } from '../../redux/batches/selectors'
import {
  getUnitaryCalibrationsFilteredById,
  getUnitaryCalibrationsRawById,
} from '../../redux/unitaryCalibrations/selectors'
import { getUnitaryAnalysisById } from '../../redux/unitaryAnalyses/selectors'
import DeleteIcon from '@material-ui/icons/Delete'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import { alpha } from '@material-ui/core'
import { fetchAddBatchNote, fetchRemoveBatchNote } from '../../redux/batches/actions'
import { fetchFinishTask } from '../../redux/tasks/actions'
import {
  fetchAddUCNote,
  fetchAllUnitaryCalibrationsNotes,
  fetchRemoveUCNote,
  fetchUnitaryCalibrationsFiltered,
} from '../../redux/unitaryCalibrations/actions'
import {
  fetchAddAnalysisNote,
  fetchAnalyses,
  fetchRemoveAnalysisNote,
} from '../../redux/analyses/actions'
import { Analysis } from './Style'
import TimelineIcon from '@material-ui/icons/Timeline'
import Box from '@material-ui/core/Box'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { palette } from '@material-ui/system'
import Grid from '@material-ui/core/Grid'
import { withRouter } from 'react-router-dom'
import Loading from '../Loading'
import { displayPopupMessage, generateErrorPart } from './Message'
import { t } from 'i18next'
import { Trans } from 'react-i18next'

const styles = (theme) => ({
  addNote: {
    marginLeft: 5,
    color: 'white',
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.secondary.main,
      transform: 'scale(1.1)',
      transition: 'all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
  },
  noteBadge: {
    '& .MuiBadge-badge': {
      top: 10,
      right: 12,
    },
  },
  tableMini: {
    padding: 0,
    marginLeft: 0,
    position: 'relative',
    top: 0,
  },
  dateDiv: {
    marginTop: 12,
    float: 'left',
    color: theme.palette.primary.main,
  },
  titleColor: {
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.secondary.main,
      transform: 'scale(1.1)',
      transition: 'all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
  },
  tableColor: {
    color: 'rgba(0, 0, 0, 0.54)',
    '&:hover': {
      color: theme.palette.secondary.main,
      transform: 'scale(1.1)',
      transition: 'all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
  },
  tooltipContainer: {
    background: 'white',
    color: 'black',
    marginBottom: 10,
    borderRadius: 5,
    padding: '0px 10px 10px',
    fontWeight: 100,
    fontSize: 12,
    '&:hover .removeNoteDiv': {
      opacity: 1,
      transition: 'all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
  },
  noteDetail: {
    background: 'whitesmoke',
    maxWidth: 350,
    boxShadow:
      '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
  },
  containerNameRemove: {
    display: 'table',
    width: '100%',
  },
  removeNoteDiv: {
    opacity: 0,
    transition: 'all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    float: 'right',
    marginTop: 6,
  },
  removeNote: {
    '& .removeNoteOutlineIcon': {
      opacity: 1,
      position: 'absolute',
      color: alpha('#d3d3d3', 0.8),
      transition: 'all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
    '& .removeNoteIcon': {
      opacity: 0,
      position: 'absolute',
      color: theme.palette.common.error,
      transition: 'all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
    '&:hover': {
      background: 'transparent',
    },
    '&:hover .removeNoteOutlineIcon': {
      opacity: 0,
      transition: 'all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
    '&:hover .removeNoteIcon': {
      opacity: 1,
      transition: 'all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
  },
  tabPanel: {
    //minHeight: 180,
    height: '100%',
    '& > .MuiBox-root': {
      height: '100%',
    },
    '& > .MuiBox-root > .MuiTypography-root': {
      height: '100%',
    },
  },
  appBar: {
    padding: '0',
    '& .MuiTabs-indicator': {
      backgroundColor: theme.palette.secondary.main,
    },
    '& button.Mui-selected': {
      color: theme.palette.secondary.main,
    },
  },
  noteItemNameColor: {
    color: theme.palette.secondary.main,
  },
})

const mapStateToProps = (state, ownProps) => {
  const { analyses, unitaryCalibrations } = state
  return {
    analyses,
    unitaryCalibrations,
  }
}

function NoteTabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`note-simple-tabpanel-${index}`}
      aria-labelledby={`note-simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}

NoteTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}
function a11yPropsNote(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

class NoteButton extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
      openDetail: false,
      newNote: '',
      tabOpen: 0,
    }
  }

  render() {
    const {
      dispatch,
      classes,
      row,
      collection,
      title,
      style,
      batchName,
      analyses,
      unitaryCalibrations,
      isLoading,
    } = this.props
    const { open, openDetail, newNote, tabOpen } = this.state

    const onChangeNote = (event) => {
      event.stopPropagation()
      event.preventDefault()
      this.setState({ newNote: event.target.value })
    }
    const openFct = (event) => {
      event.stopPropagation()
      event.preventDefault()
      this.setState({ open: true })
    }
    const openAnalysisNoteFct = (event) => {
      event.stopPropagation()
      event.preventDefault()
      if (!this.props.match.params.batchId) {
        dispatch(fetchAnalyses(row._id))
      }
      dispatch(fetchAllUnitaryCalibrationsNotes(row._id))
      this.setState({ tabOpen: 0, openDetail: true })
    }
    const openCalibrationNoteFct = (event) => {
      event.stopPropagation()
      event.preventDefault()
      if (!this.props.match.params.batchId) {
        dispatch(fetchAnalyses(row._id))
      }
      dispatch(fetchAllUnitaryCalibrationsNotes(row._id))
      this.setState({ tabOpen: 1, openDetail: true })
    }

    const handleChange = (event, newValue) => {
      this.setState({ tabOpen: newValue })
    }

    // Don't understand why I should do this to prevent click on dialog trigger row clic behind...
    const handleNothing = (event) => {
      event.stopPropagation()
      event.preventDefault()
    }

    const removeNote = async (event, note, db_collection, objId) => {
      event.stopPropagation()
      event.preventDefault()

      const obj = { _id: objId ? objId : row._id }
      switch (db_collection ? db_collection : collection) {
        // case 'batch':
        //   fullRaw = getBatchById(batches, row._id)
        //   break
        // case 'analysis':
        //   fullRaw = getAnalysisById(analyses, row._id)
        //   break
        // case 'unitary_calibration':
        //   fullRaw = getUnitaryCalibrationsRawById(unitaryCalibrations, row._id)
        //   break
        // case 'unitary_analysis':
        //   fullRaw = getUnitaryAnalysisById(unitaryAnalyses, row._id)
        //   break
        case 'batch':
          dispatch(fetchRemoveBatchNote(obj, note))
            .then(function (resp) {
              if (resp.error) {
                removeWithError(resp.error)
              } else {
                removeWithSucces()
              }
            })
            .catch(function (error) {
              removeWithError(error)
            })
          break
        case 'analysis':
          dispatch(fetchRemoveAnalysisNote(obj, note))
            .then(function (resp) {
              removeWithSucces()
            })
            .catch(function (error) {
              removeWithError(error)
            })
          break
        case 'unitary_calibration':
          dispatch(fetchRemoveUCNote(obj, note))
            .then(function (resp) {
              if (resp.error) {
                removeWithError(resp.error)
              } else {
                removeWithSucces()
              }
            })
            .catch(function (error) {
              removeWithError(error)
            })
          break
        case 'unitary_analysis':
          //dispatch(fetchRemoveNote(row, note))
          break
        default:
          break
      }
    }

    const handleDetailClose = (event) => {
      event.stopPropagation()
      event.preventDefault()
      this.setState({ openDetail: false })
    }

    const handleConfirmClose = async (event, confirm) => {
      event.stopPropagation()
      event.preventDefault()
      this.setState({ open: false })
      if (confirm) {
        let allNotes = []
        row.notes.forEach((note) => allNotes.push(note))
        const newNoteObj = {
          operator: localStorage.getItem('SESSION_user_name'),
          date: Math.round(new Date().getTime() / 1000),
          message: newNote,
        }
        allNotes.push(newNoteObj)
        switch (collection) {
          case 'batch':
            dispatch(fetchAddBatchNote(row, allNotes))
              .then(function (resp) {
                if (resp.error) {
                  addWithError(resp.error)
                } else {
                  addWithSucces()
                }
              })
              .catch(function (error) {
                addWithError(error)
              })
            break
          case 'analysis':
            dispatch(fetchAddAnalysisNote(row, allNotes))
              .then(function (resp) {
                addWithSucces()
              })
              .catch(function (error) {
                addWithError(error)
              })
            break
          case 'unitary_calibration':
            dispatch(fetchAddUCNote(row, allNotes))
              .then(function (resp) {
                if (resp.error) {
                  addWithError(resp.error)
                } else {
                  addWithSucces()
                }
              })
              .catch(function (error) {
                addWithError(error)
              })
            break
          case 'unitary_analysis':
            //dispatch(fetchAddNote(row, allNotes))
            break
          default:
            break
        }
      }
    }

    const addWithSucces = () => {
      displayPopupMessage(
        dispatch,
        'success',
        t('view.common.note_button.add_new'),
        <Fragment>{t('view.common.note_button.add_ok')}</Fragment>,
      )
    }

    const addWithError = (error) => {
      displayPopupMessage(
        dispatch,
        'error',
        t('view.common.note_button.add_new'),
        <Fragment>
          <div>{t('view.common.note_button.add_ko')}</div>
          {generateErrorPart(error)}
        </Fragment>,
      )
    }

    const removeWithSucces = () => {
      displayPopupMessage(
        dispatch,
        'success',
        t('view.common.note_button.remove'),
        <Fragment>{t('view.common.note_button.remove_ok')}</Fragment>,
      )
    }

    const removeWithError = (error) => {
      displayPopupMessage(
        dispatch,
        'error',
        t('view.common.note_button.remove'),
        <Fragment>
          <div>{t('view.common.note_button.remove_ko')}</div>
          {generateErrorPart(error)}
        </Fragment>,
      )
    }

    const notes = row.notes
    let child_notes = 0
    if (row.child_notes) {
      child_notes = row.child_notes.calibrations + row.child_notes.analysis
    }

    return (
      <Fragment>
        <Dialog
          open={open}
          // onBackdropClick={(event) => handleConfirmClose(event, false)}
          onClose={(event) => handleConfirmClose(event, false)}
          TransitionComponent={TransitionTop}
        >
          <div onClick={(event) => handleNothing(event)}>
            <StyledDialogTitle>{t('view.common.note_button.title')}</StyledDialogTitle>
            <DialogContent style={{ width: 600 }}>
              <DialogContentText style={{ fontWeight: 500, marginLeft: 12 }}>
                {new Intl.DateTimeFormat('en-GB', {
                  year: 'numeric',
                  month: 'long',
                  day: '2-digit',
                }).format(new Date())}
              </DialogContentText>
              <TextField
                autoFocus
                required
                fullWidth
                multiline
                minRows={4}
                id="add-a-note"
                label={t('view.common.note_button.note')}
                variant="outlined"
                value={newNote}
                onChange={(event) => onChangeNote(event)}
                error={newNote === '' || newNote === null || newNote === undefined}
              />
              <DialogContentText
                style={{ textAlign: 'end', fontStyle: 'italic', marginTop: 12, marginRight: 12 }}
              >
                {localStorage.getItem('SESSION_user_name')}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={(event) => handleConfirmClose(event, true)}
                color="primary"
                disabled={newNote === '' || newNote === null || newNote === undefined}
              >
                {t('view.common.note_button.add')}
              </Button>
              <Button
                autoFocus
                onClick={(event) => handleConfirmClose(event, false)}
                color="primary"
              >
                {t('view.common.note_button.cancel')}
              </Button>
            </DialogActions>
          </div>
        </Dialog>
        <Dialog
          fullWidth
          maxWidth={'lg'}
          open={openDetail}
          // onBackdropClick={(event) => handleDetailClose(event)}
          onClose={(event) => handleDetailClose(event)}
          TransitionComponent={TransitionTop}
        >
          <div onClick={(event) => handleNothing(event)}>
            <StyledDialogTitle style={{ background: 'whitesmoke', paddingBottom: 0 }}>
              <Trans i18nKey="view.common.note_button.inherited">
                Notes inherited from the batch {{ val: batchName }}
              </Trans>
            </StyledDialogTitle>
            <DialogContent style={{ padding: 0 }}>
              <AppBar className={classes.appBar} position="static" color="default">
                <Tabs
                  value={tabOpen}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="on"
                  indicatorColor="primary"
                  textColor="primary"
                  aria-label="scrollable force tabs example"
                >
                  <Tab icon={<Analysis />} {...a11yPropsNote(0)} />
                  <Tab icon={<TimelineIcon />} {...a11yPropsNote(1)} />
                </Tabs>
              </AppBar>
              <NoteTabPanel value={tabOpen} index={0} className={classes.tabPanel}>
                <Grid container className={classes.root} spacing={2}>
                  {analyses.isFetching ? (
                    <div style={{ height: 180 }}>
                      <Loading mode={'miniCircle'} />
                    </div>
                  ) : (
                    analyses.items
                      .filter(
                        (analysis) => analysis.content.notes && analysis.content.notes.length > 0,
                      )
                      .map((analysis) =>
                        analysis.content.notes.map((note) => ({
                          note: note,
                          analysisName: analysis.name,
                          analysisId: analysis._id,
                        })),
                      )
                      .flat()
                      .sort(function (a, b) {
                        if (a.note.date < b.note.date) return -1
                        if (a.note.date > b.note.date) return 1
                        return 0
                      })
                      .map((n, index) => (
                        <Grid key={index + '_' + n.note.date} item style={{ width: 350 }}>
                          <div className={clsx(classes.tooltipContainer, classes.noteDetail)}>
                            <div className={classes.containerNameRemove}>
                              <div
                                className={classes.dateDiv}
                                style={{ fontWeight: 500, marginBottom: 5 }}
                              >
                                {new Intl.DateTimeFormat('en-GB', {
                                  year: 'numeric',
                                  month: 'long',
                                  day: '2-digit',
                                }).format(new Date(n.note.date * 1000))}{' '}
                                <span style={{ color: 'black' }}>-</span>{' '}
                                <span className={classes.noteItemNameColor}>{n.analysisName}</span>
                              </div>
                              <div className={clsx('removeNoteDiv', classes.removeNoteDiv)}>
                                <IconButton
                                  aria-label="removeNote"
                                  className={classes.removeNote}
                                  onClick={(event) =>
                                    removeNote(event, n.note, 'analysis', n.analysisId)
                                  }
                                >
                                  <DeleteOutlineIcon className={'removeNoteOutlineIcon'} />
                                  <DeleteIcon className={'removeNoteIcon'} />
                                </IconButton>
                              </div>
                            </div>
                            <div style={{ margin: '5px 0px' }}>{n.note.message}</div>
                            <div style={{ textAlign: 'end', fontStyle: 'italic', fontSize: 11 }}>
                              {n.note.operator}
                            </div>
                          </div>
                        </Grid>
                      ))
                  )}
                </Grid>
              </NoteTabPanel>
              <NoteTabPanel value={tabOpen} index={1} className={classes.tabPanel}>
                <Grid container className={classes.root} spacing={2}>
                  {unitaryCalibrations.isFetchingUnitaryCalibrationsNote ? (
                    <div style={{ height: 180 }}>
                      <Loading mode={'miniCircle'} />
                    </div>
                  ) : (
                    unitaryCalibrations.unitaryCalibrationsNotes
                      .map((calibration) =>
                        calibration.notes.map((note) => ({
                          note: note,
                          calibrationName: calibration.name,
                          calibrationId: calibration._id,
                        })),
                      )
                      .flat()
                      .sort(function (a, b) {
                        if (a.note.date < b.note.date) return -1
                        if (a.note.date > b.note.date) return 1
                        return 0
                      })
                      .map((n) => (
                        <Grid item style={{ width: 350 }}>
                          <div className={clsx(classes.tooltipContainer, classes.noteDetail)}>
                            <div className={classes.containerNameRemove}>
                              <div
                                className={classes.dateDiv}
                                style={{ fontWeight: 500, marginBottom: 5 }}
                              >
                                {new Intl.DateTimeFormat('en-GB', {
                                  year: 'numeric',
                                  month: 'long',
                                  day: '2-digit',
                                }).format(new Date(n.note.date * 1000))}{' '}
                                <span style={{ color: 'black' }}>-</span>{' '}
                                <span className={classes.noteItemNameColor}>
                                  {n.calibrationName}
                                </span>
                              </div>
                              <div className={clsx('removeNoteDiv', classes.removeNoteDiv)}>
                                <IconButton
                                  aria-label="removeNote"
                                  className={classes.removeNote}
                                  onClick={(event) =>
                                    removeNote(
                                      event,
                                      n.note,
                                      'unitary_calibration',
                                      n.calibrationId,
                                    )
                                  }
                                >
                                  <DeleteOutlineIcon className={'removeNoteOutlineIcon'} />
                                  <DeleteIcon className={'removeNoteIcon'} />
                                </IconButton>
                              </div>
                            </div>
                            <div style={{ margin: '5px 0px' }}>{n.note.message}</div>
                            <div style={{ textAlign: 'end', fontStyle: 'italic', fontSize: 11 }}>
                              {n.note.operator}
                            </div>
                          </div>
                        </Grid>
                      ))
                  )}
                </Grid>
              </NoteTabPanel>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={(event) => handleDetailClose(event)} color="primary">
                Close
              </Button>
            </DialogActions>
          </div>
        </Dialog>
        <NoteTooltip
          arrow
          interactive={notes.length > 0}
          placement={notes.length > 0 ? 'right' : 'bottom'}
          title={
            isLoading ? (
              ''
            ) : notes.length > 0 ? (
              <div style={{ minWidth: 280 }}>
                <div style={{ padding: '10px 10px 0px', maxHeight: 500, overflow: 'auto' }}>
                  {notes.map((n, index) => (
                    <div key={n.date + '_key_' + index} className={classes.tooltipContainer}>
                      <div className={classes.containerNameRemove}>
                        <div
                          className={classes.dateDiv}
                          style={{ fontWeight: 500, marginBottom: 5 }}
                        >
                          {new Intl.DateTimeFormat('en-GB', {
                            year: 'numeric',
                            month: 'long',
                            day: '2-digit',
                          }).format(new Date(n.date * 1000))}
                        </div>
                        <div className={clsx('removeNoteDiv', classes.removeNoteDiv)}>
                          <IconButton
                            aria-label="removeNote"
                            className={classes.removeNote}
                            onClick={(event) => removeNote(event, n)}
                          >
                            <DeleteOutlineIcon className={'removeNoteOutlineIcon'} />
                            <DeleteIcon className={'removeNoteIcon'} />
                          </IconButton>
                        </div>
                      </div>
                      <div style={{ margin: '5px 0px' }}>{n.message}</div>
                      <div style={{ textAlign: 'end', fontStyle: 'italic', fontSize: 11 }}>
                        {n.operator}
                      </div>
                    </div>
                  ))}
                </div>
                <div className={classes.noteBadge} style={{ textAlign: 'end' }}>
                  {row.child_notes && row.child_notes.analysis > 0 && (
                    <div style={{ float: 'left' }}>
                      <NoteTooltip
                        arrow
                        placement={'bottom'}
                        title={
                          <div
                            style={{
                              fontSize: 13,
                              padding: 10,
                              background: 'rgba(97, 97, 97, 0.9)',
                              borderRadius: 4,
                            }}
                          >
                            {t('view.common.note_button.analysis_note')}
                          </div>
                        }
                      >
                        <Badge badgeContent={row.child_notes.analysis} color="secondary">
                          <IconButton
                            style={{ float: 'left' }}
                            aria-label="analysisNote"
                            className={classes.addNote}
                            onClick={(event) => openAnalysisNoteFct(event, row, notes)}
                          >
                            <Analysis style={{ width: 22, height: 26 }} />
                          </IconButton>
                        </Badge>
                      </NoteTooltip>
                    </div>
                  )}
                  {row.child_notes && row.child_notes.calibrations > 0 && (
                    <div style={{ float: 'left' }}>
                      <NoteTooltip
                        arrow
                        placement={'bottom'}
                        title={
                          <div
                            style={{
                              fontSize: 13,
                              padding: 10,
                              background: 'rgba(97, 97, 97, 0.9)',
                              borderRadius: 4,
                            }}
                          >
                            {t('view.common.note_button.calib')}
                          </div>
                        }
                      >
                        <Badge badgeContent={row.child_notes.calibrations} color="secondary">
                          <IconButton
                            aria-label="calibrationNote"
                            className={classes.addNote}
                            onClick={(event) => openCalibrationNoteFct(event, row, notes)}
                          >
                            <TimelineIcon style={{ width: 25, height: 31 }} />
                          </IconButton>
                        </Badge>
                      </NoteTooltip>
                    </div>
                  )}
                  <NoteTooltip
                    arrow
                    placement={'bottom'}
                    title={
                      <div
                        style={{
                          fontSize: 13,
                          padding: 10,
                          background: 'rgba(97, 97, 97, 0.9)',
                          borderRadius: 4,
                        }}
                      >
                        {t('view.common.note_button.title')}
                      </div>
                    }
                  >
                    <IconButton
                      aria-label="addnote"
                      className={classes.addNote}
                      onClick={(event) => openFct(event, row, notes)}
                    >
                      <NoteAddIcon />
                    </IconButton>
                  </NoteTooltip>
                </div>
              </div>
            ) : (
              <div
                style={{
                  fontSize: 13,
                  padding: 10,
                  background: 'rgba(97, 97, 97, 0.9)',
                  borderRadius: 4,
                }}
              >
                {t('view.common.note_button.title')}
              </div>
            )
          }
        >
          <IconButton
            style={style}
            aria-label="addnote"
            disabled={isLoading}
            className={clsx(classes.addNote, !title && classes.tableMini)}
            onClick={(event) => openFct(event, row, notes)}
          >
            <Badge badgeContent={notes.length + child_notes} color="secondary">
              {notes.length > 0 ? (
                <NoteIcon
                  className={title ? classes.titleColor : classes.tableColor}
                  style={{ fontSize: 24 }}
                />
              ) : (
                <NoteAddIcon
                  className={title ? classes.titleColor : classes.tableColor}
                  style={{ fontSize: 24, opacity: title ? 1 : 0.6 }}
                />
              )}
            </Badge>
          </IconButton>
        </NoteTooltip>
      </Fragment>
    )
  }
}
export default withRouter(connect(mapStateToProps)(withStyles(styles)(NoteButton)))
