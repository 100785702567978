import React, { Fragment, useEffect } from 'react'
import {
  isAlreadyRetrieve,
  retrieveBatches,
  retrieveCurrentBatch,
  updateGlobalPeak,
} from '../common/Utils'
import { StyledDialogTitle, TransitionTop } from '../common/Style'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import { useDispatch, useSelector } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Switch from '@material-ui/core/Switch'
import { makeStyles } from '@material-ui/core'
import { alpha } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { fetchScreeningForBatch } from '../../redux/screening/actions'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import clsx from 'clsx'
import NewReleasesIcon from '@material-ui/icons/NewReleases'
import IconButton from '@material-ui/core/IconButton'
import PublishIcon from '@material-ui/icons/Publish'
import WarningIcon from '@material-ui/icons/Warning'
import { t } from 'i18next'

const useStyles = makeStyles((theme) => ({
  optionLabel: {
    color: alpha(theme.palette.common.black, 0.54),
    fontSize: '1rem',
    marginRight: 20,
  },
  optionSelected: {
    color: theme.palette.secondary.main,
  },
  formControl: {
    '& .MuiOutlinedInput-root': {
      // borderRadius: '4px 0px 0px 4px',
    },
    '& .MuiSelect-outlined': {
      minWidth: 110,
      maxWidth: 300,
      // width: 255,
    },
  },
  uploadButton: {
    background: '#00000005',
    height: 56,
    borderRadius: '0px 4px 4px 0px',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderLeft: 'none',
  },
  warningInfo: {
    color: theme.palette.warning.main,
    border: '1px solid ' + theme.palette.warning.main,
    borderRadius: 5,
    padding: 10,
    marginBottom: 24,
  },
}))

export default function UpdateBatchDialog({ open, closeFct, batch }) {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [displayAll, setDisplayAll] = React.useState(false)
  const [option, setOption] = React.useState('replace')
  const [screeningSelected, setScreeningSelected] = React.useState('default')
  const inputRef = React.createRef()

  const [configurationProfile, setConfigurationProfile] = React.useState(
    localStorage.getItem('SETTINGS_configuration_profile')
      ? localStorage.getItem('SETTINGS_configuration_profile')
      : 'none',
  )
  const configurations = useSelector((state) => state.configuration.configurations)

  useEffect(() => {
    if (batch) {
      dispatch(fetchScreeningForBatch(batch, true))
    }
  }, [batch, dispatch])

  useEffect(() => {
    if (open && batch && configurations) {
      const cfg = configurations.filter((cfg) => cfg.name === batch.configuration)[0]
      if (cfg) {
        setConfigurationProfile(cfg._id)
      }
    }
  }, [batch, open, configurations])

  const screeningsForBatch = useSelector((state) => state.screening.screenings)

  const onChangeOption = (event) => {
    setOption(event.target.checked ? 'update' : 'replace')
  }
  const onChangeScreening = (event) => {
    setScreeningSelected(event.target.value)
  }
  const handleFileUpload = (event) => {
    console.log(event.target.files[0].name)
    // TODO PLUG THE UPLOAD MECHANISM
  }

  const handleConfirmClose = (event, confirm) => {
    closeFct()
    if (confirm) {
      // isAlreadyRetrieve().then(async (existingNotFinishedTasks) => {
      //   if (existingNotFinishedTasks.size > 0) {
      //     displayPopupMessage(
      // dispatch,
      //         'warning',
      //         `Update batch`,
      //         <Fragment>
      //           <div>This batch is already being updated by another task.</div>
      //           <div>This task is currently {existingNotFinishedTasks.result[0].status}.</div>
      //           <div>Please wait until it is finished before launching a new one.</div>
      //         </Fragment>,
      //       )
      //   } else {
      const config = configurations.filter((conf) => conf._id === configurationProfile)[0]
      const configName = config ? config.name : batch.configuration
      retrieveBatches(dispatch, batch, option, screeningSelected, configName).then(
        async (response) => {
          console.log(response)
        },
      )
      //   }
      // })
    }
  }

  const onChangeScreeningMode = (event) => {
    setDisplayAll(!event.target.checked)
    dispatch(fetchScreeningForBatch(batch, event.target.checked))
  }
  const handleConfigurationProfileChanged = (event) => {
    const value = event.target.value
    setConfigurationProfile(value)
  }

  return (
    <Dialog
      open={open}
      onClose={(event) => handleConfirmClose(event, false)}
      TransitionComponent={TransitionTop}
      aria-labelledby="dialog-reset-title"
    >
      <StyledDialogTitle>{t('view.dialogs.updateBatch.title')}</StyledDialogTitle>
      <DialogContent style={{ maxWidth: 520 }}>
        {batch && batch.operator && !batch.operator.every((op) => op.type !== 'validation') && (
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            className={classes.warningInfo}
          >
            <WarningIcon />
            <span style={{ marginLeft: 20 }}>{t('view.dialogs.updateBatch.valid_performed')}</span>
          </Grid>
        )}
        <DialogContentText>{t('view.dialogs.updateBatch.confirm_update')}</DialogContentText>
        <Grid
          component="label"
          container
          alignItems="center"
          spacing={1}
          style={{ margin: '10px 10px 0px' }}
        >
          <Grid item className={classes.optionLabel}>
            {t('view.dialogs.updateBatch.mode')}
          </Grid>
          <Grid item className={option === 'replace' ? classes.optionSelected : null}>
            {t('view.dialogs.updateBatch.replace')}
          </Grid>
          <Grid item>
            <Switch
              checked={option === 'update'}
              color="default"
              inputProps={{ 'aria-label': 'checkbox with default color' }}
              onChange={(event) => onChangeOption(event)}
            />
          </Grid>
          <Grid item className={option === 'update' ? classes.optionSelected : null}>
            {t('view.dialogs.updateBatch.update')}
          </Grid>
        </Grid>
        {option === 'replace' && (
          <Fragment>
            <Grid
              component="label"
              container
              alignItems="center"
              spacing={1}
              style={{ margin: '0px 10px 30px' }}
            >
              <Grid item className={classes.optionLabel}>
                {t('view.dialogs.updateBatch.screening')}
              </Grid>
              <Grid item className={displayAll ? classes.optionSelected : null}>
                {t('view.dialogs.updateBatch.all')}
              </Grid>
              <Grid item>
                <Switch
                  checked={!displayAll}
                  color="default"
                  inputProps={{ 'aria-label': 'checkbox with default color' }}
                  onChange={(event) => onChangeScreeningMode(event)}
                />
              </Grid>
              <Grid item className={displayAll ? null : classes.optionSelected}>
                {t('view.dialogs.updateBatch.last')}
              </Grid>
              <Grid item xs={12} style={{ marginLeft: 100 }}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <Select
                    value={screeningSelected}
                    name={'priority'}
                    onChange={(event) => onChangeScreening(event)}
                    displayEmpty
                    autoWidth
                    inputProps={{ 'aria-label': 'Screening_File' }}
                  >
                    <MenuItem key={'default'} value={'default'}>
                      {t('view.dialogs.updateBatch.default')}
                    </MenuItem>
                    {screeningsForBatch &&
                      screeningsForBatch.map((screening) => (
                        <MenuItem key={screening._id} value={screening._id}>
                          {screening.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              component="label"
              container
              alignItems="flex-start"
              spacing={1}
              style={{ margin: '0px 10px 6px' }}
            >
              <Grid item className={classes.optionLabel}>
                {t('view.dialogs.updateBatch.config')}
              </Grid>
              <Grid item style={{ marginLeft: 0 }}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <Select
                    variant="outlined"
                    onChange={handleConfigurationProfileChanged}
                    value={configurationProfile}
                  >
                    <MenuItem key={'none'} value={'none'}>
                      {t('view.dialogs.updateBatch.none')}
                    </MenuItem>
                    {configurations.map((conf) => (
                      <MenuItem key={conf.name} value={conf._id}>
                        {conf.name}{' '}
                        {conf._id === localStorage.getItem('SETTINGS_configuration_profile')
                          ? ' (' + t('view.dialogs.updateBatch.current') + ')'
                          : ''}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Fragment>
        )}
        <i>
          <div style={{ marginTop: 20 }}>{t('view.dialogs.updateBatch.time')}</div>
        </i>
      </DialogContent>
      <DialogActions>
        <Button onClick={(event) => handleConfirmClose(event, true)} color="primary">
          {t('view.dialogs.updateBatch.yes')}
        </Button>
        <Button autoFocus onClick={(event) => handleConfirmClose(event, false)} color="primary">
          {t('view.dialogs.updateBatch.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
