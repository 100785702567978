import React, { Fragment, Suspense, useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { alpha, makeStyles } from '@material-ui/core'
import { PUBLIC_URL } from '../../utils/config'
import { withRouter } from 'react-router-dom'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import CardMedia from '@material-ui/core/CardMedia'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import WarningIcon from '@material-ui/icons/Warning'
import DialogActions from '@material-ui/core/DialogActions'
import DeleteIcon from '@material-ui/icons/Delete'
import { displayPopupMessage } from '../common/Message'
import Loading from '../Loading'
import { TransitionBottom } from '../common/Style'
import Box from '@mui/material/Box'
import { t } from 'i18next'
import { TextField } from '@mui/material'
import Button from '@mui/material/Button'

const useStyles = makeStyles((theme) => ({
  header: {
    boxShadow: 'inset 0px 0px 82px 10px rgba(0,0,0,1)',
  },
  cardImg: {
    opacity: 0.8,
  },
  titleCard: {
    position: 'absolute',
    top: 55,
    left: 34,
    fontSize: 40,
    color: theme.palette.secondary.main,
    textShadow: '2px 1px 10px white',
  },
  warningIcon: {
    color: theme.palette.common.warning,
    verticalAlign: 'sub',
    marginRight: 10,
  },
  textareaPreferences: {
    marginBottom: 15,
    '& textarea': {
      fontFamily: 'monospace',
      whiteSpace: 'nowrap',
      overflow: 'auto !important',
    },
    '& .MuiInputBase-multiline': {
      padding: '18.5px 14px',
      color: 'white',
      background: 'black',
    },
  },
  deletePrefsButton: {
    color: 'white',
    background: theme.palette.common.error,
    '&:hover': {
      backgroundColor: '#a10404',
    },
  },
}))

const UserPreferencesDialog = withRouter((props) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { open, closeUserPrefs } = props
  const userPreferencesRef = useRef(null)

  const [userPreferences, setUserPreferences] = React.useState(localStorage)

  useEffect(() => {
    if (open) {
      setUserPreferences(localStorage)
    }
  }, [open])

  const cleanUserInfos = () => {
    Object.keys(localStorage).forEach(function (key) {
      if (
        key !== 'SESSION_user_name' &&
        key !== 'SESSION_user_json' &&
        key.indexOf('__config.json__') === -1
      ) {
        localStorage.removeItem(key)
      }
    })
    displayPopupMessage(
      dispatch,
      'info',
      t('view.dashboardToolbar.preference_clean.title'),
      t('view.dashboardToolbar.preference_clean.msg'),
    )
    setOpenUser(false)
  }

  const handleCloseUser = (event, save) => {
    if (save) {
      Object.keys(localStorage).forEach(function (key) {
        if (
          key !== 'SESSION_user_name' &&
          key !== 'SESSION_user_json' &&
          key.indexOf('__config.json__') === -1
        ) {
          localStorage.removeItem(key)
        }
      })
      const preferences = userPreferencesRef.current.children[0].children[0].value
        .split('\n')
        .reduce(function (map, obj) {
          map[obj.split(':')[0]] = obj.split(':')[1]
          return map
        }, {})

      Object.entries(preferences).forEach((preference) =>
        localStorage.setItem(preference[0], preference[1]),
      )
      displayPopupMessage(
        dispatch,
        'info',
        t('view.dashboardToolbar.pref_saved.title'),
        <Fragment>{t('view.dashboardToolbar.pref_saved.msg')}</Fragment>,
      )
      window.location.reload()
    }
    closeUserPrefs(false)
  }

  return (
    <Suspense fallback={<Loading mode="miniCircle" />}>
      <Dialog
        open={open}
        onClose={(event) => handleCloseUser(event, false)}
        TransitionComponent={TransitionBottom}
        aria-labelledby="dialog-title-user"
        fullWidth
        maxWidth={'lg'}
      >
        <DialogTitle style={{ padding: 0 }}>
          <Box className={classes.header}>
            <CardMedia
              className={classes.cardImg}
              component="img"
              alt="header image"
              height="130"
              image={PUBLIC_URL + '/images/user_preferences.jpg'}
              title="Header image"
            />
            <span className={classes.titleCard}>
              {t('view.dashboardToolbar.dialogs.preferences.title')}
            </span>
          </Box>
        </DialogTitle>
        <DialogContent style={{ marginBottom: 20 }}>
          <DialogContentText style={{ paddingTop: 15 }}>
            {t('view.dashboardToolbar.dialogs.preferences.msg1')}
          </DialogContentText>
          <DialogContentText style={{ paddingBottom: 10 }}>
            <WarningIcon className={classes.warningIcon} />
            {t('view.dashboardToolbar.dialogs.preferences.msg2')}
          </DialogContentText>
          <TextField
            id="preferences"
            // label="Preferences"
            variant="outlined"
            fullWidth
            multiline
            defaultValue={(function () {
              let preferencesLoaded = []
              Object.entries(userPreferences).forEach(function (preference) {
                if (
                  preference[0] !== 'SESSION_user_name' &&
                  preference[0] !== 'SESSION_user_json' &&
                  preference[0].indexOf('__config.json__') === -1
                ) {
                  preferencesLoaded.push(preference[0] + ':' + preference[1])
                }
              })
              preferencesLoaded = preferencesLoaded.sort()
              return preferencesLoaded.join('\n')
            })()}
            required
            minRows={15}
            className={classes.textareaPreferences}
            ref={userPreferencesRef}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            className={classes.deletePrefsButton}
            startIcon={<DeleteIcon />}
            onClick={cleanUserInfos}
          >
            {t('view.dashboardToolbar.dialogs.preferences.clear')}
          </Button>
          <div style={{ width: 'calc(100% - 362px)' }}></div>
          <Button autoFocus onClick={(event) => handleCloseUser(event, true)} color="primary">
            {t('view.dashboardToolbar.dialogs.preferences.save')}
          </Button>
          <Button onClick={(event) => handleCloseUser(event, false)} color="primary">
            {t('view.dashboardToolbar.dialogs.preferences.close')}
          </Button>
        </DialogActions>
      </Dialog>
    </Suspense>
  )
})
export default UserPreferencesDialog
