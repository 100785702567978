import { fetchChangeSettings } from '../settings/actions'
import { clearCache, updateCache } from './reducers'
import { openDatabase, STORE_NAME } from '../indexedDbUtils'
import { API_BO_URL, FLASK_URL } from '../../utils/config'

async function getUpdatedAndPresentIds(array) {
  // Open the IndexedDB
  const db = await openDatabase()
  const transaction = db.transaction([STORE_NAME], 'readonly')
  const store = transaction.objectStore(STORE_NAME)

  // Initialize empty arrays for to_get and already_present
  const toGet = []
  const alreadyPresent = []

  for (const item of array) {
    const { _id, modificationDate } = item
    const arrayDate = new Date(modificationDate)

    // Check if the id is in the store
    const getRequest = store.get(_id)
    const existingItem = await new Promise((resolve, reject) => {
      getRequest.onsuccess = function (event) {
        resolve(event.target.result)
      }
      getRequest.onerror = function (event) {
        reject(event.target.error)
      }
    })

    if (existingItem === undefined) {
      toGet.push(_id)
    } else {
      const dictDate = new Date(existingItem.modificationDate)

      if (arrayDate > dictDate) {
        toGet.push(_id)
      } else {
        alreadyPresent.push(existingItem)
      }
    }
  }

  // Return an object with to_get and already_present fields
  return {
    to_get: toGet,
    already_present: alreadyPresent,
  }
}

function getSorterFromUrl(url) {
  const urlObj = new URL(url)
  const params = urlObj.searchParams

  // Extraire les paramètres de tri et de direction
  const sortField = params.get('sort')
  const sortDir = params.get('dir')

  // Vérifier si les paramètres de tri et de direction existent
  if (!sortField || !sortDir) {
    return null // Retourner null si les paramètres de tri et de direction ne sont pas trouvés
  }
  // Retourner l'objet filtre
  return {
    sortField: sortField,
    sortDir: sortDir,
  }
}

function sortObjects(objects, sortField, sortDir) {
  return objects.sort((a, b) => {
    if (sortDir === 'ASC') {
      return a[sortField] > b[sortField] ? 1 : -1
    } else if (sortDir === 'DESC') {
      return a[sortField] < b[sortField] ? 1 : -1
    } else {
      return 0 // En cas de direction inconnue, ne pas trier
    }
  })
}

function responseTreatment(response) {
  if (!response.ok) {
    const statusText = response.statusText
    const status = response.status
    const url = response.url
    return response.text().then((errorMessage) => {
      const error = new Error(`${statusText} : ${errorMessage}`)
      if (response.headers.get('content-type') === 'application/json') {
        error.stack = JSON.stringify(
          JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
          null,
          2,
        )
      } else {
        error.stack = new Error().stack
      }
      error.statusText = statusText
      error.status = status
      error.url = url
      throw error
    })
  }
  return response.json()
}

export function fetchCachePopulate_old(url) {
  return async (dispatch, getState) => {
    // const { cache } = getState()
    dispatch(requestCacheUpdate())

    const requestOptions = {
      method: 'GET',
      credentials: 'include',
    }

    const includes = 'include=' + ['_id', 'modificationDate'].join('&include=')
    const include_url = url.indexOf('/?') !== -1 ? '&' + includes : '/?' + includes

    try {
      // Get all the UA ids object
      const response1 = await fetch(url + include_url, requestOptions)
      const data1 = await responseTreatment(response1)
      // Separate the UA already in cache and the others
      const all_uas_to_download = await getUpdatedAndPresentIds(data1.result)
      let finalResp = all_uas_to_download.already_present

      // If some UA need to be downloaded because no present in cache
      if (all_uas_to_download.to_get.length > 0) {
        // Request with GET/HEAD method cannot have body -> Use POST
        // Get the UAs object not present in cache
        const response2 = await fetch(`${FLASK_URL()}/unitary_analysis`, {
          ...requestOptions,
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify([
            {
              property: '_id',
              value: all_uas_to_download.to_get,
              filterType: 'array',
              operator: 'in',
            },
          ]),
        })
        // const response2 = await fetch(url, {
        //   ...requestOptions,
        //   method: 'GET',
        //   credentials: 'include',
        // })

        const data2 = await responseTreatment(response2)
        dispatch(updateCache(data2))
        // Combine the UAs from the cache and received
        finalResp = all_uas_to_download.already_present.concat(data2)
      }
      // Apply the sorter if present in URL
      const sorter = getSorterFromUrl(url)
      if (sorter) {
        return sortObjects(finalResp, sorter.sortField, sorter.sortDir)
      }
      // Return the list of UAs
      return finalResp
    } catch (error) {
      const authenticationError = error.status === 403
      if (authenticationError) {
        dispatch(fetchChangeSettings('loginPopup', true))
      }
      throw error // Rejeter la promise avec l'erreur capturée
    } finally {
      dispatch(receiveCacheUpdate())
    }
  }
}

const locks = new Map() // Map pour stocker les locks basés sur l'URL
export function fetchCachePopulate(url) {
  return async (dispatch, getState) => {
    // Check if a lock already exists for this URL
    if (!locks.has(url)) {
      // If there is no lock, we create one with an already resolved Promise
      locks.set(url, Promise.resolve())
    }

    // Retrieve the current Promise (previous lock) for this URL
    const previousLock = locks.get(url)

    // Create a new Promise that will execute after the previous lock
    const currentLock = (async () => {
      await previousLock // Attendez que le lock précédent soit terminé

      try {
        // Trigger the action to indicate the start of the cache update
        dispatch(requestCacheUpdate())

        // The logic of data retrieval and cache update
        const requestOptions = {
          method: 'GET',
          credentials: 'include',
        }

        const includes = 'include=' + ['_id', 'modificationDate'].join('&include=')
        const include_url = url.indexOf('/?') !== -1 ? '&' + includes : '/?' + includes

        // Retrieving data via the first query
        const response1 = await fetch(url + include_url, requestOptions)
        let data1 = await responseTreatment(response1)
        if (
          !data1.hasOwnProperty('size') &&
          !data1.hasOwnProperty('result') &&
          typeof data1 === 'object'
        ) {
          // It's because the request return just an UA, not an array of UA
          // Typically on the global view UA detail
          data1 = { result: [data1], size: 1 }
        }
        const all_uas_to_download = await getUpdatedAndPresentIds(data1.result)
        let finalResp = all_uas_to_download.already_present

        // If some objects are not in the cache, retrieve them
        if (all_uas_to_download.to_get.length > 0) {
          const response2 = await fetch(`${FLASK_URL()}/unitary_analysis`, {
            ...requestOptions,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify([
              {
                property: '_id',
                value: all_uas_to_download.to_get,
                filterType: 'array',
                operator: 'in',
              },
            ]),
          })
          const data2 = await responseTreatment(response2)
          dispatch(updateCache(data2))
          finalResp = all_uas_to_download.already_present.concat(data2)
        }

        // Apply sorting if necessary
        const sorter = getSorterFromUrl(url)
        if (sorter) {
          return sortObjects(finalResp, sorter.sortField, sorter.sortDir)
        }

        // Return the final list of objects
        return finalResp
      } catch (error) {
        const authenticationError = error.status === 403
        if (authenticationError) {
          dispatch(fetchChangeSettings('loginPopup', true))
        }
        throw error // Reject promise with captured error
      } finally {
        // Release the lock by restoring a resolved Promise
        locks.set(url, Promise.resolve())
        dispatch(receiveCacheUpdate())
      }
    })()

    // Associate this new lock (Promise) with the URL in the Lock Map
    locks.set(url, currentLock)

    // Return the new Promise to allow other calls to chain to it
    return currentLock
  }
}

function requestCacheUpdate() {
  return {
    type: 'REQUEST_CACHE_UPDATE',
  }
}
function receiveCacheUpdate() {
  return {
    type: 'RECEIVE_CACHE_UPDATE',
  }
}
export function fetchClearCache() {
  return async (dispatch) => {
    dispatch(requestCacheUpdate())
    dispatch(clearCache())
    dispatch(receiveCacheUpdate())
  }
}
