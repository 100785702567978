import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { alpha, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { Rnd } from 'react-rnd'
import Box from '@material-ui/core/Box'
import { fetchChangeSettings } from '../../../redux/settings/actions'
import { fetchAssitantStatus } from '../../../redux/assistant/actions'
import { withRouter } from 'react-router-dom'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import IconButton from '@mui/material/IconButton'
import AIContainer from './AIContainer'
import AIMenu from './AIMenu'
import ErrorBoundaryGuard from '../../ErrorBoundaryGuard'

const useStyles = makeStyles((theme) => ({
  customModal: {
    position: 'fixed',
    backgroundColor: 'white',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    zIndex: 1400,
    borderRadius: '20px 20px 15px 15px',
    width: '100%',
    height: '100%',
    paddingBottom: 40,
  },
  title: {
    cursor: 'move',
    color: theme.palette.primary.main,
  },
  header: {
    // boxShadow: 'inset 0px 0px 82px 10px rgba(0,0,0,1)',
    borderRadius: '15px 15px 0px 0px',
  },
  cardImg: {
    // filter: 'blur(1px)',
    height: 40,
    userSelect: 'none',
    // opacity: 0.5,
    borderRadius: '15px 15px 0px 0px',
    backgroundColor: alpha(theme.palette.primary.main, 1),
    backgroundSize: 'cover',
  },
  titleCard: {
    position: 'absolute',
    top: 13,
    left: '25%',
    width: '50%',
    height: 15,
    fontSize: 40,
    // color: theme.palette.secondary.main,
    backgroundColor: alpha('#FFF', 0.3),
    // textShadow: '2px 1px 10px white',
    borderRadius: 9,
  },
  moreButton: {
    position: 'absolute',
    top: 6,
    right: 14,
    color: alpha('#FFF', 1),
  },
  reduceButton: {
    position: 'absolute',
    top: 6,
    left: 14,
    color: alpha('#FFF', 1),
  },
  rnd: {
    zIndex: 1299,
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

const AIAssistantDialog = withRouter((props) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [sizeAndPos, setSizeAndPos] = useState(() => {
    let sizeAndPosTmp = {
      width: 330,
      height: 580,
      x: window.innerWidth - 330 - 30,
      y: window.innerHeight - 580 - 10,
    }
    if (localStorage.hasOwnProperty('ASSISTANT_size_and_pos')) {
      sizeAndPosTmp = JSON.parse(localStorage.getItem('ASSISTANT_size_and_pos'))
      if (sizeAndPosTmp.x > window.innerWidth - sizeAndPosTmp.width) {
        sizeAndPosTmp = { ...sizeAndPosTmp, x: window.innerWidth - sizeAndPosTmp.width }
      } else if (sizeAndPosTmp.x < 0) {
        sizeAndPosTmp = { ...sizeAndPosTmp, x: 0 }
      }
      if (sizeAndPosTmp.y > window.innerHeight - sizeAndPosTmp.height) {
        sizeAndPosTmp = { ...sizeAndPosTmp, y: window.innerHeight - sizeAndPosTmp.height }
      } else if (sizeAndPosTmp.y < 0) {
        sizeAndPosTmp = { ...sizeAndPosTmp, y: 0 }
      }
    }
    return sizeAndPosTmp
  })

  const open = useSelector(function (state) {
    return state.settings.assistantPopup
  })

  const closeFct = () => {
    dispatch(fetchChangeSettings('assistantPopup', false))
  }

  return (
    <Rnd
      minWidth={180}
      minHeight={240}
      bounds={'window'}
      dragHandleClassName={'modal-handle'}
      className={clsx(classes.rnd, classes.customModal)}
      size={{ width: sizeAndPos.width, height: sizeAndPos.height }}
      position={{ x: sizeAndPos.x, y: sizeAndPos.y }}
      resizeHandleStyles={{
        bottom: {
          cursor: 'n-resize',
        },
        top: {
          cursor: 'n-resize',
        },
        right: {
          cursor: 'e-resize',
        },
        left: {
          cursor: 'e-resize',
        },
      }}
      onDragStop={(e, d) => {
        localStorage.setItem(
          'ASSISTANT_size_and_pos',
          JSON.stringify({
            ...sizeAndPos,
            x: d.x,
            y: d.y,
          }),
        )
        setSizeAndPos((prevState) => ({
          ...prevState,
          x: d.x,
          y: d.y,
        }))
      }}
      onResizeStop={(e, direction, ref, delta, position) => {
        const newWidth = parseInt(ref.style.width.replace('px', ''))
        const newHeight = parseInt(ref.style.height.replace('px', ''))
        setSizeAndPos({
          width: newWidth,
          height: newHeight,
          x: position.x,
          y: position.y,
        })
        localStorage.setItem(
          'ASSISTANT_size_and_pos',
          JSON.stringify({
            width: newWidth,
            height: newHeight,
            x: position.x,
            y: position.y,
          }),
        )
      }}
    >
      <Box className={clsx('modal-handle', classes.title, classes.header)}>
        <div className={classes.cardImg}>
          <IconButton
            onClick={closeFct}
            className={classes.reduceButton}
            aria-label="reduce"
            size="small"
          >
            <KeyboardArrowDownIcon />
          </IconButton>
          <div className={classes.titleCard}></div>
          <AIMenu
            sizeAndPos={sizeAndPos}
            setSizeAndPos={setSizeAndPos}
            buttonClass={classes.moreButton}
          />
        </div>
      </Box>
      <ErrorBoundaryGuard>
        <AIContainer />
      </ErrorBoundaryGuard>
    </Rnd>
  )
})
export default AIAssistantDialog
