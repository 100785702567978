import React, { Fragment, useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import clsx from 'clsx'
import { Paper, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useDropzone } from 'react-dropzone'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import JSZip from 'jszip'
import CompressDialog from './dialog/upload/CompressDialog'
import { FLASK_URL, DATABASE, ZIP_LEVEL, CONFIGURATION_PROFILE, AZURE_ENV } from '../utils/config'
import { displayPopupMessage } from './common/Message'
import * as I18n from 'i18next'
import { fetchChangeSettings } from '../redux/settings/actions'
import { fetchClearTask } from '../redux/tasks/actions'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { alpha } from '@material-ui/core'
import RouterComponent from './RouterComponent'
import ErrorBoundaryGuard from './ErrorBoundaryGuard'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    // height: isMobile ? 'calc(100vh - 100px)' : '100vh',
    background: '#fafafa9c',
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'hidden',
  },
  mainCtn: {
    height: 'calc(100% - 64px)',
    // height: '100%',
    width: '100%',
  },

  footerDiv: {
    width: 0,
    position: 'relative',
    left: 7,
    top: -48,
  },
  // datagrid: {
  //   '& .MuiDataGrid-row': {
  //     cursor: 'pointer',
  //   },
  //   '& .MuiDataGrid-row:nth-child(even):hover': {
  //     backgroundColor: '#0000000F',
  //   },
  //   '& .MuiDataGrid-row:nth-child(odd)': {
  //     backgroundColor: '#00000008',
  //   },
  //   '& .MuiDataGrid-row:nth-child(odd):hover': {
  //     backgroundColor: '#00000012',
  //   },
  // },
  dragArea: {
    height: 'calc(100% - 0px)',
    // borderRadius: 20,
    '& .dropzone:focus': {
      border: 'none',
    },
    '& .dropzone': {
      position: 'absolute',
      width: '100%',
      height: 'calc(100% - 65px)',
      '&:focus': {
        border: 'none',
      },
      // flex: 1,
      // display: 'flex',
      // flexDirection: 'column',
      // alignItems: 'center',
      // border: '2px dashed',
      // borderRadius: 20,
      // backgroundColor: alpha(theme.palette.secondary.main, 0.1),
    },
    '& .dragActive': {
      border: '4px dashed',
      borderRadius: 20,
      backgroundColor: alpha(theme.palette.secondary.main, 0.1),
      background: `radial-gradient(circle, rgba(255,255,255,1) 0%, ${alpha(
        theme.palette.secondary.main,
        0.1,
      )} 100%)`,
    },
  },
  buttonSelect: {
    // padding: 20,
    // background: 'white',
    // borderRadius: 5,
    // boxShadow:
    //   '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
    fontSize: 'x-large',
  },
  zipButton: {
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.04),
    },
  },
  gridView: {
    height: '100%',
  },
  dragActive: {
    border: '4px dashed',
    borderRadius: 20,
    backgroundColor: alpha(theme.palette.secondary.main, 0.1),
    background: `radial-gradient(circle, rgba(255,255,255,1) 0%, ${alpha(
      theme.palette.secondary.main,
      0.1,
    )} 100%)`,
  },
  timeBadge: {
    background: '#f7f7f7',
    border: '2px solid #7b7b7b',
    borderRadius: 30,
    padding: '0px 10px 0px 0px',
    color: '#4a4a4a',
    textTransform: 'lowercase',
  },
  container: {
    // paddingTop: theme.spacing(4),
    // paddingBottom: theme.spacing(4),
    padding: 0,
    // backgroundColor: 'yellow',
    height: 'calc(100% - 0px)',
    // height: 'calc(100% - 64px)',
  },
}))

const DropZone = withRouter((props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const validModes = { on: true, off: true, demo: true }
  const [customDragActive, setCustomDragActive] = React.useState(false)
  const [globalProgress, setGlobalProgress] = React.useState(0)
  const [zippedFiles, setZippedFiles] = React.useState([])
  const [currentFile, setCurrentFile] = React.useState(null)
  const [totalFiles, setTotalFiles] = React.useState(0)
  const [zipLevel, setZipLevel] = React.useState(
    localStorage.getItem('SETTINGS_zip_level')
      ? parseFloat(localStorage.getItem('SETTINGS_zip_level'))
      : ZIP_LEVEL,
  )
  const [startTime, setStartTime] = React.useState(0)
  const [sendMessage, setSendMessage] = React.useState(null)
  const [zipFile, setZipFile] = React.useState(null)
  const [batchName, setBatchName] = React.useState('')
  const [batchDefault, setBatchDefault] = React.useState('')
  const [batchDescription, setBatchDescription] = React.useState('')
  const [batchDevice, setBatchDevice] = React.useState('')
  const [batchDate, setBatchDate] = React.useState(new Date())
  const [configuration, setConfiguration] = React.useState(CONFIGURATION_PROFILE())
  const [aiModeActive, setAiModeActive] = React.useState(
    localStorage.getItem('SETTINGS_aiMode_active')
      ? parseFloat(localStorage.getItem('SETTINGS_aiMode_active'))
      : false,
  )
  const [taskId, setTaskId] = React.useState(null)
  const [uploadProgress, setUploadProgress] = React.useState({
    percentage: 0,
    uploaded: 0,
    total: 0,
  })
  const [xhrRequest, setXhrRequest] = React.useState(null)
  const [config_ai_mode, setConfig_ai_mode] = React.useState('off')

  const clearArray = function (array) {
    while (array.length > 0) {
      array.pop()
    }
  }

  const task = useSelector(function (state) {
    return state.tasks.task
  })

  useEffect(() => {
    if (task) {
      updateCustomMessage(task.status)
    }
  }, [task])

  useEffect(() => {
    localStorage.setItem('SETTINGS_aiMode_active', (!aiModeActive).toString())
  }, [aiModeActive])

  const updateSendMessage = (message) => {
    setSendMessage(message)
    dispatch(fetchChangeSettings('uploadSendMessage', message))
  }
  const updateCustomMessage = (message) => {
    dispatch(fetchChangeSettings('uploadCustomMessage', message))
  }
  const updateGlobalProgress = (progress) => {
    setGlobalProgress(progress)
    dispatch(fetchChangeSettings('uploadGlobalProgress', progress))
  }

  const sendZip = function (zip) {
    let formData = new FormData()
    formData.append('file', zip)
    formData.append('name', batchName)
    formData.append('description', batchDescription)
    formData.append('device', batchDevice)
    formData.append('date', batchDate)

    if (config_ai_mode === 'on') {
      formData.append('ai_mode', aiModeActive.toString())
    }
    formData.append('db_prefix_name', DATABASE())
    formData.append('config_name', configuration)
    updateSendMessage(I18n.t('view.panel.dropzone.sent_in_progress'))
    updateCustomMessage(I18n.t('view.panel.dropzone.sent_in_progress'))

    const errorDisplay = function (msg) {
      displayPopupMessage(
        dispatch,
        'error',
        I18n.t('view.panel.dropzone.send_zip_title'),
        <Fragment>
          <div>{msg}</div>
        </Fragment>,
      )
      setUploadProgress({
        ...uploadProgress,
        percentage: 0,
        uploaded: 0,
        total: zip.size,
      })
      updateSendMessage(I18n.t('view.panel.dropzone.sent_ko'))
      updateCustomMessage(I18n.t('view.panel.dropzone.sent_ko'))
    }

    const xhr = new XMLHttpRequest()
    xhr.addEventListener(
      'load',
      function (e) {
        if (e.target.status === 200) {
          displayPopupMessage(
            dispatch,
            'success',
            I18n.t('view.panel.dropzone.send_zip_title'),
            <Fragment>
              <div>
                {I18n.t('view.panel.dropzone.send_zip_success')}
                {/*<Trans i18nKey="redux.contexts.action.create_msg">*/}
                {/*  The context {createdContext.name} is correctly created*/}
                {/*</Trans>*/}.
              </div>
            </Fragment>,
          )
          updateSendMessage(I18n.t('view.panel.dropzone.sent_done'))
          updateCustomMessage(I18n.t('view.panel.dropzone.sent_done'))
          setTaskId(JSON.parse(e.target.responseText).task_id)
          dispatch(fetchChangeSettings('taskId', JSON.parse(e.target.responseText).task_id))
        } else {
          errorDisplay(e.target.status + ': ' + e.target.statusText)
        }
      },
      false,
    )
    xhr.addEventListener(
      'error',
      function (error) {
        errorDisplay(error.target.status + ': ' + error.target.statusText)
      },
      false,
    )
    xhr.addEventListener(
      'abort',
      function () {
        displayPopupMessage(
          dispatch,
          'warning',
          I18n.t('view.panel.dropzone.send_zip_title'),
          <Fragment>
            <div>{I18n.t('view.panel.dropzone.send_zip_abort')}.</div>
          </Fragment>,
        )
      },
      false,
    )

    xhr.upload.addEventListener(
      'progress',
      function (e) {
        setUploadProgress({
          percentage: (e.loaded / e.total) * 100,
          uploaded: e.loaded,
          total: e.total,
        })
        updateGlobalProgress((e.loaded / e.total) * 100)
      },
      false,
    )
    // Azure Environment Upload: create_task
    // On-Premise Upload: upload_zip
    // xhr.open('POST', `${FLASK_URL()}/${AZURE_ENV ? 'create_task' : 'upload_zip'}`)
    xhr.open('POST', `${FLASK_URL()}/upload_zip`)
    xhr.withCredentials = true
    setXhrRequest(xhr)
    xhr.send(formData)
  }

  useEffect(() => {
    if (batchName !== '' && zipFile !== null) {
      sendZip(zipFile)
    }
  }, [batchName, zipFile])

  const onDrop = useCallback(function (acceptedFiles) {
    const isFolder =
      acceptedFiles.length > 1 && acceptedFiles.every((file) => file.path.indexOf('/') !== -1)
    const isZipsFile =
      acceptedFiles.length > 1 &&
      acceptedFiles.every((file) => ['zip'].indexOf(file.name.split('.').pop()) !== -1)
    const isLcdFiles =
      acceptedFiles.length > 1 &&
      acceptedFiles.every((file) => ['lcd'].indexOf(file.name.split('.').pop()) !== -1)
    const isZipFile =
      acceptedFiles.length === 1 &&
      acceptedFiles.every((file) => ['zip'].indexOf(file.name.split('.').pop()) !== -1)
    if (!isFolder && isZipsFile) {
      dispatch(fetchChangeSettings('uploadAcceptedFiles', []))
      displayPopupMessage(
        dispatch,
        'info',
        I18n.t('view.dialogs.compress.notGoodImport'),
        I18n.t('view.dialogs.compress.notGoodImportMsg2'),
      )
    } else if (isFolder || isZipFile || isLcdFiles) {
      dispatch(fetchChangeSettings('taskId', null))
      dispatch(fetchClearTask())
      const compressionLevel = localStorage.getItem('SETTINGS_zip_level')
        ? parseFloat(localStorage.getItem('SETTINGS_zip_level'))
        : ZIP_LEVEL
      // setStartTime(new Date().getTime())
      setZipFile(null)
      updateSendMessage(null)
      updateCustomMessage(null)
      setTaskId(null)
      setBatchName('')
      setBatchDescription('')
      setZipLevel(compressionLevel)
      setZippedFiles([])
      setCurrentFile(null)
      clearArray(zippedFiles)
      updateGlobalProgress(0)
      dispatch(fetchChangeSettings('uploadOpenCompress', true))
      setTotalFiles(acceptedFiles.length)
      setUploadProgress({
        percentage: 0,
        uploaded: 0,
        total: 0,
      })
      if (isZipFile) {
        setBatchDefault(acceptedFiles[0].name.split('.')[0])
        updateGlobalProgress(100)
        // setCurrentFile(acceptedFiles[0])
        setZippedFiles([acceptedFiles[0].name])
        setZipFile(acceptedFiles[0])
      } else {
        let zip = new JSZip()
        if (Array.from(acceptedFiles[0].path)[0] === '/') {
          // setBatchDefault(
          //   'batch_' +
          //     acceptedFiles[0].path.substring(1).split('/')[0] +
          //     '_' +
          //     new Date().toLocaleDateString().replaceAll('/', '-'),
          // )
          setBatchDefault(acceptedFiles[0].path.substring(1).split('/')[0])
        } else {
          // setBatchDefault('batch_' + new Date().toLocaleDateString().replaceAll('/', '-'))
          setBatchDefault(acceptedFiles[0].path.split('.')[0])
        }
        acceptedFiles.forEach((file) => {
          const path = Array.from(file.path)[0] === '/' ? file.path.substring(1) : file.path
          zip.file(path, file)
        })
        zip
          .generateAsync(
            {
              type: 'blob',
              compression: 'DEFLATE',
              compressionOptions: {
                level: compressionLevel,
              },
            },
            function updateCallback(metadata) {
              if (metadata.percent > 0 && zippedFiles.length === 0) {
                //Set the start time after the uploading
                setStartTime(new Date().getTime())
              }
              updateGlobalProgress(parseFloat(metadata.percent.toFixed(0)))
              const currentSplit = metadata.currentFile ? metadata.currentFile.split('/') : ['']
              const currentFile =
                currentSplit[currentSplit.length - 1] === ''
                  ? null
                  : currentSplit[currentSplit.length - 1]
              if (currentFile && zippedFiles.indexOf(currentFile) === -1) {
                zippedFiles.push(currentFile)
                setZippedFiles(zippedFiles)
                setCurrentFile(currentFile)
              }
            },
          )
          .then(function (content) {
            // const url = window.URL.createObjectURL(new Blob([content]))
            // let link = document.createElement('a')
            // link.href = url
            // link.setAttribute(
            //   'download',
            //   `uploaded-batch_${new Date().toLocaleDateString().replaceAll('/', '_')}.zip`,
            // )
            // document.body.appendChild(link)
            // // Force download
            // link.click()
            // // Clean up and remove the link
            // link.parentNode.removeChild(link)
            const zip = new File(
              [content],
              `uploaded-batch_${new Date()
                .toLocaleString()
                .replaceAll('/', '_')
                .replaceAll(', ', '_')
                .replaceAll(':', '-')}.zip`,
            )
            setZipFile(zip)
            // return zipFile
            // if (batchName !== '') {
            //   sendZip(zip)
            // }
          })
      }
    } else {
      dispatch(fetchChangeSettings('uploadAcceptedFiles', []))
      displayPopupMessage(
        dispatch,
        'info',
        I18n.t('view.dialogs.compress.notGoodImport'),
        I18n.t('view.dialogs.compress.notGoodImportMsg'),
      )
    }
  }, [])

  const { getRootProps, getInputProps, isDragActive, acceptedFiles, open } = useDropzone({
    onDrop,
    noClick: true,
  })

  const remainingTime = useMemo(() => {
    if (currentFile) {
      const timeSpent = new Date().getTime() - startTime
      const realZippedFiles = zippedFiles.filter((f) => f !== currentFile)
      const zippedFilesSize = realZippedFiles
        .map((zf) => acceptedFiles.filter((f) => f.name === zf)[0].size)
        .reduce((a, b) => a + b, 0)
      const zippedFilesSizeRemaining = acceptedFiles
        .filter((f) => realZippedFiles.indexOf(f.name) === -1)
        .map((f) => f.size)
        .reduce((a, b) => a + b, 0)
      const timeForOneOctet = timeSpent / zippedFilesSize
      const timeRemainingInSecond = ((zippedFilesSizeRemaining * timeForOneOctet) / 1000).toFixed(0)
      if (timeRemainingInSecond < 60) {
        return timeRemainingInSecond + t('view.panel.dropzone.timeremaining.seconds')
      } else if (timeRemainingInSecond < 3600) {
        return (
          Math.floor(timeRemainingInSecond / 60) +
          t('view.panel.dropzone.timeremaining.minutes') +
          (timeRemainingInSecond - Math.floor(timeRemainingInSecond / 60) * 60) +
          t('view.panel.dropzone.timeremaining.seconds')
        )
      } else {
        return (
          Math.floor(timeRemainingInSecond / 3600) +
          t('view.panel.dropzone.timeremaining.hours') +
          (timeRemainingInSecond - Math.floor(timeRemainingInSecond / 3600) * 3600) +
          t('view.panel.dropzone.timeremaining.minutes')
        )
      }
      return 0
    } else {
      return null
    }
  }, [zippedFiles.toString()])

  useEffect(() => {
    dispatch(fetchChangeSettings('uploadRemainingTime', remainingTime))
  }, [remainingTime])

  // const [sortModel, setSortModel] = React.useState(
  //   localStorage.getItem('DATAGRID_dropzone_sort')
  //     ? JSON.parse(localStorage.getItem('DATAGRID_dropzone_sort'))
  //     : [],
  // )
  const clickedUpload = useSelector(function (state) {
    return state.settings.clickUpload
  })

  useEffect(() => {
    dispatch(fetchChangeSettings('uploadAcceptedFiles', acceptedFiles))
  }, [JSON.stringify(acceptedFiles)])

  useEffect(() => {
    if (clickedUpload) {
      dispatch(fetchChangeSettings('clickUpload', false))
      open()
    }
  }, [clickedUpload])

  useEffect(() => {
    if (taskId) {
      //Polling task
    }
  }, [taskId])

  // const rows = useMemo(() => {
  //   return acceptedFiles.map((file, index) => ({
  //     id: index,
  //     path: file.path,
  //     name: file.name,
  //     date: file.lastModifiedDate,
  //     size: file.size,
  //   }))
  // }, [JSON.stringify(acceptedFiles)])
  //
  // const columns = [
  //   {
  //     field: 'name',
  //     headerClassName: classes.header,
  //     headerName: t('view.panel.dropzone.header_name.name'),
  //     flex: 3,
  //   },
  //   {
  //     field: 'date',
  //     headerClassName: classes.header,
  //     headerName: t('view.panel.dropzone.header_name.date'),
  //     flex: 2,
  //     valueFormatter: (params) => {
  //       let newDate = new Date(params.value)
  //       return (
  //         newDate.toLocaleDateString().replaceAll('/', '-') + ' ' + newDate.toLocaleTimeString()
  //       )
  //     },
  //   },
  //   {
  //     field: 'size',
  //     headerClassName: classes.header,
  //     headerName: t('view.panel.dropzone.header_name.size'),
  //     flex: 1,
  //     valueFormatter: (params) => {
  //       return humanFileSize(params.value, true)
  //     },
  //   },
  //   {
  //     field: 'actions',
  //     // type: 'actions',
  //     headerClassName: classes.header,
  //     headerName: t('view.panel.dropzone.header_name.status'),
  //     width: 70,
  //     renderCell: function (params) {
  //       let result = <PauseCircleOutlineIcon />
  //       if (params.row.name === currentFile && globalProgress !== '100') {
  //         result = <SettingsIcon style={{ animation: 'rotation 2s infinite linear' }} />
  //       } else if (zippedFiles.indexOf(params.row.name) !== -1) {
  //         result = <CheckCircleIcon />
  //       }
  //       return result
  //     },
  //   },
  // ]

  function onDragEnter(event) {
    event.preventDefault()
    // Prevent the drag if we drag a column from a table or a text from everywhere
    if (event.dataTransfer.types.includes('Files')) {
      setCustomDragActive(true)
    }
  }

  function onDragLeave(event) {
    event.preventDefault()
    setCustomDragActive(false)
  }

  useEffect(() => {
    if (!isDragActive) {
      setCustomDragActive(false)
    }
    dispatch(fetchChangeSettings('uploadIsDragActive', isDragActive))
  }, [isDragActive])

  function onCancel() {
    if (xhrRequest) {
      xhrRequest.abort()
    }
    dispatch(fetchChangeSettings('uploadOpenCompress', false))
    setCustomDragActive(false)
    setGlobalProgress(0)
    setZippedFiles([])
    setCurrentFile(null)
    setTotalFiles(0)
    setZipLevel(
      localStorage.getItem('SETTINGS_zip_level')
        ? parseFloat(localStorage.getItem('SETTINGS_zip_level'))
        : ZIP_LEVEL,
    )
    setStartTime(0)
    setSendMessage(null)
    setZipFile(null)
    setBatchName('')
    setBatchDefault('')
    setBatchDescription('')
    setTaskId(null)
    setUploadProgress({
      percentage: 0,
      uploaded: 0,
      total: 0,
    })
    dispatch(fetchChangeSettings('uploadSendMessage', null))
    dispatch(fetchChangeSettings('uploadRemainingTime', null))
    dispatch(fetchChangeSettings('uploadGlobalProgress', 0))
    dispatch(fetchChangeSettings('uploadCustomMessage', ''))
    dispatch(fetchChangeSettings('uploadAcceptedFiles', []))
  }

  return (
    <Fragment>
      <ErrorBoundaryGuard isDialog>
        <CompressDialog
          globalProgress={globalProgress}
          currentFile={currentFile}
          totalFiles={totalFiles}
          zippedFiles={zippedFiles}
          acceptedFiles={acceptedFiles}
          zipLevel={zipLevel}
          remainingTime={remainingTime}
          sendMessage={sendMessage}
          batchName={batchName}
          batchDefault={batchDefault}
          batchDescription={batchDescription}
          setBatchName={setBatchName}
          setBatchDescription={setBatchDescription}
          zipDone={zipFile !== null}
          uploadProgress={uploadProgress}
          onCancel={onCancel}
          configuration={configuration}
          setConfiguration={setConfiguration}
          aiModeActive={aiModeActive}
          setAiModeActive={setAiModeActive}
          batchDevice={batchDevice}
          setBatchDevice={setBatchDevice}
          batchDate={batchDate}
          setBatchDate={setBatchDate}
          config_ai_mode={config_ai_mode}
          setConfig_ai_mode={setConfig_ai_mode}
        />
      </ErrorBoundaryGuard>
      {/*<div*/}
      {/*  // elevation={3}*/}
      {/*  className={clsx(*/}
      {/*    classes.mainCtn,*/}
      {/*    // openTableDefect ? classes.openTable : classes.closeTable,*/}
      {/*    // pinTable ? classes.pinTable : classes.unpinTable,*/}
      {/*  )}*/}
      {/*>*/}
      {/*  <Stack spacing={0} style={{ height: '100%' }}>*/}
      {/*    <Stack*/}
      {/*      direction="row"*/}
      {/*      justifyContent="space-between"*/}
      {/*      alignItems="center"*/}
      {/*      spacing={2}*/}
      {/*      style={{*/}
      {/*        height: '100%',*/}
      {/*        // padding: 20,*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      <div*/}
      {/*        style={{*/}
      {/*          height: '100%',*/}
      {/*          width: '100%',*/}
      {/*        }}*/}
      {/*      >*/}
      <div className={classes.dragArea}>
        <div
          {...getRootProps({
            className: clsx('dropzone'),
          })}
        >
          <input
            {...getInputProps({
              // webkitdirectory: true,
              // directory: true,
              // mozdirectory: true,
              webkitdirectory: '',
              directory: '',
              mozdirectory: '',
              accept: 'application/zip, .zip, .lcd',
            })}
            // directory=""
            // webkitdirectory=""
            type="file"
            accept="application/zip, .zip, .lcd"
            id={'dropZoneInputCmp'}
          />
        </div>
        <div className={classes.gridView}>
          {!isDragActive && !customDragActive && (
            <div style={{ height: '100%' }} onDragLeave={onDragLeave} onDragEnter={onDragEnter}>
              <ErrorBoundaryGuard style={{ height: 'calc(100% - 64px)', position: 'absolute' }}>
                <RouterComponent />
              </ErrorBoundaryGuard>
            </div>
          )}
          {(isDragActive || customDragActive) && (
            <div style={{ height: '100%', width: '100%', padding: 10 }}>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                style={{ height: '100%' }}
                className={classes.dragActive}
              >
                <CloudUploadIcon
                  style={{
                    fontSize: 80,
                  }}
                />
                <p className={classes.buttonSelect}>{t('view.panel.dropzone.d&d')}</p>
              </Stack>
            </div>
          )}
        </div>
      </div>
      {/*      </div>*/}
      {/*    </Stack>*/}
      {/*  </Stack>*/}
      {/*</div>*/}
    </Fragment>
  )
})
export default DropZone
